import * as React from 'react';
import { useEffect, useState } from 'react';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { InvoiceQueryDto } from 'common/dto/query/InvoiceQueryDto';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { InvoiceApi } from 'client/components/schema/invoice/InvoiceApi';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { TableSticky } from 'client/ui/table/TableSticky';
import { PccExport002Button } from './pcc-action/PccExport002Button';
import { mapInvoiceToExportFilterToOptions } from './mapInvoiceToExportFilterToOptions';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { SorterResult } from 'antd/lib/table/interface';
import { getDateRange } from 'client/ui/table/TableColumnFilter';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { DynamicPccExport003Button } from './pcc-action/DynamicPccExport003Button';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';
import { InvoiceColumnSort } from 'common/dto/query/types/InvoiceQueryTypes';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Tooltip } from 'antd';
import { get } from 'lodash';

interface Params {
  filter?: string;
}

export interface AccountingLinePaperInvoicesListPageProps
  extends RouteComponentProps<Params> {}

/**
 * Ritorna vero se uno dei filtri viene modificato
 * e quindi è necessario il reset delle fatture selezionate
 */
function needSelectionReset(
  oldQuery: InvoiceQueryDto,
  updatedQuery: InvoiceQueryDto
) {
  const keys = [
    'issuedDateFrom',
    'issuedDateTo',
    'expireDateFrom',
    'expireDateTo',
    'issuedNumber',
    'supplierName',
    'protocolNumber',
    'protocolYear'
  ];

  for (const key of keys) {
    if (get(oldQuery, key) != get(updatedQuery, key)) {
      return true;
    }
  }
  return false;
}

/**
 * Pagina di elenco delle fatture da esportare a PCC per ricezione (modello 002)
 */
export function InvoiceToExportList(
  props: AccountingLinePaperInvoicesListPageProps
) {
  const { match } = props;
  const [options, setOptions] = useState(
    mapInvoiceToExportFilterToOptions(match.params.filter)
  );

  const [query, setQuery] = useState<InvoiceQueryDto>(
    new InvoiceQueryDto(
      getPaginationUrlQuery(props.location.search, {
        pageNumber: 1,
        pageSize: 20,
        ...options?.query
      })
    )
  );

  const [selected, setSelected] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const { response, loading, error } = useApiQuery(InvoiceApi.list, {
    data: { query }
  });
  const invoices = response?.data?.items;

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: options?.title,
    sideMenuKey: options?.sideMenuKey,
    menuKey: 'accounting'
  });

  // Aggiorno le opzioni
  useEffect(() => {
    setOptions(mapInvoiceToExportFilterToOptions(match.params.filter));
  }, [match, options?.filter]);

  useEffect(() => {
    setQuery(
      new InvoiceQueryDto(
        getPaginationUrlQuery(props.location.search, {
          pageNumber: 1,
          pageSize: 20,
          ...options?.query
        })
      )
    );
  }, [options]);

  if (error || !options) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco delle fatture"
        error={error}
      />
    );
  }

  const columns = getPaginationUrlColumns<InvoiceDto, InvoiceQueryDto>(
    options!.columns,
    query,
    {
      protocol: ['protocolNumber', 'protocolYear'],
      expiresAt: ['expireDateFrom', 'expireDateTo'],
      issuedDate: ['issuedDateFrom', 'issuedDateTo']
    }
  );

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle children={options.title} />}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={[
          <Tooltip title="Seleziona le linee contabili su tutte le pagine rispettando i filtri impostati">
            <Checkbox
              value={selectAll}
              onChange={event => {
                setSelectAll(event.target.checked);
              }}
            >
              Seleziona tutti
            </Checkbox>
          </Tooltip>,
          options.modelActionRequest ? (
            <DynamicPccExport003Button
              action={options.modelActionRequest}
              items={selected}
              query={query}
              selectAll={selectAll}
            />
          ) : (
            <PccExport002Button
              text="Genera CSV Ricezione"
              invoices={selected}
              query={query}
              selectAll={selectAll}
            />
          )
        ]}
      />
      <PageContent noPadding noSpace>
        <TableSticky
          rowSelection={
            selectAll
              ? undefined
              : {
                  hideSelectAll: true,
                  selectedRowKeys: selected,
                  onSelect: (record, selection) => {
                    if (selection) {
                      setSelected([...selected, record.id]);
                    } else {
                      setSelected(selected.filter(id => id !== record.id));
                    }
                  }
                }
          }
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={invoices}
          size="middle"
          onChange={(pagination, filters, sorter) => {
            const { field, order } = sorter as SorterResult<InvoiceDto>;

            // Date
            const issuedDateRange = getDateRange(filters.issuedDate);
            const expirationDateRange = getDateRange(filters.expiresAt);

            // Fornitore
            const supplierName = filters['supplierName']
              ? filters['supplierName'][0].toString()
              : null;

            // Numero Fattura
            const issuedNumber = filters.issuedNumber
              ? filters.issuedNumber[0].toString()
              : null;

            // Protocol
            const protocolNumber =
              filters.protocol && filters.protocol[0]
                ? filters.protocol[0].toString()
                : null;
            const protocolYear =
              filters.protocol && filters.protocol[1]
                ? filters.protocol[1].toString()
                : null;

            const invoiceQuery = new InvoiceQueryDto({
              ...query,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize,
              orderBy: order
                ? (field?.toString() as InvoiceColumnSort)
                : undefined,
              orderByDirection: order ? ColumnSortDirections[order] : undefined,
              issuedDateFrom: issuedDateRange.from,
              issuedDateTo: issuedDateRange.to,
              expireDateFrom: expirationDateRange.from,
              expireDateTo: expirationDateRange.to,
              issuedNumber,
              supplierName,
              protocolNumber,
              protocolYear
            });

            // Resetta la selezione solo se uno dei seguenti filtri viene aggiornato
            if (needSelectionReset(query, invoiceQuery)) {
              setSelected([]);
            }

            setPaginationUrlQuery(props, invoiceQuery);
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
