import * as React from 'react';
import { Button, Table, Tag, message } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { MandateSiabLinkModal } from 'client/components/schema/mandate/create/MandateSiabLinkModal';
import { Link, RouteComponentProps } from 'react-router-dom';
import { MandatesFilterableColumns } from 'client/components/schema/mandate/table/MandatesColumns';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { useEffect, useState } from 'react';
import { MandateQueryDto } from 'common/dto/query/MandateQueryDto';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { MandateApi } from 'client/components/schema/mandate/MandateApi';
import { MandateDto } from 'common/dto/generated/MandateDto';
import { SorterResult } from 'antd/lib/table/interface';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { mapMandateFilterToOptions } from './mapMandateFilterToOptions';
import { TableSticky } from 'client/ui/table/TableSticky';
import { MandateSiabImportButton } from 'client/components/schema/mandate/import/MandateSiabImportButton';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';

interface Params {
  filter?: string;
}

export interface MandatesListPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di elenco dei Mandati di Pagamento
 */
export function MandatesListPage(props: MandatesListPageProps) {
  const { match } = props;

  const [options, setOptions] = useState(
    mapMandateFilterToOptions(match.params.filter)
  );
  const [query, setQuery] = useState<MandateQueryDto>(
    new MandateQueryDto(
      getPaginationUrlQuery(props.location.search, {
        state: options.query.state,
        pageNumber: 1,
        pageSize: 20
      })
    )
  );

  const { response, loading, error } = useApiQuery(MandateApi.list, {
    data: { query }
  });

  const mandates = response?.data.items;

  const columns = getPaginationUrlColumns<MandateDto, MandateQueryDto>(
    MandatesFilterableColumns,
    query,
    {}
  );

  useEffect(() => {
    setQuery(
      new MandateQueryDto(
        getPaginationUrlQuery(props.location.search, {
          state: options.query.state,
          pageNumber: 1,
          pageSize: 20
        })
      )
    );
  }, [options]);

  // Aggiorno le opzioni
  useEffect(() => {
    const updatedOptions = mapMandateFilterToOptions(match.params.filter);
    setOptions(updatedOptions);
  }, [match, options.filter]);

  // Breadcrumbs
  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/shipping/mandates/all', title: `Mandati` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: options.title,
    menuKey: 'mandates',
    sideMenuKey: 'mandates-' + options.filter
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco dei mandati"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle children={options.title} />}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        subTitle={options.subtitle}
        extra={
          <>
            <MandateSiabLinkModal />
            <MandateSiabImportButton />
          </>
        }
      />
      <PageContent noPadding noSpace>
        <TableSticky
          columns={columns}
          rowKey="id"
          loading={loading}
          dataSource={mandates}
          size="middle"
          onRow={(record: MandateDto) => ({
            style: {
              cursor: 'pointer'
            },
            onClick: () => {
              props.history.push(
                `/shipping/mandates/${options.filter}/${record.id}`
              );
            }
          })}
          onChange={async (pagination, filters, sorter) => {
            const { field, order } = sorter as SorterResult<MandateDto>;

            const sortField = (field as unknown) as any;
            const mandateNumber =
              filters.mandateNumber && filters.mandateNumber.length > 0
                ? (filters.mandateNumber[0] as any)
                : undefined;
            const mandateYear =
              filters.mandateYear && filters.mandateYear.length > 0
                ? (filters.mandateYear[0] as any)
                : undefined;
            const beneficiary =
              filters.beneficiary && filters.beneficiary.length > 0
                ? filters.beneficiary[0].toString()
                : undefined;

            const newQuery = new MandateQueryDto({
              ...query,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize,
              orderBy: order ? field?.toString() : 'id',
              orderByDirection: order
                ? ColumnSortDirections[order]
                : ColumnSortDirections.descend,
              mandateNumber,
              mandateYear,
              beneficiary
            });

            try {
              setPaginationUrlQuery(props, await newQuery.validate());
            } catch (e) {
              message.error(e.message);
            }
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
