import { ArrowRightOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { getInvoiceExportDownloadLink } from 'client/routes/invoicing/invoices/export/getInvoiceExportDownloadLink';
import { ISODate } from 'client/ui/display/date/ISODate';
import { AnchorAPILink } from 'client/ui/link/AnchorAPILink';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { InvoiceExportDto } from 'common/dto/generated/InvoiceExportDto';
import React from 'react';
import { Link } from 'react-router-dom';

export const InvoiceExportColumns: ColumnsType<InvoiceExportDto> = [
  {
    title: 'Data creazione',
    dataIndex: 'createdAt',
    render: (_, record) => <ISODate date={record.createdAt} />,
    width: 150,
    sorter: true
  },
  {
    title: 'File',
    dataIndex: 'fileName',
    render: (_, record) => (
      <AnchorAPILink href={getInvoiceExportDownloadLink(record.id)}>
        {record.fileName}
      </AnchorAPILink>
    )
  },
  {
    title: 'Autore',
    render: (_, record) => record.author?.name ?? 'N.A.',
    width: 200
  },
  {
    title: 'Data inizio',
    dataIndex: 'fromDate',
    width: 130,
    render: (_, record) => <ISODate date={record.fromDate} />,
    ...createColumnFilter(FilterInputType.DateRange),
    sorter: true
  },
  {
    title: 'Data fine',
    dataIndex: 'toDate',
    width: 130,
    render: (_, record) => <ISODate date={record.toDate} />,
    ...createColumnFilter(FilterInputType.DateRange),
    sorter: true
  },

  {
    title: '',
    align: 'right',
    width: 80,
    render: (_, record) => (
      <Link to={`/invoicing/exports/${record.id}`}>
        Vai <ArrowRightOutlined />
      </Link>
    )
  }
];
