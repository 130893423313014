import {
  CheckOutlined,
  CloseOutlined,
  ExclamationOutlined
} from '@ant-design/icons';
import { DoubleTag, TooltipTagProps } from 'client/ui/tag/DoubleTag';
import {
  AcceptanceState,
  InvoiceState
} from 'common/schema/invoice/InvoiceTypes';
import * as React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

export interface IInvoiceStateTagProps {
  state: InvoiceState;
  acceptance: AcceptanceState;
}

function acceptanceToProps(acceptance: AcceptanceState): TooltipTagProps {
  switch (acceptance) {
    case AcceptanceState.Accepted:
      return {
        color: 'green',
        children: <CheckOutlined />,
        visible: false
      };
    case AcceptanceState.Rejected:
      return {
        color: 'red',
        children: <CloseOutlined />,
        visible: false
      };
    case AcceptanceState.Indeterminate:
      return {
        children: <ExclamationOutlined />,
        color: 'orange',
        tooltipMessage: 'Da Accettare/Rifiutare',
        visible: false
      };
  }
}

function stateToProps(state: InvoiceState): TooltipTagProps {
  switch (state) {
    case InvoiceState.ToValidate:
      return { color: 'cyan', children: 'Da Associare' };
    case InvoiceState.Validated:
      return { color: 'blue', children: 'Da Esitare|Protoc.' };
    case InvoiceState.ToAccount:
      return { color: 'purple', children: 'Da Contabilizzare' };
    case InvoiceState.Rejected:
      return { color: 'red', children: 'Rifiutata' };
    case InvoiceState.Accounted:
      return { color: 'geekblue', children: 'Contabilizzata' };
    case InvoiceState.PaidPartial:
      return { color: 'lime', children: 'Pagata Parz.' };
    case InvoiceState.Paid:
      return { color: 'green', children: 'Pagata' };
  }
  assertNever(state);
}

/**
 * Visualizzazione come Tag dello stato della fattura
 */
export function InvoiceStateTag(props: IInvoiceStateTagProps) {
  return (
    <DoubleTag
      left={stateToProps(props.state)}
      right={acceptanceToProps(props.acceptance)}
    />
  );
}
