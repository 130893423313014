import { ArrowRightOutlined } from '@ant-design/icons';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { InvoiceCommunicationColumns } from 'client/components/schema/invoice/InvoiceCommunicationColumns';
import { InvoiceAllColumns } from 'client/components/schema/invoice/table/InvoiceAllColumns';
import { InvoiceIsPaperColumns } from 'client/components/schema/invoice/table/InvoiceIsPaperColumns';
import { createTypedColumns } from 'client/ui/table/createTypedColumns';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceQueryDto } from 'common/dto/query/InvoiceQueryDto';
import React from 'react';
import { Link } from 'react-router-dom';
import { Model003Action } from 'server/schema/pcc/model003/Model003Types';
import { assertNever } from 'server/utils/typings/assertNever';

function addLinkActionToColumn(columns: ColumnsType<InvoiceDto>) {
  const typed = createTypedColumns(InvoiceDto, {
    actions: {
      title: '',
      align: 'right',
      width: 55,
      fixed: 'right',
      render: (_, record) => (
        <Link to={`/invoicing/invoices/all/${record.id}`}>
          Vai <ArrowRightOutlined />
        </Link>
      )
    }
  });

  return [...columns, typed.actions];
}

interface IInvoiceToExportOption {
  title: string;
  columns: ColumnsType<InvoiceDto>;
  query: Partial<InvoiceQueryDto>;
  filter: string;
  selectable?: boolean;
  modelActionRequest: Model003Action | null;
  sideMenuKey: string;
}

export function mapInvoiceToExportFilterToOptions(
  filter: string | undefined
): IInvoiceToExportOption | undefined {
  switch (filter) {
    case 'reception':
      return {
        filter,
        title: 'Fatture da Comunicare Ricezione a PCC',
        columns: addLinkActionToColumn(InvoiceIsPaperColumns),
        query: {
          paper: true,
          needsPccReception: true
        },
        modelActionRequest: null,
        sideMenuKey: 'to-ship-reception'
      };
    case 'accounting':
      return {
        filter,
        title: 'Fatture da Comunicare Contabilizzazione a PCC',
        columns: addLinkActionToColumn(InvoiceCommunicationColumns),
        query: {
          needsPccAccounting: true
        },
        modelActionRequest: Model003Action.CO,
        sideMenuKey: 'to-ship-accounting'
      };
    case 'expiration':
      return {
        filter,
        title: 'Fatture da Comunicare Scadenza a PCC',
        columns: addLinkActionToColumn(InvoiceCommunicationColumns),
        query: {
          needsPccExpiration: true
        },
        modelActionRequest: Model003Action.CS,
        sideMenuKey: 'to-ship-expiration'
      };
  }
}
