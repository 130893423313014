import React from 'react';
import clx from 'classnames';
import { Menu } from 'antd';
const { SubMenu } = Menu;
import { useThemeConfig } from 'client/components/schema/config/ClientConfigModule';
import { FileSyncOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useCurrentSideMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';

export interface ShippingSideMenuProps {
  classNames?: string;
}

/**
 * Menu laterale della sezione Spedizioni UBRRAC.
 */
export function ShippingSideMenu(props: ShippingSideMenuProps) {
  const { options } = useThemeConfig();
  const currentPathKey = useCurrentSideMenuKey();

  return (
    <Menu
      className={props.classNames}
      mode="inline"
      selectedKeys={currentPathKey}
      defaultOpenKeys={['mandates', 'shipping']}
    >
      <SubMenu key="shipping" title="Spedizioni UBRRAC" icon={<MailOutlined />}>
        <Menu.Item key="shipment-all">
          <Link to="/shipping/all">Tutte</Link>
        </Menu.Item>
        <Menu.Item key="shipment-in-progress">
          <Link to="/shipping/in-progress">In Lavorazione</Link>
        </Menu.Item>
        <Menu.Item key="shipment-shipped">
          <Link to="/shipping/shipped">Inviate</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
}
