import { ColumnsType } from 'antd/lib/table';
import { createInvoiceProtocolColumnFilter } from 'client/components/schema/invoice/table/createInvoiceProtocolColumnFilter';
import { InvoiceColumns } from 'client/components/schema/invoice/table/InvoiceColumns';
import { InvoiceProtocolName } from 'client/components/schema/invoice/view/InvoiceProtocolName';
import { ISODate } from 'client/ui/display/date/ISODate';
import { EllipsisTooltip } from 'client/ui/ellipsis/EllipsisTooltip';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { EconomicStatementSimulationItemDto } from 'common/dto/generated/EconomicStatementSimulationItemDto';
import React from 'react';
import { Link } from 'react-router-dom';

export const EconomicSimulationItemColumns: ColumnsType<EconomicStatementSimulationItemDto> = [
  {
    title: 'Fattura n.',
    key: 'invoiceNumber',
    ellipsis: { showTitle: false },
    width: 120,
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => (
      <EllipsisTooltip children={record.invoice.issuedNumber} />
    )
  },
  {
    title: 'Protocollo',
    dataIndex: 'protocol',
    key: 'protocol',
    width: 120,
    sorter: true,
    ...createInvoiceProtocolColumnFilter(),
    render: (_, record) => <InvoiceProtocolName invoice={record.invoice} />
  },
  {
    title: 'Data',
    key: 'invoiceDate',
    dataIndex: 'invoiceDate',
    width: 100,
    sorter: true,
    ...createColumnFilter(FilterInputType.DateRange),
    render: (_, record) => <ISODate date={record.invoice.issuedDate} />
  },
  {
    title: 'Fornitore',
    key: 'supplierName',
    ellipsis: { showTitle: false },
    width: 220,
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => (
      <EllipsisTooltip children={record.invoice.supplier?.getName()} />
    )
  },
  {
    title: 'Referente Amministrativo',
    key: 'adminReferentName',
    width: 190,
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => (
      <EllipsisTooltip
        children={record.invoice.contract?.adminReferent?.name}
      />
    )
  },
  {
    title: 'CIG',
    width: 120,
    dataIndex: 'cigCode',
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) =>
      record.invoice.contract?.cigCode ? (
        <Link to={`/contracts/all/${record.invoice.contract.id}`}>
          {record.invoice.contract.cigCode}
        </Link>
      ) : (
        <span>--</span>
      )
  },
  {
    title: 'Linea',
    key: 'line.number',
    width: 60,
    render: (_, record) => record.line?.number ?? '--'
  },
  {
    title: 'Anomalie Riscontrate',
    dataIndex: 'description'
  }
];
