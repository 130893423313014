import { InvoiceState } from 'common/schema/invoice/InvoiceTypes';
import { Role } from './Role';

const System = new Role('System', 'Utente di Sistema', [
  'current-user.read',
  // Utenti
  'users.read',
  'users.write',
  // Importazioni SDI
  'sdi.import',
  // Lettura
  'invoice.read',
  'shipments.read',
  'contract.read',
  'accounting.line.read',
  'economy.read',
  'economic-entry.read',
  // Notifica invio spedizione
  'shipments.notify-send',
  // Fornitori
  'supplier.read',
  'supplier.write'
]);

const ProtocolUser = new Role('ProtocolUser', 'Utente Protocollo', [
  'current-user.read',
  'users.read-selectable',
  'mandates.read',
  'mandates.write',
  'pcc.exports.read',
  'pcc.exports.write',
  'shipments.read',
  'shipments.write',
  'shipments.send',
  'shipments.notify-send',
  'documental.document.read',
  'documental.correspondents.read',
  'invoice.read',
  `invoice.access:${InvoiceState.Accounted}`,
  `invoice.access:${InvoiceState.Paid}`,
  `invoice.access:${InvoiceState.PaidPartial}`,
  `invoice.access:${InvoiceState.Rejected}`,
  `invoice.access:${InvoiceState.ToAccount}`,
  `invoice.access:${InvoiceState.ToValidate}`,
  `invoice.access:${InvoiceState.Validated}`,
  'invoice.access:toAccept',
  'invoice.access:toProtocol',
  'invoice.create',
  'invoice.write',
  'invoice.protocol',
  'invoice.accept',
  'invoice.edit-contract',
  'batch.read',
  'accounting.line.read',
  'accounting.line.edit:dates',
  'contract.read',
  'contract.write',
  'contract.state.change',
  'activity.log.read',
  'economy.generate',
  'economy.simulate',
  'economy.read',
  'economic-entry.read',
  'economic-entry.write',
  'supplier.read',
  'supplier.write'
]);

const Admin = new Role('Admin', 'Utente Amministrativo', [
  // Utenti
  'current-user.read',
  'users.read-selectable',
  // Mandati
  'mandates.read',
  'mandates.write',
  // Spedizioni
  'shipments.read',
  'shipments.write',
  'shipments.send',
  'shipments.notify-send',
  // Documentale
  'documental.document.read',
  'documental.correspondents.read',
  // Fatture
  'invoice.read',
  `invoice.access:${InvoiceState.Accounted}`,
  `invoice.access:${InvoiceState.Paid}`,
  `invoice.access:${InvoiceState.PaidPartial}`,
  `invoice.access:${InvoiceState.Rejected}`,
  `invoice.access:${InvoiceState.ToAccount}`,
  `invoice.access:${InvoiceState.Validated}`,
  'invoice.write',
  'invoice.protocol',
  'invoice.accept',
  // Lotti
  'batch.read',
  // Linee
  'accounting.line.read',
  // Contratto
  'contract.read',
  'contract.write',
  'contract.state.change',
  // Activity Log
  'activity.log.read',
  // Contraenti
  'supplier.read',
  'supplier.write',
  // Piano dei Conti
  'economic-entry.read',
  'economy-simulation.read'
]);

const SuperAdmin = new Role('SuperAdmin', 'Super Amministratore', [
  'users.read',
  'users.read-selectable',
  'users.write',
  'users.token.create',
  ...ProtocolUser.permissions
]);

const DocumentalUser = new Role('DocumentalUser', 'Utente Documentale', [
  'current-user.read',
  'contract.read',
  'activity.log.read',
  'supplier.read'
]);

export const Roles = {
  SuperAdmin,
  ProtocolUser,
  Admin,
  System,
  DocumentalUser
};

export const RoleOptions = Object.keys(Roles).map(role => ({
  label: Roles[role as RoleName].displayName,
  value: Roles[role as RoleName].name
}));

export type RoleName = keyof typeof Roles;
