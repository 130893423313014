export enum DocumentalDocumentType {
  /** Non Protocollato */
  notRegistered = 'G',
  /** Protocollato in Uscita */
  Outgoing = 'P',
  /** Protocollato in Entrata */
  Incoming = 'A',
  /** Protocollato interno */
  Internal = 'I'
}

export enum DocumentalCorrespondentType {
  /** Uscita */
  Outgoing = 'U',
  /** Entrata */
  Incoming = 'E',
  /** Persona */
  P = 'P',
  /** Ufficio (?) */
  U = 'U'
}

/**
 * Tipi disponibili da usare nel filtro "TYPE" nella searchCorrespondent
 */
export enum DocumentalUserTypeFilter {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
  Global = 'GLOBAL'
}
