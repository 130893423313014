import * as React from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { useEffect } from 'react';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { MandateApi } from 'client/components/schema/mandate/MandateApi';
import { useField, useFormikContext } from 'formik';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';

export interface ShipmentDescriptionItemProps {}

export function ShipmentDescriptionItem(props: ShipmentDescriptionItemProps) {
  const formik = useFormikContext<ShipmentDto>();
  const formContext = useFormikFormContext();
  const mandateId = formik.values.mandateId;

  const [, , descriptionHelper] = useField<string>('description');

  const [getDescription] = useApiMutation(
    MandateApi.getShipmentDescription,
    {}
  );

  useEffect(() => {
    /**
     * Ottiene la descrizione della spedizione a partire dal mandato,
     * alla modifica del mandato, viene modificato anche l'oggetto
     */
    const fetchAndUpdateDescription = async () => {
      if (!mandateId) return;

      if (!formContext.editable) return;

      const result = await getDescription({ data: { id: mandateId } });

      descriptionHelper.setValue(result.data.description);
    };

    fetchAndUpdateDescription();
  }, [mandateId]);

  return (
    <FormFieldItem
      label="Descrizione"
      name={'description'}
      component={TextAreaInput}
      placeholder="Descrizione"
      rows={3}
    />
  );
}
