import React from 'react';
import { ExportFields } from '../../../../../common/schema/invoice/exports/ExportFields';
import { FormFieldsContainerCheckBox } from './FormFieldsContainerCheckBox';

export interface InvoiceExportFieldsSelectionProps {}

export function InvoiceExportFieldsSelection(
  props: InvoiceExportFieldsSelectionProps
) {
  const fields = ExportFields;

  return (
    <>
      <FormFieldsContainerCheckBox
        title="Dati Fattura"
        fields={fields.invoice}
        columns={6}
        removeTopTitleSpacing
      />
      <FormFieldsContainerCheckBox
        title="Dati Linee di Contabilizzazione"
        fields={fields.accounting}
        columns={6}
      />
      <FormFieldsContainerCheckBox
        title="Dati Linee di Fattura"
        fields={fields.lines}
        columns={6}
      />
      <FormFieldsContainerCheckBox
        title="Dati Sospensione dei termini"
        fields={fields.suspending}
        columns={6}
      />
      <FormFieldsContainerCheckBox
        title="Dati del Contratto"
        fields={fields.contract}
        columns={6}
      />
      <FormFieldsContainerCheckBox
        title="Dati del Mandato / Spedizione"
        fields={fields.mandate}
        columns={6}
      />
    </>
  );
}
