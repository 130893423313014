import { Tag } from 'antd';
import { PccRowDto } from 'common/dto/generated/PccRowDto';
import { PccRowStateMap } from 'common/schema/pcc/pcc-row/PccRowTypes';
import React from 'react';

interface IPccRowStateTagProps {
  line: PccRowDto;
}

export function PccRowStateTag(props: IPccRowStateTagProps) {
  const state = PccRowStateMap.find(
    stateMap => stateMap.value === props.line.state
  );

  if (!state) {
    return null;
  }

  return <Tag color={state?.color} children={state?.label} />;
}
