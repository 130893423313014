import React, { useCallback } from 'react';
import { InputNumber } from 'antd';
import { FormikValues, useField } from 'formik';
import { InputNumberProps } from 'antd/lib/input-number';
import { useFormikFormContext } from '../FormikFormContext';
import Decimal from 'decimal.js-light';

export interface DecimalInputProps<T extends FormikValues>
  extends Omit<InputNumberProps, 'name'> {
  name: keyof T & string;
}

/**
 * Campo per input di tipo Decimal che non sono valute, ad esempio
 * numeri con la virgola come la quantità.
 */
export function DecimalInput<T extends FormikValues>(
  props: DecimalInputProps<T>
) {
  const { name } = props;
  const [field, meta, helpers] = useField<Decimal | null | undefined>(name);
  const { disabled } = useFormikFormContext();

  const handleChange = useCallback(
    (value: string | number | undefined | null) => {
      helpers.setValue(
        value === '' || value == null ? null : new Decimal(value)
      );
    },
    [helpers.setValue]
  );

  return (
    <InputNumber
      {...field}
      value={field.value == null ? undefined : field.value.toNumber()}
      max={4_000_000_000}
      onChange={handleChange}
      {...props}
      formatter={value => `${value}`}
      parser={value => {
        return value ? value : '';
      }}
      decimalSeparator=","
      disabled={props.disabled || disabled}
    />
  );
}

DecimalInput.renderFieldValue = (value: any) => {
  return value instanceof Decimal ? value.toFixed() : value;
};
