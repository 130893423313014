import { CopyOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal, Tooltip } from 'antd';
import { ShipmentApi } from 'client/components/schema/shipment/ShipmentApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import { ShipmentMachine } from 'common/schema/shipment/ShipmentMachine';
import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

export interface IShipmentIntegrateButtonProps {
  shipment: ShipmentDto;
  disabled?: boolean;
}

export function ShipmentIntegrateButton(props: IShipmentIntegrateButtonProps) {
  const { shipment, disabled } = props;
  const history = useHistory();

  const actionable = ShipmentMachine.can(shipment, 'action.integrate', true);

  const [createIntegration] = useApiMutation(
    ShipmentApi.integrations.create,
    {}
  );

  const handleClick = useCallback(async () => {
    Modal.confirm({
      title: `Vuoi integrare questa spedizione?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Integra',
      cancelText: 'Annulla',
      content: `La spedizione verrà integrata.`,
      async onOk() {
        try {
          const integration = await createIntegration({
            data: { id: shipment.id }
          });
          message.success('Integrazione creata con successo.');
          // TODO controllare URL MMAA
          history.push(`/shipping/all/${integration.data.id}`);
        } catch {
          message.error(
            "Si è verificato un errore durante l'integrazione. Riprovare."
          );
        }
      },
      onCancel() {}
    });
  }, []);

  if (!actionable.allowed && actionable.skipped) {
    return null;
  }

  return (
    <Tooltip title={!actionable.allowed && actionable.message}>
      <Button
        icon={<CopyOutlined />}
        onClick={handleClick}
        disabled={disabled || !actionable.allowed}
      >
        Integra Spedizione
      </Button>
    </Tooltip>
  );
}
