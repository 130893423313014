import { InfoBoxActions } from 'client/ui/info-box/InfoBoxActions';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { ProtocolResponseBag } from 'common/dto/response-bag/ProtocolResponseBag';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import * as React from 'react';
import { useState } from 'react';
import { InvoiceProtocolErrorAlert } from './InvoiceProtocolErrorAlert';
import { InvoiceProtocolModal } from './InvoiceProtocolModal';

export interface IInvoiceProtocolInfoBoxProps {
  invoice: InvoiceDto;
}

export function InvoiceProtocolInfoBox(props: IInvoiceProtocolInfoBoxProps) {
  const actionable = InvoiceMachine.can(props.invoice, 'edit.protocol', true);
  const [errorBag, setErrorBag] = useState<ProtocolResponseBag>({ errors: [] });

  const showInfoBox =
    actionable.allowed || (!actionable.allowed && !actionable.skipped);

  return (
    <>
      {showInfoBox && (
        <InfoBoxActions
          title="Protocollazione Fattura"
          text={
            actionable.allowed ? (
              <>
                Tutti i campi richiesti per il Protocollo sono presenti. È
                necessario protocollare la fattura per procedere alla
                Contabilizzazione.
              </>
            ) : (
              <ul>
                {actionable.errors?.map(error => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            )
          }
          secondaryButton={
            <InvoiceProtocolModal
              invoice={props.invoice}
              setErrorBag={bag => {
                setErrorBag(bag);
              }}
            />
          }
        />
      )}
      <InvoiceProtocolErrorAlert bag={errorBag} />
    </>
  );
}
