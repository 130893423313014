import * as React from 'react';
import { useState } from 'react';
import { Button, Table } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import {
  ArrowRightOutlined,
  CalculatorOutlined,
  ClockCircleOutlined,
  FileOutlined,
  FileSyncOutlined
} from '@ant-design/icons';
import { Formik } from 'formik';
import { FormikForm } from 'client/ui/form/FormikForm';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { Section } from 'client/ui/section/Section';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { RouteComponentProps } from 'react-router';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { AccountingLineApi } from 'client/components/schema/accounting-line/AccountingLineApi';
import {
  AccountingLineDto,
  AccountingLineSchema
} from 'common/dto/generated/AccountingLineDto';
import { AccountingLineStateTag } from 'client/components/schema/accounting-line/view/AccountingLineStateTag';
import { AccountingLineFormFields } from 'client/components/schema/accounting-line/edit/AccountingLineFormFields';
import { DebtStateTag } from 'client/components/schema/accounting-line/view/DebtStateTag';
import { MandatesColumns } from 'client/components/schema/mandate/table/MandatesColumns';
import { Link } from 'react-router-dom';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { mapAccountingLineFilterToOptions } from '../list/mapAccountingLineFilterToOptions';
import { LinkButton } from 'client/ui/link/LinkButton';
import { PccCommunicationsState } from 'client/components/schema/accounting-line/view/PccCommunicationsState';
import { AccountingLineInvoiceColumns } from 'client/components/schema/invoice/table/AccountingLineInvoiceColumns';
import { ActivityLogTable } from 'client/components/schema/activity-log/table/ActivityLogTable';

interface Params {
  id: string;
  filter?: string;
}

export interface AccountingLinePageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di dettaglio della Linea Contabile
 */
export function AccountingLinePage(props: AccountingLinePageProps) {
  const [editable, setEditable] = useState(false);

  const id = props.match.params.id ? parseInt(props.match.params.id, 10) : null;

  // TODO Breadcrumbs
  // Gestione API
  const { response, error, loading, refetch } = useApiQuery(
    AccountingLineApi.find,
    {
      skip: id == null,
      data: { id: id! }
    }
  );
  const line = response?.data;

  const pageOptions = mapAccountingLineFilterToOptions(
    props.match.params.filter
  );

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/accounting/lines/all',
    title: `Contabilizzazione`
  });
  useBreadcrumbItem({
    path: `/accounting/${pageOptions.sideMenuPrefix}/${pageOptions.filter}`,
    title: pageOptions.title
  });
  useBreadcrumbItem({
    priority: 20,
    path: props.location.pathname,
    title: `Linea Contabile #${id}`,
    sideMenuKey: pageOptions.sideMenuPrefix + '-' + pageOptions.filter,
    menuKey: 'accounting'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere alla linea contabile"
        error={error}
      />
    );
  }

  if (!line) return <LoadingPage />;

  return (
    <CorePageLayout>
      <Formik<AccountingLineDto>
        initialValues={line}
        enableReinitialize
        validate={schemaToValidator(AccountingLineSchema)}
        onReset={() => {
          setEditable(false);
        }}
        onSubmit={() => {}}
      >
        <FormikForm editable={editable} helpInTooltips showVisualFeedback>
          <PageHeading
            title={
              <PageHeadingTitle>Dettaglio Linea Contabile</PageHeadingTitle>
            }
            breadcrumbRender={() => <BreadcrumbsContainer />}
            tags={
              <>
                <PccCommunicationsState line={line} />
                <AccountingLineStateTag state={line.state} />
                <DebtStateTag state={line.debtState} />
              </>
            }
            extra={
              <>
                {line.mandateId ? (
                  <LinkButton
                    to={`/shipping/mandates/all/${line.mandateId}`}
                    icon={<FileSyncOutlined />}
                  >
                    Visualizza Mandato
                  </LinkButton>
                ) : (
                  <Button
                    icon={<FileSyncOutlined />}
                    disabled
                    children="Visualizza Mandato"
                  />
                )}
                <LinkButton
                  to={`/invoicing/invoices/all/${line.invoiceId}`}
                  icon={<FileOutlined />}
                  children="Visualizza Fattura"
                />
              </>
            }
          ></PageHeading>

          <PageContent fixedWidht>
            {/*  Dati Generali  */}
            <Section
              icon={<CalculatorOutlined />}
              title="Dati Linea Contabile"
              hasPadding
            >
              <AccountingLineFormFields />
            </Section>

            <Section icon={<FileOutlined />} title="Fattura">
              <Table
                columns={[
                  ...AccountingLineInvoiceColumns,
                  {
                    title: '',
                    dataIndex: 'azioni',
                    align: 'right',
                    width: 100,
                    render: (_, record) => (
                      <Link to={`/invoicing/invoices/all/${line.invoiceId}`}>
                        Vai <ArrowRightOutlined />
                      </Link>
                    )
                  }
                ]}
                rowKey="id"
                dataSource={[line.invoice]}
                size="middle"
                pagination={false}
              />
            </Section>

            {line.mandate && (
              <Section icon={<FileSyncOutlined />} title="Mandato">
                <Table
                  columns={[
                    ...MandatesColumns,
                    {
                      title: '',
                      dataIndex: 'azioni',
                      align: 'right',
                      width: 100,
                      render: (_, record) => (
                        <Link to={`/shipping/mandates/all/${line.mandateId}`}>
                          Vai <ArrowRightOutlined />
                        </Link>
                      )
                    }
                  ]}
                  rowKey="id"
                  dataSource={[line.mandate]}
                  size="middle"
                  pagination={false}
                />
              </Section>
            )}
            {id && (
              <Section
                icon={<ClockCircleOutlined />}
                title="Cronologia Attività"
              >
                <ActivityLogTable entity="accounting_line" entityId={id} />
              </Section>
            )}
          </PageContent>
        </FormikForm>
      </Formik>
    </CorePageLayout>
  );
}
