import { Tag, TreeSelect } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { TreeSelectInputProps } from 'client/ui/form/input/TreeSelectInput';
import { AsyncText } from 'client/ui/form/text/AsyncText';
import { EconomicEntryDto } from 'common/dto/generated/EconomicEntryDto';
import {
  createTreeFromEconomicEntries,
  ICreateEconomicEntriesTreeOptions,
  IEconomicEntryNode,
  IEconomicEntryTree
} from 'common/schema/economic-entry/createTreeFromEconomicEntries';
import { FormikContextType, useField, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { EconomicEntryApi } from './EconomicEntryApi';

export interface IEconomicEntrySingleSelectInputProps<T extends object>
  extends Omit<
    TreeSelectInputProps<T>,
    | 'name'
    | 'multiple'
    | 'defaultValue'
    | 'onSelect'
    | 'value'
    | 'onDeselect'
    | 'onChange'
  > {
  name: string;
  /** In caso siano già caricate le liste */
  initialTree?: IEconomicEntryTree;
  /** Opzioni per l'albero */
  treeOptions?: ICreateEconomicEntriesTreeOptions;
}

/**
 * Permette la selezione di una un singolo Codice di Conto economico
 * mantenendo la logica del sistema multi-codice
 * @param props
 */
export function EconomicEntrySingleSelectInput<T extends object>(
  props: IEconomicEntrySingleSelectInputProps<T>
) {
  const { name, initialTree, treeOptions, ...otherProps } = props;

  const [field, , helper] = useField<Partial<EconomicEntryDto>[] | null>(name);

  const [tree, setTree] = useState(initialTree);
  const { loading } = useApiQuery(EconomicEntryApi.list, {
    data: {},
    onLoad: response => {
      setTree(createTreeFromEconomicEntries(response.data.items, treeOptions));
    }
  });

  const handleOnChange = useCallback(
    (value: number) => {
      helper.setValue([{ id: value }]);
    },
    [helper, tree]
  );

  return (
    <TreeSelect
      loading={loading}
      {...otherProps}
      showSearch
      treeNodeFilterProp="searchText"
      placeholder={props.placeholder ?? 'Seleziona i conti economici'}
      showArrow={true}
      onClear={() => helper.setValue(null)}
      treeData={tree?.roots}
      value={field.value?.[0]?.id ?? undefined}
      onChange={handleOnChange}
    />
  );
}

EconomicEntrySingleSelectInput.renderFieldValue = (
  values?: Partial<EconomicEntryDto>[] | null
) => {
  if (!values || !values[0]) return '';

  return (
    <Tag key={values[0].id}>
      {values[0].code} - {values[0].title}
    </Tag>
  );
};
