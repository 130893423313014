import { Tag } from 'antd';
import { RoleName, Roles } from 'common/permissions/Roles';
import React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

export interface IUserRoleTagProps {
  role: RoleName;
}

interface ITagOptions {
  color: string | undefined;
  children: string;
}

function getTagFromRole(role: RoleName): ITagOptions {
  switch (role) {
    case Roles.SuperAdmin.name:
      return { color: 'blue', children: Roles.SuperAdmin.displayName };
    case Roles.System.name:
      return { color: 'green', children: Roles.System.displayName };
    case Roles.ProtocolUser.name:
      return { color: 'magenta', children: Roles.ProtocolUser.displayName };
    case Roles.DocumentalUser.name:
      return { color: 'gold', children: Roles.DocumentalUser.displayName };
    case Roles.Admin.name:
      return { color: 'cyan', children: Roles.Admin.displayName };
    default:
      assertNever(role);
  }
}

/**
 * Crea il tag colorato per il ruolo utente fornito.
 * @param props
 */
export function UserRoleTag(props: IUserRoleTagProps) {
  const options = getTagFromRole(props.role);

  return <Tag {...options} />;
}
