import * as React from 'react';
import clx from 'classnames';
import './InvoicePaperTableIndicator.scss';

export interface IInvoicePaperTableIndicatorProps {
  isPaper: boolean;
}

/**
 * Indicatore visivo per fattura cartacea nelle tabelle fattura
 */
export function InvoicePaperTableIndicator(
  props: IInvoicePaperTableIndicatorProps
) {
  return (
    <div
      className={clx('invoice-type-column-td', { 'is-paper': props.isPaper })}
    />
  );
}
