import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { useFormikFieldValidation } from 'client/ui/form/hooks/validation/useFormikFieldValidation';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { SelectInputProps } from 'client/ui/form/input/SelectInput';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { ContractQueryDto } from 'common/dto/query/ContractQueryDto';
import { FormikValues, useField } from 'formik';
import * as React from 'react';
import { ContractApi } from '../ContractApi';
import { ContractLink } from '../view/ContractLink';
import { Typography } from 'antd';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { cigIncongruenceTest } from 'client/routes/invoicing/invoices/single/views/InvoiceCigIncongruenceTag';
import { ContractState } from 'common/schema/contract/ContractTypes';
import { Roles } from 'common/permissions/Roles';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { RolesLogic } from 'common/permissions/RolesLogic';
import { RequirementOfficeAsyncText } from 'client/routes/contract/single/sections/components/RequirementOfficeAsyncText';
const { Title, Text } = Typography;

export interface IContractSelectInputProps<T extends FormikValues>
  extends SelectInputProps<T> {
  hasReferences?: boolean;
  invoice: InvoiceDto;
}

function makeContractLabel(contract: ContractDto): string {
  return (
    `CIG ${contract.cigCode}` +
    `${contract.number ? ` - ${contract.number}` : ''}` +
    ` - ${contract.subject}`
  );
}

/**
 * Permette di selezionare un contratto
 */
export function ContractSelectInput<T extends FormikValues>(
  props: IContractSelectInputProps<T>
) {
  const { hasReferences, ...otherProps } = props;

  const [field, _, helpers] = useField<number | undefined>(props.name);
  const validation = useFormikFieldValidation(props.name);

  const currentUser = useCurrentUser();

  const { response } = useApiQuery(ContractApi.find, {
    skip: field.value == null,
    data: { id: field.value! }
  });
  const contract = field.value == null ? null : response?.data;

  const cigIncongruence = cigIncongruenceTest(props.invoice, contract);

  return (
    <>
      <FormFieldsContainer
        columns={3}
        title={props.hasReferences ? 'Selezione Contratto' : undefined}
      >
        <DataItem
          colSpan={3}
          label="Contratto"
          value={
            validation.editable &&
            currentUser?.role &&
            Roles[currentUser?.role].hasPermission('invoice.edit-contract') ? (
              <AsyncSelectInput
                {...otherProps}
                name={props.name}
                placeholder="Seleziona Contratto"
                size="small"
                allowClear
                query={{
                  apiFn: ContractApi.list,
                  options: (formik, search) => ({
                    data: {
                      query: new ContractQueryDto({
                        pageNumber: 1,
                        pageSize: 20,
                        orderBy: 'id',
                        labelSearch: search,
                        invoiceSelectable: true,
                        includeContractId: props.invoice.contractId
                      })
                    }
                  })
                }}
                showSearch
                refreshOnSearch
                responseTransform={data => data.items}
                responseMeta={data => ({
                  shown: data.items.length,
                  total: data.meta.total
                })}
                optionTransform={(contract: ContractDto) => ({
                  value: contract.id,
                  label: makeContractLabel(contract)
                })}
              />
            ) : contract ? (
              makeContractLabel(contract)
            ) : (
              '-'
            )
          }
        />
        <DataItem label="Numero" value={<ContractLink contract={contract} />} />
        <DataItem
          label="Codice CIG"
          value={
            <Text type={cigIncongruence ? 'danger' : undefined}>
              {contract?.cigCode ?? '-'}
            </Text>
          }
        />
        <DataItem label="Codice CUP" value={contract?.cupCode ?? '-'} />
      </FormFieldsContainer>
      {contract && (
        <>
          <FormFieldsContainer>
            <RequirementOfficeAsyncText
              officeCode={contract.requirementOfficeCode}
            />
          </FormFieldsContainer>
          <FormFieldsContainer columns={3} title="Referenti">
            <DataItem
              inline
              label="Amministrativo"
              value={contract.adminReferent?.name ?? '-'}
            />
            <DataItem
              inline
              label="RUP"
              value={contract.rupReferent?.name ?? '-'}
            />
            <DataItem
              inline
              label="DEC"
              value={contract.decReferent?.name ?? '-'}
            />
          </FormFieldsContainer>
        </>
      )}
    </>
  );
}
