import { resolveApiPath } from 'client/core/appConfig';

/**
 * Ottiene il link per il download del conto economico
 *
 * @param statementId Id del conto economico
 * @returns L'URL per il download
 */
export function getEconomicStatementDownloadLink(statementId: number): string {
  return resolveApiPath(`economic-statements/${statementId}/download`);
}
