import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { AccountingLineState, DebtState, DebtReason, ExpenseNature } from 'common/schema/accounting-line/AccountingLineTypes';
import { MandateDto, MandateSchema } from './MandateDto';
import { InvoiceDto } from './InvoiceDto';

/**
 * Rappresentazione DTO della classe AccountingLine definita in: src/server/schema/accounting/accounting-line/AccountingLine.entity.ts
 * Hash: 980b7458b66a0433fa3f0414a235e2b8
 */
@ValidationSchema(() => AccountingLineSchema)
export class AccountingLineDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: () => InvoiceDto })
  @Type(() => InvoiceDto)
  invoice!: InvoiceDto;
  @ApiProperty({ required: false, type: Number })
  invoiceId!: number;
  @ApiProperty({ required: false, type: Number, description: 'Autore' })
  authorId?: number | undefined;
  @ApiProperty({ type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Importo' })
  @TypeTransformDecimal()
  amount!: Decimal;
  @ApiProperty({ required: false, enum: ['NotPayable', 'ToShip', 'ToPay', 'Paid'], description: 'Stato' })
  state!: AccountingLineState;
  @ApiProperty({ required: false, enum: ['LIQ', 'SOSP', 'NOLIQ', 'LIQdaSOSP', 'LIQdaNL', 'SOSPdaLIQ', 'SOSPdaNL', 'NLdaLIQ', 'NLdaSOSP', 'ADEGLIQ'] })
  debtPrevState?: DebtState | null | undefined;
  @ApiProperty({ enum: ['LIQ', 'SOSP', 'NOLIQ', 'LIQdaSOSP', 'LIQdaNL', 'SOSPdaLIQ', 'SOSPdaNL', 'NLdaLIQ', 'NLdaSOSP', 'ADEGLIQ'], description: 'Stato del debito' })
  debtState!: DebtState;
  @ApiProperty({ required: false, enum: ['ATTLIQ', 'CONT', 'ATTNC', 'NCRED', 'PAGTERZI', 'IVARC', 'DNC', 'ATTESECOLL'], description: 'Causale del debito' })
  debtReason?: DebtReason | null | undefined;
  @ApiProperty({ required: false, type: Number })
  mandateId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => MandateDto })
  @Type(() => MandateDto)
  mandate?: MandateDto | null;
  @ApiProperty({ required: false, type: Boolean, description: 'Pagamento eseguito' })
  paymentExecuted!: boolean;
  @ApiProperty({ required: false, type: String, format: 'date', description: 'Data di pagamento' })
  paymentDate?: Date | null | undefined;
  @ApiProperty({ enum: ['CO', 'CA', 'NA'], description: 'Natura di spesa' })
  expenseNature!: ExpenseNature;
  @ApiProperty({ required: false, type: Number, description: 'Capitolo di spesa' })
  expenseChapter?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Termini' })
  terms?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Descrizione' })
  description?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Note' })
  notes?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Comunicazione CO a PCC' })
  pccExportedAccounting!: boolean;
  @ApiProperty({ required: false, type: Boolean, description: 'Comunicazione CP a PCC' })
  pccExportedPayment!: boolean;
  @ApiProperty({ required: false, type: Boolean, description: 'Comunicazione CS a PCC' })
  pccExportedExpiration!: boolean;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<AccountingLineDto>) {
    if (values != null) {
      Object.assign(this, values instanceof AccountingLineDto ? values : plainToClass(AccountingLineDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await AccountingLineSchema.validate(classToPlain(this), options);
    return new AccountingLineDto(validated);
  }
}

/** Interfaccia simmetrica al DTO AccountingLineDto */
export type IAccountingLineType = IDto<AccountingLineDto>;

/**
 * DTO Paginato della classe AccountingLine
 */
export class PaginatedAccountingLineDto {
  @ApiProperty({ type: [AccountingLineDto] })
  @Type(() => AccountingLineDto)
  items!: AccountingLineDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const AccountingLineSchema = yup
  .object({
    id: yup.number(),
    invoiceId: yup.number(),
    amount: yup.mixed().required().label('Importo'),
    debtPrevState: yup.string().oneOfEnum(DebtState).nullable(),
    debtState: yup.string().oneOfEnum(DebtState).required().label('Stato del debito'),
    debtReason: yup.string().oneOfEnum(DebtReason).nullable().label('Causale del debito'),
    mandateId: yup.number().nullable(),
    paymentExecuted: yup.boolean().default(false).label('Pagamento eseguito'),
    paymentDate: yup.date().dateOnlyFormat().nullable().when(['mandateId'], {
          is: (mandateId: number | null | undefined) => mandateId != null,
          then: schema => schema.strip()
        }).label('Data di pagamento'),
    expenseNature: yup.string().oneOfEnum(ExpenseNature).required().label('Natura di spesa'),
    expenseChapter: yup.number().nullable().label('Capitolo di spesa'),
    terms: yup.string().nullable().label('Termini'),
    description: yup.string().nullable().label('Descrizione'),
    notes: yup.string().nullable().label('Note')
  })
  .noUnknown()
  .meta({ schemaName: "AccountingLineSchema" })
  .required();
