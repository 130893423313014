import * as React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';

interface AnchorLinkButtonProps extends ButtonProps {
  /**
   * Props aggiuntive del tah<a> HTML
   */
  anchorProps?: Omit<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    'href' | 'onClick'
  >;
  /**
   * Permette di escludere il Button di Ant
   */
  linkOnly?: boolean;
}

/**
 * Tag <a> HTML con stile Button di Ant (opzionale) da utilzzare per le chamate API
 */
export function AnchorLinkButton(props: AnchorLinkButtonProps) {
  const {
    linkOnly,
    href,
    onClick,
    anchorProps,
    className,
    style,
    ...buttonProps
  } = props;

  // Al tag <a /> passiamo alternativamente href oppure onClick;
  const aProps = href
    ? { ...anchorProps, href, className, style }
    : { ...anchorProps, onClick, className, style };

  const aIcon = linkOnly && props.icon ? <>{props.icon} </> : null;

  return linkOnly ? (
    <a {...aProps}>
      {aIcon}
      {props.children}
    </a>
  ) : (
    <a {...aProps}>
      <Button {...buttonProps} />
    </a>
  );
}
