import React from 'react';
import clx from 'classnames';
import { Route, Switch } from 'react-router';
import './SideMenuSwitch.scss';
import { InvoicingSideMenu } from '../invoicing/InvoicingSideMenu';
import { AccountingSideMenu } from '../accounting/AccountingSideMenu';
import { ContractsSideMenu } from '../contract/ContractsSideMenu';
import { ShippingSideMenu } from '../shipping/shipments/ShippingSideMenu';
import { EconomySideMenu } from '../economy/EconomySideMenu';
import { UserSideMenu } from '../user/UserSideMenu';
import { pageFixedSizes } from 'client/ui/layout/PageLayout';
import { MandateSideMenu } from '../shipping/mandate/MandateSideMenu';

export interface SideMenuSwitchProps {}

/**
 * Switch del Sider Menu del Layout principale dell'applicativo.
 * Il Menu Laterale viene selezionato in base al path del Route
 */
export function SideMenuSwitch(props: SideMenuSwitchProps) {
  const style: React.CSSProperties = {
    position: 'fixed',
    right: 'auto',
    width: pageFixedSizes.siderWidht - 1,
    left: 0,
    top: pageFixedSizes.topHeaderHeight,
    bottom: 0,
    overflow: 'hidden'
  };

  return (
    <div style={style}>
      <Switch>
        <Route path="/invoicing/">
          <InvoicingSideMenu classNames="side-menu" />
        </Route>
        <Route path="/accounting/">
          <AccountingSideMenu classNames="side-menu" />
        </Route>
        <Route path="/contracts/">
          <ContractsSideMenu classNames="side-menu" />
        </Route>
        <Route path="/shipping/mandates">
          <MandateSideMenu classNames="side-menu" />
        </Route>
        <Route path="/shipping">
          <ShippingSideMenu classNames="side-menu" />
        </Route>
        <Route path="/economy/">
          <EconomySideMenu classNames="side-menu" />
        </Route>
        <Route path="/design">
          <InvoicingSideMenu classNames="side-menu" />
        </Route>
        <Route path="/users/">
          <UserSideMenu classNames="side-menu" />
        </Route>
      </Switch>
    </div>
  );
}
