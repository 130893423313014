import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { InvoiceAttachmentDto } from 'common/dto/generated/InvoiceAttachmentDto';

export const InvoiceAttachementColumns: ColumnsType<InvoiceAttachmentDto> = [
  {
    title: 'Nome',
    dataIndex: 'name'
  },
  {
    title: 'Descrizione',
    dataIndex: 'description'
  },
  {
    title: 'Formato',
    dataIndex: 'format'
  }
];
