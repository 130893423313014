export enum InvoiceColumnSort {
  id = 'id',
  receivedAt = 'receivedAt',
  issuedDate = 'issuedDate',
  issuedYear = 'issuedYear',
  expiresAt = 'expiresAt',
  issuedNumber = 'issuedNumber',
  invoiceTypeCode = 'invoiceTypeCode',
  totalVatTaxableAmount = 'totalVatTaxableAmount',
  // Ordinamenti speciali (necessitano di query particolari)
  // gestite nell InvoiceController
  supplierName = 'supplierName',
  protocol = 'protocol'
}
