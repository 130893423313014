import { CalendarOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { ConfigEnumInput } from 'client/ui/form/input/configurable/ConfigEnumInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { Section } from 'client/ui/section/Section';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import React from 'react';

export interface IInvoceSuspensionSectionProps {
  invoice: InvoiceDto;
}

/**
 * Mostra i dati relativi alla sospensione / scadenza della fattura
 */
export function InvoiceSuspensionSection(props: IInvoceSuspensionSectionProps) {
  const { invoice } = props;

  const formContext = useFormikFormContext();

  return (
    <Section
      icon={<CalendarOutlined />}
      title="Regolare Esecuzione e Collaudo – Sospensione "
      hasPadding
    >
      <FormFieldsContainer columns={3}>
        <FormFieldItem
          inline
          component={DatePickerInput}
          label="Data esecuzione Prestazione"
          type="from"
          name="executionDate"
          placeholder="Data Esecuzione"
        />
        <FormFieldItem
          inline
          component={DatePickerInput}
          label="Data Regolare Esecuzione/Collaudo"
          type="from"
          name="testingDate"
          placeholder="Data Collaudo"
        />
        <FormFieldItem
          inline
          component={DatePickerInput}
          label="Data Scadenza"
          type="from"
          name="expiresAt"
          placeholder="Data Scadenza"
        />
      </FormFieldsContainer>
      <Divider style={{ margin: '12px 0' }} />
      <FormFieldsContainer columns={3}>
        <FormFieldItem
          inline
          component={DatePickerInput}
          label="Data Inizio Sospensione"
          type="date-only"
          name="suspendedFromDate"
          placeholder="Data Inizio Sospensione"
        />
        <FormFieldItem
          inline
          component={DatePickerInput}
          label="Data Fine Sospensione"
          type="date-only"
          name="suspendedToDate"
          placeholder="Data Fine Sospensione"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={3}>
        <FormFieldItem
          inline
          colSpan={3}
          component={TextAreaInput}
          label="Motivazione Sospensione"
          name="suspendedReason"
          placeholder="Motivazione Sospensione"
        />
      </FormFieldsContainer>
      {formContext.editable && (
        <Paragraph type="danger">
          Possono essere registrati in questa sezione solo eventi attribuiti a
          responsabilità dell'impresa (es.: attesa nota di credito; DURC
          irregolare; Equitalia irregolare). Non devono essere registrati eventi
          attribuiti all'amministrazione. (es.: fondi perenti; chiusura
          esercizio finanziario; indisponibilità somme sul capitolo ecc.)
        </Paragraph>
      )}
      <FormFieldsContainer title="Ritardo pagamenti" columns={3}>
        <FormFieldItem
          inline
          colSpan={3}
          component={ConfigEnumInput}
          label="Motivazione"
          placeholder="Motivazione del ritardo pagamenti"
          feature="invoice.payment.delay"
          enumKey="reasons"
          dropdownMatchSelectWidth={600}
          name="paymentDelayReason"
          allowClear
        />
      </FormFieldsContainer>
    </Section>
  );
}
