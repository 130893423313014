import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaReadonly } from "cli/generate/decorators/SchemaDecorators";
import { MandateDto } from '../generated/MandateDto';

@GenerateDto()
class MandateFindResponse {
  @SchemaLabel("Mandato esistente")
  @SchemaReadonly()
  existing?: MandateDto | null;
  @SchemaLabel("Mandato di Siab")
  @SchemaReadonly()
  siab?: MandateDto | null;
}

/**
 * Rappresentazione DTO della classe MandateFindResponse 
 * Hash: dd61e1b5195e0801e36f73275b1cebc3
 */
@ValidationSchema(() => MandateFindResponseSchema)
export class MandateFindResponseDto {
  @ApiProperty({ required: false, type: MandateDto, description: 'Mandato esistente' })
  existing?: MandateDto | null | undefined;
  @ApiProperty({ required: false, type: MandateDto, description: 'Mandato di Siab' })
  siab?: MandateDto | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<MandateFindResponseDto>) {
    if (values != null) {
      Object.assign(this, values instanceof MandateFindResponseDto ? values : plainToClass(MandateFindResponseDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await MandateFindResponseSchema.validate(classToPlain(this), options);
    return new MandateFindResponseDto(validated);
  }
}

export const MandateFindResponseSchema = yup
  .object({})
  .noUnknown()
  .meta({ schemaName: "MandateFindResponseSchema" })
  .required();
