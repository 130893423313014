import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import moment from 'moment';
import { EconomicEntryDto } from './EconomicEntryDto';

/**
 * Rappresentazione DTO della classe InvoiceLine definita in: src/server/schema/invoice/invoice-line/InvoiceLine.entity.ts
 * Hash: b2e641c4212470cb931278f4c8b592c0
 */
@ValidationSchema(() => InvoiceLineSchema)
export class InvoiceLineDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: Number })
  invoiceId!: number;
  @ApiProperty({ required: false, type: Number })
  number?: number | null | undefined;
  @ApiProperty({ type: String })
  description!: string;
  @ApiProperty({ required: false, type: Number })
  economicEntryId?: number | null | undefined;
  @ApiProperty({ required: false, type: String })
  economicEntryCode?: string | null | undefined;
  @ApiProperty({ type: Boolean })
  invalidEconomicEntryCode!: boolean;
  @ApiProperty({ required: false, type: () => EconomicEntryDto })
  @Type(() => EconomicEntryDto)
  economicEntry?: EconomicEntryDto | null;
  @ApiProperty({ required: false, type: String, format: 'date' })
  periodStartDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date' })
  periodEndDate?: Date | null | undefined;
  @ApiProperty({ type: String, pattern: '^-?\\d+(.\\d{1,8})?$' })
  @TypeTransformDecimal()
  priceUnit!: Decimal;
  @ApiProperty({ type: String, pattern: '^-?\\d+(.\\d{1,8})?$' })
  @TypeTransformDecimal()
  priceTotal!: Decimal;
  @ApiProperty({ type: String, pattern: '^-?\\d+(.\\d{1,8})?$' })
  @TypeTransformDecimal()
  vatRate!: Decimal;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$' })
  @TypeTransformDecimal()
  quantity?: Decimal | null | undefined;
  @ApiProperty({ required: false, type: String })
  measureUnit?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceLineDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceLineDto ? values : plainToClass(InvoiceLineDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await InvoiceLineSchema.validate(classToPlain(this), options);
    return new InvoiceLineDto(validated);
  }
}

/** Interfaccia simmetrica al DTO InvoiceLineDto */
export type IInvoiceLineType = IDto<InvoiceLineDto>;

/**
 * DTO Paginato della classe InvoiceLine
 */
export class PaginatedInvoiceLineDto {
  @ApiProperty({ type: [InvoiceLineDto] })
  @Type(() => InvoiceLineDto)
  items!: InvoiceLineDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const InvoiceLineSchema = yup
  .object({
    id: yup.number(),
    invoiceId: yup.number(),
    number: yup.number().nullable().needsAction('edit.vat-summary-and-lines'),
    description: yup.string().required().needsAction('edit.vat-summary-and-lines'),
    economicEntryId: yup.number().nullable().needsAction('edit.lines.accounting'),
    periodStartDate: yup.date().dateOnlyFormat().nullable().checkPeriod('periodEndDate').needsAction('edit.lines.accounting'),
    periodEndDate: yup.date().dateOnlyFormat().nullable().needsAction('edit.lines.accounting'),
    priceUnit: yup.mixed().required().needsAction('edit.vat-summary-and-lines'),
    priceTotal: yup.mixed().required().needsAction('edit.vat-summary-and-lines'),
    vatRate: yup.mixed().required().needsAction('edit.vat-summary-and-lines'),
    quantity: yup.mixed().nullable().needsAction('edit.vat-summary-and-lines'),
    measureUnit: yup.string().nullable().needsAction('edit.vat-summary-and-lines')
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceLineSchema" })
  .required();
