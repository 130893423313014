import { ConfigStorage } from 'client/components/config/ConfigStorage';
import { apiClient } from 'client/core/network/apiClient';
import {
  AccountingLineDto,
  PaginatedAccountingLineDto
} from 'common/dto/generated/AccountingLineDto';
import { AccountingLineQueryDto } from 'common/dto/query/AccountingLineQueryDto';
import { adminReferentFilterHeader } from 'common/schema/contract/adminReferentFilterHeader';

export const AccountingLineApi = {
  list: (opts: { query: AccountingLineQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedAccountingLineDto)
      .get(`/accounting-lines`, {
        params: opts.query,
        headers: {
          [adminReferentFilterHeader]: ConfigStorage.getValue(
            'adminReferentFilter'
          )
        }
      }),
  find: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(AccountingLineDto)
      .get(`/accounting-lines/${opts.id}`)
};
