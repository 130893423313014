import { IUserType } from 'common/dto/generated/UserDto';

export class UserLogic {
  static canLogout(user: IUserType | null) {
    if (user?.originalStrategy === 'jwt') return true;
    if (user?.originalStrategy === 'saml') return true;

    return false;
  }
}
