import React from 'react';
import { Menu } from 'antd';
const { SubMenu } = Menu;
import {
  DownloadOutlined,
  FileOutlined,
  InboxOutlined,
  NotificationOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useCurrentSideMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { Roles } from 'common/permissions/Roles';
import { InvoiceState } from 'common/schema/invoice/InvoiceTypes';
import { RolesLogic } from 'common/permissions/RolesLogic';

export interface InvoicingSideMenuProps {
  classNames?: string;
}

/**
 * Menu laterale della sezione fatture.
 */
export function InvoicingSideMenu(props: InvoicingSideMenuProps) {
  const currentPathKey = useCurrentSideMenuKey();
  const user = useCurrentUser();

  return (
    <Menu
      className={props.classNames}
      mode="inline"
      defaultSelectedKeys={['all']}
      selectedKeys={currentPathKey}
      defaultOpenKeys={['invoicing']}
    >
      <SubMenu key="invoicing" title="Fatture" icon={<FileOutlined />}>
        <Menu.Item key="all">
          <Link to="/invoicing/invoices/all">Tutte</Link>
        </Menu.Item>
        {RolesLogic.canUserRead(user, InvoiceState.ToValidate) && (
          <Menu.Item key="to-evaluate">
            <Link to="/invoicing/invoices/to-evaluate">Da Associare</Link>
          </Menu.Item>
        )}
        {RolesLogic.canUserRead(user, 'toAccept') && (
          <Menu.Item key="to-outcome">
            <Link to="/invoicing/invoices/to-outcome">
              Da Accettare/Rifiutare
            </Link>
          </Menu.Item>
        )}
        {RolesLogic.canUserRead(user, 'toProtocol') && (
          <Menu.Item key="to-protocol">
            <Link to="/invoicing/invoices/to-protocol">Da Protocollare</Link>
          </Menu.Item>
        )}
        {RolesLogic.canUserRead(user, InvoiceState.ToAccount) && (
          <Menu.Item key="to-account">
            <Link to="/invoicing/invoices/to-account">Da Contabilizzare</Link>
          </Menu.Item>
        )}
        {RolesLogic.canUserRead(user, InvoiceState.Accounted) && (
          <Menu.Item key="accounted">
            <Link to="/invoicing/invoices/accounted">Contabilizzate</Link>
          </Menu.Item>
        )}
        {RolesLogic.canUserRead(user, InvoiceState.PaidPartial) && (
          <Menu.Item key="partial-paid">
            <Link to="/invoicing/invoices/partial-paid">
              Pagate Parzialmente
            </Link>
          </Menu.Item>
        )}
        {RolesLogic.canUserRead(user, InvoiceState.Paid) && (
          <Menu.Item key="paid">
            <Link to="/invoicing/invoices/paid">Pagate</Link>
          </Menu.Item>
        )}

        <Menu.Item key="accepted">
          <Link to="/invoicing/invoices/accepted">Accettate</Link>
        </Menu.Item>

        {RolesLogic.canUserRead(user, InvoiceState.Rejected) && (
          <Menu.Item key="rejected">
            <Link to="/invoicing/invoices/rejected">Rifiutate</Link>
          </Menu.Item>
        )}

        <Menu.Item key="is-paper">
          <Link to="/invoicing/invoices/is-paper">Cartacee</Link>
        </Menu.Item>

        <Menu.Item key="expired-not-paid">
          <Link to="/invoicing/invoices/expired-not-paid">
            Scadute non Pagate
          </Link>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="batches" icon={<InboxOutlined />}>
        <Link to="/invoicing/batches">Lotti</Link>
      </Menu.Item>

      <Menu.Item key="sdi" icon={<NotificationOutlined />}>
        <Link to="/invoicing/notifications">Notifiche SDI</Link>
      </Menu.Item>

      <Menu.Item key="export-list" icon={<DownloadOutlined />}>
        <Link to="/invoicing/exports">Esportazioni Fatture</Link>
      </Menu.Item>
    </Menu>
  );
}
