import { ColumnsType, ColumnType } from 'antd/lib/table';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import React from 'react';
import { useCallback, useEffect, useState } from 'react';

interface FilterMap {
  [dataIndex: string]: string[];
}

interface Query {
  orderBy?: string;
  orderByDirection?: ColumnSortDirections;
  [key: string]: any;
}

/**
 * Imposta i filtri indicati dalla query nelle colonne della table.
 * @param columns colonne della table
 * @param query query in uso sui dati
 * @param filterMap mappatura dei campi della query rispetto ai filtri.
 * Per esempio la data di creazione (un filtro unico) contiene due valori (da-a); dovrà essere mappata come {createdAt: ['createdAtFrom', 'createdAtTo']}.
 * Oppure, se un dataIndex differisce dalla query, ex: { dataIndexLabelA: ['dataIndexLabelB']}
 * Se un campo mantiene la stessa chiave sia nella query che nel dataIndex, non è necessario indicarlo.
 */
function setFilters<T, Q extends Query>(
  columns: ColumnsType<T>,
  query: Q,
  filterMap?: FilterMap
) {
  return columns.map((column: ColumnType<T>) => ({
    ...column,
    filteredValue: getFilterArray(column, query, filterMap)
  }));
}

/**
 * Ritorna l'array con i filtri indicati nella query
 */
function getFilterArray<T, Q extends Query>(
  column: ColumnType<T>,
  query: Q,
  filterMap?: FilterMap
): string[] | null {
  // Se la colonna non è filtrabile o non presenta un dataIndex, non imposta il filtro.
  if (!column.filterDropdown) return null;
  if (!column.dataIndex) return null;
  const index = column.key ?? (column.dataIndex as string);
  // Mappa il filtro nell'array secondo la mappa
  if (filterMap && filterMap[index]) {
    const filter = filterMap[index].map(filterId => query[filterId]);

    // Se tutti i risultati non sono definiti, ignora il filtro
    if (filter.every(f => f == null)) {
      return null;
    }
    return filterMap[index].map(filterId => query[filterId]);
  }

  // Se non è indicato nella mappa,
  // recupera il valore del filtro direttamente dalla query
  return query[index] ? [query[index]] : null;
}

/**
 * Rimuove tutti gli ordinamenti presenti sulle colonne passate in input
 */
function resetSortOrder<T>(columns: ColumnsType<T>): ColumnsType<T> {
  return columns.map(column => ({ ...column, sortOrder: null }));
}

/**
 * Imposta l'ordinamento delle colonne in base a quanto indicato nella query.
 */
function setSorter<T, Q extends Query>(columns: ColumnsType<T>, query: Q) {
  // Rimuovo tutti i sorting e se necessario, imposto quelli richiesti dalla query
  const unsorted = resetSortOrder(columns);

  if (!query.orderBy || !query.orderByDirection) {
    return unsorted;
  }

  const index = unsorted.findIndex(
    (column: ColumnType<T>) => column.dataIndex === query.orderBy
  );

  if (index < 0) {
    return unsorted;
  }

  unsorted[index] = {
    ...columns[index],
    sortOrder:
      query.orderByDirection === ColumnSortDirections.ascend
        ? 'ascend'
        : 'descend'
  };
  return unsorted;
}

/**
 * Gestione dell'impostazione dei filtri automatici di default
 * in base alla query passata.
 * @param inputColumns Colonne di input della tabella
 * @param query Query utilizzata per filtrare i dati della tabella
 * @param filterMap mappatura dei campi della query rispetto ai filtri.
 * Per esempio la data di creazione (un filtro unico) contiene due valori (da-a); dovrà essere mappata come {createdAt: ['createdAtFrom', 'createdAtTo']}.
 * Oppure, se un dataIndex differisce dalla query, ex: { dataIndexLabelA: ['dataIndexLabelB']}
 * Se un campo mantiene la stessa chiave sia nella query che nel dataIndex, non è necessario indicarlo.
 * @returns l'oggetto colonne completo di paginazione da fornire alla tabella.
 */
export function getPaginationUrlColumns<T, Q extends Query>(
  columns: ColumnsType<T>,
  query: Q,
  filterMap?: FilterMap
) {
  const sortedColumns = setSorter(columns, query);
  const filteredColumns = setFilters(sortedColumns, query, filterMap);
  return filteredColumns;
}
