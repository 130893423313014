import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { CigExclusionReasonOptions } from 'common/schema/contract/ContractLabels';
import { useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useEffect } from 'react';

interface IProps {}

export function ExcludeCigForm(props: IProps) {
  const formik = useFormikContext<ContractDto>();
  const [oldCig, setOldCig] = useState(formik.values.cigCode);

  useEffect(() => {
    if (formik.values.excludeCig) {
      setOldCig(formik.values.cigCode);
      formik.setFieldValue('cigCode', null);
    } else {
      formik.setFieldValue('cigCode', oldCig);
    }
  }, [formik.values.excludeCig]);

  return (
    <>
      <FormFieldsContainer columns={6}>
        <FormFieldItem
          component={CheckboxInput}
          label="Esclusione CIG"
          name="excludeCig"
          colSpan={1}
        />
        {formik.values.excludeCig && (
          <FormFieldItem
            component={SelectInput}
            label="Motivo esclusione CIG"
            name="cigExclusionReason"
            options={CigExclusionReasonOptions}
            labelWidth="width-160"
            disabled={!formik.values.excludeCig}
            colSpan={5}
          />
        )}
      </FormFieldsContainer>
    </>
  );
}
