import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { SupplierInput } from 'client/components/schema/supplier/input/SupplierInput';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { ConfigEnumInput } from 'client/ui/form/input/configurable/ConfigEnumInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Section } from 'client/ui/section/Section';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { ClientInvoiceView } from '../views/ClientInvoiceView';
import { CurrencyInput } from 'client/ui/form/input/CurrencyInput';
import { ForceExportRuleSelectInput } from '../input/ForceExportRuleSelectInput';
import Text from 'antd/lib/typography/Text';

export interface InvoiceGeneralDataSectionProps {
  invoice: InvoiceDto;
}

/**
 * Mostra i dati generali della fattura
 */
export function InvoiceGeneralDataSection(
  props: InvoiceGeneralDataSectionProps
) {
  const { invoice } = props;

  return (
    <Section icon={<InboxOutlined />} title="Dati Generali" hasPadding>
      <FormFieldsContainer columns={2}>
        <FormFieldsContainer title="Cedente/Prestatore" removeTopTitleSpacing>
          <SupplierInput />
        </FormFieldsContainer>
        <FormFieldsContainer
          title="Cessionario/Committente"
          removeTopTitleSpacing
        >
          <ClientInvoiceView />
        </FormFieldsContainer>
      </FormFieldsContainer>
      {/* DATI FATTURA */}
      <FormFieldsContainer title="Dati Documento" columns={4}>
        <FormFieldItem
          component={TextInput}
          label="Numero"
          name="issuedNumber"
          placeholder="Numero"
        />

        <FormFieldItem
          colSpan={3}
          component={ConfigEnumInput}
          feature="sdi.values"
          enumKey="invoiceType"
          label="Tipologia"
          name="invoiceTypeCode"
          placeholder="Tipo Documento"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={4}>
        <FormFieldItem
          // inline
          component={DatePickerInput}
          label="Data Emissione"
          type="from"
          name="issuedDate"
          placeholder="Data"
        />
        <FormFieldItem
          // inline
          component={DatePickerInput}
          label="Data Ricezione"
          type="from"
          name="receivedAt"
          placeholder="Data Ricezione"
        />
        <FormFieldItem
          inline
          component={CheckboxInput}
          name="isArt73"
          label={null}
          children="Emesso Art.73"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          component={TextAreaInput}
          label="Causale"
          name="subject"
          placeholder="Causale / Oggetto"
          rows={3}
        />
      </FormFieldsContainer>
      {/* DATI PAGAMENTO */}
      <FormFieldsContainer title="Dati Pagamento" columns={2}>
        <FormFieldItem
          component={DatePickerInput}
          label="Scadenza"
          type="from"
          name="paymentExpiresAt"
          placeholder="Data Scadenza"
        />
        <FormFieldItem
          component={CurrencyInput}
          name="paymentAmount"
          label="Importo"
          placeholder="Importo del Pagamento"
        />
        <FormFieldItem
          component={ConfigEnumInput}
          feature="sdi.values"
          enumKey="paymentTermsScheme"
          label="Tipo"
          name="paymentTerms"
          placeholder="Tipo di Pagamento"
        />
        <FormFieldItem
          component={ConfigEnumInput}
          feature="sdi.values"
          enumKey="paymentMethodScheme"
          label="Metodo"
          name="paymentMethod"
          placeholder="Metodo di Pagamento"
        />
        <FormFieldItem
          component={TextInput}
          label="IBAN"
          name="paymentIban"
          placeholder="IBAN"
        />
      </FormFieldsContainer>
      {invoice.isPaper && (
        <>
          <FormFieldsContainer title="Esportazione PCC" columns={2}>
            <ForceExportRuleSelectInput />
          </FormFieldsContainer>
          <Text style={{ opacity: '0.65', fontSize: '0.9em' }}>
            Forza l’immissione dei dati su PCC riferiti ad una fattura che, in
            fase di elaborazione, sia stata esitata con un warning (c.d. fatture
            sospette).
          </Text>
        </>
      )}
    </Section>
  );
}
