import moment from 'moment';

export enum EconomicSemester {
  First = '1',
  Second = '2',
  Both = 'Both'
}

export const EconomicSemesterOptions = [
  { label: '1° Semestre', value: EconomicSemester.First },
  { label: '2° Semestre', value: EconomicSemester.Second },
  { label: 'Intero Anno', value: EconomicSemester.Both }
];

export class EconomicSemesterLogic {
  static render(semester: EconomicSemester): string {
    switch (semester) {
      case EconomicSemester.First:
        return '1º Semestre';
      case EconomicSemester.Second:
        return '2º Semestre';
      case EconomicSemester.Both:
        return 'Intero Anno';
    }
  }

  static renderInFileName(semester: EconomicSemester): string {
    switch (semester) {
      case EconomicSemester.First:
        return 'Semestre1';
      case EconomicSemester.Second:
        return 'Semestre2';
      case EconomicSemester.Both:
        return 'InteroAnno';
    }
  }

  /**
   * Calcola le date di inizio e di fine per il semestre indicato
   */
  static semesterToPeriod(year: number, semester: EconomicSemester) {
    const start = moment({
      year,
      month:
        semester === EconomicSemester.First ||
        semester === EconomicSemester.Both
          ? 0
          : 6
    }).startOf('month');

    const end = moment({
      year,
      month: semester === EconomicSemester.First ? 5 : 11
    }).endOf('month');

    return {
      start: start.format('YYYY-MM-DD'),
      end: end.format('YYYY-MM-DD')
    };
  }
}
