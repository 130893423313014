import { Tag, TagProps } from 'antd';
import { StateTag } from 'client/ui/state/StateTag';
import { PccExportDto } from 'common/dto/generated/PccExportDto';
import { AccountingLineState } from 'common/schema/accounting-line/AccountingLineTypes';
import * as React from 'react';
import { Model003Action } from 'server/schema/pcc/model003/Model003Types';
import { PccModelType } from 'server/schema/pcc/pcc-model-base/PccTypes';

export interface IPccActionTypeTagTagProps {
  pccExport: PccExportDto;
}

/**
 * Tag di stato dell'operazione da effettuare su PCC
 */
export function PccActionTypeTag(props: IPccActionTypeTagTagProps) {
  if (props.pccExport.model === PccModelType.Reception) {
    return <StateTag children="RC" />;
  }
  if (!props.pccExport.type) {
    return null;
  }
  return <StateTag children={props.pccExport.type} />;
}
