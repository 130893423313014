export type ActivityLogEntityType =
  | 'contract'
  | 'invoice'
  | 'contract_supplier'
  | 'accounting_line'
  | 'mandate'
  | 'shipment';

export enum ActivityLogAction {
  /** Creazione entità */
  create = 'create',
  /** Eliminazione entità */
  delete = 'delete',
  /** Aggiornamento entità */
  update = 'update',
  /** Annullamento Cancellazione */
  restore = 'restore',
  /** Modifica stato entità */
  changeState = 'changeState',
  /** Aggiunto elemento a entità */
  add = 'add',
  /** Rimozione elemento da entità */
  remove = 'remove',
  /** Protocollo parziale (con errori) */
  partialProtocol = 'partialProtocol',
  /** Protocollazione entità */
  protocol = 'protocol',
  /** Accettazione entità */
  accept = 'accept',
  /** Rifiuto entità */
  reject = 'reject',
  /** Ri-accettazione automatica (reinvio a SDI dopo Scarto Esito) */
  automaticAccept = 'automaticAccept',
  /** Ri-rifiuto automatico (reinvio a SDI dopo Scarto Esito) */
  automaticReject = 'automaticReject',
  /** Un log di errore */
  error = 'error'
}
