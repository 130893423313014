import { ColumnsType } from 'antd/lib/table';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceFilteredColumns } from './InvoiceColumns';

export const InvoiceToAccountColumns: ColumnsType<InvoiceDto> = [
  InvoiceFilteredColumns.isPaper,
  { ...InvoiceFilteredColumns.issuedDate, fixed: 'left' },
  InvoiceFilteredColumns.executionDate,
  InvoiceFilteredColumns.testingDate,
  InvoiceFilteredColumns.expiresAt,
  // InvoiceFilteredColumns.state,
  InvoiceFilteredColumns.supplierName,
  InvoiceFilteredColumns.protocol,
  InvoiceFilteredColumns.supplierFiscal,
  InvoiceFilteredColumns.issuedNumber,
  InvoiceFilteredColumns.invoiceTypeCode,
  InvoiceFilteredColumns.totalAmount
];
