import { AccountingLineDto } from 'common/dto/generated/AccountingLineDto';
import * as React from 'react';
import clx from 'classnames';
import {
  CalculatorFilled,
  CarryOutFilled,
  EuroCircleFilled
} from '@ant-design/icons';
import './PccCommunicationsState.scss';
import { Tooltip } from 'antd';

const renderMessage = (status: boolean) => {
  return status ? 'Comunicata' : 'Da comunicare';
};
export interface PccCommunicationsStateProps {
  line: AccountingLineDto;
}

/**
 * Serie di indicatori dello stato delle comunicazioni a PCC
 * delle linee di contabilizzazione
 */
export function PccCommunicationsState(props: PccCommunicationsStateProps) {
  const account = props.line.pccExportedAccounting;
  const expired = props.line.pccExportedExpiration;
  const payed = props.line.pccExportedPayment;
  return (
    <span className="pcc-communications-state">
      <Tooltip title={`Contabilizzazione: ${renderMessage(account)}`}>
        <CalculatorFilled className={clx('icon', { active: account })} />
      </Tooltip>
      <Tooltip title={`Scadenza: ${renderMessage(expired)}`}>
        <CarryOutFilled className={clx('icon', { active: expired })} />
      </Tooltip>
      <Tooltip title={`Pagamento: ${renderMessage(payed)}`}>
        <EuroCircleFilled className={clx('icon', { active: payed })} />
      </Tooltip>
    </span>
  );
}
