import { ArrowRightOutlined, DownloadOutlined } from '@ant-design/icons';
import { Divider, message, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getPccExportDownloadLink } from 'client/components/schema/pcc/getPccExportDownloadLink';
import { PccExportStateTag } from 'client/components/schema/pcc/PccExportStateTag';
import { ISODate } from 'client/ui/display/date/ISODate';
import { AnchorAPILink } from 'client/ui/link/AnchorAPILink';
import { PccExportDto } from 'common/dto/generated/PccExportDto';
import React from 'react';
import { Link } from 'react-router-dom';
import { Model003Action } from 'server/schema/pcc/model003/Model003Types';
import { PccModelType } from 'server/schema/pcc/pcc-model-base/PccTypes';
import { PccActionTypeTag } from '../../view/PccActionTypeTag';

export const PccExportColumns: ColumnsType<PccExportDto> = [
  {
    title: '#',
    dataIndex: 'id',
    width: 120
  },
  {
    title: 'Modello',
    dataIndex: 'model',
    width: 110,
    render: (_: any, record: PccExportDto) => <Tag>Modello {record.model}</Tag>
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    width: 70,
    render: (_: any, record: PccExportDto) => (
      <PccActionTypeTag pccExport={record} />
    )
  },
  {
    title: 'Stato',
    width: 180,
    render: (_, record) => <PccExportStateTag pccExport={record} />
  },
  {
    title: 'Data',
    dataIndex: 'createdAt',
    width: 120,
    render: (_: any, record: PccExportDto) =>
      record.createdAt && <ISODate date={record.createdAt} />
  },
  {
    title: 'Autore',
    dataIndex: 'authorId',
    render: (_: any, record: PccExportDto) => record.author?.name ?? '–'
  },

  // {
  //   title: 'File',
  //   dataIndex: 'fileName',
  //   render: (_: any, record: PccExportDto) => (
  //     <AnchorAPILink href={getPccExportDownloadLink(record.id)}>
  //       {record.fileName}
  //     </AnchorAPILink>
  //   )
  // },

  {
    title: '',
    align: 'right',
    width: 250,
    render: (_, record) => {
      let filter: string = '';
      if (record.model === PccModelType.Reception) {
        filter = 'shipped-reception';
      } else {
        switch (record.type) {
          case Model003Action.CO:
            filter = 'shipped-accounting';
            break;
          case Model003Action.CP:
            filter = 'shipped-payment';
            break;
          case Model003Action.CS:
            filter = 'shipped-expiration';
            break;
          default:
            message.error(`Tipo linea ID#${record.id} non riconosciuto`);
        }
      }
      return (
        <Space direction="horizontal">
          <AnchorAPILink href={getPccExportDownloadLink(record.id)}>
            Download File Invio <DownloadOutlined />
          </AnchorAPILink>
          <Divider type="vertical" />
          <Link to={`/accounting/pcc-exports/${filter}/${record.id}`}>
            Vai <ArrowRightOutlined />
          </Link>
        </Space>
      );
    }
  }
];
