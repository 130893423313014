import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import {
  SchemaLabel,
  SchemaPageNumber,
  SchemaPageSize,
  SchemaValidate
} from 'cli/generate/decorators/SchemaDecorators';
import { ContractState } from 'common/schema/contract/ContractTypes';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { GenerateDto } from 'cli/generate/decorators/GenerateDecorators';

@GenerateDto()
class ContractQuery {
  @SchemaPageNumber()
  pageNumber!: number;

  @SchemaPageSize()
  pageSize!: number;

  @SchemaLabel("Stato del contratto")
  state?: null | ContractState;

  @SchemaLabel("Data inizio")
  fromDate?: Date | null;

  @SchemaLabel("Data fine")
  toDate?: Date | null;

  @SchemaLabel("Ordinamento")
  orderBy?: string = 'createdAt';

  @SchemaLabel("Direzione di Ordinamento")
  orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;
  
  @SchemaLabel('Ricerca Oggetto')
  subject?: string | null;

    /** Ricerca sia per Oggetto che per dettaglio */ 
  @SchemaLabel("Ricerca Oggetto e Dettaglio")
  extendedSubject?: string | null;

  /* Ricerca in base al CIG o all'oggetto */
  @SchemaLabel('Ricerca codice CIG/oggetto')
  labelSearch?: string | null;

  @SchemaLabel('Nome Contraente')
  supplierName?: string | null;

  @SchemaLabel('CIG')
  cigCode?: string | null;

  @SchemaLabel('Numero del Contratto')
  number?: string | null;

  /*
   * Filtra solo i contratti collegabili alla fattura.
   */
  @SchemaLabel('Collegabile alla fattura')
  invoiceSelectable?: boolean | null;

  /*
  * forza l'inclusione di un contratto in una ricerca
  */
  @SchemaLabel('Includi contratto')
  includeContractId?: number | null;

  @SchemaLabel('Nome Referente Amministrativo')
  adminReferentName?: string | null;
}

/**
 * Rappresentazione DTO della classe ContractQuery 
 * Hash: 723a4b1a0819e663695701cfb579657b
 */
@ValidationSchema(() => ContractQuerySchema)
export class ContractQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, enum: ['InProgress', 'Signing', 'Stipulated', 'Registered', 'Canceled'], description: 'Stato del contratto' })
  state?: ContractState | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data inizio' })
  fromDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data fine' })
  toDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Ordinamento' })
  orderBy?: string | undefined = 'createdAt';
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;
  @ApiProperty({ required: false, type: String, description: 'Ricerca Oggetto' })
  subject?: string | null | undefined;
  /** Ricerca sia per Oggetto che per dettaglio */
  @ApiProperty({ required: false, type: String, description: 'Ricerca Oggetto e Dettaglio' })
  extendedSubject?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Ricerca codice CIG/oggetto' })
  labelSearch?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome Contraente' })
  supplierName?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'CIG' })
  cigCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Numero del Contratto' })
  number?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Collegabile alla fattura' })
  invoiceSelectable?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Includi contratto' })
  includeContractId?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome Referente Amministrativo' })
  adminReferentName?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ContractQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ContractQueryDto ? values : plainToClass(ContractQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ContractQuerySchema.validate(classToPlain(this), options);
    return new ContractQueryDto(validated);
  }
}

export const ContractQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    state: yup.string().oneOfEnum(ContractState).nullable().label('Stato del contratto'),
    fromDate: yup.date().nullable().label('Data inizio'),
    toDate: yup.date().nullable().label('Data fine'),
    orderBy: yup.string().default('createdAt').label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento'),
    subject: yup.string().nullable().label('Ricerca Oggetto'),
    extendedSubject: yup.string().nullable().label('Ricerca Oggetto e Dettaglio'),
    labelSearch: yup.string().nullable().label('Ricerca codice CIG/oggetto'),
    supplierName: yup.string().nullable().label('Nome Contraente'),
    cigCode: yup.string().trim().nullable().label('CIG'),
    number: yup.string().nullable().label('Numero del Contratto'),
    invoiceSelectable: yup.boolean().nullable().label('Collegabile alla fattura'),
    includeContractId: yup.number().nullable().label('Includi contratto'),
    adminReferentName: yup.string().nullable().label('Nome Referente Amministrativo')
  })
  .noUnknown()
  .meta({ schemaName: "ContractQuerySchema" })
  .required();
