import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';

/**
 * Rappresentazione DTO della classe InvoiceTransmissionData definita in: src/server/schema/invoice/invoice/invoice-data/InvoiceTransmissionData.embedded.ts
 * Hash: de171d55b984235b2c86dbf416a3a145
 */
@ValidationSchema(() => InvoiceTransmissionDataSchema)
export class InvoiceTransmissionDataDto {
  @ApiProperty({ type: String })
  senderIdCountry!: string;
  @ApiProperty({ type: String })
  senderIdCode!: string;
  @ApiProperty({ type: String })
  progressive!: string;
  @ApiProperty({ type: String })
  format!: string;
  @ApiProperty({ type: String })
  recipientCode!: string;
  @ApiProperty({ required: false, type: String })
  recipientPec?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceTransmissionDataDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceTransmissionDataDto ? values : plainToClass(InvoiceTransmissionDataDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await InvoiceTransmissionDataSchema.validate(classToPlain(this), options);
    return new InvoiceTransmissionDataDto(validated);
  }
}

/** Interfaccia simmetrica al DTO InvoiceTransmissionDataDto */
export type IInvoiceTransmissionDataType = IDto<InvoiceTransmissionDataDto>;

/**
 * DTO Paginato della classe InvoiceTransmissionData
 */
export class PaginatedInvoiceTransmissionDataDto {
  @ApiProperty({ type: [InvoiceTransmissionDataDto] })
  @Type(() => InvoiceTransmissionDataDto)
  items!: InvoiceTransmissionDataDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const InvoiceTransmissionDataSchema = yup
  .object({
    senderIdCountry: yup.string().required(),
    senderIdCode: yup.string().required(),
    progressive: yup.string().required(),
    format: yup.string().required(),
    recipientCode: yup.string().required(),
    recipientPec: yup.string().nullable()
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceTransmissionDataSchema" })
  .required();
