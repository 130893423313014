import { IAccountingLineType } from 'common/dto/generated/AccountingLineDto';
import { EntityMachine } from 'common/fsm/EntityMachine';
import { AccountingLineState, PayableDebtStates } from './AccountingLineTypes';

export const AccountingLineMachine = EntityMachine.for<IAccountingLineType>()
  .withState(line => line.state)
  /* Una linea pagata non può essere modificata */
  .withAction('edit.general', {
    allowed: line =>
      line.state !== AccountingLineState.Paid || line.mandateId == null
  })
  .withAction('transition.to-ship', {
    allowed: line => PayableDebtStates.includes(line.debtState)
  });
