import { TableOutlined } from '@ant-design/icons';
import { ISODate } from 'client/ui/display/date/ISODate';
import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { Section } from 'client/ui/section/Section';
import { PccExportDto } from 'common/dto/generated/PccExportDto';
import * as React from 'react';

export interface IPccExportSectionProps {
  pccExport: PccExportDto;
}

export function PccExportSection(props: IPccExportSectionProps) {
  const { pccExport } = props;
  return (
    <Section
      icon={<TableOutlined />}
      title="Dati della comunicazione"
      hasPadding
    >
      <FormFieldsContainer columns={2}>
        <DataItem label="ID" value={pccExport.id} />
        <DataItem label="Modello" value={pccExport.model} />
        <DataItem
          label="Data"
          value={
            <ISODate
              date={pccExport.createdAt}
              format={'DD/MM/YYYY HH:mm:ss'}
            />
          }
        />
        <DataItem label="Autore" value={pccExport.author?.name ?? 'N.D.'} />
        <DataItem label="File" value={pccExport.fileName} />
      </FormFieldsContainer>
    </Section>
  );
}
