import { logger } from 'client/core/logger/logger';
import { EconomicEntryDto } from 'common/dto/generated/EconomicEntryDto';

export type IEconomicEntryTree = {
  roots: IEconomicEntryNode[];
  findNodeByPath: (path: string) => IEconomicEntryNode | undefined;
  getNode: (value: string | number) => IEconomicEntryNode | undefined;
};

export type IEconomicEntryNode =
  | {
      type: 'Node';
      key: string;
      id: string;
      value: string;
      label: string;
      searchText: string;
      path: string;
      selectable: boolean;
      children: IEconomicEntryNode[];
    }
  | {
      type: 'Leaf';
      key: number;
      id: number;
      value: number;
      label: string;
      searchText: string;
      entry: EconomicEntryDto;
      children?: IEconomicEntryNode[]; // Non dovrebbe mai accadere.
    };

export interface ICreateEconomicEntriesTreeOptions {
  canSelectNodes?: boolean;
}

/**
 * Crea un albero a partire dalle voci di conto
 */
export function createTreeFromEconomicEntries(
  entries: EconomicEntryDto[],
  options: ICreateEconomicEntriesTreeOptions = {}
): IEconomicEntryTree {
  // Hashmap intermedia in cui registro l'associazione label - nodo. È utile per evitare
  // di dover cercare il label per ogni Entry.
  const store = new Map<string, IEconomicEntryNode>();

  // Struttura finale in cui registro i nodi Root
  const tree: IEconomicEntryTree = {
    roots: [],
    findNodeByPath: path => store.get(path),
    getNode: value =>
      Array.from(store.values()).find(node => node.value === value)
  };

  // Per ogni voce di conto, considero tutto il path (i.e. "Categoria\Sottocategoria\Voce"),
  // e costruisco l'albero (se non c'è già). In questo caso aggiungo anche
  // l'albero nello `store` per avere una cache.
  for (const entry of entries) {
    const labels = entry.path.split('\\');
    const title = labels.pop(); // Rimuovo l'ultimo elemento dai label (perché è il titolo del nodo)

    let parent = tree.roots;
    let exploredLabels: string[] = [];

    // Itero sui label dalla voce di livello 1 a quella più annidata,
    // per creare i nodi intermedi in caso non siano presenti.
    for (const label of labels) {
      exploredLabels.push(label);
      const nodePath = exploredLabels.join('\\');

      let node = store.get(nodePath);

      // Creo il nodo se non c'è nell'Hashmap.
      if (!node) {
        node = {
          type: 'Node',
          key: nodePath,
          id: label,
          path: nodePath,
          value: nodePath,
          label,
          searchText: nodePath,
          selectable: !!options.canSelectNodes,
          children: []
        };
        store.set(nodePath, node);
        parent.push(node);
      }

      // Passo al nodo figlio
      if (node.type !== 'Node') {
        logger.warn(`[CC/Tree] Si sta aggiungendo un nodo figlio ad una voce di conto economico! (${node.label})`); // prettier-ignore
      }

      node.children ??= [];
      parent = node!.children;
    }

    // Una volta raggiunto l'ultimo nodo intermedio, inserisco il figlio.
    const leaf: IEconomicEntryNode = {
      type: 'Leaf',
      key: entry.id,
      id: entry.id,
      label: `${entry.code} - ${entry.title}`,
      searchText: `${entry.code} ${entry.path}`,
      value: entry.id,
      entry
    };
    parent.push(leaf);
    store.set(entry.path, leaf);
  }

  return tree;
}

/**
 * Tree vuoto come placeholder
 */
export const EmptyEconomicEntriesTree: IEconomicEntryTree = {
  roots: [],
  getNode: () => undefined,
  findNodeByPath: () => undefined
};
