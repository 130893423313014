import { SorterResult } from 'antd/lib/table/interface';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { InvoiceApi } from 'client/components/schema/invoice/InvoiceApi';
import { InvoiceExportColumns } from 'client/components/schema/invoice/table/InvoiceExportColumns';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { getDateRange } from 'client/ui/table/TableColumnFilter';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { TableSticky } from 'client/ui/table/TableSticky';
import { InvoiceExportDto } from 'common/dto/generated/InvoiceExportDto';
import { InvoiceExportQueryDto } from 'common/dto/query/InvoiceExportQueryDto';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { InvoiceExportModal } from './InvoiceExportModal';

interface Params {}

export interface IInvoiceExportListPageProps
  extends RouteComponentProps<Params> {}

export function InvoiceExportListPage(props: IInvoiceExportListPageProps) {
  const [query, setQuery] = useState<InvoiceExportQueryDto>(
    new InvoiceExportQueryDto(
      getPaginationUrlQuery(props.location.search, {
        pageNumber: 1,
        pageSize: 20
      })
    )
  );

  const { response, loading, error } = useApiQuery(InvoiceApi.export.list, {
    data: { query }
  });
  const invoiceExport = response?.data?.items;

  const columns = getPaginationUrlColumns<
    InvoiceExportDto,
    InvoiceExportQueryDto
  >(InvoiceExportColumns, query, {
    fromDate: ['fromDateStart', 'fromDateEnd'],
    toDate: ['toDateStart', 'toDateEnd']
  });

  useEffect(() => {
    setQuery(
      new InvoiceExportQueryDto(
        getPaginationUrlQuery(props.location.search, {
          pageNumber: 1,
          pageSize: 20
        })
      )
    );
  }, [props.location.search]);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/invoicing/invoices/all', title: `Fatture` });
  useBreadcrumbItem({
    priority: 20,
    path: '/invoicing/exports',
    title: 'Esportazioni',
    menuKey: 'invoicing',
    sideMenuKey: 'export-list'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco delle esportazioni"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle children="Lista Esportazioni" />}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={<InvoiceExportModal history={props.history} />}
      />
      <PageContent noPadding noSpace>
        <TableSticky
          loading={loading || !invoiceExport}
          rowKey="id"
          columns={columns}
          dataSource={invoiceExport}
          size="middle"
          onChange={(pagination, filters, sorter) => {
            const { field, order } = sorter as SorterResult<InvoiceExportDto>;

            // Date
            const fromDateRange = getDateRange(filters.fromDate);
            const toDateRange = getDateRange(filters.toDate);

            const exportQuery = new InvoiceExportQueryDto({
              ...query,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize,
              fromDateStart: fromDateRange.from,
              fromDateEnd: fromDateRange.to,
              toDateStart: toDateRange.from,
              toDateEnd: toDateRange.to,
              orderBy: order ? field?.toString() : 'id',
              orderByDirection: order
                ? ColumnSortDirections[order]
                : ColumnSortDirections.descend
            });

            setPaginationUrlQuery(props, exportQuery);
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
