import { TagProps } from 'antd';
import { SelectOption } from 'client/ui/form/input/SelectInput';
import { ShipmentState } from './ShipmentTypes';
import { ShipmentMachine } from './ShipmentMachine';

interface StateSelectOption extends SelectOption, TagProps {}

export const ShipmentStateOptions: StateSelectOption[] = [
  {
    value: ShipmentState.InProgress,
    label: 'In Lavorazione',
    color: 'blue'
  },
  {
    value: ShipmentState.Shipped,
    label: 'Inviata',
    color: 'green'
  }
];
