import React, { useCallback, useState } from 'react';
import { Modal, Button, Alert, message } from 'antd';

import { LinkOutlined, PlusOutlined } from '@ant-design/icons';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import {
  FormFieldItem,
  IFormFieldItemProps
} from 'client/ui/form/field/FormFieldItem';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { Formik } from 'formik';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ModalFooter } from 'client/ui/modal/ModalFooter';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { MandateApi } from '../MandateApi';
import {
  MandateFindQueryDto,
  MandateFindQuerySchema
} from 'common/dto/query/MandateFindQueryDto';
import { useHistory } from 'react-router';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';

export interface MandateSiabLinkModalProps {}

/**
 * Modal per la creazione del Mandato collegato a Siab
 * Raccoglie i dati di Numero e Anno mandato per avviare la richiesta a Siab
 * per ottenere i dati del Mandato
 */
export function MandateSiabLinkModal(props: MandateSiabLinkModalProps) {
  const [isVisible, setIsVisible] = useState(false);

  const [query, setQuery] = useState(new MandateFindQueryDto());
  const history = useHistory();

  const { response, loading, error, setPristine } = useApiQuery(
    MandateApi.siab.find,
    {
      skip: query.mandateNumber == null || query.mandateYear == null,
      data: { query }
    }
  );

  const [create, createState] = useApiMutation(MandateApi.create, {});

  const mandate = response?.data;

  // Stato
  const canLink = mandate && mandate.siab && !mandate.existing;
  const alreadyLinked = mandate && mandate.siab && mandate.existing;
  const notFound = mandate && !mandate.siab;

  const showModal = () => {
    setIsVisible(true);
  };

  // Collega il mandato
  const handleLink = useCallback(async () => {
    if (!mandate || !mandate.siab) return;

    try {
      const result = await create({
        data: {
          input: new MandateFindQueryDto({
            mandateNumber: mandate.siab.mandateNumber!,
            mandateYear: mandate.siab.mandateYear!,
            commitmentNumber: mandate.siab.commitmentNumber
          })
        }
      });
      message.success('Mandato registrato con successo');
      history.push(`/shipping/mandates/all/${result.data.id}`);
      setIsVisible(false);
    } catch (e) {
      console.error(e);
      message.error(
        `Si è verificato un errore durante la registrazione del mandato. Si prega di riprovare.`
      );
    }
  }, [mandate, history, setIsVisible]);

  // Chiudo la modal
  const handleCancel = useCallback(() => {
    if (createState.loading) return;
    setIsVisible(false);
    setPristine();
    setQuery(new MandateFindQueryDto());
  }, [createState.loading, setIsVisible, setQuery]);

  const labelWidth: ILabelWidth = 'width-140';

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
        Collega Mandato SIAB
      </Button>
      <ModalDialog
        size="small"
        title="Collega Mandato SIAB"
        visible={isVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Formik
          enableReinitialize
          initialValues={query}
          onSubmit={async rawValues => {
            const query = await rawValues.validate();
            setQuery(query);
          }}
          validate={schemaToValidator(MandateFindQuerySchema)}
        >
          <FormikForm
            editable={true}
            disabled={loading || createState.loading || !!canLink}
            helpInTooltips
          >
            <FormFieldsContainer>
              <FormFieldItem
                labelWidth={labelWidth}
                component={NumberInput}
                label="Numero Mandato"
                name="mandateNumber"
                placeholder="Numero Mandato"
              />
              <FormFieldItem
                labelWidth={labelWidth}
                component={NumberInput}
                label="Anno Esercizio"
                name="mandateYear"
                placeholder="Anno Esercizio"
              />
              <FormFieldItem
                labelWidth={labelWidth}
                component={NumberInput}
                label="Numero Impegno"
                name="commitmentNumber"
                placeholder="Numero Impegno (opzionale)"
              />
            </FormFieldsContainer>
            {canLink && (
              <Alert
                style={{ marginTop: '16px' }}
                message="Mandato Trovato."
                description={`È stato trovato il mandato con beneficiario '${
                  mandate!.siab!.beneficiary ?? 'N.A.'
                }'. Premi su 'Collega Mandato' per confermare il collegamento.`}
                type="success"
                showIcon
              />
            )}
            {notFound && (
              <Alert
                message="Mandato non trovato. "
                description="Modifica i valori ed effettua una nuova ricerca."
                type="warning"
                showIcon
              />
            )}
            {alreadyLinked && (
              <Alert
                message="Mandato già registrato. "
                description="Il mandato è stato trovato su SIAB ma è già registrato a sistema."
                type="warning"
                showIcon
              />
            )}
            <ModalFooter>
              <Button key="back" onClick={handleCancel}>
                Annulla
              </Button>
              <>
                {canLink ? (
                  <Button
                    icon={<LinkOutlined />}
                    type="primary"
                    loading={createState.loading}
                    onClick={handleLink}
                  >
                    Collega Mandato
                  </Button>
                ) : (
                  <FormikSendButton type="primary" loading={loading}>
                    Cerca Mandato
                  </FormikSendButton>
                )}
              </>
            </ModalFooter>
          </FormikForm>
        </Formik>
      </ModalDialog>
    </>
  );
}
