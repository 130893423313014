import React from 'react';
import clx from 'classnames';
import { Menu } from 'antd';
const { SubMenu } = Menu;
import { useThemeConfig } from 'client/components/schema/config/ClientConfigModule';
import { FileSyncOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useCurrentSideMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';

export interface MandateSideMenuProps {
  classNames?: string;
}

/**
 * Menu laterale della sezione Spedizioni UBRRAC.
 */
export function MandateSideMenu(props: MandateSideMenuProps) {
  const { options } = useThemeConfig();
  const currentPathKey = useCurrentSideMenuKey();

  return (
    <Menu
      className={props.classNames}
      mode="inline"
      selectedKeys={currentPathKey}
      defaultOpenKeys={['mandates']}
    >
      <SubMenu key="mandates" title="Mandati" icon={<FileSyncOutlined />}>
        <Menu.Item key="mandates-all">
          <Link to="/shipping/mandates/all">Tutti</Link>
        </Menu.Item>
        <Menu.Item key="mandates-imported">
          <Link to="/shipping/mandates/imported">Importati</Link>
        </Menu.Item>
        <Menu.Item key="mandates-pending">
          <Link to="/shipping/mandates/pending">Pendenti</Link>
        </Menu.Item>
        <Menu.Item key="mandates-manual">
          <Link to="/shipping/mandates/shipped">Spediti</Link>
        </Menu.Item>
        <Menu.Item key="mandates-paid">
          <Link to="/shipping/mandates/paid">Pagati</Link>
        </Menu.Item>
        <Menu.Item key="mandates-canceled">
          <Link to="/shipping/mandates/canceled">Annullati</Link>
        </Menu.Item>
        <Menu.Item key="mandates-manual">
          <Link to="/shipping/mandates/manual">Manuali</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
}
