import { Tag, TagProps } from 'antd';
import { StateTag } from 'client/ui/state/StateTag';
import { MandateDto } from 'common/dto/generated/MandateDto';
import { MandateState } from 'common/schema/mandate/MandateTypes';
import * as React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

export interface MandateStateTagProps {
  mandate: MandateDto;
}

function stateToProps(mandate: MandateDto): TagProps {
  switch (mandate.state) {
    case MandateState.Pending:
      return { color: 'blue', children: 'Pendente' };
    case MandateState.Shipped:
      return { color: 'cyan', children: 'Spedito' };
    case MandateState.Paid:
      return { color: 'green', children: 'Pagato' };
    case MandateState.Canceled:
      return { color: 'gold', children: 'Annullato' };
    case MandateState.Imported:
      return { color: 'default', children: 'Importato' };
    case MandateState.Manual:
      return { color: 'magenta', children: 'Manuale' };
  }
  assertNever(mandate.state);
}

export function MandateStateTag(props: MandateStateTagProps) {
  return <StateTag {...stateToProps(props.mandate)} />;
}
