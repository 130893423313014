import { Tag } from 'antd';
import { PccExportDto } from 'common/dto/generated/PccExportDto';
import React from 'react';

interface IPccExportStateTagProps {
  pccExport: PccExportDto;
}

/**
 * Ritorna il tag di stato relativo all'export.
 * @param props
 * @returns
 */
export function PccExportStateTag(props: IPccExportStateTagProps) {
  const { pccExport } = props;

  return pccExport.withError ? (
    <Tag color="orange">Risposta PCC con Errori</Tag>
  ) : pccExport.responseId ? (
    <Tag color="green">Risposta PCC Importata</Tag>
  ) : (
    <Tag color="blue">Esportato per PCC</Tag>
  );
}
