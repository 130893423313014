import React from 'react';
import clx from 'classnames';
import { Layout, BackTop } from 'antd';
const { Content, Sider } = Layout;
import './PageLayout.scss';
import { useThemeConfig } from 'client/components/schema/config/ClientConfigModule';
import { PageLayoutHeader } from './header/PageLayoutHeader';
import { MainMenu } from '../../routes/menu/MainMenu';
import { SideMenuSwitch } from 'client/routes/menu/SideMenuSwitch';

export const getSiderWidth = () => {
  return 220;
};
export const pageFixedSizes = {
  siderWidht: 220,
  topMenuHeight: 34, // TODO attualmente impostati anche da css
  topLogoHeight: 40, // TODO attualmente impostati anche da css
  topHeaderHeight: 74 // somma dei precedenti TODO attualmente impostati anche da css
};
export interface PageLayoutProps {
  children: React.ReactNode;
  hasMainMenu?: boolean;
  hasSideMenu?: boolean;
  className?: string;
}

/**
 * Layout principale dell'applicativo.
 */
export function PageLayout(props: PageLayoutProps) {
  const { options } = useThemeConfig();

  return (
    <Layout className={clx('page-layout', props.className)}>
      <PageLayoutHeader>{props.hasMainMenu && <MainMenu />}</PageLayoutHeader>
      {props.hasSideMenu ? (
        <Layout>
          <Sider
            width={pageFixedSizes.siderWidht}
            className="page-layout-sider"
          >
            <SideMenuSwitch />
          </Sider>
          <Layout>
            <Content className="page-layout-content">{props.children}</Content>
          </Layout>
        </Layout>
      ) : (
        <Content className="page-layout-content">{props.children}</Content>
      )}
      <BackTop className="page-back-top" visibilityHeight={200} />
    </Layout>
  );
}
