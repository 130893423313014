import React from 'react';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { DocumentalUsersQueryDto } from 'common/dto/query/DocumentalUsersQueryDto';
import { DocumentalApi } from './DocumentalApi';
import { DocumentalCorrespondentDto } from 'common/dto/documental/DocumentalCorrespondentDto';
import { UserDto } from 'common/dto/generated/UserDto';
import { SelectProps } from 'antd';
import { DocumentalUserTypeFilter } from 'common/schema/documental/DocumentalTypes';
import { AsyncText } from 'client/ui/form/text/AsyncText';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';
import { useField } from 'formik';

interface Props extends SelectProps<any> {
  name: string;
  idFieldName: string;
  shownUsername?: UserDto | null;
  label?: React.ReactNode;
  placeholder?: string;
  fallbackOffice?: string | null;
}

// TODO Gestire errori
/**
 * Permette la selezione di un utente del documentale
 * @param shownUsername il nome mostrato in modalità non-edit. Se non fornito, verrà mostrato il codice utente del documentale
 */
export function DocumentalOfficeSelectInput(props: Props) {
  const {
    name,
    idFieldName,
    label,
    placeholder,
    shownUsername,
    ...otherProps
  } = props;

  const [, , idHelper] = useField(idFieldName);

  const baseQuery = new DocumentalUsersQueryDto({
    pageNumber: 1,
    pageSize: 20,
    isOffice: true,
    isUser: false,
    correspondentType: DocumentalUserTypeFilter.Internal
  });

  return (
    <AsyncSelectInput
      {...otherProps}
      name={name}
      placeholder={placeholder}
      query={{
        apiFn: DocumentalApi.correspondent.list,
        options: (_, search) => ({
          data: {
            query: new DocumentalUsersQueryDto({
              ...baseQuery,
              description: search
            })
          }
        })
      }}
      optionTransform={(office: DocumentalCorrespondentDto) => ({
        label: office.code + ' - ' + office.description,
        value: office.code
      })}
      responseTransform={data => data.items}
      responseMeta={data => ({
        shown: data.items.length,
        total: data.meta.total
      })}
      dropdownMatchSelectWidth={false}
      showSearch
      refreshOnSearch
      allowClear
      onAfterSelect={(office?: DocumentalCorrespondentDto) => {
        // Imposto l'office ID alla selezione dell'ufficio
        if (office) {
          idHelper.setValue(office.id);
        }
      }}
      loadInitialValue={{
        apiFn: DocumentalApi.correspondent.find,
        options: (formik, search) => ({
          skip: !formik.values[name] || formik.values[name] == '',
          data: {
            query: new DocumentalUsersQueryDto(baseQuery),
            code: formik.values[name]
          }
        })
      }}
    />
  );
}

DocumentalOfficeSelectInput.renderFieldValue = (
  value?: string | null,
  props?: Props
) => {
  if (!value && props?.fallbackOffice) return props.fallbackOffice;

  if (!value) return '-';

  return (
    <AsyncText
      query={{
        apiFn: DocumentalApi.correspondent.list,
        options: {
          data: {
            query: new DocumentalUsersQueryDto({
              pageSize: 1,
              pageNumber: 1,
              isOffice: true,
              isUser: false,
              correspondentType: DocumentalUserTypeFilter.Internal,
              exactCode: value
            })
          }
        }
      }}
      responseTransform={data =>
        data.items[0]?.description ?? `Ufficio ${value} non trovato`
      }
    />
  );
};
