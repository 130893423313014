import { ArrowRightOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { AccountingLineApi } from 'client/components/schema/accounting-line/AccountingLineApi';
import { AccountingLineStateColumns } from 'client/components/schema/accounting-line/table/AccountingLineStateColumns';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import {
  getDateRange,
  getInvoiceFilter
} from 'client/ui/table/TableColumnFilter';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { TableSticky } from 'client/ui/table/TableSticky';
import { AccountingLineDto } from 'common/dto/generated/AccountingLineDto';
import { AccountingLineQueryDto } from 'common/dto/query/AccountingLineQueryDto';
import { AccountingLineColumnSort } from 'common/dto/query/types/AccountingLineQueryTypes';
import { AccountingLinePccQuery } from 'common/schema/accounting-line/AccountingLinePccQuery';
import { get } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Model003Action } from 'server/schema/pcc/model003/Model003Types';
import { DynamicPccExport003Button } from './pcc-action/DynamicPccExport003Button';

interface Params {}
export interface AccountingLineListPageProps
  extends RouteComponentProps<Params> {}

export interface AccountingLinePaperInvoicesListPageProps
  extends RouteComponentProps<Params> {}

/**
 * Ritorna vero se uno dei filtri viene modificato
 * e quindi è necessario il reset delle linee selezionate
 */
function needSelectionReset(
  oldQuery: AccountingLineQueryDto,
  updatedQuery: AccountingLineQueryDto
) {
  const keys = ['description', 'expireDateTo', 'expireDateFrom'];

  for (const key of keys) {
    if (get(oldQuery, key) != get(updatedQuery, key)) {
      return true;
    }
  }
  return false;
}

/**
 * Pagina di Elenco delle Linee di Contabilizzazione
 */
export function AccountingLinePaymentListPage(
  props: AccountingLineListPageProps
) {
  const options = {
    title: 'Linee Contabili da Comunicare Pagamento a PCC',
    columns: [
      ...AccountingLineStateColumns,
      {
        title: '',
        align: 'right',
        fixed: 'right',
        width: 55,
        render: (_: any, record: AccountingLineDto) => (
          <Link to={`/accounting/to-ship/payment/${record.id}`}>
            Vai <ArrowRightOutlined />
          </Link>
        )
      }
    ] as ColumnsType<AccountingLineDto>,
    query: AccountingLinePccQuery.CP,
    filter: 'payment',
    sideMenuPrefix: 'to-ship'
  };

  const defaultQuery = {
    pageNumber: 1,
    pageSize: 20
  };

  const [query, setQuery] = useState<AccountingLineQueryDto>(
    new AccountingLineQueryDto(
      getPaginationUrlQuery(props.location.search, {
        ...defaultQuery,
        ...options.query
      })
    )
  );
  const [selected, setSelected] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const { response, loading, error } = useApiQuery(AccountingLineApi.list, {
    data: { query }
  });
  const lines = response?.data?.items;

  useEffect(() => {
    setQuery(
      new AccountingLineQueryDto(
        getPaginationUrlQuery(props.location.search, {
          ...defaultQuery,
          ...options?.query
        })
      )
    );
  }, [props.location.search]);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/accounting/lines/all',
    title: `Contabilizzazione`
  });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: options.title,
    sideMenuKey: 'to-ship-payment',
    menuKey: 'accounting'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco delle linee contabili"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle>{options.title}</PageHeadingTitle>}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={
          <>
            <Tooltip title="Seleziona le linee contabili su tutte le pagine rispettando i filtri impostati">
              <Checkbox
                value={selectAll}
                onChange={event => {
                  setSelectAll(event.target.checked);
                }}
              >
                Seleziona tutti
              </Checkbox>
            </Tooltip>

            <DynamicPccExport003Button
              items={selected}
              action={Model003Action.CP}
              query={query}
              selectAll={selectAll}
            />
          </>
        }
      />
      <PageContent noPadding noSpace>
        <TableSticky
          loading={loading}
          scroll={{ x: true }}
          rowKey="id"
          columns={options.columns}
          rowSelection={
            selectAll
              ? undefined
              : {
                  hideSelectAll: true,
                  selectedRowKeys: selected,
                  onSelect: (record, selection) => {
                    if (selection) {
                      setSelected([...selected, record.id]);
                    } else {
                      setSelected(selected.filter(id => id !== record.id));
                    }
                  }
                }
          }
          dataSource={lines}
          size="middle"
          onChange={(pagination, filters, sorter) => {
            const { field, order } = sorter as SorterResult<AccountingLineDto>;
            const expirationDateRange = getDateRange(
              filters['invoice.expiresAt']
            );

            const description = filters['description']
              ? filters['description'][0].toString()
              : null;

            const invoiceFilter = getInvoiceFilter(filters['invoiceId']);

            const linesQuery = new AccountingLineQueryDto({
              ...query,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize,
              orderBy: order
                ? (field?.toString() as AccountingLineColumnSort)
                : undefined,
              orderByDirection: order ? ColumnSortDirections[order] : undefined,
              expireDateFrom: expirationDateRange.from,
              expireDateTo: expirationDateRange.to,
              description,
              invoiceNumber: invoiceFilter.invoiceNumber,
              invoiceYear: invoiceFilter.invoiceYear
            });

            // Resetta la selezione solo se uno dei seguenti filtri viene aggiornato
            if (needSelectionReset(query, linesQuery)) {
              setSelected([]);
            }

            setPaginationUrlQuery(props, linesQuery);
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
