import * as React from 'react';
import { Section } from 'client/ui/section/Section';
import { ArrowRightOutlined, MailOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { ShipmentApi } from 'client/components/schema/shipment/ShipmentApi';
import { useState } from 'react';
import { ShipmentQueryDto } from 'common/dto/query/ShipmentQueryDto';
import { ShipmentDetailColumns } from 'client/components/schema/shipment/table/ShipmentDetailColumns';

export interface ShipmentIntegrationsSectionProps {
  shipmentId: number | undefined | null;
  disabled: boolean;
}

/**
 * Sezione Dati Spedizione della Pagina delle Spedizioni
 */
export function ShipmentIntegrationsSection(
  props: ShipmentIntegrationsSectionProps
) {
  const { shipmentId, disabled } = props;

  const [query, setQuery] = useState<ShipmentQueryDto>(
    new ShipmentQueryDto({
      pageNumber: 1,
      pageSize: 5
    })
  );

  const { response, loading } = useApiQuery(ShipmentApi.integrations.list, {
    skip: !shipmentId,
    data: { id: shipmentId! }
  });

  const integrationShipments = response?.data.items;

  if (disabled) return <></>;

  return (
    <Section icon={<MailOutlined />} title="Integrazioni Spedizione">
      <Table
        loading={loading}
        columns={[
          ...ShipmentDetailColumns,
          {
            title: '',
            dataIndex: 'azioni',
            align: 'right',
            render: (_, record) => (
              <Link to={`/shipping/all/${record.id}`}>
                Vai <ArrowRightOutlined />
              </Link>
            )
          }
        ]}
        rowKey="id"
        dataSource={integrationShipments}
        size="middle"
        onChange={pagination => {
          setQuery(
            new ShipmentQueryDto({
              ...query,
              pageNumber: pagination.current || query.pageNumber,
              pageSize: pagination.pageSize || query.pageSize
            })
          );
        }}
        pagination={{
          size: 'small',
          position: ['bottomRight'],
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.pageSize,
          current: query.pageNumber,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </Section>
  );
}
