import * as React from 'react';
import { message, Button, Table, Typography, Row, Col } from 'antd';
const { Title, Text } = Typography;
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { Section } from 'client/ui/section/Section';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { RouteComponentProps } from 'react-router';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { resolveApiPath } from 'client/core/appConfig';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { SupplierApi } from 'client/components/schema/supplier/SupplierApi';
import { SupplierQueryDto } from 'common/dto/query/SupplierQueryDto';
import { SupplierFormFieldItems } from 'client/components/schema/supplier/edit/SupplierFormFieldItems';
import { Formik } from 'formik';
import { FormikForm } from 'client/ui/form/FormikForm';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { SupplierDto, SupplierSchema } from 'common/dto/generated/SupplierDto';
import { useState } from 'react';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { EditAndSaveButton } from 'client/ui/form/button/EditAndSaveButton';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';

interface Params {
  id: string;
}

export interface SupplierPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di dettaglio dell'anagrafica fornitore
 */
export function SupplierPage(props: SupplierPageProps) {
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;
  const [editable, setEditable] = useState(id == null);

  const { response, error, loading, refetch } = useApiQuery(SupplierApi.find, {
    skip: id == null,
    data: {
      id: id!
    }
  });

  const [create] = useApiMutation(SupplierApi.create, {
    invalidates: [SupplierApi.find]
  });
  const [update] = useApiMutation(SupplierApi.update, {
    invalidates: [SupplierApi.find]
  });

  const supplier = id == null ? new SupplierDto() : response?.data;

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/contracts/all', title: `Contratti` });
  useBreadcrumbItem({
    priority: 10,
    path: '/contracts/supplier',
    title: `Contraenti`
  });
  useBreadcrumbItem({
    priority: 20,
    path: props.location.pathname,
    title: id ? (supplier ? `${supplier.getName()}` : '') : 'Nuovo Contraente',
    menuKey: 'contracts',
    sideMenuKey: 'supplier'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere al contraente"
        error={error}
      />
    );
  }

  if (!supplier) return <LoadingPage />;

  return (
    <CorePageLayout>
      <FormikAugmented<SupplierDto>
        enableReinitialize
        initialValues={supplier}
        validationSchema={SupplierSchema}
        validationContext={{}}
        onReset={() => {
          setEditable(false);
        }}
        onSubmit={async values => {
          if (!editable) return;
          try {
            const input = await values.validate();
            if (id) {
              await update({ data: { id, input } });
              message.success('Contraente aggiornato con successo. ');
            } else {
              const result = await create({
                data: { input }
              });
              message.success('Contraente aggiunto con successo. ');
              props.history.push(`/contracts/supplier/${result.data!.id}`);
            }
          } catch (e) {
            message.error(
              'Si è verificato un errore nella gestione del contraente'
            );
            console.error(e);
          }
        }}
      >
        <FormikForm editable={editable} helpInTooltips>
          <PageHeading
            title={
              <PageHeadingTitle
                children={
                  id ? `${supplier.getName() ?? ''}` : 'Nuovo Contraente'
                }
              />
            }
            breadcrumbRender={() => <BreadcrumbsContainer />}
            subTitle={`${supplier.getFiscalNumber() ?? ''}`}
            extra={
              <EditAndSaveButton
                isNew={id == null}
                name="Contraente"
                state={[editable, setEditable]}
              />
            }
          />

          <PageContent fixedWidht>
            <Section icon={<InboxOutlined />} title="Dati Generali" hasPadding>
              <SupplierFormFieldItems />
            </Section>
          </PageContent>
        </FormikForm>
      </FormikAugmented>
    </CorePageLayout>
  );
}
