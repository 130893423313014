import { ISODate } from 'client/ui/display/date/ISODate';
import { StateTag } from 'client/ui/state/StateTag';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { DocumentalProtocolLink } from '../../documental/DocumentalProtocolLink';
import { daysFromNow } from './daysFromNow';

export interface IInvoiceProtocolNameProps {
  invoice: InvoiceDto;

  // TODO possibilità di link a protocollo ?
  // isLink?: boolean
}

/**
 * Visualizzazione il protocollo di una fattura
 */
export function InvoiceProtocolName(props: IInvoiceProtocolNameProps) {
  const { invoice } = props;

  if (!invoice.protocolNumber) return null;

  return <DocumentalProtocolLink protocolData={invoice} />;
}
