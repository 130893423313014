import { DocumentalApi } from 'client/components/schema/documental/DocumentalApi';
import { AsyncText } from 'client/ui/form/text/AsyncText';
import { DocumentalUsersQueryDto } from 'common/dto/query/DocumentalUsersQueryDto';
import { DocumentalUserTypeFilter } from 'common/schema/documental/DocumentalTypes';
import React from 'react';

export interface IRequirementOfficeAsyncTextProps {
  officeCode: string | null | undefined;
}

export function RequirementOfficeAsyncText(
  props: IRequirementOfficeAsyncTextProps
) {
  const { officeCode: value } = props;
  if (!value) return null;
  return (
    <AsyncText
      query={{
        apiFn: DocumentalApi.correspondent.list,
        options: {
          data: {
            query: new DocumentalUsersQueryDto({
              pageSize: 1,
              pageNumber: 1,
              isOffice: true,
              isUser: false,
              correspondentType: DocumentalUserTypeFilter.Internal,
              exactCode: value
            })
          }
        }
      }}
      responseTransform={data =>
        data.items[0]?.description ?? `Ufficio ${value} non trovato`
      }
    />
  );
}
