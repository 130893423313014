import { DataItem } from 'client/ui/form/field/DataItem';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { MandateDto } from 'common/dto/generated/MandateDto';
import { gebilaStateOptions } from 'common/schema/mandate/MandateTypes';
import React from 'react';

interface IGebilaStateDataItemProps {
  labelWidth: ILabelWidth | undefined;
  mandate: MandateDto;
}

export function GebilaStateDataItem(props: IGebilaStateDataItemProps) {
  const { mandate, labelWidth } = props;
  const stateOption = gebilaStateOptions.find(
    s => s.state === mandate.gebilaState
  );

  return (
    <DataItem
      labelWidth={labelWidth}
      label="Stato GEBILA"
      value={stateOption?.label ?? '--'}
    />
  );
}
