import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { AuthCurrentLoader } from 'client/components/auth/current-loader/AuthCurrentLoader';
import { useAuth } from 'client/components/auth/AuthModule';
import { NotFoundPage } from 'client/components/errors/not-found/NotFoundPage';
import { useModalHistoryCleanup } from 'client/ui/modal/useModalHistoryCleanup';
import { ClientConfigLoader } from 'client/components/schema/config/ClientConfigLoader';
import { LoginPage } from 'client/components/auth/login/LoginPage';
import { InvoicesListPage } from './invoicing/invoices/list/InvoicesListPage';
import { InvoicePage } from './invoicing/invoices/single/InvoicePage';
import { BatchPage } from './invoicing/batches/single/BatchPage';
import { ContractPage } from './contract/single/ContractPage';
import { ContractListPage } from './contract/list/ContractsListPage';
import { SdiNotificationPage } from './invoicing/notifications/single/SdiNotificationPage';
import { SdiNotificationsListPage } from './invoicing/notifications/list/SdiNotificationsListPage';
import { MandatesListPage } from './shipping/mandate/list/MandatesListPage';
import { MandatePage } from './shipping/mandate/single/MandatePage';
import { EconomicEntryListPage } from './economy/entries/EconomicEntryListPage';
import { ShipmentsListPage } from './shipping/shipments/list/ShipmentsListPage';
import { ShipmentPage } from './shipping/shipments/single/ShipmentPage';
import { BatchesListPage } from './invoicing/batches/list/BatchesListPage';
import { AccountingLineListPage } from './accounting/list/AccountingLineListPage';
import { AccountingLinePage } from './accounting/single/AccountingLinePage';
import { UserListPage } from './user/list/UserListPage';
import { UserPage } from './user/single/UserPage';
import { EconomicSimulationListPage } from './economy/simulation/list/EconomicSimulationListPage';
import { EconomicSimulationPage } from './economy/simulation/single/EconomicSimulationPage';
import { EconomicStatementListPage } from './economy/statement/list/EconomicStatementListPage';
import { SupplierListPage } from './contract/supplier/list/SupplierListPage';
import { SupplierPage } from './contract/supplier/single/SupplierPage';
import { InvoiceToExportList } from './accounting/list/InvoiceToExportList';
import { AccountingLinePaymentListPage } from './accounting/list/AccountingLinePaymentListPage';
import { InvoiceExportPage } from './invoicing/invoices/export/InvoiceExportPage';
import { InvoiceExportListPage } from './invoicing/invoices/export/InvoiceExportListPage';
import { PccExportPage } from './accounting/report/single/PCCExportPage';
import { PccExportListPage } from './accounting/report/list/PCCExportListPage';

export const Routes = () => {
  const { logged } = useAuth();

  // const permissions = usePermissions();

  // Chiudiamo tutte le modal alla navigazione
  useModalHistoryCleanup();

  return (
    <ClientConfigLoader>
      <AuthCurrentLoader>
        {!logged ? (
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Redirect to={{ pathname: '/login' }} />
          </Switch>
        ) : (
          <Switch>
            <Redirect exact from="/login" to="/" />
            <Redirect exact from="/" to="/invoicing/invoices/all" />

            {/* <Redirect from="/admin" exact to="/admin/procedures/" />  */}

            {/* Fatture */}
            <Route
              path="/invoicing/invoices/:filter/:id"
              component={InvoicePage}
            />
            <Route
              path="/invoicing/invoices/:filter"
              component={InvoicesListPage}
            />
            {/* Lotti */}
            <Route path="/invoicing/batches/:id" component={BatchPage} />
            <Route path="/invoicing/batches" component={BatchesListPage} />
            {/* Notifiche SDI */}
            <Route
              path="/invoicing/notifications/:id"
              component={SdiNotificationPage}
            />
            <Route
              exact
              path="/invoicing/notifications"
              component={SdiNotificationsListPage}
            />
            <Route
              exact
              path="/invoicing/exports"
              component={InvoiceExportListPage}
            />
            <Route
              path="/invoicing/exports/:id"
              component={InvoiceExportPage}
            />

            {/* Linee Contabili | Report esportazioni PCC   */}
            <Route
              path="/accounting/pcc-exports/:filter/:id"
              component={PccExportPage}
            />
            <Route
              path="/accounting/pcc-exports/:filter"
              component={PccExportListPage}
            />
            {/* Linee Contabili */}
            <Route
              path="/accounting/lines/:filter/:id"
              component={AccountingLinePage}
            />
            <Route
              path="/accounting/lines/:filter"
              component={AccountingLineListPage}
            />
            <Route
              path="/accounting/to-ship/payment"
              exact
              component={AccountingLinePaymentListPage}
            />
            <Route
              path="/accounting/to-ship/:filter/:id"
              component={AccountingLinePage}
            />
            <Route
              path="/accounting/to-ship/:filter"
              component={InvoiceToExportList}
            />

            {/* Spedizioni UBRRAC - Mandati di Pagamento */}
            <Route
              path="/shipping/mandates/:filter/:id"
              component={MandatePage}
            />
            <Route
              path="/shipping/mandates/:filter?"
              component={MandatesListPage}
            />

            {/* Spedizioni UBRRAC */}
            <Route
              exact
              path="/shipping/:filter/:id"
              component={ShipmentPage}
            />
            <Route
              exact
              path="/shipping/:filter?"
              component={ShipmentsListPage}
            />

            {/* Conto Economico */}
            <Route
              path="/economy/economic-entries"
              component={EconomicEntryListPage}
            />
            <Route
              path="/economy/economic-statements"
              component={EconomicStatementListPage}
            />
            <Route
              path="/economy/economic-simulations/:id"
              component={EconomicSimulationPage}
            />
            <Route
              path="/economy/economic-simulations"
              component={EconomicSimulationListPage}
            />

            {/** Anagrafiche */}
            <Route path="/contracts/supplier/:id" component={SupplierPage} />
            <Route path="/contracts/supplier" component={SupplierListPage} />

            {/** Contratti  */}
            <Route path="/contracts/:filter/:id" component={ContractPage} />
            <Route path="/contracts/:filter?" component={ContractListPage} />

            {/** Utenti */}
            <Route path="/users/:id" component={UserPage} />
            <Route path="/users" component={UserListPage} />

            {/** Pagina non trovata */}
            <Route component={NotFoundPage} />
          </Switch>
        )}
      </AuthCurrentLoader>
    </ClientConfigLoader>
  );
};
