import React from 'react';
import { Typography, Col, Row, Layout } from 'antd';
const { Content, Header } = Layout;
const { Text } = Typography;
import './PageLayoutHeader.scss';
import { useThemeConfig } from 'client/components/schema/config/ClientConfigModule';
import { resolvePublicPath } from 'client/core/appConfig';
import { UserSubMenu } from './UserSubMenu';

/**
 * Placeholder per il logo proncipale dell'applicativo:
 * inseriamo un'immagine trasparente con le dimensioni del logo (il logo sara inserito come background).
 * Le dimensioni e il file del logo sono definite nel thema scss.
 */
const LogoPlaceholder = require('./image/logo-space.png');

export interface PageLayoutHeaderProps {
  children: React.ReactNode;
}

/**
 * Fascia in alto della pagina che contiene il logo
 * il nome e il ruolo dell'utente ed eventualmente il nome della UID
 */
export function PageLayoutHeader(props: PageLayoutHeaderProps) {
  const theme = useThemeConfig();

  return (
    <Header className="page-header">
      <div className="top-header">
        <Row align="middle" className="top-name-row">
          <Col>
            <Text strong>{theme.labels.headerText}</Text>
          </Col>
          <Col className="col-user">
            <UserSubMenu />
          </Col>
          {/* Elementi con posizione Absolute */}
          <div className="logo">
            <img src={resolvePublicPath(LogoPlaceholder)} alt="Logo" />
          </div>
          {/* <div className="today-date-wrapper">
            <Text className="today-date"><LiveDate /></Text>
          </div> */}
        </Row>
      </div>
      <Row className="page-menu">
        <Col span={24} className="page-menu-column">
          {props.children}
        </Col>
      </Row>
    </Header>
  );
}
