import { ColumnsType } from 'antd/lib/table';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceColumns } from './InvoiceColumns';

export const AccountingLineInvoiceColumns: ColumnsType<InvoiceDto> = [
  { ...InvoiceColumns.state, fixed: 'left' },
  InvoiceColumns.issuedNumber,
  InvoiceColumns.issuedYear,
  InvoiceColumns.supplierName,
  InvoiceColumns.supplierFiscal,
  InvoiceColumns.invoiceTypeCode,
  InvoiceColumns.totalAmount
];
