import { ColumnsType } from 'antd/lib/table';
import { AccountingLineDto } from 'common/dto/generated/AccountingLineDto';
import { AccountingLineColumns } from './AccountingLineColumns';

export const MandateAccountingLineColumns: ColumnsType<AccountingLineDto> = [
  AccountingLineColumns.state,
  AccountingLineColumns.amount,
  AccountingLineColumns.supplierName,
  AccountingLineColumns.invoiceNumber,
  AccountingLineColumns.invoiceChargeabilityCompact,
  AccountingLineColumns.expenseNature,
  AccountingLineColumns.debtState,
  AccountingLineColumns.pcc
  // AccountingLineColumns.debtReason
];
