export enum EconomicReportKind {
  Standard = 'Standard',
  Detail = 'Detail'
}

export const EconomicReportKindOptions = [
  { value: EconomicReportKind.Standard, label: 'Report Standard' },
  { value: EconomicReportKind.Detail, label: 'Report di Dettaglio' }
];

export function economicReportKindInFileName(kind: EconomicReportKind): string {
  switch (kind) {
    case EconomicReportKind.Detail:
      return 'Dettaglio';
    case EconomicReportKind.Standard:
      return 'Standard';
  }
}
