import * as React from 'react';
import { Button, Table, Typography, Row, Col, Space } from 'antd';
const { Title, Text } = Typography;
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { Section } from 'client/ui/section/Section';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { ClientBatchView } from './views/ClientBatchView';
import {
  DownloadOutlined,
  InboxOutlined,
  NotificationOutlined
} from '@ant-design/icons';
import { SupplierBatchView } from './views/SupplierBatchView';
import { TransmissionBatchView } from './views/TransmissionBatchView';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { RouteComponentProps } from 'react-router';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { InvoiceBatchApi } from 'client/components/schema/invoice-batch/InvoiceBatchApi';
import { InvoiceBatchDto } from 'common/dto/generated/InvoiceBatchDto';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { BatchInvoiceSection } from './section/BatchInvoiceSection';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { BatchNotificationsSection } from './section/BatchNotificationsSection';
import { resolveApiPath } from 'client/core/appConfig';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { AnchorAPILink } from 'client/ui/link/AnchorAPILink';

interface Params {
  id: string;
}

export interface BatchPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina dei lotti di fatture
 */
export function BatchPage(props: BatchPageProps) {
  // Prendo l'ID del Lotto
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const { response, error, loading, refetch } = useApiQuery(
    InvoiceBatchApi.find,
    {
      skip: id == null,
      data: {
        id: id!
      }
    }
  );

  const batch = id == null ? new InvoiceBatchDto() : response?.data;

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    priority: 10,
    path: '/invoicing/invoices/all',
    title: `Fatture`
  });
  useBreadcrumbItem({
    priority: 20,
    path: '/invoicing/batches',
    title: `Lotti`
  });
  useBreadcrumbItem({
    priority: 30,
    path: props.location.pathname,
    title: `Lotto ${batch?.sdiId ?? ''}`,
    menuKey: 'invoicing',
    sideMenuKey: 'batches'
  });

  if (error) {
    return (
      <NetworkAlertPage message="Impossibile accedere al lotto" error={error} />
    );
  }

  if (!batch) return <LoadingPage />;

  return (
    <CorePageLayout>
      <PageHeading
        title={
          <PageHeadingTitle
            children={
              batch?.sdiId
                ? 'Lotto - Identificativo SDI numero ' + batch?.sdiId
                : 'Lotto'
            }
          />
        }
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={
          <Space>
            <AnchorAPILink
              showAsButton
              icon={<DownloadOutlined />}
              target="_blank"
              href={resolveApiPath(`/batches/${batch.id}/xml/download/preview`)}
            >
              Anteprima XML
            </AnchorAPILink>
            <AnchorAPILink
              showAsButton
              icon={<DownloadOutlined />}
              target="_blank"
              href={resolveApiPath(
                `/batches/${batch.id}/xml/download/original`
              )}
            >
              Scarica XML Originale
            </AnchorAPILink>
          </Space>
        }
      />

      <PageContent fixedWidht>
        <Section icon={<InboxOutlined />} title="Dati Generali" hasPadding>
          <Row gutter={24}>
            <Col span={9}>
              <SupplierBatchView supplier={batch.supplier} />
            </Col>
            <Col span={9}>
              <ClientBatchView client={batch.client} />
            </Col>
            <Col span={6}>
              <TransmissionBatchView
                transmission={batch.transmission}
                sdiId={batch.sdiId}
              />
            </Col>
          </Row>
        </Section>

        <BatchInvoiceSection batchId={id} />

        <BatchNotificationsSection batchId={id} />
      </PageContent>
    </CorePageLayout>
  );
}
