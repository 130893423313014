import { DownloadOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { InvoiceAttachementColumns } from 'client/components/schema/invoice/table/InvoiceAttachementColumns';
import { resolveApiPath } from 'client/core/appConfig';
import { AnchorAPILink } from 'client/ui/link/AnchorAPILink';
import { Section } from 'client/ui/section/Section';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import * as React from 'react';

export interface IInvoiceAttachmentSectionProps {
  invoice: InvoiceDto;
}

/**
 * Mostra gli allegati della fattura.
 */
export function InvoiceAttachmentSection(
  props: IInvoiceAttachmentSectionProps
) {
  const { invoice } = props;

  return (
    <Section icon={<PaperClipOutlined />} title="Allegati">
      <Table
        rowKey="id"
        columns={[
          ...InvoiceAttachementColumns,
          {
            title: '',
            key: 'actions',
            align: 'right',
            render: (_, record) => (
              <AnchorAPILink
                icon={<DownloadOutlined />}
                children="Scarica Allegato"
                target="_blank"
                href={resolveApiPath(
                  `/invoices/attachment/${record.id}/download`
                )}
              />
            )
          }
        ]}
        dataSource={invoice.attachments}
        size="small"
        pagination={false}
      />
    </Section>
  );
}
