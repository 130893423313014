import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";


@GenerateDto()
class ProtocolInvoiceOptions {
    @SchemaLabel('Oggetto')
    protocolSubject!: string;
    @SchemaLabel('Classificazione')
    classification!: string;
    @SchemaLabel('IVA')
    vatRelevant!: boolean
    // @SchemaLabel('Referente Amministrativo')
    // admReferent!: 
    // @SchemaLabel('Referente DEC')
    // decReferent!: 
    // @SchemaLabel('Referente RUP')
    // rupReferent!: 
}

/**
 * Rappresentazione DTO della classe ProtocolInvoiceOptions 
 * Hash: 614e9fd7b0fab1ee7c59b91d726d6cf7
 */
@ValidationSchema(() => ProtocolInvoiceOptionsSchema)
export class ProtocolInvoiceOptionsDto {
  @ApiProperty({ type: String, description: 'Oggetto' })
  protocolSubject!: string;
  @ApiProperty({ type: String, description: 'Classificazione' })
  classification!: string;
  @ApiProperty({ type: Boolean, description: 'IVA' })
  vatRelevant!: boolean;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ProtocolInvoiceOptionsDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ProtocolInvoiceOptionsDto ? values : plainToClass(ProtocolInvoiceOptionsDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ProtocolInvoiceOptionsSchema.validate(classToPlain(this), options);
    return new ProtocolInvoiceOptionsDto(validated);
  }
}

export const ProtocolInvoiceOptionsSchema = yup
  .object({
    protocolSubject: yup.string().required().label('Oggetto'),
    classification: yup.string().required().label('Classificazione'),
    vatRelevant: yup.boolean().required().label('IVA')
  })
  .noUnknown()
  .meta({ schemaName: "ProtocolInvoiceOptionsSchema" })
  .required();
 