import * as React from 'react';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { RouteComponentProps } from 'react-router-dom';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useEffect, useState } from 'react';
import { PccExportApi } from 'client/components/schema/pcc/PccExportApi';
import { PccExportQueryDto } from 'common/dto/query/PccExportQueryDto';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { mapPccExportFilterToOptions } from './mapPCCExportFilterToOptions';
import { TableSticky } from 'client/ui/table/TableSticky';

interface Params {
  filter?: string;
}

export interface PCCExportListPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di Elenco dei Report degli export a PCC delle Linee di Contabilizzazione
 */
export function PccExportListPage(props: PCCExportListPageProps) {
  const { match } = props;
  const [options, setOptions] = useState(
    mapPccExportFilterToOptions(match.params.filter)
  );
  const [query, setQuery] = useState<PccExportQueryDto>(
    new PccExportQueryDto({
      pageNumber: 1,
      pageSize: 20,
      orderByDirection: ColumnSortDirections.descend,
      orderBy: 'createdAt',
      ...options.query
    })
  );

  const { response, loading, error } = useApiQuery(PccExportApi.list, {
    data: { query }
  });
  const reports = response?.data?.items;

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/accounting/lines/all',
    title: `Contabilizzazione`
  });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: options.title ?? 'File di Risposta PCC',
    sideMenuKey: match.params.filter ?? 'shipping-response',
    menuKey: 'accounting'
  });

  // Aggiorno le opzioni
  useEffect(() => {
    const updatedOptions = mapPccExportFilterToOptions(match.params.filter);
    setOptions(updatedOptions);
    setQuery(
      new PccExportQueryDto({
        ...query,
        ...updatedOptions.query
      })
    );
  }, [match, options.filter]);

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco delle linee contabili"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle children={options.title} />}
        breadcrumbRender={() => <BreadcrumbsContainer />}
      />
      <PageContent noPadding noSpace>
        <TableSticky
          loading={loading || !reports}
          rowKey="id"
          columns={options.columns}
          dataSource={reports}
          size="middle"
          onChange={(pagination, filters, sorter) => {
            setQuery(
              new PccExportQueryDto({
                ...query,
                pageNumber: pagination.current ?? query.pageNumber,
                pageSize: pagination.pageSize ?? query.pageSize
              })
            );
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
