import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { InvoiceReferenceDto } from 'common/dto/generated/InvoiceReferenceDto';
import { InvoiceReferenceTypeLabels } from 'common/schema/invoice/InvoiceLabels';
import * as React from 'react';

export interface IInvoceReferenceRowProps {
  reference: InvoiceReferenceDto;
}

/**
 * Dettaglio in riga di un singolo riferimento
 */
export function InvoceReferenceRow(props: IInvoceReferenceRowProps) {
  const { reference } = props;
  return (
    <FormFieldsContainer columns={4}>
      <DataItem
        label="Tipo"
        inline
        strong
        value={InvoiceReferenceTypeLabels[reference.type]}
      />
      <DataItem inline label="CIG" value={reference.cigCode ?? 'NA'} />
      <DataItem inline label="CUP" value={reference.cupCode ?? 'NA'} />
      <DataItem
        inline
        label="Codice"
        value={reference.sdiReferenceCode ?? 'NA'}
      />
    </FormFieldsContainer>
  );
}
