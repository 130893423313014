import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";
import { ContractState } from "common/schema/contract/ContractTypes";

@GenerateDto()
class ContractStateChange {
  @SchemaLabel('Stato Accettazione Fattura')
  state!: ContractState;
}

/**
 * Rappresentazione DTO della classe ContractStateChange 
 * Hash: f22374a7d6805bc02293977427c4ae1d
 */
@ValidationSchema(() => ContractStateChangeSchema)
export class ContractStateChangeDto {
  @ApiProperty({ enum: ['InProgress', 'Signing', 'Stipulated', 'Registered', 'Canceled'], description: 'Stato Accettazione Fattura' })
  state!: ContractState;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ContractStateChangeDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ContractStateChangeDto ? values : plainToClass(ContractStateChangeDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ContractStateChangeSchema.validate(classToPlain(this), options);
    return new ContractStateChangeDto(validated);
  }
}

export const ContractStateChangeSchema = yup
  .object({
    state: yup.string().oneOfEnum(ContractState).required().label('Stato Accettazione Fattura')
  })
  .noUnknown()
  .meta({ schemaName: "ContractStateChangeSchema" })
  .required();
