import * as React from 'react';
import { Button, Table, TableProps } from 'antd';
import { getPageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { pageFixedSizes } from '../layout/PageLayout';
import { tableLoaderSpin } from '../loader/tableLoaderSpin';

export interface TableStickyProps<T>
  extends Omit<TableProps<T>, 'loading' | 'sticky'> {
  loading?: boolean;
}

/**
 * Tabella Personalizzata con Header Sticky
 */
export function TableSticky<T extends {}>(props: TableStickyProps<T>) {
  const { loading, ...otherProps } = props;

  const heading = getPageHeading();
  const style: React.CSSProperties = {
    position: 'fixed',
    right: 0,
    left: pageFixedSizes.siderWidht,
    top: heading.clientRect?.bottom ?? 169,
    bottom: 0,
    overflowY: 'scroll'
  };

  const table = document.getElementById('fixed-table-container');
  const stickyProps = table
    ? ({
        sticky: {
          getContainer: () => table
        }
      } as Partial<TableProps<any>>)
    : {};

  // console.log('TableSticky', {
  //   style,
  //   rect: heading.clientRect,
  //   table: table != null
  // });

  return (
    <div id="fixed-table-container" style={style}>
      <Table<T>
        loading={tableLoaderSpin(loading)}
        {...otherProps}
        size="middle"
        {...stickyProps}
        scroll={{ x: true }}
      />
    </div>
  );
}
