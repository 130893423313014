import { ISODate } from 'client/ui/display/date/ISODate';
import { SummaryItemProps } from 'client/ui/layout/page/heading/PageHeadingSummary';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceLogic } from 'common/schema/invoice/InvoiceLogic';
import React from 'react';
import { getRevisionData, RevisionData } from './getRevisionData';

/**
 * Ritorna i dati della revisione per il ricapitolo nella testata della fattura.
 * In caso non siano presenti controlla se la fattura sia da revisionare
 * e ritorna solo se è necessario il testo 'Revisione: da Effettuare'
 */
export function getRevisionHeader(invoice: InvoiceDto): SummaryItemProps {
  const expiredNotPaid = InvoiceLogic.needRevision(invoice);
  const invoiceRevision = getRevisionData(invoice);
  return invoiceRevision.date
    ? // se è stata revisionata mostriamo la  data
      {
        label: 'Data Revisione',
        value: <ISODate date={invoiceRevision.date} />
      }
    : expiredNotPaid
    ? // altrimenti se è da revisionare lo indichiamo
      { label: 'Revisione', value: 'Da effettuare' }
    : // oppure non mostriamo niente
      { label: '', labelStyle: { opacity: 0 } };
}
