import { DataItem } from 'client/ui/form/field/DataItem';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { MandateDto } from 'common/dto/generated/MandateDto';
import { sicogeStateOptions } from 'common/schema/mandate/MandateTypes';
import React from 'react';

interface ISicogeStateDataItemProps {
  labelWidth: ILabelWidth | undefined;
  mandate: MandateDto;
}

export function SicogeStateDataItem(props: ISicogeStateDataItemProps) {
  const { mandate, labelWidth } = props;
  const stateOption = sicogeStateOptions.find(
    s => s.state === mandate.sicogeState
  );

  return (
    <DataItem
      labelWidth={labelWidth}
      label="Stato SICOGE"
      value={stateOption?.label ?? '--'}
    />
  );
}
