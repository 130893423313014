export enum NotificationDirection {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing'
}

/**
 * Tipo di Notifica SDI, il codice deve corrispondere
 * ESATTAMENTE a quello presente nel nome del file della notifica
 */
export enum NotificationType {
  /** Notifica Esito Committente */
  EC = 'EC',
  /** Scarto esito committente */
  SE = 'SE',
  /** Decorrenza termini */
  DT = 'DT',
  /** Metadati invio file */
  MT = 'MT',
  /** Non riconosciuti */
  NONE = 'NONE'
}

/** Codice Notifica SDI */
export enum NotificationCode {
  /** Non conforme al formato */
  NonConforme = 'EN00',
  /** Non ammissibile */
  NonAmmissibile = 'EN01',
  /** Accettazione */
  Accettazione = 'EC01',
  /** Rifiuto */
  Rifiuto = 'EC02'
}
