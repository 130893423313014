import { SelectOption } from 'client/ui/form/input/SelectInput';
import { uniq } from 'lodash';

type SelectOptions = SelectOption[];

export interface ICheckCombination {
  kind?: string;
  subject?: string;
  description?: string;
  detail?: string;
}

/**
 * Ritorna la lista di Subject selezionabili dato il Kind
 * @param kind
 * @returns
 */
export function getSubjectOptions(
  list: ICheckCombination[],
  kind: string | null | undefined
): SelectOptions {
  if (!kind) return [];
  const combinationList = list.filter(combination => combination.kind === kind);

  // Genera la SelectOptions degli elementi (unici) non vuoti
  return uniq(combinationList.map(combination => combination.subject))
    .filter(option => !!option && option !== '')
    .map(option => ({ value: option!, label: option }));
}

/**
 * Ritorna la lista delle Description selezionabili dato il Subject
 * @param kind
 * @returns
 */
export function getDescriptionOptions(
  list: ICheckCombination[],
  kind: string | null | undefined,
  subject: string | null | undefined
): SelectOptions {
  if (!kind || !subject) return [];
  const combinationList = list.filter(
    combination => combination.kind === kind && combination.subject === subject
  );

  // Genera la SelectOptions degli elementi (unici) non vuoti
  return uniq(combinationList.map(combination => combination.description))
    .filter(option => !!option && option !== '')
    .map(option => ({ value: option!, label: option }));
}

/**
 * Ritorna la lista dei Detail selezionabili data la Description
 * @param kind
 * @returns
 */
export function getDetailOptions(
  list: ICheckCombination[],
  kind: string | null | undefined,
  subject: string | null | undefined,
  description: string | null | undefined
): SelectOptions {
  if (!kind || !subject || !description) return [];
  const combinationList = list.filter(
    combination =>
      combination.kind === kind &&
      combination.subject === subject &&
      combination.description === description
  );

  // Genera la SelectOptions degli elementi (unici) non vuoti
  return uniq(combinationList.map(combination => combination.detail))
    .filter(option => !!option && option !== '')
    .map(option => ({ value: option!, label: option }));
}
