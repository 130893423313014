import { IInvoiceType } from 'common/dto/generated/InvoiceDto';
import { IInvoiceVATSummaryType } from 'common/dto/generated/InvoiceVATSummaryDto';

export class InvoiceVATSummaryLogic {
  /** Verifica se sono presenti esigibilità dell'IVA nei ricapitoli */
  static hasChargeabilities(invoice: IInvoiceType) {
    const chargeabilities = this.getChargeabilities(invoice.vatSummaries);

    return invoice.isSDI() ? chargeabilities.length > 0 : true;
  }

  /** Verifica se le esigibilità dell'IVA nei ricapitoli sono discordanti */
  static areChargeabilitiesCongruent(invoice: IInvoiceType) {
    const chargeabilities = this.getChargeabilities(invoice.vatSummaries);

    // Se non ci sono ricapitoli iva, saltiamo il controllo
    if (chargeabilities.length === 0) return true;

    return invoice.vatSummaries.every(
      s => s.chargeability === chargeabilities[0]
    );
  }

  private static getChargeabilities(vatSummaries: IInvoiceVATSummaryType[]) {
    return (vatSummaries ?? [])
      .filter(s => s.chargeability != null)
      .map(s => s.chargeability);
  }
}
