import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";
import { InvoiceQueryDto } from './InvoiceQueryDto';



@GenerateDto()
class Model002CreateQuery {
    @SchemaLabel('Fatture')
    invoiceIds?: number[] | null;
    @SchemaLabel('Query Fatture')
    query?: InvoiceQueryDto | null;
}

/**
 * Rappresentazione DTO della classe Model002CreateQuery 
 * Hash: 68ed6ffd32a7e21ffbd90034b9af1d72
 */
@ValidationSchema(() => Model002CreateQuerySchema)
export class Model002CreateQueryDto {
  @ApiProperty({ required: false, type: [Number], description: 'Fatture' })
  invoiceIds?: number[] | null | undefined;
  @ApiProperty({ required: false, type: InvoiceQueryDto, description: 'Query Fatture' })
  query?: InvoiceQueryDto | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<Model002CreateQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof Model002CreateQueryDto ? values : plainToClass(Model002CreateQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await Model002CreateQuerySchema.validate(classToPlain(this), options);
    return new Model002CreateQueryDto(validated);
  }
}

export const Model002CreateQuerySchema = yup
  .object({
    invoiceIds: yup.array(yup.number().required()).nullable().label('Fatture'),
    query: yup.mixed().nullable().label('Query Fatture')
  })
  .noUnknown()
  .meta({ schemaName: "Model002CreateQuerySchema" })
  .required();
