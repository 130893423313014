/**
 * Congruenza delle linee di contabilizzazione
 */
export enum AccountingCongruenceType {
  /** Fattura non ancora contabilizzata */
  ToAccount = 'ToAccount',
  /** Importi congruenti */
  CongruentAmounts = 'CongruentAmounts',
  /** Importi incongruenti */
  IncongruentAmounts = 'IncongruentAmounts'
}
