import React from 'react';
import clx from 'classnames';

import { Drawer, Layout, DrawerProps } from 'antd';
const { Content, Sider } = Layout;
import './BottomDrawer.scss';

export interface BottomDrawerProps extends DrawerProps {
  sider?: React.ReactNode;
  siderWidth?: string | number | undefined;
  children: React.ReactNode;

  /**
   * Box con fondo bianco a tutta larghezza in cui inserire il content
   */
  boxed?: boolean;
}

/**
 * Drawer posizionato in basso
 * con content a due colonne (sider)
 * per il form
 */
export function BottomDrawer(props: BottomDrawerProps) {
  const { sider, siderWidth, children, boxed, ...drawerProps } = props;
  return (
    <Drawer
      className={clx('bottom-drawer', props.className)}
      placement="bottom"
      height="70%"
      bodyStyle={{ padding: 0 }}
      {...drawerProps}
    >
      <Layout style={{ height: '100%' }} className="bottom-drawer-body">
        {sider && (
          <Sider width={siderWidth ?? 300} className="bottom-drawer-body-sider">
            {sider}
          </Sider>
        )}
        <Content
          className={clx('bottom-drawer-body-content', {
            'is-boxed': boxed
          })}
        >
          {boxed ? (
            <div className="bottom-drawer-body-content-boxed">{children}</div>
          ) : (
            children
          )}
        </Content>
      </Layout>
    </Drawer>
  );
}
