import { ExclamationCircleOutlined, MailOutlined } from '@ant-design/icons';
import { Button, message, Modal, Tooltip } from 'antd';
import { ShipmentApi } from 'client/components/schema/shipment/ShipmentApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import { InvoiceAcceptanceDto } from 'common/dto/query/InvoiceAcceptanceDto';
import { ShipmentMachine } from 'common/schema/shipment/ShipmentMachine';
import React, { useCallback } from 'react';

interface ShipmentSendButtonProps {
  shipment: ShipmentDto | null | undefined;
  editable: boolean;
}

export function ShipmentSendButton(props: ShipmentSendButtonProps) {
  const { shipment, editable } = props;
  const id = shipment?.id;

  const actionable = ShipmentMachine.can(shipment, 'action.dossier', true);

  const [send] = useApiMutation(ShipmentApi.send, {
    invalidates: [ShipmentApi.find]
  });

  const handleClick = useCallback(async () => {
    Modal.confirm({
      title: `Sei sicuro di voler creare il fascicolo UBRRAC?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Invia',
      cancelText: 'Annulla',
      content: `Verrà creato il fascicolo della spedizione sul documentale con classificazione ${shipment?.classification}.`,
      async onOk() {
        try {
          await send({ data: { id } });
          message.success('Fascicolo spedizione UBRRAC creato con successo.');
        } catch (e) {
          message.error(
            'Si è verificato un errore durante la creazione della spedizione. Riprovare.'
          );
        }
      },
      onCancel() {}
    });
  }, []);

  if (!actionable.allowed && actionable.skipped) {
    return null;
  }

  return (
    <Tooltip title={!actionable.allowed && actionable.message}>
      <Button
        key="send"
        icon={<MailOutlined />}
        disabled={id == null || editable || !actionable.allowed}
        onClick={handleClick}
      >
        Crea Fascicolo UBRRAC
      </Button>
    </Tooltip>
  );
}
