import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, plainToClass, classToPlain } from 'class-transformer';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { ActivityLogAction } from 'server/schema/activity-log/ActivityLogEntityType';
import type { ActivityLogEntityType } from 'server/schema/activity-log/ActivityLogEntityType';
import { UserDto } from './UserDto';

/**
 * Rappresentazione DTO della classe ActivityLog definita in: src/server/schema/activity-log/ActivityLog.entity.ts
 * Hash: 5a2df7ae77d33ec23a452936184ca399
 */
@ValidationSchema(() => ActivityLogSchema)
export class ActivityLogDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data attività' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: Number, description: 'Id Utente' })
  userId!: number;
  @ApiProperty({ required: false, type: () => UserDto, description: 'Utente' })
  @Type(() => UserDto)
  user!: UserDto;
  @ApiProperty({ enum: ['create', 'delete', 'update', 'restore', 'changeState', 'add', 'remove', 'protocol', 'accept', 'reject'], description: 'Azione' })
  action!: ActivityLogAction;
  @ApiProperty({ description: 'Entità modificata' })
  entity!: ActivityLogEntityType;
  @ApiProperty({ type: Number, description: 'ID Entità modificata' })
  entityId!: number;
  @ApiProperty({ required: false, type: String, description: 'Descrizione' })
  description!: string;
  @ApiProperty({ required: false, type: String, description: 'Nuovo Stato' })
  newState?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ActivityLogDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ActivityLogDto ? values : plainToClass(ActivityLogDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ActivityLogSchema.validate(classToPlain(this), options);
    return new ActivityLogDto(validated);
  }
}

/** Interfaccia simmetrica al DTO ActivityLogDto */
export type IActivityLogType = IDto<ActivityLogDto>;

/**
 * DTO Paginato della classe ActivityLog
 */
export class PaginatedActivityLogDto {
  @ApiProperty({ type: [ActivityLogDto] })
  @Type(() => ActivityLogDto)
  items!: ActivityLogDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const ActivityLogSchema = yup
  .object({
    id: yup.number(),
    userId: yup.number().label('Id Utente'),
    action: yup.string().oneOfEnum(ActivityLogAction).required().label('Azione'),
    entity: yup.mixed().required().label('Entità modificata'),
    entityId: yup.number().required().label('ID Entità modificata'),
    description: yup.string().default('').label('Descrizione'),
    newState: yup.string().nullable().label('Nuovo Stato')
  })
  .noUnknown()
  .meta({ schemaName: "ActivityLogSchema" })
  .required();
