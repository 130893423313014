import { apiClient } from 'client/core/network/apiClient';
import {
  InvoiceBatchDto,
  PaginatedInvoiceBatchDto
} from 'common/dto/generated/InvoiceBatchDto';
import { PaginatedInvoiceDto } from 'common/dto/generated/InvoiceDto';
import { PaginatedNotificationDto } from 'common/dto/generated/NotificationDto';
import { InvoiceBatchQueryDto } from 'common/dto/query/InvoiceBatchQueryDto';
import { InvoiceQueryDto } from 'common/dto/query/InvoiceQueryDto';
import { NotificationQueryDto } from 'common/dto/query/NotificationQueryDto';

export const InvoiceBatchApi = {
  list: (opts: { query: InvoiceBatchQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedInvoiceBatchDto)
      .get(`/batches`, { params: opts.query }),
  find: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(InvoiceBatchDto)
      .get(`/batches/${opts.id}`),

  invoices: {
    list: (opts: { id: number; query: InvoiceQueryDto }) =>
      apiClient
        .requestWithResponseType(PaginatedInvoiceDto)
        .get(`/batches/${opts.id}/invoices`, { params: opts.query })
  },

  notifications: {
    list: (opts: { id: number; query: NotificationQueryDto }) =>
      apiClient
        .requestWithResponseType(PaginatedNotificationDto)
        .get(`/batches/${opts.id}/notifications`, { params: opts.query })
  }
};
