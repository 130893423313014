import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  AccountingLineAllColumns,
  AccountingLineStateColumns
} from 'client/components/schema/accounting-line/table/AccountingLineStateColumns';
import { AccountingLineState } from 'common/schema/accounting-line/AccountingLineTypes';
import { AccountingLineDto } from 'common/dto/generated/AccountingLineDto';
import { Model003Action } from 'server/schema/pcc/model003/Model003Types';
import { createTypedColumns } from 'client/ui/table/createTypedColumns';
import { ArrowRightOutlined } from '@ant-design/icons';
import { AccountingLineQueryDto } from 'common/dto/query/AccountingLineQueryDto';
import { ColumnsType } from 'antd/lib/table';
import { AcceptanceState } from 'common/schema/invoice/InvoiceTypes';
import { AccountingLinePccQuery } from 'common/schema/accounting-line/AccountingLinePccQuery';

function accountingLineStateColumnsWithActions(filter: string, prefix: string) {
  const typed = createTypedColumns(AccountingLineDto, {
    actions: {
      title: '',
      align: 'right',
      width: 55,
      render: (_, record) => (
        <Link to={`/accounting/${prefix}/${filter}/${record.id}`}>
          Vai <ArrowRightOutlined />
        </Link>
      )
    }
  });
  return [...AccountingLineStateColumns, typed.actions];
}

interface IAccountLineFilterOption {
  title: string;
  columns: ColumnsType<AccountingLineDto>;
  query: Partial<AccountingLineQueryDto>;
  tag?: AccountingLineState;
  filter: string;
  sideMenuPrefix: string;
  selectable?: boolean;
  modelActionRequest?: Model003Action;
}

export function mapAccountingLineFilterToOptions(
  filter: string | undefined
): IAccountLineFilterOption {
  switch (filter) {
    case 'to-ship':
      return {
        title: 'Linee Contabili da Spedire',
        columns: AccountingLineStateColumns,
        query: {
          state: AccountingLineState.ToShip
        },
        tag: AccountingLineState.ToShip,
        filter: filter,
        sideMenuPrefix: 'lines'
      };
    case 'to-pay':
      return {
        title: 'Linee Contabili Spedite',
        columns: AccountingLineStateColumns,
        query: {
          state: AccountingLineState.ToPay
        },
        tag: AccountingLineState.ToPay,
        filter: filter,
        sideMenuPrefix: 'lines'
      };
    case 'not-payable':
      return {
        title: 'Linee Contabili Sospese o Non Liquidabili',
        columns: AccountingLineStateColumns,
        query: {
          state: AccountingLineState.NotPayable
        },
        tag: AccountingLineState.NotPayable,
        filter: filter,
        sideMenuPrefix: 'lines'
      };
    case 'paid':
      return {
        title: 'Linee Contabili Pagate',
        columns: AccountingLineStateColumns,
        query: {
          state: AccountingLineState.Paid
        },
        tag: AccountingLineState.Paid,
        filter: filter,
        sideMenuPrefix: 'lines'
      };
    // Submenu Da Comunicare... lines-not-payable
    case 'accounting':
      return {
        title: 'Linee Contabili da Comunicare Contabilizzazione a PCC',
        columns: accountingLineStateColumnsWithActions(filter, 'to-ship'),
        query: AccountingLinePccQuery.CO,
        selectable: true,
        modelActionRequest: Model003Action.CO,
        filter: filter,
        sideMenuPrefix: 'to-ship'
      };
    case 'payment':
      return {
        title: 'Linee Contabili da Comunicare Pagamento a PCC',
        columns: accountingLineStateColumnsWithActions(filter, 'to-ship'),
        query: AccountingLinePccQuery.CP,
        selectable: true,
        modelActionRequest: Model003Action.CP,
        filter: filter,
        sideMenuPrefix: 'to-ship'
      };
    case 'expiration':
      return {
        title: 'Linee Contabili da Comunicare Scadenza a PCC',
        columns: accountingLineStateColumnsWithActions(filter, 'to-ship'),
        query: {
          ...AccountingLinePccQuery.CS,
          invoiceAcceptance: AcceptanceState.Accepted
        },
        selectable: true,
        modelActionRequest: Model003Action.CS,
        filter: filter,
        sideMenuPrefix: 'to-ship'
      };

    default:
      return {
        title: 'Tutte le Linee Contabili',
        columns: AccountingLineAllColumns,
        query: {
          state: null
        },
        filter: 'all',
        sideMenuPrefix: 'lines'
      };
  }
}
