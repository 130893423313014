export interface IExportField {
  prefix: string;
  label: string;
  name: string;
  csvLabel?: string;
  csvOrder: number;
  // needFunction?: boolean;
}
export interface IExportFields {
  invoice: IExportField[];
  contract: IExportField[];
  accounting: IExportField[];
  mandate: IExportField[];
  suspending: IExportField[];
  lines: IExportField[];
}

/**
 * Elenco Colonne da esportare
 * Ordinati secondo le colonne dell'excel di esempio Siged
 */
export const ExportFields: IExportFields = {
  invoice: [
    { csvOrder: 101, prefix: 'invoice', name: 'id', label: 'Id MF' },
    {
      csvOrder: 102,
      prefix: 'invoice',
      name: 'supplier',
      label: 'Cedente Prestatore'
    },
    {
      csvOrder: 103,
      prefix: 'invoice',
      name: 'fiscalNumber',
      label: 'Cod Fiscale',
      csvLabel: 'CP CodFis'
    },
    {
      csvOrder: 104,
      prefix: 'invoice',
      name: 'vatNumber',
      label: 'Partita IVA',
      csvLabel: 'CP PIVA'
    },
    { csvOrder: 105, prefix: 'invoice', name: 'issuedNumber', label: 'Numero' },
    { csvOrder: 106, prefix: 'invoice', name: 'issuedDate', label: 'Data' },
    { csvOrder: 107, prefix: 'invoice', name: 'currency', label: 'Divisa' },
    {
      csvOrder: 108,
      prefix: 'invoice',
      name: 'totalVatTaxableAmount',
      label: 'Totale IVA Esclusa'
    },
    {
      csvOrder: 109,
      prefix: 'invoice',
      name: 'totalVatAmount',
      label: 'Totale IVA'
    },
    { csvOrder: 110, prefix: 'invoice', name: 'totalAmount', label: 'Totale' },
    {
      csvOrder: 111,
      prefix: 'invoice',
      name: 'receivedAt',
      label: 'Data consegna'
    },
    { csvOrder: 112, prefix: 'invoice', name: 'protocol', label: 'Protocollo' },
    {
      csvOrder: 113,
      prefix: 'invoice',
      name: 'protocolDate',
      label: 'Data Protocollo'
    },
    {
      csvOrder: 114,
      prefix: 'invoice',
      name: 'executionDate',
      label: 'Data Prestazione'
    },
    {
      csvOrder: 115,
      prefix: 'invoice',
      name: 'testingDate',
      label: 'Data Collaudo'
    },
    {
      csvOrder: 116,
      prefix: 'invoice',
      name: 'expiresAt',
      label: 'Data Scadenza PCC'
    },
    {
      csvOrder: 117,
      prefix: 'invoice',
      name: 'paymentDelayReason',
      label: 'Motivazioni Ritardi'
    },
    {
      csvOrder: 118,
      prefix: 'invoice',
      name: 'references',
      label: 'Assegnatari'
    },
    {
      csvOrder: 119,
      prefix: 'invoice',
      name: 'acceptance',
      label: 'Stato SDI'
    },
    {
      csvOrder: 120,
      prefix: 'invoice',
      name: 'invoiceType',
      label: 'Tipo Documento'
    }
  ],
  contract: [
    { csvOrder: 201, prefix: 'contract', name: 'subject', label: 'Oggetto' },
    {
      csvOrder: 202,
      prefix: 'contract',
      name: 'suppliers',
      label: 'Contraente'
    },
    {
      csvOrder: 203,
      prefix: 'contract',
      name: 'totalAmount',
      label: 'Importo complessivo'
    },
    {
      csvOrder: 204,
      prefix: 'contract',
      name: 'netAmount',
      label: 'Importo IVA esclusa'
    },
    { csvOrder: 205, prefix: 'contract', name: 'cigCode', label: 'CIG' },
    { csvOrder: 206, prefix: 'contract', name: 'cupCode', label: 'CUP' },
    {
      csvOrder: 207,
      prefix: 'contract',
      name: 'documentYear',
      label: 'Data Prot/Repertorio'
    },
    {
      csvOrder: 208,
      prefix: 'contract',
      name: 'documentNumber',
      label: 'Protocollo/Repertorio'
    },
    {
      csvOrder: 209,
      prefix: 'contract',
      name: 'expireDate',
      label: 'Scadenza contratto'
    },
    {
      csvOrder: 210,
      prefix: 'contract',
      name: 'paymentTermsDays',
      label: 'Termini pagamento',
      csvLabel: 'Termine di pagamento'
    },
    {
      csvOrder: 211,
      prefix: 'contract',
      name: 'testingTermsDays',
      label: 'Termini collaudo',
      csvLabel: 'Termine di pagamento'
    },
    {
      csvOrder: 212,
      prefix: 'contract',
      name: 'anacProcedureKind',
      label: 'Procedura ANAC'
    },
    {
      csvOrder: 213,
      prefix: 'contract',
      name: 'requirementOfficeCode',
      label: 'Ufficio rifermento',
      csvLabel: 'Ufficio di rifermento'
    },
    {
      csvOrder: 214,
      prefix: 'contract',
      name: 'adminReferent',
      label: 'Referente'
    },
    { csvOrder: 215, prefix: 'contract', name: 'rupReferent', label: 'RUP' },
    { csvOrder: 216, prefix: 'contract', name: 'decReferent', label: 'DEC' },
    {
      csvOrder: 217,
      prefix: 'contract',
      name: 'contractType',
      label: 'Tipo Contratto'
    },
    { csvOrder: 218, prefix: 'contract', name: 'state', label: 'Stato' },
    {
      csvOrder: 219,
      prefix: 'contract',
      name: 'economicEntries',
      label: 'Codice C.E.',
      csvLabel: 'Codice Conto Economico Contratto'
    }
  ],
  accounting: [
    {
      csvOrder: 301,
      prefix: 'accounting',
      name: 'amount',
      label: 'Importo',
      csvLabel: 'Importo linea di contabilizzazione'
    },
    {
      csvOrder: 302,
      prefix: 'accounting',
      name: 'debtState',
      label: 'Stato',
      csvLabel: 'Stato linea di contabilizzazione PCC'
    },
    {
      csvOrder: 303,
      prefix: 'accounting',
      name: 'debtReason',
      label: 'Causale'
    },
    { csvOrder: 304, prefix: 'accounting', name: 'state', label: 'Stato MF' },
    {
      csvOrder: 305,
      prefix: 'accounting',
      name: 'paymentDate',
      label: 'Data di pagamento'
    },
    {
      csvOrder: 306,
      prefix: 'accounting',
      name: 'expenseNature',
      label: 'Natura di spesa'
    },
    {
      csvOrder: 307,
      prefix: 'accounting',
      name: 'pccExportedAccounting',
      label: 'Esito PCC CO'
    },
    {
      csvOrder: 308,
      prefix: 'accounting',
      name: 'pccExportedPayment',
      label: 'Esito PCC CP'
    }
  ],
  mandate: [
    {
      csvOrder: 401,
      prefix: 'mandate',
      name: 'mandateNumber',
      label: 'Nr. OP'
    },
    { csvOrder: 402, prefix: 'mandate', name: 'camicia', label: 'Nr. Camicia' },
    {
      csvOrder: 403,
      prefix: 'mandate',
      name: 'expenseChapter',
      label: 'Capitolo'
    },
    {
      csvOrder: 404,
      prefix: 'mandate',
      name: 'beneficiary',
      label: 'Beneficiario'
    },
    { csvOrder: 405, prefix: 'mandate', name: 'amount', label: 'Importo OP' },
    {
      csvOrder: 406,
      prefix: 'mandate',
      name: 'mandateYear',
      label: 'Esercizio gestione',
      csvLabel: 'Esercizio di gestione'
    },
    {
      csvOrder: 407,
      prefix: 'mandate',
      name: 'originYear',
      label: 'Esercizio provenienza',
      csvLabel: 'Esercizio di provenienza'
    },
    {
      csvOrder: 408,
      prefix: 'mandate',
      name: 'sicogeState',
      label: 'Stato Sicoge'
    },
    {
      csvOrder: 409,
      prefix: 'mandate',
      name: 'gebilaState',
      label: 'Stato Gebila'
    },
    {
      csvOrder: 410,
      prefix: 'mandate',
      name: 'signatureDate',
      label: 'Data firma OP'
    },
    {
      csvOrder: 411,
      prefix: 'mandate',
      name: 'validateDate',
      label: 'Data Validazione'
    },
    {
      csvOrder: 412,
      prefix: 'mandate',
      name: 'paymentDate',
      label: 'Data di pagamento'
    },
    {
      csvOrder: 413,
      prefix: 'mandate',
      name: 'managementPlan',
      label: 'Piano gestionale'
    },
    {
      csvOrder: 414,
      prefix: 'mandate',
      name: 'commitmentType',
      label: 'Tipo di impegno'
    },
    {
      csvOrder: 400,
      prefix: 'mandate',
      name: 'commitmentNumber',
      label: 'Numero impegno'
    },
    {
      csvOrder: 415,
      prefix: 'mandate',
      name: 'shipDate',
      label: 'Data sped. UBRRAC',
      csvLabel: 'Data spedizione UBRRAC'
    }
  ],
  suspending: [
    {
      csvOrder: 501,
      prefix: 'suspending',
      name: 'suspendedFromDate',
      label: 'Data inizio',
      csvLabel: 'Data inizio sospensione termini'
    },
    {
      csvOrder: 502,
      prefix: 'suspending',
      name: 'suspendedToDate',
      label: 'Data fine',
      csvLabel: 'Data fine sospensione termini'
    },
    {
      csvOrder: 503,
      prefix: 'suspending',
      name: 'suspendedReason',
      label: 'Motivazione'
    }
  ],
  lines: [
    {
      csvOrder: 601,
      prefix: 'lines',
      name: 'economicEntryCode',
      label: 'Codice C.E.',
      csvLabel: 'Codice Conto Economico'
    },
    {
      csvOrder: 602,
      prefix: 'lines',
      name: 'periodStartDate',
      label: 'Data Inizio',
      csvLabel: 'Data Inizio Periodo'
    },
    {
      csvOrder: 603,
      prefix: 'lines',
      name: 'periodEndDate',
      label: 'Data Fine',
      csvLabel: 'Data Fine Periodo'
    },
    { csvOrder: 604, prefix: 'lines', name: 'priceTotal', label: 'Totale' },
    { csvOrder: 605, prefix: 'lines', name: 'vatRate', label: 'IVA' }
  ]
};
