import { ArrowRightOutlined, AuditOutlined } from '@ant-design/icons';
import { Alert, Tag } from 'antd';
import { ContractSelectInput } from 'client/components/schema/contract/input/ContractSelectInput';
import { InvoceReferenceRow } from 'client/components/schema/invoice-reference/view/InvoiceReferenceRow';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { Section } from 'client/ui/section/Section';
import { SectionButton } from 'client/ui/section/SectionButton';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import * as React from 'react';
import { InvoiceCigIncongruenceTag } from '../views/InvoiceCigIncongruenceTag';

export interface IInvoceContractSectionProps {
  invoice: InvoiceDto;
}

/**
 * Mostra i riferimenti contrattuali ricevuti da SDI e la selezione
 * del contratto.
 */
export function InvoceContractSection(props: IInvoceContractSectionProps) {
  const { invoice } = props;

  const hasReferences = invoice.isSDI() && invoice.references?.length > 0;
  const shouldWarnMissingReferences = invoice.isSDI() && !hasReferences;

  return (
    <Section
      icon={<AuditOutlined />}
      hasPadding
      title={
        <>
          Gestione Contratto{' '}
          {shouldWarnMissingReferences && (
            <Tag
              color="warning"
              children="Nessun riferimento a Contratti in fattura"
            />
          )}{' '}
          <InvoiceCigIncongruenceTag invoice={invoice} />
        </>
      }
      extra={
        invoice.contractId && (
          <SectionButton
            type="default"
            to={`/contracts/all/${invoice.contractId}`}
          >
            Vai al Contratto <ArrowRightOutlined />
          </SectionButton>
        )
      }
    >
      {hasReferences && (
        <FormFieldsContainer
          title="Riferimenti in Fattura"
          removeTopTitleSpacing
        >
          {invoice.references.map(reference => (
            <InvoceReferenceRow key={reference.id} reference={reference} />
          ))}
        </FormFieldsContainer>
      )}
      <ContractSelectInput
        name="contractId"
        hasReferences={hasReferences}
        invoice={invoice}
      />
    </Section>
  );
}
