export enum ShipmentPriority {
  /** Bassa */
  Low = 'Low',
  /** Media - Normale */
  Medium = 'Medium',
  /** Alta - Urgente */
  High = 'High',
  /** Altissima - Urgentissimo */
  Highest = 'Highest'
}

export enum ShipmentState {
  /** In Lavorazione */
  InProgress = 'InProgress',
  /** Inviata */
  Shipped = 'Shipped'
  // /** Concluso */
  // Closed = 'Closed'
}
