import { Space, Button } from 'antd';
import { visible } from 'chalk';
import { RelationEditor } from 'client/components/form/relations/useRelationEditor';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { InvoiceLineDto } from 'common/dto/generated/InvoiceLineDto';
import { InvoiceLineMassiveEditDto } from 'common/dto/query/InvoiceLineMassiveEditDto';
import { useField, useFormikContext } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { EconomicEntrySelectInput } from '../../economy/economic-entry/EconomicEntrySelectInput';

export interface IInvoiceLineMassiveEditFormProps {
  editable?: boolean;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export function InvoiceLineMassiveEditForm(
  props: IInvoiceLineMassiveEditFormProps
) {
  const { visible, setVisible } = props;

  const formik = useFormikContext<InvoiceLineMassiveEditDto>();

  const handleClose = () => {
    setVisible(false);
    formik.resetForm();
  };

  return (
    <ModalDialog
      title="Modifica Massiva delle Linee di Fattura"
      visible={visible}
      onCancel={handleClose}
      footer={
        <Space>
          <Button onClick={handleClose}>Annulla</Button>
          {props.editable && (
            <Button
              type="primary"
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
              onClick={formik.submitForm}
            >
              Modifica Linee
            </Button>
          )}
        </Space>
      }
    >
      <span>
        Valorizzare uno o più campi per aggiornare i valori di tutte le linee di
        fattura. I campi non valorizzati non saranno aggiornati.
      </span>
      <FormFieldsContainer title="Dati Contabilizzazione" columns={2}>
        <FormFieldItem
          inline
          colSpan={2}
          component={EconomicEntrySelectInput}
          name="economicEntryId"
          label="Codice Conto Economico"
          placeholder="Codice Conto Economico"
        />
        <FormFieldItem
          inline
          component={DatePickerInput}
          type="date-only"
          name="periodStartDate"
          label="Inizio Periodo"
          placeholder="Inizio Periodo"
        />
        <FormFieldItem
          inline
          component={DatePickerInput}
          type="date-only"
          name="periodEndDate"
          label="Fine Periodo"
          placeholder="Fine Periodo"
        />
      </FormFieldsContainer>
    </ModalDialog>
  );
}
