import { yup } from 'common/validation/initYup';
import { assertNever } from 'server/utils/typings/assertNever';
import { ValidationError } from 'yup';
import { ActionConfig } from './ActionConfig';

type Action<E extends object, A extends string> = {
  [Key in A]: ActionConfig<E>;
};

export type ActionCanResult =
  | { allowed: true }
  | {
      allowed: false;
      skipped?: boolean;
      message?: string | undefined;
      errors?: string[];
    };

// Necessario per evitare che le migrazioni crashino per mancato import (ts-node)
type Maybe<T> = T | null | undefined;

/**
 * Macchina a stati con supporto ad azioni personalizzate
 */
export class EntityMachine<
  E extends object,
  S extends string,
  A extends string
> {
  private actions: Action<E, A> = {} as any;
  private transitions: { [Key in S]?: S[] } = {};
  private getState!: (entity: E) => S;

  static for<E extends object>() {
    return new EntityMachine<E, never, never>();
  }

  // Builder

  /**
   * Specifica la funzione per ottenere lo stato a partire dall'entità.
   */
  withState<SExt extends string>(getState: (entity: E) => SExt) {
    this.getState = getState as any;
    return (this as unknown) as EntityMachine<E, SExt, A>;
  }

  /**
   * Aggiunge un'azione (con una chiave) che può poi essere controllata
   * su un singolo record con il metodo `can`
   */
  withAction<K extends string>(key: K, config?: ActionConfig<E>) {
    const machine = (this as unknown) as EntityMachine<E, S, A | K>;
    machine.actions[key] = config ?? { allowed: () => true };
    return machine;
  }

  /**
   * Aggiunge una transizione di stato. Dato lo stato iniziale, specifica
   * quali sono quelli di destinazione.
   */
  withTransition(from: S, to: S | S[]) {
    this.transitions[from] = Array.isArray(to) ? to : [to];
    return this;
  }

  // Funzioni di controllo

  /**
   * Ottiene l'elenco delle azioni disponibili per un record `E`
   */
  actionsFor(record?: E | null): A[] {
    if (!record) return [];
    return Object.keys(this.actions).filter(key => {
      return this.can(record, key as any);
    }) as A[];
  }

  /**
   * Verifica se il record è abilitato alla funzione indicata e restituisce
   * i dati di dettaglio ad esempio il messaggio
   */
  can(record: Maybe<E>, action: A, fullResult: true): ActionCanResult;
  /**
   * Restituisce un booleano se è possibile o meno effettuare l'azione
   */
  can(record: Maybe<E>, action: A): boolean;
  can(
    record: Maybe<E>,
    action: A,
    fullResult: boolean = false
  ): ActionCanResult | boolean {
    const canResult = this.isActionAllowed(record, action);
    return fullResult ? canResult : canResult.allowed;
  }

  private isActionAllowed(record: Maybe<E>, action: A): ActionCanResult {
    const config = this.actions[action] as ActionConfig<E>;

    // 1. Caso in cui manchi il record
    if (!record) {
      return { allowed: false };
    }

    // 2. Skip richiesto
    if (config.skip?.(record)) {
      return { allowed: false, skipped: true };
    }

    // 3. Caso con schema di validazione
    if ('schema' in config) {
      try {
        config.schema.validateSync(record, {
          abortEarly: false,
          context: { record }
        });
        return {
          allowed: true
        };
      } catch (e) {
        if (!(e instanceof ValidationError)) throw e;

        return {
          allowed: false,
          message: config.schemaMessage?.(record, e) ?? e.errors[0],
          errors: e.errors
        };
      }
    }
    // 4. Caso con funzione di validazione
    else {
      const allowed = config.allowed?.(record) ?? true;
      return {
        allowed,
        message: allowed ? undefined : config.message?.(record)
      };
    }
  }

  /**
   * Verifica se il record può transitare verso lo stato indicato
   */
  to(record: Maybe<E>, state: S) {
    if (!record) return false;

    const prevState = this.getState(record);

    // // Permettiamo sempre la transizione allo stesso stato
    // TODO Rimossa per questioni di UI (menu) - da riabilitare ma gestendolo lato UI
    // if (prevState === state) return true;

    return (this.transitions[prevState] ?? ([] as S[])).includes(state);
  }

  /**
   * Verifica se il record è nello stato indicato
   */
  is(record: Maybe<E>, state: S) {
    if (!record) return false;
    return this.getState(record) === state;
  }

  /**
   * Verifica la possibilità di passaggio da uno stato al successivo.
   * Ritorna l'entità validata con il passaggio di stato, o lancia
   * un'eccezione altrimenti.
   * @param from entità iniziale
   * @param to entità in ingresso
   */
  async validateWithActions<
    EntityDto extends E & {
      __dto: any;
      validate: (options?: any) => Promise<EntityDto>;
    }
  >(from: E, to: EntityDto): Promise<EntityDto> {
    return await to.validate({
      context: { machineActions: this.actionsFor(from) }
    });
  }
}
