import * as React from 'react';
import { FileSyncOutlined, NotificationOutlined } from '@ant-design/icons';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';
import { ISODate } from 'client/ui/display/date/ISODate';
import { DataItem } from 'client/ui/form/field/DataItem';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { Section } from 'client/ui/section/Section';
import { MandateDto } from 'common/dto/generated/MandateDto';
import { SicogeStateDataItem } from 'client/components/schema/mandate/view/SicogeStateDataItem';
import { GebilaStateDataItem } from 'client/components/schema/mandate/view/GebilaStateDataItem';

export interface IMandateSiabSectionProps {
  mandate: MandateDto;
}

/**
 * Dati ottenuti da Siab del Mandato.
 */
export function MandateSiabSection(props: IMandateSiabSectionProps) {
  const { mandate } = props;
  const labelWidth: ILabelWidth = 'width-140';

  return (
    <Section
      icon={<FileSyncOutlined />}
      title={mandate.isSiabImported ? 'Dati SIAB' : 'Dati Mandato'}
      hasPadding
    >
      <FormFieldsContainer removeTopTitleSpacing columns={4}>
        <DataItem
          labelWidth={labelWidth}
          label="Esercizio Gestione"
          value={mandate.mandateYear}
        />
        <DataItem
          strong
          labelWidth={labelWidth}
          label="Numero Mandato"
          value={mandate.mandateNumber}
        />
        <DataItem
          strong
          labelWidth={labelWidth}
          label="Numero Impegno"
          value={mandate.commitmentNumber}
        />
      </FormFieldsContainer>
      <FormFieldsContainer title="Dati Principali" columns={4}>
        <DataItem
          labelWidth={labelWidth}
          label="Esercizio Provenienza"
          value={mandate.originYear}
        />
        <DataItem
          labelWidth={labelWidth}
          label="Capitolo Spesa"
          value={mandate.expenseChapter}
        />
        <DataItem
          labelWidth={labelWidth}
          label="Camicia SIGOGE"
          value={mandate.camicia}
        />
        <DataItem
          labelWidth={labelWidth}
          label="Numero Repertorio"
          value={mandate.repertory}
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <DataItem
          labelWidth={labelWidth}
          label="Beneficiario"
          value={mandate.beneficiary}
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={2}>
        <DataItem
          labelWidth={labelWidth}
          label="Importo"
          value={<CurrencyRender value={mandate.amount} />}
        />
        <DataItem
          labelWidth={labelWidth}
          label="Importo Netto"
          value={<CurrencyRender value={mandate.netAmount} />}
        />
        <DataItem
          labelWidth={labelWidth}
          label="Data Pagamento"
          value={<ISODate date={mandate.paymentDate} />}
        />
      </FormFieldsContainer>
      <FormFieldsContainer title="Altri dati">
        <DataItem
          labelWidth={labelWidth}
          label="Causale"
          value={mandate.reason}
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={4}>
        <DataItem
          labelWidth={labelWidth}
          label="Piano Gestionale"
          value={mandate.managementPlan}
        />
        <DataItem
          labelWidth={labelWidth}
          label="Tipo Impegno"
          value={mandate.commitmentType}
        />
        <DataItem
          labelWidth={labelWidth}
          label="Saldo"
          value={mandate.balance}
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={2}>
        <SicogeStateDataItem labelWidth={labelWidth} mandate={mandate} />
        <GebilaStateDataItem labelWidth={labelWidth} mandate={mandate} />
        <DataItem
          labelWidth={labelWidth}
          label="Data Firma"
          value={<ISODate date={mandate.signatureDate} />}
        />
        <DataItem
          labelWidth={labelWidth}
          label="Data Validazione"
          value={<ISODate date={mandate.validateDate} />}
        />
      </FormFieldsContainer>
    </Section>
  );
}
