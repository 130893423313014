import {
  NotificationCode,
  NotificationDirection,
  NotificationType
} from './NotificationTypes';

export const notificationDirectionLabels = {
  [NotificationDirection.Incoming]: 'Entrata',
  [NotificationDirection.Outgoing]: 'Uscita'
};

export const notificationCodeLabels = {
  [NotificationCode.Accettazione]: 'Accettazione',
  [NotificationCode.NonAmmissibile]: 'Non Ammissibile',
  [NotificationCode.NonConforme]: 'Non Conforme',
  [NotificationCode.Rifiuto]: 'Rifiuto'
};

export const notificationTypeLabels = {
  [NotificationType.EC]: 'Esito Committente',
  [NotificationType.SE]: 'Scarto Esito Committente',
  [NotificationType.DT]: 'Decorrenza Termini',
  [NotificationType.MT]: 'File dei Metadati',
  [NotificationType.NONE]: 'N.A.'
};
