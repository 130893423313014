import { Model003Action } from 'server/schema/pcc/model003/Model003Types';
import { DebtState, PayableDebtStates } from './AccountingLineTypes';

export interface IAccountingLinePccQuery {
  pccExportedExpiration?: boolean;
  debtStates?: DebtState[];
  pccExportedPayment?: boolean;
  paymentExecuted?: boolean;
  pccExportedAccounting?: boolean;
  needsPccPayment?: boolean;
}

export const AccountingLinePccQuery: {
  [action: string]: IAccountingLinePccQuery;
} = {
  [Model003Action.CS]: {
    pccExportedExpiration: false
  },
  [Model003Action.CP]: {
    debtStates: PayableDebtStates,
    pccExportedPayment: false,
    paymentExecuted: true,
    needsPccPayment: true
  },
  [Model003Action.CO]: {
    paymentExecuted: false,
    pccExportedAccounting: false
  }
};
