import { getRevisionHeader } from 'client/components/schema/invoice/revision/getRevisionHeader';
import { InvoiceProtocolName } from 'client/components/schema/invoice/view/InvoiceProtocolName';
import { ISODate } from 'client/ui/display/date/ISODate';
import {
  PageHeadingSummary,
  SummaryItemProps
} from 'client/ui/layout/page/heading/PageHeadingSummary';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import * as React from 'react';

export interface InvoiceHeaderContentProps {
  invoice: InvoiceDto;
}

/**
 * Contenuto supplementare dell'intestazione della pagina,
 * posizionato sotto il titolo pagina e i pulsanti azione,
 * per mostrare ulteriori dati riassuntivi dell'elemento.
 * * Per pagina Fattura
 */
export function InvoiceHeaderContent(props: InvoiceHeaderContentProps) {
  const { invoice } = props;

  return (
    <PageHeadingSummary
      column={3}
      items={[
        {
          label: 'Protocollo',
          value: <InvoiceProtocolName invoice={invoice} />
        },
        {
          label: 'RUF',
          value: invoice.rufNumber
        },
        getRevisionHeader(invoice),
        { label: 'Numero Fattura', value: invoice.issuedNumber },
        { label: 'Data Fattura', value: <ISODate date={invoice.issuedDate} /> },
        {
          label: 'Data Ricezione',
          value: <ISODate date={invoice.receivedAt} />
        }
      ]}
      values={[
        {
          label: 'Totale',
          value: invoice.totalAmount?.toFixed(2),
          suffix: '€'
        },
        {
          label: 'Imposta',
          value: invoice.totalVatAmount?.toFixed(2),
          suffix: '€'
        },
        {
          label: 'Imponibile',
          value: invoice.totalVatTaxableAmount?.toFixed(2),
          suffix: '€'
        }
      ]}
    />
  );
}
