import * as React from 'react';
import clx from 'classnames';
import { CheckCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import './PccInvoiceCommunicationsState.scss';

const renderMessage = (status?: boolean | null) => {
  return status ? 'Comunicata' : 'Da comunicare';
};
export interface PccInvoiceCommunicationsStateProps {
  pccExported?: boolean | null;
}

/**
 * Serie di indicatori dello stato delle comunicazioni a PCC
 * delle linee di contabilizzazione
 */
export function PccInvoiceCommunicationsState(
  props: PccInvoiceCommunicationsStateProps
) {
  return (
    <span className="pcc-invoice-communications-state">
      <Tooltip title={`PCC Ricezione: ${renderMessage(props.pccExported)}`}>
        <CheckCircleFilled
          className={clx('icon', { active: props.pccExported })}
        />
      </Tooltip>
    </span>
  );
}
