import * as React from 'react';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { ArrowRightOutlined, MailOutlined } from '@ant-design/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { ShipmentQueryDto } from 'common/dto/query/ShipmentQueryDto';
import { useEffect, useState } from 'react';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { ShipmentApi } from 'client/components/schema/shipment/ShipmentApi';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { ShipmentAllColumns } from 'client/components/schema/shipment/table/ShipmentAllColumns';
import { mapShipmentFilterToOptions } from './mapShipmentFilterToOptions';
import { LinkButton } from 'client/ui/link/LinkButton';
import { TableSticky } from 'client/ui/table/TableSticky';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import { SorterResult } from 'antd/lib/table/interface';
import { getDateRange, getFilter } from 'client/ui/table/TableColumnFilter';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';

interface Params {
  filter?: string;
}

export interface ShipmentsListPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di elenco delle Spedizioni UBRRAC
 */
export function ShipmentsListPage(props: ShipmentsListPageProps) {
  const { match } = props;

  const [options, setOptions] = useState(
    mapShipmentFilterToOptions(match.params.filter)
  );

  const [query, setQuery] = useState<ShipmentQueryDto>(
    new ShipmentQueryDto(
      getPaginationUrlQuery(props.location.search, {
        state: options.state,
        pageNumber: 1,
        pageSize: 10
      })
    )
  );

  const { response, loading, error } = useApiQuery(ShipmentApi.list, {
    data: { query }
  });

  const shipments = response?.data.items;

  const columns = getPaginationUrlColumns<ShipmentDto, ShipmentQueryDto>(
    [
      ...ShipmentAllColumns,
      {
        title: '',
        dataIndex: 'azioni',
        align: 'right',
        width: 60,
        fixed: 'right',
        render: (_, record) => (
          <Link to={`/shipping/${options.filter}/${record.id}`}>
            Vai <ArrowRightOutlined />
          </Link>
        )
      }
    ],
    query,
    { createdAt: ['creationFromDate', 'creationToDate'] }
  );

  useEffect(() => {
    setQuery(
      new ShipmentQueryDto(
        getPaginationUrlQuery(props.location.search, {
          state: options.state,
          pageNumber: 1,
          pageSize: 10
        })
      )
    );
  }, [options]);

  useEffect(() => {
    setOptions(mapShipmentFilterToOptions(match.params.filter));
  }, [match, options.state]);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/shipping/all',
    title: `Spedizioni UBRRAC`
  });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: options.title,
    menuKey: 'shipping',
    sideMenuKey: `shipment-${options.filter}`
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco delle spedizioni. Riprovare."
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle>{options.title}</PageHeadingTitle>}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={[
          <LinkButton
            to={'/shipping/all/create'}
            key="create"
            type="primary"
            icon={<MailOutlined />}
          >
            Nuova Spedizione
          </LinkButton>
        ]}
      />
      <PageContent noPadding noSpace>
        <TableSticky
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={shipments}
          size="middle"
          onChange={async (pagination, filters, sorter) => {
            const { field, order } = sorter as SorterResult<ShipmentDto>;

            const createdAtRange = getDateRange(filters.createdAt);

            const description = getFilter(filters, 'description')?.toString();
            const recipient = getFilter(filters, 'recipient')?.toString();
            const number = getFilter(filters, 'number') as any;

            const shipmentQuery = new ShipmentQueryDto({
              ...query,
              orderBy: order ? field?.toString() : 'id',
              orderByDirection: order
                ? ColumnSortDirections[order]
                : ColumnSortDirections.descend,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize,
              creationFromDate: createdAtRange.from,
              creationToDate: createdAtRange.to,
              description,
              recipient,
              number
            });

            setPaginationUrlQuery(props, shipmentQuery);
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
