import * as React from 'react';
import './SimulationStatistic.scss';

export interface SimulationStatisticProps {
  total: number;
  repaired: number | null | undefined;
  warning: number;
}

/**
 * Elemento per mostrare le statistiche delle linee processate
 * in una simulazione di conto economico
 */
export function SimulationStatistic(props: SimulationStatisticProps) {
  const { total, warning, repaired } = props;

  return (
    <div className="report-statistic">
      <div className="label">
        <span>Linee processate / </span>
        <span>Bonificate / </span>
        <span className="warning">anomalie</span>
      </div>
      <div className="data">
        <span>{total}/</span>
        <span>{repaired ?? 0}/</span>
        <span className="warning">{warning}</span>
      </div>
    </div>
  );
}
