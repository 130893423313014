import { DataItem } from 'client/ui/form/field/DataItem';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { useFormikContext } from 'formik';
import React from 'react';
import { useModuleConfigOptions } from '../../config/ClientConfigModule';

interface IContractRegisterItemProps {
  label: string;
  colSpan?: number;
}

export function ContractRegisterItem(props: IContractRegisterItemProps) {
  const { label } = props;

  const registers = useModuleConfigOptions('pitre.allowed.registers');
  const formik = useFormikContext<ContractDto>();

  const value = registers.find(
    register => register.value === formik.values.documentRegister
  );

  return <DataItem inline value={value?.name ?? ''} {...props} />;
}
