import { ArrowRightOutlined, FileOutlined } from '@ant-design/icons';
import { Table, Tag } from 'antd';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { ContractInvoiceColumns } from 'client/components/schema/invoice/table/ContractInvoiceColumns';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { Section } from 'client/ui/section/Section';
import { InvoiceQueryDto } from 'common/dto/query/InvoiceQueryDto';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export interface ContractInvoiceSectionProps {
  contractId: number | null;
}

export function ContractInvoiceSection(props: ContractInvoiceSectionProps) {
  const { contractId } = props;

  const [query, setQuery] = useState<InvoiceQueryDto>(
    new InvoiceQueryDto({ pageSize: 30 })
  );

  const { response, loading } = useApiQuery(ContractApi.invoices.list, {
    skip: contractId == null,
    data: {
      id: contractId!,
      query: query
    }
  });

  const invoices = response?.data.items ?? [];
  const total = response?.data.meta.total ?? 0;

  return (
    <Section icon={<FileOutlined />} title="Fatture Collegate">
      <Table
        rowKey="id"
        loading={loading}
        columns={[
          ...ContractInvoiceColumns,
          {
            title: '',
            dataIndex: 'azioni',
            align: 'right',
            fixed: 'right',
            width: 70,
            render: (_, record) => (
              <Link to={`/invoicing/invoices/all/${record.id}`}>
                Vai <ArrowRightOutlined />
              </Link>
            )
          }
        ]}
        scroll={{ x: true }}
        dataSource={invoices}
        size="middle"
        onChange={(pagination, filters, sorter) => {
          setQuery(
            new InvoiceQueryDto({
              ...query,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize
            })
          );
        }}
        pagination={
          total <= query.pageSize
            ? false
            : {
                size: 'small',
                position: ['bottomRight'],
                showSizeChanger: true,
                total: total,
                pageSize: query.pageSize,
                current: query.pageNumber,
                pageSizeOptions: ['5', '10', '20', '30', '40']
              }
        }
      />
    </Section>
  );
}
