import { base64 } from 'client/core/encoding/base64';

const STORAGE_KEY = 'cfStorage';

interface IStorage {
  adminReferentFilter: boolean | null;
}

export const ConfigStorage = {
  get(): IStorage {
    const data = window.localStorage.getItem(STORAGE_KEY);
    return {
      ...(data && JSON.parse(base64.decode(data)))
    };
  },

  /**
   * Carichiamo i dati iniziali dal `localStorage` in caso siano
   * presenti.
   */
  initialState(): IStorage {
    return this.get();
  },

  /**
   * Salva un valore specifico all'interno dello stato
   */
  saveValue(key: keyof IStorage, value: IStorage[keyof IStorage]) {
    window.localStorage.setItem(
      STORAGE_KEY,
      base64.encode(JSON.stringify({ ...this.get(), [key]: value }))
    );
  },

  /** Recupera un valore di configurazione salvato nello stato */
  getValue(key: keyof IStorage) {
    const storage = this.get();
    return storage[key];
  }
};
