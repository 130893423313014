import { apiClient } from 'client/core/network/apiClient';
import {
  MandateDto,
  PaginatedMandateDto
} from 'common/dto/generated/MandateDto';
import { MandateFindQueryDto } from 'common/dto/query/MandateFindQueryDto';
import { MandateFindResponseDto } from 'common/dto/query/MandateFindResponseDto';
import { MandateGetDescriptionResponseDto } from 'common/dto/query/MandateGetDescriptionResponseDto';
import { MandateQueryDto } from 'common/dto/query/MandateQueryDto';

export const MandateApi = {
  list: (opts: { query: MandateQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedMandateDto)
      .get(`/mandates`, { params: opts.query }),

  find: (opts: { id: number }) =>
    apiClient.requestWithResponseType(MandateDto).get(`/mandates/${opts.id}`),

  create: (opts: { input: MandateFindQueryDto }) =>
    apiClient.requestWithResponseType(MandateDto).post(`/mandates`, opts.input),

  update: (opts: { id: number; input: MandateDto }) =>
    apiClient
      .requestWithResponseType(MandateDto)
      .put(`/mandates/${opts.id}`, opts.input),

  siab: {
    find: (opts: { query: MandateFindQueryDto }) =>
      apiClient
        .requestWithResponseType(MandateFindResponseDto)
        .get(`/mandates/siab`, { params: opts.query }),

    import: () => apiClient.request().post('/mandates/actions/import')
  },

  getShipmentDescription: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(MandateGetDescriptionResponseDto)
      .get(`/mandates/${opts.id}/shipment-description`)
};
