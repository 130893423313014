import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaPageNumber, SchemaPageSize, SchemaLabel } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from "client/ui/table/TableColumnSort";
import { SupplierCustomColumnSort } from './types/SupplierCustomColumnSort';
import type { SupplierColumnSort } from './types/SupplierCustomColumnSort';


@GenerateDto()
class SupplierQuery {
  @SchemaPageNumber()
  pageNumber!: number;
  @SchemaPageSize()
  pageSize!: number;
  @SchemaLabel("Denominazione")
  fullname?: string | null;
  @SchemaLabel("P.IVA/CF")
  fiscalIdCode?: string | null;

  @SchemaLabel("Ordinamento")
  orderBy?: SupplierColumnSort = SupplierCustomColumnSort.fullname;
  @SchemaLabel("Direzione di Ordinamento")
  orderByDirection?: ColumnSortDirections = ColumnSortDirections.ascend;

  /** Quando svolge la query, includi anche gli ID elencati tra i dati richiesti. */
  @SchemaLabel('ID aggiuntivi')
  includeAlso?: number[] | null;
}

/**
 * Rappresentazione DTO della classe SupplierQuery 
 * Hash: c9b2b9adc74c1c5d3eca3ca448c90c52
 */
@ValidationSchema(() => SupplierQuerySchema)
export class SupplierQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, type: String, description: 'Denominazione' })
  fullname?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'P.IVA/CF' })
  fiscalIdCode?: string | null | undefined;
  @ApiProperty({ required: false, description: 'Ordinamento' })
  orderBy?: SupplierColumnSort | undefined = SupplierCustomColumnSort.fullname;
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.ascend;
  /** Quando svolge la query, includi anche gli ID elencati tra i dati richiesti. */
  @ApiProperty({ required: false, type: [Number], description: 'ID aggiuntivi' })
  includeAlso?: number[] | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<SupplierQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof SupplierQueryDto ? values : plainToClass(SupplierQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await SupplierQuerySchema.validate(classToPlain(this), options);
    return new SupplierQueryDto(validated);
  }
}

export const SupplierQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    fullname: yup.string().nullable().label('Denominazione'),
    fiscalIdCode: yup.string().nullable().label('P.IVA/CF'),
    orderBy: yup.mixed().default(SupplierCustomColumnSort.fullname).label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.ascend).label('Direzione di Ordinamento'),
    includeAlso: yup.array(yup.number().required()).nullable().label('ID aggiuntivi')
  })
  .noUnknown()
  .meta({ schemaName: "SupplierQuerySchema" })
  .required();
