import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ISODate } from 'client/ui/display/date/ISODate';
import { EconomicStatementSimulationDto } from 'common/dto/generated/EconomicStatementSimulationDto';
import React from 'react';
import { GeneratedIcon } from '../../statement/view/GeneratedIcon';
import { NameOrError } from '../../statement/view/NameOrError';
import { EconomySemesterTag } from '../semester/EconomySemesterTag';

export const EconomicSimulationColumns: ColumnsType<EconomicStatementSimulationDto> = [
  {
    title: '',
    dataIndex: 'state',
    width: 40,
    render: (_, record) => <GeneratedIcon state={record.state} />
  },
  {
    title: 'Periodo',
    key: 'period',
    width: 160,
    render: (_, record) => (
      <EconomySemesterTag year={record.year} semester={record.semester} />
    )
  },
  {
    title: 'Fatture',
    key: 'onlyPaid',
    width: 90,
    render: (_, record) =>
      record.onlyPaid ? <Tag>Pagate</Tag> : <Tag>Accettate</Tag>
  },
  {
    title: 'Nome',
    dataIndex: 'title',
    render: (_, record) => (
      <NameOrError title={record.title} state={record.state} />
    )
  },
  {
    title: 'Data Simulazione',
    dataIndex: 'createdAt',
    width: 170,
    render: (_, record) => (
      <ISODate date={record.createdAt} format="DD/MM/YYYY HH:mm:ss" />
    )
  },
  {
    title: 'Utente',
    key: 'author.name',
    width: 170,
    render: (_, record) => record.author?.name
  }
];
