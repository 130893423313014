import { ColumnsType } from 'antd/lib/table';
import { AccountingLineDto } from 'common/dto/generated/AccountingLineDto';
import { AccountingLineColumns } from './AccountingLineColumns';

export const InvoiceAccountingLineColumns: ColumnsType<AccountingLineDto> = [
  AccountingLineColumns.state,
  AccountingLineColumns.amount,
  AccountingLineColumns.expenseNature,
  AccountingLineColumns.pcc,
  AccountingLineColumns.debtState,
  AccountingLineColumns.description,
  AccountingLineColumns.mandate,
  AccountingLineColumns.paymentDate,
  AccountingLineColumns.expenseChapter
];
