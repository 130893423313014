import * as React from 'react';
import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { InvoiceClientDataDto } from 'common/dto/generated/InvoiceClientDataDto';

export interface ISupplierBatchViewProps {
  client: InvoiceClientDataDto;
}

export function ClientBatchView(props: ISupplierBatchViewProps) {
  const { client } = props;

  if (!client)
    return (
      <FormFieldsContainer title="Cessionario/Committente">
        {' '}
      </FormFieldsContainer>
    );
  const province = client.hqProvince ? ' (' + client.hqProvince + ')' : '';
  return (
    <FormFieldsContainer title="Cessionario/Committente">
      <DataItem label="Denominazione" value={client.name} strong />
      <DataItem label="Codice Fiscale" value={client.fiscalNumber} />
      <DataItem label="Indirizzo" value={client.hqAddress} />
      <DataItem label="Comune" value={client.hqCity + province} />
      <DataItem label="CAP" value={client.hqZip} />
      <DataItem label="Nazione" value={client.hqCountry} />
    </FormFieldsContainer>
  );
}
