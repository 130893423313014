import React, { useCallback, useEffect, useState } from 'react';
import {
  ClockCircleOutlined,
  DeleteOutlined,
  DownOutlined
} from '@ant-design/icons';
import { message, Tag, Dropdown, Button, Tooltip } from 'antd';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { Section } from 'client/ui/section/Section';
import { ContractDto, ContractSchema } from 'common/dto/generated/ContractDto';
import { TermsKind } from 'common/schema/contract/ContractTypes';
import { RouteComponentProps } from 'react-router';
import { ContractAccountingSection } from './sections/ContractAccountingSection';
import { ContractGeneralSection } from './sections/ContractGeneralSection';
import { ContractInvoiceSection } from './sections/ContractInvoicesSection';
import { ContractReferentsSection } from './sections/ContractReferentsSection';
import { ContractSuppliersSection } from './sections/ContractSuppliersSection';
import { EditAndSaveButton } from 'client/ui/form/button/EditAndSaveButton';
import { PageHeadingSummary } from 'client/ui/layout/page/heading/PageHeadingSummary';
import { ContractStateTag } from 'client/components/schema/contract/view/ContractStateTag';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { ContractStateChange } from 'client/routes/contract/ContractStateChange';
import { mapContractsFilterToOptions } from '../list/FilterToOptionsContracts';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { ActivityLogTable } from 'client/components/schema/activity-log/table/ActivityLogTable';
import { ActivityLogApi } from 'client/components/schema/activity-log/ActivityLogApi';
import { ContractMachine } from 'common/schema/contract/ContractMachine';
import { ContractAttachmentsSection } from './sections/ContractAttachmentsSection';

interface Params {
  id: string;
  filter?: string;
}

export interface ContractPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina del Contratto
 */
export function ContractPage(props: ContractPageProps) {
  // Prendo l'ID del contratto
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const { response, error, loading, refetch } = useApiQuery(ContractApi.find, {
    skip: id == null,
    data: {
      id: id!
    }
  });

  const contract =
    id != null
      ? response?.data
      : new ContractDto({
          cupCode: 'NA',
          isCompleted: false,
          hasStampDuty: false,
          hasRegistration: false,
          hasPaperCopyReturn: false,
          paymentTermsDays: 60,
          paymentTermsKind: TermsKind.SolarDays,
          testingTermsDays: 30,
          testingTermsKind: TermsKind.SolarDays,
          adminReferentDocumentalCode: undefined,
          rupReferentDocumentalCode: undefined,
          decReferentDocumentalCode: undefined,
          isDlgs2312002: true
        });

  const deletable = ContractMachine.can(contract, 'delete', true);
  const [editable, setEditable] = useState(id == null);

  const [create] = useApiMutation(ContractApi.create, {
    invalidates: [ContractApi.find, ActivityLogApi.list]
  });
  const [update] = useApiMutation(ContractApi.update, {
    invalidates: [ContractApi.find, ActivityLogApi.list]
  });
  // non c'è l'invalidates perché dopo la delete c'è un redirect
  // alla lista dei contratti
  const [deleteContract] = useApiMutation(ContractApi.softDelete, {
    invalidates: []
  });

  const handleDelete = useCallback(async () => {
    if (!id) return;
    if (!deletable) return;

    if (!deletable.allowed) {
      message.error(deletable.message);
      return;
    }

    try {
      await deleteContract({ data: { id } });
      message.success('Contratto eliminato con successo.');
      props.history.push('/contracts/all');
    } catch (e) {
      message.error("Errore durante l'eliminazione del contratto. Riprovare.");
    }
  }, [id, deletable]);

  const contractTitle =
    id == null
      ? 'Nuovo Contratto'
      : `Contratto ${
          contract?.number ? `n.${contract?.number}` : `#${contract?.id}`
        }`;

  const pageOptions = mapContractsFilterToOptions(props.match.params.filter);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/contracts/all', title: `Contratti` });
  useBreadcrumbItem({
    path: `/contracts/${pageOptions.filter}`,
    title: pageOptions.title
  });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: contractTitle,
    menuKey: 'contracts',
    sideMenuKey: pageOptions.filter
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere al contratto"
        error={error}
      />
    );
  }

  if (!contract) return <LoadingPage />;

  return (
    <CorePageLayout>
      <FormikAugmented<ContractDto>
        initialValues={contract}
        enableReinitialize
        onReset={() => {
          setEditable(false);
        }}
        onSubmit={async rawValues => {
          try {
            const input = await rawValues.validate();
            if (id) {
              await update({ data: { input } });
              message.success('Contratto modificato con successo. ');
            } else {
              const result = await create({
                data: { input }
              });
              message.success('Contratto creato con successo. ');
              props.history.push(`/contracts/all/${result.data!.id}`);
            }
            setEditable(false);
          } catch (e) {
            message.error(
              'Si è verificato un errore durante la creazione del contratto.'
            );
          }
        }}
        validationSchema={ContractSchema}
        validationContext={{
          machineActions: ContractMachine.actionsFor(contract)
        }}
        validateOnChange={false}
      >
        <FormikForm editable={editable} helpInTooltips showVisualFeedback>
          <PageHeading
            title={<PageHeadingTitle>{contractTitle}</PageHeadingTitle>}
            breadcrumbRender={() => <BreadcrumbsContainer />}
            // TODO Potremmo renderlo un comportamento standard
            tags={
              id == null ? (
                <Tag>Non ancora salvato</Tag>
              ) : (
                <ContractStateTag state={contract.state} />
              )
            }
            extra={
              <EditAndSaveButton
                isNew={id == null}
                name="Contratto"
                state={[editable, setEditable]}
                whenSaved={
                  <>
                    <Dropdown
                      overlay={<ContractStateChange contract={contract} />}
                      overlayStyle={{
                        border: '1px solid #eaeaea',
                        borderRadius: '2px',
                        boxShadow:
                          '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)'
                      }}
                    >
                      <Button>
                        Assegna Stato <DownOutlined />
                      </Button>
                    </Dropdown>

                    <Tooltip
                      title={
                        id != null && !deletable?.allowed && deletable?.message
                      }
                    >
                      <ButtonDeleteConfirm
                        title="Sei sicuro di voler eliminare il contratto?"
                        loading={loading}
                        size="middle"
                        disabled={id == null || !deletable?.allowed}
                        buttonIcon={<DeleteOutlined />}
                        buttonDanger
                        buttonType="primary"
                        onConfirm={handleDelete}
                      />
                    </Tooltip>
                  </>
                }
              />
            }
          >
            {id && (
              <PageHeadingSummary
                column={3}
                items={[
                  { label: 'Oggetto', value: contract.subject, span: 3 },
                  {
                    label: 'Referente',
                    value: contract.adminReferent?.name ?? '--'
                  },
                  { label: 'RUP', value: contract.rupReferent?.name ?? '--' },
                  { label: 'DEC', value: contract.decReferent?.name ?? '--' }
                ]}
              />
            )}
          </PageHeading>
          <PageContent fixedWidht>
            {/* DATI GENERALI CONTRATTO */}
            <ContractGeneralSection contract={contract} />

            {/* SEZIONE REFERENTI */}
            <ContractReferentsSection contract={contract} />

            {/* CONTRAENTI */}
            <ContractSuppliersSection />

            {/* DATI REGISTRAZIONE CONTABILE */}
            <ContractAccountingSection />

            {/* FATTURE */}
            <ContractInvoiceSection contractId={id} />

            {/* ALLEGATI */}
            {id && <ContractAttachmentsSection contractId={id} />}

            {/* LOG */}
            {id && (
              <Section
                icon={<ClockCircleOutlined />}
                title="Cronologia Attività"
              >
                <ActivityLogTable entity="contract" entityId={id} />
              </Section>
            )}
          </PageContent>
        </FormikForm>
      </FormikAugmented>
    </CorePageLayout>
  );
}
