import {
  DeleteOutlined,
  FullscreenOutlined,
  PercentageOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Alert, Button, Table, Tag } from 'antd';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import { InvoiceVatSummaryEditDrawer } from 'client/components/schema/invoice-vat-summary/edit/InvoiceVatSummaryEditDrawer';
import { InvoiceVatSummaryColumns } from 'client/components/schema/invoice-vat-summary/table/InvoiceVatSummaryColumns';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { Section } from 'client/ui/section/Section';
import { SectionButton } from 'client/ui/section/SectionButton';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceVATSummaryDto } from 'common/dto/generated/InvoiceVATSummaryDto';
import { InvoiceVATSummaryLogic } from 'common/logic/InvoiceVATSummaryLogic';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import * as React from 'react';

export interface IInvoiceVatSummariesSectionProps {
  invoice: InvoiceDto;
}

export function InvoiceVatSummariesSection(
  props: IInvoiceVatSummariesSectionProps
) {
  const { invoice } = props;

  const editor = useRelationEditor<InvoiceVATSummaryDto>('vatSummaries');
  const formContext = useFormikFormContext();

  // Posso aggiungere o rimuovere linee solo se non è protocollata
  const canAddOrRemove = InvoiceMachine.can(
    invoice,
    'edit.vat-summary-and-lines'
  );

  const hasChargeabilities = InvoiceVATSummaryLogic.hasChargeabilities(invoice);
  const areChargeabilitiesCongruent = InvoiceVATSummaryLogic.areChargeabilitiesCongruent(
    invoice
  );

  return (
    <Section
      icon={<PercentageOutlined />}
      title={
        <>
          Riepilogo IVA{' '}
          {hasChargeabilities && !areChargeabilitiesCongruent && (
            <Tag color="orange" children="Esigibilità dell'IVA discordanti" />
          )}
          {!hasChargeabilities && (
            <Tag color="orange" children="Esigibilità dell'IVA non presente" />
          )}
        </>
      }
      extra={
        formContext.editable &&
        canAddOrRemove && (
          <SectionButton
            key="add"
            type="primary"
            size="small"
            icon={<PlusOutlined />}
            onClick={e => {
              editor.handle.open(new InvoiceVATSummaryDto());
              e.stopPropagation();
            }}
          >
            Aggiungi Riepilogo IVA
          </SectionButton>
        )
      }
    >
      <Table
        rowKey="id"
        columns={[
          ...InvoiceVatSummaryColumns,
          {
            title: '',
            key: 'actions',
            align: 'right',
            render: (_, record) => (
              <>
                <span>
                  <Button
                    size="small"
                    type="link"
                    children={formContext.editable ? 'Modifica' : 'Dettaglio'}
                    icon={<FullscreenOutlined />}
                    onClick={() => editor.handle.open(record)}
                  />
                </span>
                {formContext.editable && canAddOrRemove && (
                  <span>
                    <Button
                      size="small"
                      type="link"
                      children={'Elimina'}
                      icon={<DeleteOutlined />}
                      disabled={!formContext.editable}
                      danger
                      onClick={() => editor.remove(record)}
                    />
                  </span>
                )}
              </>
            )
          }
        ]}
        dataSource={editor.items}
        size="small"
        pagination={false}
      />
      <InvoiceVatSummaryEditDrawer
        invoice={invoice}
        editor={editor}
        editable={formContext.editable}
      />
    </Section>
  );
}
