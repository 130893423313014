import { Button } from 'antd';
import { InfoBoxActions } from 'client/ui/info-box/InfoBoxActions';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { ProtocolResponseBag } from 'common/dto/response-bag/ProtocolResponseBag';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import React, { useMemo, useState } from 'react';
import { InvoiceProtocolErrorAlert } from './InvoiceProtocolErrorAlert';
import { InvoiceProtocolModal } from './InvoiceProtocolModal';

export interface IInvoicePartialProtocolInfoBoxProps {
  invoice: InvoiceDto;
}

export function InvoicePartialProtocolInfoBox(
  props: IInvoicePartialProtocolInfoBoxProps
) {
  const { invoice } = props;

  const fullProtocolAction = useMemo(
    () => InvoiceMachine.can(invoice, 'edit.protocol', true),
    [invoice]
  );
  const partialProtocolAction = useMemo(
    () => InvoiceMachine.can(invoice, 'edit.protocol:partial', true),
    [invoice]
  );
  const partialCheckpoints = useMemo(() => invoice.getPartialCheckpoints(), [
    invoice
  ]);

  const [errorBag, setErrorBag] = useState<ProtocolResponseBag>({ errors: [] });

  // Se è possibile fare il protocollo completo, non mostro nulla
  if (fullProtocolAction.allowed) {
    return null;
  }
  // Se non è possibile protocollare parzialmente, non mostrare nulla
  if (!partialProtocolAction.allowed) {
    return null;
  }

  return (
    <>
      <InfoBoxActions
        title="Completa protocollazione fattura"
        text={
          <>
            Durante il tentativo di protocollazione si sono presentati degli
            errori. È necessario correggerli per poter procedere alla
            protocollazione completa. Avviare la procedura di protocollazione
            per completare i seguenti passaggi:
            <ul>
              {partialCheckpoints.map(checkpoint => (
                <li>{checkpoint}</li>
              ))}
            </ul>
          </>
        }
        secondaryButton={
          <InvoiceProtocolModal
            invoice={props.invoice}
            setErrorBag={bag => {
              setErrorBag(bag);
            }}
            partial={true}
          />
        }
      />
      <InvoiceProtocolErrorAlert bag={errorBag} />
    </>
  );
}
