import { SelectOption } from 'client/ui/form/input/SelectInput';
import {
  AccountingLineState,
  DebtReason,
  DebtState,
  ExpenseNature
} from './AccountingLineTypes';

export const DebtStateOptions: SelectOption[] = [
  { value: DebtState.LIQ, label: 'LIQ' },
  { value: DebtState.NOLIQ, label: 'NOLIQ' },
  { value: DebtState.SOSP, label: 'SOSP' },
  { value: DebtState.LIQdaSOSP, label: 'LIQ da SOSP' },
  { value: DebtState.LIQdaNL, label: 'LIQ da NOLIQ' },
  { value: DebtState.ADEGLIQ, label: 'ADEGLIQ' },
  { value: DebtState.SOSPdaLIQ, label: 'SOSP da LIQ' },
  { value: DebtState.SOSPdaNL, label: 'SOSP da NOLIQ' },
  { value: DebtState.NLdaLIQ, label: 'NOLIQ da LIQ' },
  { value: DebtState.NLdaSOSP, label: 'NOLIQ da SOSP' }
];

export const DebtReasonOptions: SelectOption[] = [
  { value: DebtReason.ATTLIQ, label: 'ATTLIQ - Attesa di Liquidazione' },
  { value: DebtReason.CONT, label: 'CONT - Contenzioso' },
  { value: DebtReason.ATTNC, label: 'ATTNC - Attesa Nota di Credito' },
  { value: DebtReason.NCRED, label: 'NCRED - Nota di Credito attesa' },
  { value: DebtReason.PAGTERZI, label: 'PAGTERZI - Pagamento da Terzi' },
  { value: DebtReason.IVARC, label: 'IVARC - IVA Reverse Charge' },
  { value: DebtReason.DNC, label: 'DNC - Debiti non Commerciali' },
  {
    value: DebtReason.ATTESECOLL,
    label: 'ATTESECOLL - Attesa di Regolare Esecuzione/Collaudo'
  }
];

export const ExpenseNatureOptions: SelectOption[] = [
  { value: ExpenseNature.ContoCapitale, label: 'Conto Capitale' },
  { value: ExpenseNature.SpesaCorrente, label: 'Spesa Corrente' },
  { value: ExpenseNature.NonApplicabile, label: 'N.A.' }
];

export const AccountingLineStateLabel = {
  [AccountingLineState.NotPayable]: 'Non Pagabile',
  [AccountingLineState.ToShip]: 'Da Spedire',
  [AccountingLineState.ToPay]: 'Spedita',
  [AccountingLineState.Paid]: 'Pagata'
};
