import { Affix, PageHeader, PageHeaderProps } from 'antd';
import * as React from 'react';
import { pageFixedSizes } from '../../PageLayout';
import './PageHeading.scss';

/**
 * Funzione per ottenere il Nodo del titolo di pagina o le sue dimensioni
 * Utilizzato per gestire gli elementi fixed della pagina
 */
export function getPageHeading() {
  const node = document.getElementById('page-heading');
  return {
    node,
    clientRect: node?.getBoundingClientRect()
  };
}

export interface PageHeadingProps extends PageHeaderProps {
  children?: React.ReactNode;
}

/**
 * Intestazione della singola pagina
 */
export function PageHeading(props: PageHeadingProps) {
  return (
    <div
      className="page-heading"
      id="page-heading"
      style={{
        position: 'fixed',
        top: pageFixedSizes.topHeaderHeight,
        left: pageFixedSizes.siderWidht,
        right: 0,
        height: 'auto',
        zIndex: 1
      }}
    >
      <PageHeader {...props} />
    </div>
  );
}
