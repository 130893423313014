import * as React from 'react';
import clx from 'classnames';
import {
  CheckCircleFilled,
  Loading3QuartersOutlined,
  WarningFilled
} from '@ant-design/icons';
import './GeneratedIcon.scss';
import { QueueTaskState } from 'common/schema/queue-task/QueueTaskState';

export interface GeneratedIconProps {
  state?: QueueTaskState;
}

/**
 * Icona per mostrare il loading durante
 * la generazione del report dei conti economici
 */
export function GeneratedIcon(props: GeneratedIconProps) {
  return (
    <span
      className={clx('generated-icon', {
        success: props.state === QueueTaskState.Completed,
        error: props.state === QueueTaskState.Failed
      })}
    >
      {props.state === QueueTaskState.Completed ? (
        <CheckCircleFilled />
      ) : props.state === QueueTaskState.Failed ? (
        <WarningFilled />
      ) : (
        <Loading3QuartersOutlined spin />
      )}
    </span>
  );
}
