import { ColumnProps } from 'antd/lib/table';
import { resolveApiPath } from 'client/core/appConfig';
import { AnchorAPILink } from 'client/ui/link/AnchorAPILink';
import { ContractAttachmentDto } from 'common/dto/generated/ContractAttachmentDto';
import React from 'react';

export const ContractAttachmentsColumns: ColumnProps<ContractAttachmentDto>[] = [
  {
    title: 'File',
    dataIndex: 'filename',
    width: 120,
    render: (_, record) => (
      <AnchorAPILink
        href={resolveApiPath(
          `contracts/${record.contractId}/attachments/${record.id}/download`
        )}
        target="_blank"
      >
        {record.filename}
      </AnchorAPILink>
    )
  },
  {
    title: 'Descrizione',
    dataIndex: 'description'
  }
];
