import * as React from 'react';
import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { InvoiceSupplierDataDto } from 'common/dto/generated/InvoiceSupplierDataDto';

export interface ISupplierBatchViewProps {
  supplier: InvoiceSupplierDataDto;
}

export function SupplierBatchView(props: ISupplierBatchViewProps) {
  const { supplier } = props;

  if (!supplier) {
    return (
      <FormFieldsContainer title="Cedente/Prestatore"> </FormFieldsContainer>
    );
  }
  const province = supplier.hqProvince ? ' (' + supplier.hqProvince + ')' : '';

  return (
    <FormFieldsContainer title="Cedente/Prestatore">
      <DataItem label="Denominazione" value={supplier.name} strong />
      <DataItem label="Partita IVA" value={supplier.getFiscalId() ?? '--'} />
      <DataItem label="Codice Fiscale" value={supplier.fiscalNumber ?? '--'} />
      <DataItem label="Indirizzo" value={supplier.hqAddress} />
      <DataItem label="Comune" value={supplier.hqCity + province} />
      <DataItem label="CAP" value={supplier.hqZip} />
      <DataItem label="Nazione" value={supplier.hqCountry} />
    </FormFieldsContainer>
  );
}
