import { map, mapValues } from 'lodash';
import qs from 'qs';
import { RouteComponentProps } from 'react-router-dom';

export interface PaginationUrlOptions {
  init?: object | null;
}

const IS_NUMBER = /^\d+$/g;

/**
 * Dato che nei DTO alcuni valori sono strettamente _numerici_ ma nella
 * querystring vengono parsati sempre come stringhe, provvediamo almeno
 * alla conversione per i numeri interi.
 *
 * Questo risolve i problemi di paginazione di ant, che altrimenti nell'andare
 * alla pagina successiva _concatenava_ le stringhe (i.e. pag 2 -> +1 , pag. 21)
 */
function convertQueryString(query: any) {
  if (!query) return query;

  return mapValues(query, (value, key) =>
    typeof value === 'string' && value[0] !== '0' && IS_NUMBER.test(value)
      ? Number(value)
      : value
  );
}

/**
 * Fornisce l'oggetto query indicato nella querystring dell'URL.
 * Insieme a `setPaginationUrlQuery` permette la gestione delle query da URL
 * anziché come stato del componente, garantendo il mantenimento dei filtri
 * attraverso la navigazione della cronologia.
 * @param options opzioni aggiuntive (se presenti nella querystring, verranno sovrascritte)
 */
export function getPaginationUrlQuery(
  querystring: string,
  options?: any | null
) {
  return {
    ...(options ?? {}),
    ...convertQueryString(qs.parse(querystring, { ignoreQueryPrefix: true }))
  };
}

/**
 * Imposta la querystring dell'URL con le informazioni passate come query.
 * Insieme a `getPaginationUrlQuery` permette la gestione delle query da URL
 * anziché come stato del componente, garantendo il mantenimento dei filtri
 * attraverso la navigazione della cronologia.
 * @param input query che verrà inserita nell'URL
 */
export function setPaginationUrlQuery<T>(
  props: RouteComponentProps<any>,
  input: T
) {
  props.history.push(
    props.history.location.pathname +
      qs.stringify(input, {
        addQueryPrefix: true,
        skipNulls: true
      })
  );
}
