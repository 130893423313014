import { Tag, TagProps } from 'antd';
import { ContractState } from 'common/schema/contract/ContractTypes';
import React from 'react';

export interface IContractStateTagProps {
  state?: ContractState | null;
  style?: React.CSSProperties;
}

function stateToProps(state?: ContractState | null): TagProps {
  switch (state) {
    case ContractState.InProgress:
      return { color: 'processing', children: 'In Lavorazione' };
    case ContractState.Registered:
      return { color: 'cyan', children: 'Registrato' };
    case ContractState.Signing:
      return { color: 'green', children: 'Alla Firma' };
    case ContractState.Stipulated:
      return { color: 'geekblue', children: 'Stipulato' };
    case ContractState.Canceled:
      return { color: 'warning', children: 'Annullato' };
    default:
      return { children: 'Nessuno' };
  }
}

/**
 * Visualizzazione come Tag dello stato del Contratto
 */
export function ContractStateTag(props: IContractStateTagProps) {
  return <Tag {...stateToProps(props.state)} style={props.style} />;
}
