import * as React from 'react';
import clx from 'classnames';
import { NotificationDirection } from 'common/schema/notification/NotificationTypes';
import { ExportOutlined, ImportOutlined } from '@ant-design/icons';
import { NotificationDto } from 'common/dto/generated/NotificationDto';

export interface NotificationDirectionSymbolProps {
  direction: NotificationDto['direction'];
  size?: string;
}

/**
 * Icona stilizzata delle otifiche SDI
 * */
export function NotificationDirectionSymbol(
  props: NotificationDirectionSymbolProps
) {
  const size = props.size ?? '16px';
  if (props.direction === NotificationDirection.Outgoing)
    return <ExportOutlined style={{ fontSize: `${size}`, opacity: 0.5 }} />;

  return <ImportOutlined style={{ fontSize: `${size}` }} />;
}
