import { resolveApiPath } from 'client/core/appConfig';

/**
 * Ritorna l'URI dell'API per il download di un file csv da esportare
 * verso PCC
 * @param fileId
 * @returns
 */
export function getPccExportDownloadLink(fileId: number): string {
  return resolveApiPath(`pcc/${fileId}/download`);
}
