import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";


@GenerateDto()
class SearchDocumentalQuery {
    @SchemaLabel('Numero Documento')
    number!: number;
    @SchemaLabel('Anno Documento')
    year!: number;
    @SchemaLabel('Registro Documentale')
    register!: string;
}

/**
 * Rappresentazione DTO della classe SearchDocumentalQuery 
 * Hash: 0d39241a367d038741ebfa7d5ea03c78
 */
@ValidationSchema(() => SearchDocumentalQuerySchema)
export class SearchDocumentalQueryDto {
  @ApiProperty({ type: Number, description: 'Numero Documento' })
  number!: number;
  @ApiProperty({ type: Number, description: 'Anno Documento' })
  year!: number;
  @ApiProperty({ type: String, description: 'Registro Documentale' })
  register!: string;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<SearchDocumentalQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof SearchDocumentalQueryDto ? values : plainToClass(SearchDocumentalQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await SearchDocumentalQuerySchema.validate(classToPlain(this), options);
    return new SearchDocumentalQueryDto(validated);
  }
}

export const SearchDocumentalQuerySchema = yup
  .object({
    number: yup.number().required().label('Numero Documento'),
    year: yup.number().required().label('Anno Documento'),
    register: yup.string().required().label('Registro Documentale')
  })
  .noUnknown()
  .meta({ schemaName: "SearchDocumentalQuerySchema" })
  .required();
