import { Button, Card, Col, message, Row, Space } from 'antd';
import { RelationEditor } from 'client/components/form/relations/useRelationEditor';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { CurrencyInput } from 'client/ui/form/input/CurrencyInput';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { PercentageInput } from 'client/ui/form/input/PercentageInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import {
  InvoiceVATSummaryDto,
  InvoiceVATSummarySchema
} from 'common/dto/generated/InvoiceVATSummaryDto';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import { SDIChargeabilityOptions } from 'common/schema/invoice/VatSummaryLabels';
import { Formik } from 'formik';
import * as React from 'react';

export interface IInvoiceVatSummaryEditDrawerProps {
  invoice: InvoiceDto;
  editor: RelationEditor<InvoiceVATSummaryDto>;
  editable?: boolean;
}

export function InvoiceVatSummaryEditDrawer(
  props: IInvoiceVatSummaryEditDrawerProps
) {
  const { editor } = props;

  return (
    <>
      <FormikAugmented
        enableReinitialize
        initialValues={editor.handle.current ?? new InvoiceVATSummaryDto()}
        validationSchema={InvoiceVATSummarySchema}
        validationContext={{
          machineActions: InvoiceMachine.actionsFor(props.invoice)
        }}
        validateOnChange={false}
        onSubmit={async values => {
          if (!props.editable) return;
          try {
            const input = await values.validate();
            const type = editor.handle.apply(input);
            if (type === 'updated') {
              message.success('Riepilogo IVA modificato');
            } else {
              message.success('Riepilogo IVA aggiunto');
            }
          } catch (e) {
            message.error(
              'Si è verificato un errore nella modifica del Riepilogo IVA'
            );
            console.error(e);
          }
        }}
      >
        <FormikForm nested editable={props.editable} helpInTooltips>
          <ModalDialog
            title={
              props.editable && editor.handle.isNew
                ? 'Crea riepilogo IVA'
                : props.editable
                ? 'Modifica riepilogo IVA'
                : 'Dettaglio riepilogo IVA'
            }
            visible={editor.handle.isVisible}
            onCancel={editor.handle.cancel}
            footer={
              <Space>
                <Button onClick={editor.handle.cancel}>Annulla</Button>
                {props.editable && <FormikSendButton>Salva</FormikSendButton>}
              </Space>
            }
          >
            <FormErrorsNotification />

            <FormFieldsContainer columns={3}>
              <FormFieldItem
                component={PercentageInput}
                name="vatRate"
                label="Aliquota IVA"
                placeholder="Aliquota IVA"
              />
              <FormFieldItem
                component={CurrencyInput}
                name="vatTaxableAmount"
                label="Imponibile"
                placeholder="Importo Imponibile"
              />
              <FormFieldItem
                component={CurrencyInput}
                name="vatAmount"
                label="Imposta"
                placeholder="Imposta"
              />
              <FormFieldItem
                component={SelectInput}
                dropdownMatchSelectWidth={false}
                options={SDIChargeabilityOptions}
                name="chargeability"
                label="Esigibilità IVA"
                placeholder="Esigibilità IVA"
              />
            </FormFieldsContainer>
          </ModalDialog>
        </FormikForm>
      </FormikAugmented>
    </>
  );
}
