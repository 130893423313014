import React, { useState } from 'react';
import { Button, message } from 'antd';
import { Formik, FormikValues, useField } from 'formik';
import { LinkOutlined } from '@ant-design/icons';
import { FormFieldsContainer } from '../../field/row/FormFieldsContainer';
import { FormFieldItem } from '../../field/FormFieldItem';
import { NumberInput } from '../NumberInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { DocumentalApi } from 'client/components/schema/documental/DocumentalApi';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import {
  SearchDocumentalQueryDto,
  SearchDocumentalQuerySchema
} from 'common/dto/query/SearchDocumentalQueryDto';
import { DocumentalRegisterSelectInput } from 'client/components/schema/documental/DocumentalRegisterSelectInput';
import {
  DocumentalSwitchLink,
  ProtocolSwitchField
} from 'client/components/schema/documental/DocumentalSwitchLink';

export interface DocumentInputProps<T extends FormikValues> {
  name: keyof T & string;
  protocolData: ProtocolSwitchField;
}

/**
 * Campo per input dedicato per selezione documento di PiTre.
 */
export function DocumentInput<T extends FormikValues>(
  props: DocumentInputProps<T>
) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [idField, , idHelper] = useField('documentUid');
  const [numberField, , numberHelper] = useField('documentNumber');
  const [yearField, , yearHelper] = useField('documentYear');
  const [registerField, , registerHelper] = useField('documentRegister');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Formik
      initialValues={
        new SearchDocumentalQueryDto({
          number: numberField.value,
          year: yearField.value,
          register: registerField.value
          // TODO aggiungere Uid == id documento dul documentale
        })
      }
      onSubmit={async rawValues => {
        setLoading(true);
        const input = await rawValues.validate();
        try {
          const document = await DocumentalApi.find({ query: input });

          numberHelper.setValue(input.number);
          yearHelper.setValue(input.year);
          registerHelper.setValue(input.register);
          idHelper.setValue(document.data.id);
          message.success('Documento selezionato con successo.');
          setIsModalVisible(false);
        } catch (e) {
          console.log(e);
        } finally {
          setLoading(false);
        }
      }}
      validate={schemaToValidator(SearchDocumentalQuerySchema)}
    >
      {formik => (
        <>
          <Button
            type="link"
            icon={<LinkOutlined />}
            size="small"
            onClick={showModal}
          >
            Seleziona
          </Button>
          <ModalDialog
            size="small"
            title="Seleziona Documento"
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Annulla
              </Button>,
              <Button
                key="submit"
                icon={<LinkOutlined />}
                type="primary"
                onClick={() => formik.submitForm()}
                loading={loading}
              >
                Seleziona Documento
              </Button>
            ]}
          >
            <FormFieldsContainer>
              <DocumentalRegisterSelectInput
                name="register"
                label="Registro"
                placeholder="Registro Documentale"
              />
              <FormFieldItem
                component={NumberInput}
                label="Numero"
                name="number"
                placeholder="Numero"
              />
              <FormFieldItem
                component={NumberInput}
                label="Anno"
                name="year"
                placeholder="Anno"
              />
            </FormFieldsContainer>
          </ModalDialog>
        </>
      )}
    </Formik>
  );
}

/**
 * Gestisce il rendering per i `FormField` in modalità non di modifica
 */
DocumentInput.renderFieldValue = (value: string, props: any) => {
  return <DocumentalSwitchLink protocolData={props.protocolData} />;
};
