import React, { useState } from 'react';
import { Modal, Button, message, Space } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { FormikForm } from 'client/ui/form/FormikForm';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { EconomicStatementApi } from 'client/components/schema/economy/statement/EconomicStatementApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import {
  EconomicStatementInputDto,
  EconomicStatementInputSchema
} from 'common/dto/query/EconomicStatementInputDto';
import { logger } from 'client/core/logger/logger';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { EconomicSemesterOptions } from 'common/schema/economic-statement/EconomicSemester';
import { EconomicReportKindOptions } from 'common/schema/economic-statement/EconomicReportKind';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';

export interface EconomicStatementModalProps
  extends Omit<ButtonProps, 'onClick'> {}

/**
 * Modal per l'avvio della generazione del Report di conto economico.
 */
export function EconomicStatementModal(props: EconomicStatementModalProps) {
  const { ...buttonProps } = props;

  const [visible, setVisible] = useState(false);

  const [create, createState] = useApiMutation(EconomicStatementApi.create, {
    data: {},
    invalidates: [EconomicStatementApi.list]
  });

  return (
    <>
      <Button {...buttonProps} onClick={() => setVisible(true)} />
      <FormikAugmented<EconomicStatementInputDto>
        initialValues={new EconomicStatementInputDto({ onlyPaid: true })}
        validationSchema={EconomicStatementInputSchema}
        validationContext={{}}
        onSubmit={async values => {
          try {
            const input = await values.validate();
            const result = await create({ data: { input } });
            message.success(`Conto economico generato con successo`);
            setVisible(false);
          } catch (e) {
            logger.error(e);
            message.error(
              `Si è verificato un errore durante la generazione della conto economico`
            );
          }
        }}
      >
        <ModalDialog
          size="small"
          title="Nuovo Report di Conto Economico"
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={
            <Space>
              <Button onClick={() => setVisible(false)}>Chiudi</Button>
              <FormikSendButton>Avvia</FormikSendButton>
            </Space>
          }
        >
          <FormikForm editable helpInTooltips>
            <FormFieldsContainer
              title="Selezione dell'anno di competenza del Report"
              removeTopTitleSpacing
            >
              <FormFieldItem
                labelWidth="width-140"
                component={NumberInput}
                label="Anno di Competenza"
                name="year"
                placeholder="Anno (YYYY)"
              />
              <FormFieldItem
                labelWidth="width-140"
                component={SelectInput}
                options={EconomicSemesterOptions}
                label="Semestre"
                name="semester"
                placeholder="Semestre"
              />
              <FormFieldItem
                labelWidth="width-140"
                component={SelectInput}
                options={EconomicReportKindOptions}
                label="Tipo Report"
                name="reportKind"
              />
              <FormFieldItem
                labelWidth="width-140"
                component={CheckboxInput}
                label="Solo fatture pagate"
                name="onlyPaid"
              />
            </FormFieldsContainer>
          </FormikForm>
        </ModalDialog>
      </FormikAugmented>
    </>
  );
}
