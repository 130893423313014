import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { AcceptanceState, ForceExportRule,  InvoiceState } from 'common/schema/invoice/InvoiceTypes';
import moment from 'moment';
import { IInvoiceLineType, InvoiceLineDto, InvoiceLineSchema } from './InvoiceLineDto';
import { InvoiceVATSummaryDto, InvoiceVATSummarySchema } from './InvoiceVATSummaryDto';
import { SupplierDto } from './SupplierDto';
import { InvoiceAttachmentDto, InvoiceAttachmentSchema } from './InvoiceAttachmentDto';
import { AccountingLineDto, AccountingLineSchema, IAccountingLineType } from './AccountingLineDto';
import { InvoiceReferenceDto, InvoiceReferenceSchema } from './InvoiceReferenceDto';
import { ContractDto, ContractSchema } from './ContractDto';
import { SDIChargeabilityType } from 'common/schema/invoice/VATSummaryTypes';
import { AccountingLineState } from 'common/schema/accounting-line/AccountingLineTypes';
import { AccountingCongruenceType } from 'common/schema/invoice/AccountingCongruenceType';
import { EconomicEntryDefaultCode } from 'common/schema/economic-entry/EconomicEntryDefaultCode';
import { UserDto } from './UserDto';
import { ReferentType } from 'common/schema/contract/ContractTypes';

/**
 * Rappresentazione DTO della classe Invoice definita in: src/server/schema/invoice/invoice/Invoice.entity.ts
 * Hash: 6de9b795a4859a8d7c9c9afb8323d411
 */
@ValidationSchema(() => InvoiceSchema)
export class InvoiceDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: String })
  migrationId?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Valuta' })
  currency!: string;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$' })
  @TypeTransformDecimal()
  totalAmount?: Decimal | null | undefined;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$' })
  @TypeTransformDecimal()
  totalVatAmount?: Decimal | null | undefined;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$' })
  @TypeTransformDecimal()
  totalVatTaxableAmount?: Decimal | null | undefined;
  @ApiProperty({ type: String, description: 'Tipo Documento' })
  invoiceTypeCode!: string;
  @ApiProperty({ required: false, type: Number, description: 'Fornitore' })
  supplierId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => SupplierDto })
  @Type(() => SupplierDto)
  supplier?: SupplierDto | null;
  @ApiProperty({ required: false, type: Number, description: 'Contratto' })
  contractId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => ContractDto })
  @Type(() => ContractDto)
  contract?: ContractDto | null;
  @ApiProperty({ required: false, type: Number })
  batchId?: number | null | undefined;
  @ApiProperty({ required: false, type: Number })
  batchPosition?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Partita IVA' })
  vatNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Fiscale' })
  fiscalNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Oggetto' })
  subject?: string | null | undefined;
  @ApiProperty({ type: String, description: 'Numero fattura' })
  issuedNumber!: string;
  @ApiProperty({ type: Number, description: 'Anno di emissione' })
  issuedYear!: number;
  @ApiProperty({ type: String, format: 'date', description: 'Data di emissione' })
  issuedDate!: Date;
  @ApiProperty({ required: false, type: Boolean, description: 'Articolo 73' })
  isArt73?: boolean | null | undefined;
  @ApiProperty({ required: false, enum: ['ToValidate', 'Validated', 'ToAccount', 'Rejected', 'Accounted', 'PaidPartial', 'Paid'], description: 'Stato' })
  state!: InvoiceState;
  @ApiProperty({ required: false, enum: ['Accepted', 'Rejected', 'Indeterminate'], description: 'Accettazione' })
  acceptance!: AcceptanceState;
  @ApiProperty({ required: false, type: Boolean })
  isPaper!: boolean;
  @ApiProperty({ required: false, type: String, format: 'date' })
  expiresAt?: Date | null;
  @ApiProperty({ required: false, type: Number })
  protocolYear?: number | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Numero protocollo documentale' })
  protocolNumber?: number | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date', description: 'Data protocollo documentale' })
  protocolDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Id protocollo documentale' })
  protocolUid?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Oggetto protocollo documentale' })
  protocolSubject?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nodo Classificazione' })
  protocolClassification?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Flag Spesa rilevante ai fini IVA' })
  protocolVatRelevant?: boolean | null | undefined;
  @ApiProperty({ type: Boolean, description: 'Documento classificato' })
  isClassified!: boolean;
  @ApiProperty({ type: Boolean, description: 'Documento principale caricato' })
  isMainFileUploaded!: boolean;
  @ApiProperty({ required: false, type: Boolean, description: 'Lotto XML caricato' })
  isBatchUploaded?: boolean | null;
  @ApiProperty({ required: false, enum: ['DEC', 'RUP', 'Amministrativo'], description: 'Trasmissione avvenuta' })
  isTransmitted?: ReferentType[] | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Protocollazione completata' })
  isFullyProtocolled?: boolean | undefined;
  @ApiProperty({ required: false, type: String, format: 'date' })
  receivedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date', description: 'Data Regolare Esecuzione/Collaudo' })
  testingDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date', description: 'Data esecuzione Prestazione' })
  executionDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date', description: 'Data di Inizio Sospensione' })
  suspendedFromDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date', description: 'Data di Fine Sospensione' })
  suspendedToDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Motivazione' })
  suspendedReason?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  paymentTerms?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  paymentMethod?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date' })
  paymentExpiresAt?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$' })
  @TypeTransformDecimal()
  paymentAmount?: Decimal | null | undefined;
  @ApiProperty({ required: false, type: String })
  paymentIban?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  paymentDelayReason?: string | null | undefined;
  @ApiProperty({ required: false, type: Number })
  rufNumber?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Note' })
  notes?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Inviata a PCC per ricezione' })
  pccExported?: boolean | null | undefined;
  @ApiProperty({ required: false, enum: ['AG', 'SO'], description: 'Forza immissione' })
  forceExportRule?: ForceExportRule | null | undefined;
  @ApiProperty({ required: false, type: () => [InvoiceLineDto] })
  @Type(() => InvoiceLineDto)
  lines!: InvoiceLineDto[];
  @ApiProperty({ required: false, type: () => [InvoiceReferenceDto] })
  @Type(() => InvoiceReferenceDto)
  references!: InvoiceReferenceDto[];
  @ApiProperty({ required: false, type: () => [InvoiceVATSummaryDto] })
  @Type(() => InvoiceVATSummaryDto)
  vatSummaries!: InvoiceVATSummaryDto[];
  @ApiProperty({ required: false, type: () => [InvoiceAttachmentDto] })
  @Type(() => InvoiceAttachmentDto)
  attachments!: InvoiceAttachmentDto[];
  @ApiProperty({ required: false, type: () => [AccountingLineDto] })
  @Type(() => AccountingLineDto)
  accountingLines!: AccountingLineDto[];
  @ApiProperty({ required: false, enum: ['ToAccount', 'CongruentAmounts', 'IncongruentAmounts'] })
  accountingCongruence!: AccountingCongruenceType;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  revisionDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: Number })
  revisionAuthorId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  revisionAuthor?: UserDto | null;
  @ApiProperty({ required: false, type: String })
  generatedFilePath?: string | null | undefined;
  @ApiProperty({ enum: ['I', 'D', 'S'], description: 'Esigibilità IVA' })
  chargeability!: SDIChargeabilityType;
  @ApiProperty({ type: Boolean })
  isProcessed!: boolean;
  @ApiProperty({ required: false, type: Number, description: 'Tentativi di reinvio' })
  resendAttempts?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Motivo Rifiuto' })
  rejectMotivation?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceDto ? values : plainToClass(InvoiceDto, values));
    }
  }

  /**
   * Ritorna il totale della fattura dai riepiloghi IVA
   * come somma di imponibile e imposta
   */
  getTotalAmount(): Decimal {
    return this.getTotalVatTaxableAmount().plus(this.getTotalVatAmount());
  }

  /**
   * Ritorna il totale dell'Imposta
   */
  getTotalVatAmount(): Decimal {
    if (!this.vatSummaries) {
      throw new Error("Impossibile calcolare l'imponibile");
    }

    return this.vatSummaries
    .map(summary => summary.vatAmount)
    .reduce((total, amount) => total.plus(amount), new Decimal(0));
  }

  /**
   * Ritorna il totale imponibile IVA calcolato sui riepiloghi IVA della
   * Fattura. Se la relazione non c'è, lancia un'eccezione.
   */
  getTotalVatTaxableAmount(): Decimal {
    if (!this.vatSummaries) {
      throw new Error(
        "Impossibile calcolare l'imponibile fattura. Riepiloghi IVA non presenti."
      );
    }

    return this.vatSummaries
    .map(summary => summary.vatTaxableAmount)
    .reduce((total, amount) => total.plus(amount), new Decimal(0));
  }

  /**
   * Controlla se è collegata a SDI
   */
  isSDI() {
    return !this.isPaper && this.batchId != null;
  }

  /**
   * Ottiene il totale delle linee di contabilizzazione
   */
  getAccountingLinesTotal() {
    if (!this.accountingLines) {
      return new Decimal(0);
    }

    return this.accountingLines
    .map(line => line.amount)
    .reduce((total, amount) => total.plus(amount), new Decimal(0));
  }

  /**
   * Ottiene l'esigibilità iva della fattura
   */
  getChargeability() {
    if (!this.vatSummaries || this.vatSummaries.length === 0) {
      return SDIChargeabilityType.Split;
    }

    const vatSummary = this.vatSummaries[0];
    return !vatSummary.chargeability
    ? SDIChargeabilityType.Split
    : vatSummary.chargeability;
  }

  /**
   * Verifica che l'importo delle linee di contabilizzazione sia congruente
   * con l'importo della fattura con o senza IVA a seconda del tipo di esigibilità
   */
  getAccountingCongruence() {
    if (!this.accountingLines || this.accountingLines.length < 1) {
      return AccountingCongruenceType.ToAccount;
    }

    const linesTotal = this.getAccountingLinesTotal();
    const chargeability = this.getChargeability();
    const isTotalCongruent = chargeability === SDIChargeabilityType.Split
              ? linesTotal.equals(this.getTotalVatTaxableAmount())
              : linesTotal.equals(this.getTotalAmount());
    return isTotalCongruent
    ? AccountingCongruenceType.CongruentAmounts
    : AccountingCongruenceType.IncongruentAmounts;
  }

  /**
   * Ritorna vero se è avvenuta la trasmissione a tutti i referenti.
   */
  isFullTransmitted() {
    return Object.values(ReferentType).every(type =>
      this.isTransmitted?.includes(type)
    );
  }

  /**
   * Ritorna vero se è avvenuto l'upload di tutti gli allegati
   */
  isFullAttachmentsUploaded() {
    return this.attachments?.every(attachment => attachment.isUploaded);
  }

  /**
   * Ritorna una lista di stringhe che descrivono i checkpoint non superati.
   */
  getPartialCheckpoints() {
    const result = [];
    if (!this.isClassified) {
      result.push('Non è stato possibile classificare il documento');
    }

    if (!this.isMainFileUploaded) {
      result.push('Non è stato possibile caricare il file principale');
    }

    if (!this.isBatchUploaded) {
      result.push('Non è stato possibile caricare il file del lotto XML');
    }

    if (!this.isFullTransmitted()) {
      result.push('Alcune trasmissioni non sono state effettuate');
    }

    if (!this.isFullAttachmentsUploaded()) {
      result.push('Alcuni allegati non sono stati caricati');
    }

    return result;
  }

  async validate(options?: any) {
    const validated = await InvoiceSchema.validate(classToPlain(this), options);
    return new InvoiceDto(validated);
  }
}

/** Interfaccia simmetrica al DTO InvoiceDto */
export type IInvoiceType = IDto<InvoiceDto>;

/**
 * DTO Paginato della classe Invoice
 */
export class PaginatedInvoiceDto {
  @ApiProperty({ type: [InvoiceDto] })
  @Type(() => InvoiceDto)
  items!: InvoiceDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const InvoiceSchema = yup
  .object({
    id: yup.number(),
    currency: yup.string().default('EUR').label('Valuta'),
    invoiceTypeCode: yup.string().required().needsAction('edit.general').label('Tipo Documento'),
    supplierId: yup.number().nullable().required().needsAction('edit.general').label('Fornitore'),
    contractId: yup.number().nullable().needsAction('edit.contract').label('Contratto'),
    batchId: yup.number().nullable(),
    batchPosition: yup.number().nullable(),
    vatNumber: yup.string().nullable().label('Partita IVA'),
    fiscalNumber: yup.string().nullable().label('Codice Fiscale'),
    subject: yup.string().nullable().needsAction('edit.general').label('Oggetto'),
    issuedNumber: yup.string().required().needsAction('edit.general').label('Numero fattura'),
    issuedDate: yup.date().dateOnlyFormat().required().needsAction('edit.general').label('Data di emissione'),
    isArt73: yup.boolean().nullable().needsAction('edit.general').label('Articolo 73'),
    state: yup.string().oneOfEnum(InvoiceState).default(InvoiceState.ToValidate).label('Stato'),
    expiresAt: yup.date().dateOnlyFormat().nullable().needsAction('edit.expiresAt'),
    testingDate: yup.date().dateOnlyFormat().nullable().needsAction('edit.execution').label('Data Regolare Esecuzione/Collaudo'),
    executionDate: yup.date().dateOnlyFormat().nullable().checkPeriod('testingDate', {
          optional: true,
          endDateLabel: 'Data Collauto',
          startDateLabel: 'Data esecuzione Prestazione',
          errorMessage:
            'La Data esecuzione Prestazione deve precedere quella di Collaudo'
        }).needsAction('edit.execution').label('Data esecuzione Prestazione'),
    suspendedFromDate: yup.date().dateOnlyFormat().nullable().checkPeriod('suspendedToDate', {
          endDateLabel: 'Data di Fine Sospensione',
          startDateLabel: 'Data di Inizio Sospensione',
          errorMessage:
            'La Data di Inizio Sospensione deve precedere quella di Fine'
        }).needsAction('edit.execution').label('Data di Inizio Sospensione'),
    suspendedToDate: yup.date().dateOnlyFormat().nullable().needsAction('edit.execution').label('Data di Fine Sospensione'),
    suspendedReason: yup.string().nullable().when(['suspendedFromDate', 'suspendedToDate'], {
          is: (
            suspendedFromDate: Date | null | undefined,
            suspendedToDate: Date | null | undefined
          ) => suspendedFromDate != null || suspendedToDate != null,
          then: schema => schema.required()
        }).needsAction('edit.execution').label('Motivazione'),
    paymentTerms: yup.string().nullable(),
    paymentMethod: yup.string().nullable().needsAction('edit.general'),
    paymentExpiresAt: yup.date().dateOnlyFormat().nullable().needsAction('edit.general'),
    paymentAmount: yup.mixed().nullable().needsAction('edit.general'),
    paymentIban: yup.string().nullable().needsAction('edit.general'),
    paymentDelayReason: yup.string().nullable(),
    notes: yup.string().nullable().label('Note'),
    forceExportRule: yup.string().oneOfEnum(ForceExportRule).nullable().when('isPaper', (isPaper: boolean, schema: any) =>
          isPaper ? schema : schema.trim()
        ).label('Forza immissione'),
    lines: yup.array(InvoiceLineSchema).when(['state', 'accountingLines', '$isClient'], {
          is: (
            state: InvoiceState,
            accountingLines: IAccountingLineType[],
            isClient?: boolean
          ) =>
            isClient &&
            state === InvoiceState.ToAccount &&
            accountingLines &&
            accountingLines.length >= 1,
          then: schema =>
            schema.test({
              message:
                'Il periodo di competenza delle linee di fatturazione deve essere valorizzato',
              name: 'hasPeriodDates',
              exclusive: true,
              test(value, context: any) {
                const originalValue: IInvoiceLineType[] = context.originalValue;

                if (!originalValue) return true;

                return originalValue.every(
                  v =>
                    v.economicEntry?.code ===
                      EconomicEntryDefaultCode.NoCompetence ||
                    (v.periodStartDate && v.periodEndDate)
                );
              }
            })
        }),
    references: yup.array(InvoiceReferenceSchema),
    vatSummaries: yup.array(InvoiceVATSummarySchema),
    attachments: yup.array(InvoiceAttachmentSchema).needsAction('edit.general'),
    accountingLines: yup.array(AccountingLineSchema).needsAction('edit.accounting'),
    generatedFilePath: yup.string().nullable(),
    resendAttempts: yup.number().nullable().label('Tentativi di reinvio'),
    rejectMotivation: yup.string().nullable().label('Motivo Rifiuto')
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceSchema" })
  .required();
