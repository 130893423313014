import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { InvoiceApi } from 'client/components/schema/invoice/InvoiceApi';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { AnchorAPILink } from 'client/ui/link/AnchorAPILink';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { Section } from 'client/ui/section/Section';
import { InvoiceExportDto } from 'common/dto/generated/InvoiceExportDto';
import { Formik } from 'formik';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { getInvoiceExportDownloadLink } from './getInvoiceExportDownloadLink';

interface Params {
  id: string;
  filter?: string;
}

export interface IInvoiceExportPageProps extends RouteComponentProps<Params> {}

export function InvoiceExportPage(props: IInvoiceExportPageProps) {
  const id = props.match.params.id ? parseInt(props.match.params.id, 10) : null;

  const { response, error, loading } = useApiQuery(InvoiceApi.export.find, {
    skip: id == null,
    data: { id: id! }
  });
  const invoiceExport = response?.data;

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/invoicing/invoices/all', title: `Fatture` });
  useBreadcrumbItem({
    priority: 40,
    path: '/invoicing/exports',
    title: 'Esportazioni'
  });
  useBreadcrumbItem({
    priority: 50,
    path: props.location.pathname,
    title: 'File #' + id,
    menuKey: 'invoicing',
    sideMenuKey: 'export-list'
  });

  if (error || !id) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere al file delle fatture"
        error={error}
      />
    );
  }

  if (!invoiceExport) return <LoadingPage />;

  return (
    <CorePageLayout>
      <Formik<InvoiceExportDto>
        initialValues={invoiceExport}
        enableReinitialize
        onSubmit={() => {}}
      >
        <FormikForm editable={false} helpInTooltips>
          <PageHeading
            title={
              <PageHeadingTitle>
                Dettaglio Esportazione Fatture
              </PageHeadingTitle>
            }
            breadcrumbRender={() => <BreadcrumbsContainer />}
            extra={
              <AnchorAPILink
                showAsButton
                href={getInvoiceExportDownloadLink(id)}
                icon={<DownloadOutlined />}
                type="primary"
              >
                Download Esportazione Fatture
              </AnchorAPILink>
            }
          />
          <PageContent fixedWidht>
            <Section
              icon={<InboxOutlined />}
              title="Dati Esportazione"
              hasPadding
            >
              <FormFieldsContainer columns={2}>
                <FormFieldItem
                  component={TextInput}
                  name="author.name"
                  label="Autore"
                  readOnly
                />
                <FormFieldItem
                  component={DatePickerInput}
                  name="createdAt"
                  label="Creata il"
                  type="date-only"
                  readOnly
                />
              </FormFieldsContainer>
              <FormFieldsContainer columns={2} title="Fatture Selezionate">
                <FormFieldItem
                  component={DatePickerInput}
                  name="fromDate"
                  label="Data inizio"
                  type="from"
                  readOnly
                />
                <FormFieldItem
                  component={DatePickerInput}
                  name="toDate"
                  label="Data fine"
                  type="to"
                  readOnly
                />
              </FormFieldsContainer>
            </Section>
          </PageContent>
        </FormikForm>
      </Formik>
    </CorePageLayout>
  );
}
