export enum DebtState {
  /** Liquidato */
  LIQ = 'LIQ',
  /** Sospeso */
  SOSP = 'SOSP',
  /** Non Liquidabile */
  NOLIQ = 'NOLIQ',
  /** da sospeso a liquidato */
  LIQdaSOSP = 'LIQdaSOSP',
  /** a non liquidabile a liquidato */
  LIQdaNL = 'LIQdaNL',
  /** da liquidato a sospeso */
  SOSPdaLIQ = 'SOSPdaLIQ',
  /** da non liquidabile a sospeso */
  SOSPdaNL = 'SOSPdaNL',
  /** da liquidato a non liquidabile */
  NLdaLIQ = 'NLdaLIQ',
  /** da sospeso a non liquidabile */
  NLdaSOSP = 'NLdaSOSP',
  /** variazioni della Natura di spesa all’interno dello stato liquidato */
  ADEGLIQ = 'ADEGLIQ'
}

/**
 * Stati del debito che permettono l'associazione a un mandato, ovvero pagabili
 */
export const PayableDebtStates = [
  DebtState.LIQ,
  DebtState.LIQdaNL,
  DebtState.LIQdaSOSP,
  DebtState.ADEGLIQ
];

export enum DebtReason {
  /** Attesa di Liquidazione */
  ATTLIQ = 'ATTLIQ',
  /** Contenzioso */
  CONT = 'CONT',
  /** Attesa Nota di Credito */
  ATTNC = 'ATTNC',
  /** Nota di Credito attesa */
  NCRED = 'NCRED',
  /** Pagamento da Terzi */
  PAGTERZI = 'PAGTERZI',
  /** IVA Reverse Charge */
  IVARC = 'IVARC',
  /** Debiti non Commerciali */
  DNC = 'DNC',
  /** Attesa di Regolare Esecuzione/Collaudo */
  ATTESECOLL = 'ATTESECOLL'
}

export enum ExpenseNature {
  SpesaCorrente = 'CO',
  ContoCapitale = 'CA',
  NonApplicabile = 'NA'
}

/** Stato linea di contabilizzazione */
export enum AccountingLineState {
  /** Non Pagabile */
  NotPayable = 'NotPayable',
  /** Da Spedire */
  ToShip = 'ToShip',
  /** Spedite (ex. Da Pagare) */
  ToPay = 'ToPay',
  /** Pagata */
  Paid = 'Paid'
}
