import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaAction, SchemaLabel, SchemaValidate } from "cli/generate/decorators/SchemaDecorators";
import { InvoiceMachine } from "common/schema/invoice/InvoiceMachine";
import { DateSchema } from "yup";


@GenerateDto()
class InvoiceLineMassiveEdit {
    @SchemaLabel('ID Codice di conto economico')
    @SchemaAction(InvoiceMachine, 'edit.lines.accounting')
    economicEntryId?: number | null;

    @SchemaLabel('Data inizio periodo')
    @SchemaAction(InvoiceMachine, 'edit.lines.accounting')
    @SchemaValidate((schema: DateSchema) => schema.checkPeriod('periodEndDate'))
    periodStartDate?: Date | null;

    @SchemaLabel('Data fine periodo')
    @SchemaAction(InvoiceMachine, 'edit.lines.accounting')
    periodEndDate?: Date | null;
}

/**
 * Rappresentazione DTO della classe InvoiceLineMassiveEdit 
 * Hash: 4d5229865f26469ba840bd2fd3a8ed26
 */
@ValidationSchema(() => InvoiceLineMassiveEditSchema)
export class InvoiceLineMassiveEditDto {
  @ApiProperty({ required: false, type: Number, description: 'ID Codice di conto economico' })
  economicEntryId?: number | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data inizio periodo' })
  periodStartDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data fine periodo' })
  periodEndDate?: Date | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceLineMassiveEditDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceLineMassiveEditDto ? values : plainToClass(InvoiceLineMassiveEditDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await InvoiceLineMassiveEditSchema.validate(classToPlain(this), options);
    return new InvoiceLineMassiveEditDto(validated);
  }
}

export const InvoiceLineMassiveEditSchema = yup
  .object({
    economicEntryId: yup.number().nullable().needsAction('edit.lines.accounting').label('ID Codice di conto economico'),
    periodStartDate: yup.date().nullable().checkPeriod('periodEndDate').needsAction('edit.lines.accounting').label('Data inizio periodo'),
    periodEndDate: yup.date().nullable().needsAction('edit.lines.accounting').label('Data fine periodo')
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceLineMassiveEditSchema" })
  .required();
