import { PlusOutlined } from '@ant-design/icons';
import { Input, Button } from 'antd';
import clx from 'classnames';

import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DataItem } from 'client/ui/form/field/DataItem';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { useFormikFieldValidation } from 'client/ui/form/hooks/validation/useFormikFieldValidation';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { SupplierDto } from 'common/dto/generated/SupplierDto';
import { SupplierQueryDto } from 'common/dto/query/SupplierQueryDto';
import { Formik, useField } from 'formik';
import * as React from 'react';
import { useRef } from 'react';
import {
  SupplierEditHandle,
  SupplierEditModal
} from '../edit/SupplierEditModal';
import { SupplierApi } from '../SupplierApi';

export interface ISupplierInputProps {}

export function SupplierInput(props: ISupplierInputProps) {
  const [field, _, helpers] = useField<number | undefined>('supplierId');
  const validation = useFormikFieldValidation('supplierId');

  const { response } = useApiQuery(SupplierApi.find, {
    skip: field.value == null,
    data: { id: field.value! }
  });
  const supplier = response?.data;

  const editModalRef = useRef<SupplierEditHandle>(null);

  return (
    <>
      <DataItem
        className={clx({ editable: validation.editable })}
        ghost
        label="Denominazione"
        itemProps={{ required: validation.editable && validation.required }}
        value={
          validation.editable ? (
            <Input.Group compact style={{ display: 'flex' }}>
              <AsyncSelectInput
                style={{ flex: '1 1 329px', maxWidth: '329px' }}
                name="supplierId"
                placeholder="Seleziona Anagrafica"
                size="small"
                query={{
                  apiFn: SupplierApi.list,
                  options: (formik, search) => ({
                    data: {
                      query: new SupplierQueryDto({
                        pageNumber: 1,
                        pageSize: 20,
                        fullname: search,
                        includeAlso:
                          !search && formik.values['supplierId']
                            ? [formik.values['supplierId']]
                            : null
                      })
                    }
                  })
                }}
                dropdownMatchSelectWidth={false}
                showSearch
                refreshOnSearch
                responseTransform={data => data.items}
                responseMeta={data => ({
                  shown: data.items.length,
                  total: data.meta.total
                })}
                optionTransform={(supplier: SupplierDto) => ({
                  label: supplier.getName(),
                  value: supplier.id
                })}
              />
              <Button
                key="1"
                icon={<PlusOutlined />}
                // type="primary"
                style={{ flex: '0 1 90px' }}
                size="small"
                onClick={() => editModalRef.current?.edit(new SupplierDto())}
              >
                Nuova
              </Button>
            </Input.Group>
          ) : (
            supplier?.getName() ?? '-'
          )
        }
      />
      <DataItem label="Partita IVA" value={supplier?.getFiscalNumber()} />
      <DataItem
        label="Indirizzo"
        value={supplier?.hqAddress ? supplier.getFullAddress() : ''}
      />
      <SupplierEditModal
        editable={validation.editable}
        ref={editModalRef}
        onSelect={selected => helpers.setValue(selected.id)}
      />
    </>
  );
}
