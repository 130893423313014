import React from 'react';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { DocumentalUsersQueryDto } from 'common/dto/query/DocumentalUsersQueryDto';
import { DocumentalApi } from './DocumentalApi';
import { DocumentalCorrespondentDto } from 'common/dto/documental/DocumentalCorrespondentDto';
import { UserDto } from 'common/dto/generated/UserDto';
import { SelectProps } from 'antd';
import { DocumentalUserTypeFilter } from 'common/schema/documental/DocumentalTypes';

interface Props extends SelectProps<any> {
  name: string;
  shownUsername?: UserDto | null;
  label?: React.ReactNode;
  placeholder?: string;
}

// TODO Gestire errori
/**
 * Permette la selezione di un utente del documentale
 * @param name nome componente
 * @param shownUsername il nome mostrato in modalità non-edit. Se non fornito, verrà mostrato il codice utente del documentale
 * @param label
 * @param placeholder
 */
export function DocumentalUserSelectInput(props: Props) {
  const { name, label, placeholder, shownUsername, ...otherProps } = props;

  const baseQuery = new DocumentalUsersQueryDto({
    pageNumber: 1,
    pageSize: 20,
    isUser: true,
    correspondentType: DocumentalUserTypeFilter.Internal
  });

  return (
    <AsyncSelectInput
      {...otherProps}
      name={name}
      placeholder={placeholder}
      query={{
        apiFn: DocumentalApi.correspondent.list,
        options: (_, search) => ({
          data: {
            query: new DocumentalUsersQueryDto({
              ...baseQuery,
              code: search
            })
          }
        })
      }}
      optionTransform={(correspondent: DocumentalCorrespondentDto) => ({
        label:
          correspondent.code +
          ' | ' +
          correspondent.name +
          ' ' +
          correspondent.surname,
        value: correspondent.code
      })}
      responseTransform={data => data.items}
      responseMeta={data => ({
        shown: data.items.length,
        total: data.meta.total
      })}
      dropdownMatchSelectWidth={false}
      showSearch
      refreshOnSearch
      allowClear
      loadInitialValue={{
        apiFn: DocumentalApi.correspondent.find,
        options: (formik, search) => ({
          skip: !formik.values[name] || formik.values[name] == '',
          data: {
            query: new DocumentalUsersQueryDto(baseQuery),
            code: formik.values[name]
          }
        })
      }}
    />
  );
}

DocumentalUserSelectInput.renderFieldValue = (
  value?: string | null,
  props?: Props
) => {
  if (!props?.shownUsername?.name) return value ?? '-';
  return props?.shownUsername?.name;
};
