import * as React from 'react';
import { Tooltip } from 'antd';
import { FileFilled, FileOutlined } from '@ant-design/icons';

export interface PaperInvoiceIconProps {}

export function PaperInvoiceIcon(props: PaperInvoiceIconProps) {
  return (
    <Tooltip title="Fattura Cartacea">
      <FileFilled style={{ color: '#1890ff', marginLeft: '.3em' }} />
    </Tooltip>
  );
}
