import { ISODate } from 'client/ui/display/date/ISODate';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import * as React from 'react';
import { daysFromNow } from './daysFromNow';

export interface IInvoiceReceivedDaysProps {
  invoice: InvoiceDto;
}

/**
 * Visualizzazione Data ricezione e Griorni trascorsi
 */
export function InvoiceReceivedDays(props: IInvoiceReceivedDaysProps) {
  return (
    <span>
      <b
        style={{
          minWidth: '22px',
          display: 'inline-block',
          textAlign: 'right',
          marginRight: '10px'
        }}
      >
        {daysFromNow(props.invoice.receivedAt)}
      </b>
      <ISODate date={props.invoice.receivedAt} />
    </span>
  );
}
