import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { EconomicReportKind } from 'common/schema/economic-statement/EconomicReportKind';
import { UserDto } from './UserDto';
import { EconomicSemester } from 'common/schema/economic-statement/EconomicSemester';
import { QueueTaskState } from 'common/schema/queue-task/QueueTaskState';

/**
 * Rappresentazione DTO della classe EconomicStatement definita in: src/server/schema/economy/economic-statement/EconomicStatement.entity.ts
 * Hash: 05fd6688b708dd5a96970dfb477270ae
 */
@ValidationSchema(() => EconomicStatementSchema)
export class EconomicStatementDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ type: String })
  title!: string;
  @ApiProperty({ required: false, type: String })
  filePath?: string | null | undefined;
  @ApiProperty({ type: Number })
  year!: number;
  @ApiProperty({ enum: ['1', '2', 'Both'] })
  semester!: EconomicSemester;
  @ApiProperty({ required: false, type: Boolean })
  onlyPaid!: boolean;
  @ApiProperty({ required: false, enum: ['Running', 'Completed', 'Failed'] })
  state!: QueueTaskState;
  @ApiProperty({ required: false, type: Number })
  authorId!: number;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  author!: UserDto;
  @ApiProperty({ enum: ['Standard', 'Detail'] })
  reportKind!: EconomicReportKind;
  @ApiProperty({ required: false, type: Number })
  sourceVersionId?: number | null | undefined;
  @ApiProperty({ type: Boolean })
  isGenerated!: boolean;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<EconomicStatementDto>) {
    if (values != null) {
      Object.assign(this, values instanceof EconomicStatementDto ? values : plainToClass(EconomicStatementDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await EconomicStatementSchema.validate(classToPlain(this), options);
    return new EconomicStatementDto(validated);
  }
}

/** Interfaccia simmetrica al DTO EconomicStatementDto */
export type IEconomicStatementType = IDto<EconomicStatementDto>;

/**
 * DTO Paginato della classe EconomicStatement
 */
export class PaginatedEconomicStatementDto {
  @ApiProperty({ type: [EconomicStatementDto] })
  @Type(() => EconomicStatementDto)
  items!: EconomicStatementDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const EconomicStatementSchema = yup
  .object({
    id: yup.number(),
    title: yup.string().required(),
    year: yup.number().required(),
    semester: yup.string().oneOfEnum(EconomicSemester).required(),
    onlyPaid: yup.boolean().default(false),
    state: yup.string().oneOfEnum(QueueTaskState).default(QueueTaskState.Running),
    authorId: yup.number(),
    sourceVersionId: yup.number().nullable()
  })
  .noUnknown()
  .meta({ schemaName: "EconomicStatementSchema" })
  .required();
