export enum Model003Action {
  /** Contabilizzazione */
  CO = 'CO',
  /** Comunicazione Pagamento */
  CP = 'CP',
  /** Comunicazione Scadenza */
  CS = 'CS'
}

export enum Model003ExpirationMessageCode {
  SI = 'SI',
  RS = 'RS'
}
