import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { UserDto } from 'common/dto/generated/UserDto';

export interface RevisionData {
  date?: Date | null;
  author?: UserDto | null;
}
/**
 * // TODO
 * Ritorna i dati della revisione
 */
export function getRevisionData(invoice: InvoiceDto): RevisionData {
  // TODO Funzione per Ottenere dati revisione
  return {
    date: invoice.revisionDate,
    author: invoice.revisionAuthor
  };
}
