import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Button, Table, Typography, Breadcrumb, Tag } from 'antd';
const { Title, Text } = Typography;
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { NotificationColumns } from 'client/components/schema/notification-sdi/table/NotificationColumns';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { NotificationQueryDto } from 'common/dto/query/NotificationQueryDto';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { NotificationApi } from 'client/components/schema/notification-sdi/NotificationApi';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { TableSticky } from 'client/ui/table/TableSticky';
import { NotificationDto } from 'common/dto/generated/NotificationDto';
import { SorterResult } from 'antd/lib/table/interface';
import { getDateRange } from 'client/ui/table/TableColumnFilter';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';

interface Params {}

export interface SdiNotificationsListPageProps
  extends RouteComponentProps<Params> {}

/**
 * Pagina di elenco delle notifiche SDI
 */
export function SdiNotificationsListPage(props: SdiNotificationsListPageProps) {
  const [query, setQuery] = useState<NotificationQueryDto>(
    new NotificationQueryDto(
      getPaginationUrlQuery(props.location.search, {
        pageNumber: 1,
        pageSize: 20
      })
    )
  );

  const { response, loading, error } = useApiQuery(NotificationApi.list, {
    data: { query }
  });
  const notifications = response?.data?.items;

  const columns = getPaginationUrlColumns<
    NotificationDto,
    NotificationQueryDto
  >(NotificationColumns, query, { receivedAt: ['fromDate', 'toDate'] });

  useEffect(() => {
    setQuery(
      new NotificationQueryDto(
        getPaginationUrlQuery(props.location.search, {
          pageNumber: 1,
          pageSize: 20
        })
      )
    );
  }, [props.location.search]);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/invoicing/invoices/all', title: `Fatture` });
  useBreadcrumbItem({
    priority: 20,
    path: props.location.pathname,
    title: `Notifiche SDI`,
    menuKey: 'invoicing',
    sideMenuKey: 'sdi'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco delle notifiche"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle children="Elenco delle notifiche SDI" />}
        breadcrumbRender={() => <BreadcrumbsContainer />}
      />
      <PageContent noPadding noSpace>
        <TableSticky<NotificationDto>
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={notifications}
          size="middle"
          onChange={(pagination, filters, sorter) => {
            const { field, order } = sorter as SorterResult<NotificationDto>;
            const dateRange = getDateRange(filters.receivedAt);
            const notificationQuery = new NotificationQueryDto({
              ...query,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize,
              orderBy: order ? field?.toString() : undefined,
              orderByDirection: order ? ColumnSortDirections[order] : undefined,
              fromDate: dateRange.from,
              toDate: dateRange.to
            });

            setPaginationUrlQuery(props, notificationQuery);
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
