import * as React from 'react';
import { ShipmentState } from 'common/schema/shipment/ShipmentTypes';

export function mapShipmentFilterToOptions(filter: string | undefined) {
  switch (filter) {
    case 'in-progress':
      return {
        filter: filter,
        title: 'Spedizioni in Lavorazione',
        state: ShipmentState.InProgress
      };
    case 'shipped':
      return {
        filter: filter,
        title: 'Spedizioni Inviate',
        state: ShipmentState.Shipped
      };
    default:
      return { filter: 'all', title: 'Tutte le Spedizioni', state: undefined };
  }
}
