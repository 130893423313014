import * as React from 'react';
import { Button, Table, Typography, Breadcrumb, Tag } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { Link, RouteComponentProps } from 'react-router-dom';
import { EconomicSimulationColumns } from 'client/components/schema/economy/simulation/table/EconomicSimulationColumns';
import { EconomicSimulationModal } from './EconomicSimulationModal';
import { useState } from 'react';
import { EconomicStatementSimulationQueryDto } from 'common/dto/query/EconomicStatementSimulationQueryDto';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { EconomicSimulationApi } from 'client/components/schema/economy/simulation/EconomicSimulationApi';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { TableSticky } from 'client/ui/table/TableSticky';
import { usePermissions } from 'client/components/auth/AuthModule';

export interface Params {}

export interface EconomicSimulationListPageProps
  extends RouteComponentProps<Params> {}

/**
 * Pagina di Elenco delle Simulazioni di Conto Economico
 */
export function EconomicSimulationListPage(
  props: EconomicSimulationListPageProps
) {
  const [query, setQuery] = useState(
    new EconomicStatementSimulationQueryDto({ pageNumber: 1, pageSize: 30 })
  );
  const { response, error, loading } = useApiQuery(EconomicSimulationApi.list, {
    data: { query }
  });
  const permissions = usePermissions();

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/economy/economic-statements',
    title: `Conto Economico`
  });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Simulazioni di Conto Economico',
    menuKey: 'economy',
    sideMenuKey: 'economic-simulations'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco delle simulazioni del conto economico"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={
          <PageHeadingTitle children="Elenco Simulazioni di Conto Economico" />
        }
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={
          permissions.has('economy.simulate') && (
            <EconomicSimulationModal
              icon={<PlusOutlined />}
              key="modal"
              type="primary"
              children="Nuova Simulazione"
            />
          )
        }
      />
      <PageContent noPadding noSpace>
        <TableSticky
          rowKey="id"
          loading={loading}
          columns={[
            ...EconomicSimulationColumns,
            {
              title: '',
              dataIndex: 'azioni',
              align: 'right',
              width: 120,
              render: (_: any, record) => (
                <Link to={`/economy/economic-simulations/${record.id}`}>
                  Dettaglio <ArrowRightOutlined />
                </Link>
              )
            }
          ]}
          dataSource={response?.data.items ?? []}
          size="small"
          onChange={pagination => {
            setQuery(
              new EconomicStatementSimulationQueryDto({
                pageNumber: pagination.current ?? query.pageNumber,
                pageSize: pagination.pageSize ?? query.pageSize
              })
            );
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
