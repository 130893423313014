import { ColumnsType } from 'antd/lib/table';
import { AccountingLineDto } from 'common/dto/generated/AccountingLineDto';
import { AccountingLineColumns } from './AccountingLineColumns';

export const AccountingLineAllColumns: ColumnsType<AccountingLineDto> = [
  AccountingLineColumns.expiresAt,
  AccountingLineColumns.state,
  AccountingLineColumns.amount,
  AccountingLineColumns.expenseNature,
  AccountingLineColumns.pcc,
  AccountingLineColumns.debtState,
  AccountingLineColumns.description,
  AccountingLineColumns.debtReason,
  AccountingLineColumns.paymentDate,
  AccountingLineColumns.reference
];

export const AccountingLineStateColumns: ColumnsType<AccountingLineDto> = [
  AccountingLineColumns.expiresAt,
  AccountingLineColumns.amount,
  AccountingLineColumns.expenseNature,
  AccountingLineColumns.pcc,
  AccountingLineColumns.debtState,
  AccountingLineColumns.description,
  AccountingLineColumns.debtReason,
  AccountingLineColumns.paymentDate,
  AccountingLineColumns.reference
];

// Colonne To-Ship-Accounting con link per selezione dettaglio
export const AccountingLineWithActionColumns: ColumnsType<AccountingLineDto> = [
  AccountingLineColumns.state,
  AccountingLineColumns.amount,
  AccountingLineColumns.expenseNature,
  AccountingLineColumns.pcc,
  AccountingLineColumns.debtState,
  AccountingLineColumns.description,
  AccountingLineColumns.debtReason,
  AccountingLineColumns.paymentDate,
  AccountingLineColumns.reference,
  AccountingLineColumns.actions
];
