import { IContractType } from 'common/dto/generated/ContractDto';
import { EntityMachine } from 'common/fsm/EntityMachine';
import { ContractState } from './ContractTypes';

export const ContractMachine = EntityMachine.for<IContractType>()
  .withState(contract => contract.state ?? ContractState.InProgress)
  .withAction('delete', {
    allowed: contract =>
      contract.state !== ContractState.Stipulated &&
      contract.state !== ContractState.Registered &&
      !contract.hasInvoices,
    message: contract => {
      if (contract.state === ContractState.Stipulated)
        return 'Un contratto stipulato non può essere eliminato';
      if (contract.state === ContractState.Registered)
        return 'Un contratto registrato non può essere eliminato';
      if (contract.hasInvoices)
        return 'Un contratto a cui sono connesse delle fatture non può essere eliminato';
      return 'Il contratto non può essere eliminato. (CM001)';
    }
  })
  .withAction('edit.important', {
    allowed: contract => contract.state !== ContractState.Stipulated,
    message: () => 'Un contratto stipulato non può essere modificato'
  })
  .withAction('transition.stipulated', {
    allowed: contract => true,
    message: () => 'Non è possibile stipulare il contratto'
  })
  .withAction('attach.invoice', {
    allowed: contract => contract.state === ContractState.Stipulated,
    message: contract =>
      'Impossibile collegare fatture a un contratto non stipulato.'
  });
