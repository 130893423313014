import { SelectOption } from 'client/ui/form/input/SelectInput';
import { ShipmentPriority } from './ShipmentTypes';

export const shipmentPriorityOptions: SelectOption[] = [
  {
    label: 'Altissima',
    value: ShipmentPriority.Highest
  },
  {
    label: 'Alta',
    value: ShipmentPriority.High
  },
  {
    label: 'Media',
    value: ShipmentPriority.Medium
  },
  {
    label: 'Bassa',
    value: ShipmentPriority.Low
  }
];
