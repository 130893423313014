import * as React from 'react';
import { Tag, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { ContractDto } from 'common/dto/generated/ContractDto';

export const cigIncongruenceTest = (
  invoice: InvoiceDto,
  contract?: ContractDto | null
) => {
  if (!contract?.id || !invoice.references || invoice.references.length < 1)
    return false;
  const contractCig = contract.cigCode;
  return !invoice.references.some(
    reference => reference.cigCode == contractCig
  );
};

export interface InvoiceCigIncongruenceTagProps {
  invoice: InvoiceDto;
}

export function InvoiceCigIncongruenceTag(
  props: InvoiceCigIncongruenceTagProps
) {
  const context = useFormikContext<InvoiceDto>();
  const { response } = useApiQuery(ContractApi.find, {
    skip: !context.values.contractId,
    data: { id: context.values.contractId! }
  });
  const cigIncongruence = cigIncongruenceTest(props.invoice, response?.data);
  if (!cigIncongruence) return null;

  return <Tag color="warning" children="Codice CIG non corrispondente" />;
}
