import * as React from 'react';
import { QueueTaskState } from 'common/schema/queue-task/QueueTaskState';
import './NameOrError.scss';

export interface INameOrErrorProps {
  title: string;
  state: QueueTaskState;
}

/**
 * Per visualizzare il Nome di un report o in alternativa il testo di errore
 */
export function NameOrError(props: INameOrErrorProps) {
  const { title, state } = props;
  if (state === QueueTaskState.Failed)
    return (
      <span className="name-or-error error">
        Si è verificato un errore durante la generazione
      </span>
    );
  return <span className="name-or-error">{title}</span>;
}
