import { ISODate } from 'client/ui/display/date/ISODate';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import React from 'react';
import { InvoiceStateTag } from '../view/InvoiceStateTag';
import { InvoiceReceivedDays } from '../view/InvoiceReceivedDays';
import { InvoiceProtocolName } from '../view/InvoiceProtocolName';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';
import { createTypedColumns } from 'client/ui/table/createTypedColumns';
import { ConfigEnumRenderValue } from 'client/ui/form/input/configurable/ConfigEnumInput';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { createInvoiceProtocolColumnFilter } from './createInvoiceProtocolColumnFilter';
import { omit, mapValues } from 'lodash';
import { InvoicePaperTableIndicator } from '../view/InvoicePaperTableIndicator';
import { EllipsisTooltip } from 'client/ui/ellipsis/EllipsisTooltip';
import { InvoiceRevisionTableIndicator } from '../view/InvoiceRevisionTableIndicator';

/**
 * Colonne di base per tabelle Fatture
 */
export const InvoiceFilteredColumns = createTypedColumns(InvoiceDto, {
  isPaper: {
    width: 5, // diventa 8px visto il padding interno della cella
    dataIndex: 'isPaper',
    fixed: 'left',
    render: (_, record) => (
      <InvoicePaperTableIndicator isPaper={record.isPaper} />
    )
  },
  dataRevision: {
    width: 20, // diventa 8px visto il padding interno della cella
    dataIndex: 'revisioned',
    render: (_, record) => <InvoiceRevisionTableIndicator invoice={record} />
  },
  receivedAt: {
    title: 'Ricezione (gg|data)',
    width: 140,
    dataIndex: 'receivedAt',
    sorter: true,
    render: (_, record) => <InvoiceReceivedDays invoice={record} />
  },
  receivedDate: {
    title: 'Ricezione',
    width: 100,
    dataIndex: 'receivedAt',
    sorter: true,
    render: (_, record) => <ISODate date={record.receivedAt} />
  },
  state: {
    title: 'Stato',
    dataIndex: 'state',
    width: 130,
    render: (_, record) => (
      <InvoiceStateTag state={record.state} acceptance={record.acceptance} />
    )
  },
  issuedDate: {
    title: 'Data',
    width: 100,
    dataIndex: 'issuedDate',
    ...createColumnFilter(FilterInputType.DateRange),
    sorter: true,
    render: (_, record) => <ISODate date={record.issuedDate} />
  },
  issuedYear: {
    title: 'Anno',
    dataIndex: 'issuedYear',
    ...createColumnFilter(FilterInputType.Text),
    sorter: true
  },
  executionDate: {
    title: 'Esecuzione',
    dataIndex: 'executionDate',
    width: 100,
    render: (_, record) => <ISODate date={record.executionDate} />
  },
  // TODO Data Lotto
  batchDate: {
    title: 'Data Lotto',
    dataIndex: 'receivedAt',
    width: 100,
    render: (_, record) => <ISODate date={record.receivedAt} />
  },
  testingDate: {
    title: 'Collaudo',
    dataIndex: 'testingDate',
    width: 100,
    render: (_, record) => <ISODate date={record.testingDate} />
  },
  expiresAt: {
    title: 'Scadenza',
    dataIndex: 'expiresAt',
    width: 110,
    ...createColumnFilter(FilterInputType.DateRange),
    sorter: true,
    render: (_, record) => <ISODate date={record.expiresAt} />
  },
  supplierName: {
    title: 'Denominazione',
    dataIndex: 'supplierName',
    width: 290,
    sorter: true,
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => record.supplier?.getName()
  },
  supplierFiscal: {
    title: 'CF/P.IVA',
    dataIndex: 'supplierFiscalNumber',
    width: 120,
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => record.supplier?.getFiscalNumber()
  },
  protocol: {
    title: 'Protocollo',
    dataIndex: 'protocol',
    width: 120,
    ...createInvoiceProtocolColumnFilter(),
    sorter: true,
    render: (_, record) => <InvoiceProtocolName invoice={record} />
  },
  protocolDate: {
    title: 'Data Prot.',
    dataIndex: 'protocolDate',
    width: 100,
    ...createColumnFilter(FilterInputType.DateRange),
    render: (_, record) => <ISODate date={record.protocolDate} />
  },
  cig: {
    title: 'CIG',
    dataIndex: 'cigCode',
    width: 120,
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => record.contract?.cigCode ?? '-'
  },
  cup: {
    title: 'CUP',
    dataIndex: 'cupCode',
    width: 100,
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => record.contract?.cupCode ?? '-'
  },
  issuedNumber: {
    title: 'Numero',
    dataIndex: 'issuedNumber',
    width: 100,
    sorter: true,
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => <EllipsisTooltip children={record.issuedNumber} />
  },
  invoiceTypeCode: {
    title: 'Tipo',
    dataIndex: 'invoiceTypeCode',
    ellipsis: true,
    width: 120,
    sorter: true,
    render: (_, record) => (
      <ConfigEnumRenderValue
        feature="sdi.values"
        enumKey="invoiceType"
        name="invoiceTypeCode"
        value={record.invoiceTypeCode}
      />
    )
  },
  totalAmount: {
    title: 'Imponibile',
    dataIndex: 'totalVatTaxableAmount',
    align: 'right',
    className: 'bold-data',
    width: 140,
    fixed: 'right',
    sorter: true,
    render: (_, record) => (
      <CurrencyRender value={record.totalVatTaxableAmount} />
    )
  }
});

export const InvoiceColumns = mapValues(InvoiceFilteredColumns, column =>
  omit(column, ['sorter', 'filterDropdown'])
);
