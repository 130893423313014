import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { ValidationAction } from 'common/validation/ValidationAction';

/**
 * Rappresentazione DTO della classe User definita in: src/server/schema/user/user/User.entity.ts
 * Hash: 2fcf865f680365bc8870f3d2a030c2a2
 */
@ValidationSchema(() => UserSchema)
export class UserDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ type: String, description: 'Login Utente' })
  login!: string;
  @ApiProperty({ type: String, description: 'Nome Utente' })
  name!: string;
  @ApiProperty({ description: 'Ruolo' })
  role!: "SuperAdmin" | "ProtocolUser" | "Admin" | "System" | "DocumentalUser";
  @ApiProperty({ type: Boolean, description: 'Autorizzazione attraverso password' })
  enablePassword!: boolean;
  @ApiProperty({ required: false, type: String, description: 'Password' })
  password?: string;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  deletedAt?: Date | undefined;
  @ApiProperty({ required: false, type: String })
  serviceToken?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Fiscale' })
  fiscalCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice utente documentale' })
  documentalUserCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'SystemID utente documentale' })
  documentalSystemId?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean })
  adminReferentFilter?: boolean | null | undefined;
  @ApiProperty({ type: String })
  strategy!: string;
  @ApiProperty({ type: String })
  originalStrategy!: string;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<UserDto>) {
    if (values != null) {
      Object.assign(this, values instanceof UserDto ? values : plainToClass(UserDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await UserSchema.validate(classToPlain(this), options);
    return new UserDto(validated);
  }
}

/** Interfaccia simmetrica al DTO UserDto */
export type IUserType = IDto<UserDto>;

/**
 * DTO Paginato della classe User
 */
export class PaginatedUserDto {
  @ApiProperty({ type: [UserDto] })
  @Type(() => UserDto)
  items!: UserDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const UserSchema = yup
  .object({
    id: yup.number(),
    login: yup.string().required().label('Login Utente'),
    name: yup.string().required().label('Nome Utente'),
    role: yup.mixed().required().label('Ruolo'),
    enablePassword: yup.boolean().required().label('Autorizzazione attraverso password'),
    password: yup.string().when(['$action', 'enablePassword'], {
          is: (action: string, enablePassword: boolean) =>
            action === ValidationAction.create && enablePassword,
          then: (schema: any) => schema.required()
        }).label('Password'),
    serviceToken: yup.string().nullable(),
    fiscalCode: yup.string().nullable().label('Codice Fiscale'),
    documentalUserCode: yup.string().nullable().label('Codice utente documentale'),
    documentalSystemId: yup.string().nullable().label('SystemID utente documentale'),
    adminReferentFilter: yup.boolean().nullable()
  })
  .noUnknown()
  .meta({ schemaName: "UserSchema" })
  .required();
