import { ColumnsType } from 'antd/lib/table';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import { ShipmentColumns } from './ShipmentColumns';

export const ShipmentAllColumns: ColumnsType<ShipmentDto> = [
  ShipmentColumns.number,
  ShipmentColumns.state,
  ShipmentColumns.isIntegration,
  ShipmentColumns.mandateNumber,
  ShipmentColumns.recipient,
  // ShipmentColumns.mandateBeneficiary,
  ShipmentColumns.dossier,
  ShipmentColumns.shipmentDescription,
  ShipmentColumns.createdAt
];
