import * as React from 'react';
import { getPageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { pageFixedSizes } from '../../PageLayout';
import { useEffect, useState } from 'react';

export interface PageContentFixedWrapperProps {
  children: React.ReactNode;
}

/**
 * Fixed Container del contenuto della pagina
 * inserito per il corretto funzionamento dei popover e dei dropdown
 * che se posizionati nella fascia del titolo pagina
 * scrollavano con la pagina invece di rimanere fissi
 */
export function PageContentFixedWrapper(props: PageContentFixedWrapperProps) {
  // const [style, setStyle] = useState({
  //   position: 'fixed',
  //   right: 0,
  //   left: pageFixedSizes.siderWidht,
  //   top: 169,
  //   bottom: 0,
  //   overflowY: 'scroll',
  //   zIndex: 0
  // } as React.CSSProperties);

  const heading = getPageHeading();
  // useEffect(() => {
  //   setStyle({ ...style, top: heading.clientRect?.bottom ?? 169 });
  // }, [heading]);

  const style: React.CSSProperties = {
    position: 'fixed',
    right: 0,
    left: pageFixedSizes.siderWidht,
    top: heading.clientRect?.bottom ?? 169,
    bottom: 0,
    overflowY: 'scroll',
    zIndex: 0
  };

  return <div style={style}>{props.children}</div>;
}
