import React from 'react';
import { LoaderSpinProps, LoaderSpin } from './LoaderSpin';
import { PageLayout } from '../layout/PageLayout';

export interface LoadingPageProps extends LoaderSpinProps {
  removeSideMenu?: boolean;
}

/**
 * Pagina di caricamento.
 */
export function LoadingPage(props: LoadingPageProps) {
  const { removeSideMenu, ...otherProps } = props;

  return (
    <PageLayout hasMainMenu hasSideMenu={!removeSideMenu}>
      <LoaderSpin {...otherProps} transparent />
    </PageLayout>
  );
}
