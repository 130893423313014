import { Typography } from 'antd';
import { useModuleConfigEnum } from 'client/components/schema/config/ClientConfigModule';
import { EllipsisTooltip } from 'client/ui/ellipsis/EllipsisTooltip';
import { FormikValues } from 'formik';
import * as React from 'react';
import { SelectInput, SelectInputProps } from '../SelectInput';
const { Text } = Typography;

export interface ConfigEnumInputProps<T extends FormikValues>
  extends SelectInputProps<T> {
  feature: string;
  enumKey: string;
}

/**
 * Select che utilizza come valori quelli configurati in un enum
 * nell'app.config.xml (sezione `modules`)
 */
export function ConfigEnumInput<T extends FormikValues>(
  props: ConfigEnumInputProps<T>
) {
  const { enumKey, feature, ...otherProps } = props;
  const enumConfig = useModuleConfigEnum(feature, enumKey);
  return (
    <SelectInput
      {...otherProps}
      showSearch
      optionFilterProp="label"
      options={enumConfig?.members.map(member => {
        const label = enumConfig?.showMemberKeys
          ? `${member.key} - ${member.text}`
          : member.text;

        return {
          value: member.key!,
          label,
          children: label
        };
      })}
    />
  );
}

ConfigEnumInput.renderFieldValue = function (
  value: string,
  props: ConfigEnumInputProps<any>
) {
  return <ConfigEnumRenderValue {...props} value={value} />;
};

/**
 * Componente per il rendering di un ConfigEnum.
 */
export function ConfigEnumRenderValue(props: ConfigEnumInputProps<any>) {
  const { enumKey, feature, value } = props;
  const enumConfig = useModuleConfigEnum(feature, enumKey);

  if (!value) return <>–</>;

  const member = enumConfig?.members.find(m => m.key === value);
  if (!member) return <>–</>;

  return (
    <EllipsisTooltip>
      {enumConfig?.showMemberKeys
        ? `${member.key} - ${member.text}`
        : member.text}
    </EllipsisTooltip>
  );
}
