import { message } from 'antd';
import { useModuleConfigEnum } from 'client/components/schema/config/ClientConfigModule';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';
import { SelectInput, SelectOption } from 'client/ui/form/input/SelectInput';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import {
  getDescriptionOptions,
  getDetailOptions,
  getSubjectOptions
} from 'common/schema/shipment/CheckCombinationList';
import { FormikContextType, useFormikContext } from 'formik';
import { uniq } from 'lodash';
import React from 'react';
import { useState } from 'react';

export interface IShipmentCheckSelectProps {
  labelWidth: ILabelWidth;
}

export interface ICheckCombination {
  kind: string;
  subject: string;
  description: string;
  detail: string;
}

function createCheckCombinationList(checkValuesConfig?: {
  members: { text: string }[];
}): ICheckCombination[] {
  if (!checkValuesConfig) {
    console.warn('Configurazione Provvedimenti non presente');
    message.warning('Configurazione Provvedimenti non presente');
    return [];
  }

  const combinations = checkValuesConfig.members.map(row => {
    const rowValues = row.text.split('#');
    return {
      kind: rowValues[0],
      subject: rowValues[1],
      description: rowValues[2],
      detail: rowValues[3]
    };
  });
  return combinations ?? [];
}

function getKindOptionsFromCombinations(
  combinations: ICheckCombination[]
): SelectOption[] {
  return uniq(combinations.map(combination => combination.kind)).map(kind => ({
    label: kind,
    value: kind
  }));
}

export function ShipmentCheckSelect(props: IShipmentCheckSelectProps) {
  const { labelWidth } = props;
  const checkValuesConfig = useModuleConfigEnum(
    'shipment.check.values',
    'combinations'
  );

  const formik = useFormikContext<ShipmentDto>();

  const { checkKind, checkSubject, checkDescription } = formik.values;

  const combinations = createCheckCombinationList(checkValuesConfig);

  const kindOptions = getKindOptionsFromCombinations(combinations);
  const [subjectOptions, setSubjectOptions] = useState<SelectOption[]>(
    getSubjectOptions(combinations, checkKind)
  );
  const [descriptionOptions, setDescriptionOptions] = useState<SelectOption[]>(
    getDescriptionOptions(combinations, checkKind, checkSubject)
  );
  const [detailOptions, setDetailOptions] = useState<SelectOption[]>(
    getDetailOptions(combinations, checkKind, checkSubject, checkDescription)
  );

  // Reset campi e ricalcolo opzioni al cambio di checkKind
  useFormikFieldChange(
    (formik: FormikContextType<ShipmentDto>) => {
      const { checkKind } = formik.values;
      setSubjectOptions(getSubjectOptions(combinations, checkKind));
      setDescriptionOptions([]);
      setDetailOptions([]);

      const { mandate, ...otherValues } = formik.values;

      const updated = new ShipmentDto({
        ...otherValues,
        checkSubject: null,
        checkDescription: null,
        checkDescriptionDetail: null
      });

      formik.setValues(updated);
    },
    ['checkKind'],
    { listenInitialValues: true }
  );

  // Reset campi e ricalcolo opzioni al cambio di checkSubject
  useFormikFieldChange(
    (formik: FormikContextType<ShipmentDto>) => {
      if (formik.values.checkSubject == null) return;
      const { checkKind, checkSubject } = formik.values;
      setDescriptionOptions(
        getDescriptionOptions(combinations, checkKind, checkSubject)
      );
      setDetailOptions([]);

      const { mandate, ...otherValues } = formik.values;

      const updated = new ShipmentDto({
        ...otherValues,
        checkDescription: null,
        checkDescriptionDetail: null
      });

      formik.setValues(updated);
    },
    ['checkSubject'],
    { listenInitialValues: true }
  );

  // Reset campi e ricalcolo opzioni al cambio di description
  useFormikFieldChange(
    (formik: FormikContextType<ShipmentDto>) => {
      if (formik.values.checkDescription == null) return;
      const { checkKind, checkSubject, checkDescription } = formik.values;
      setDetailOptions(
        getDetailOptions(
          combinations,
          checkKind,
          checkSubject,
          checkDescription
        )
      );
      const { mandate, ...otherValues } = formik.values;

      const updated = new ShipmentDto({
        ...otherValues,
        checkDescriptionDetail: null
      });

      formik.setValues(updated);
    },
    ['checkDescription'],
    { listenInitialValues: true }
  );

  return (
    <FormFieldsContainer columns={1} title="Piste di controllo">
      <FormFieldItem
        labelWidth={labelWidth}
        label="Tipo di Provvedimento"
        name={'checkKind'}
        component={SelectInput}
        placeholder="Seleziona"
        options={kindOptions}
      />
      <FormFieldItem
        labelWidth={labelWidth}
        label="Oggetto"
        name={'checkSubject'}
        component={SelectInput}
        placeholder={subjectOptions?.length > 0 ? 'Seleziona...' : ''}
        options={subjectOptions}
        disabled={subjectOptions?.length === 0}
      />
      <FormFieldItem
        labelWidth={labelWidth}
        label="Descrizione"
        name={'checkDescription'}
        component={SelectInput}
        placeholder={descriptionOptions?.length > 0 ? 'Seleziona...' : ''}
        options={descriptionOptions}
        disabled={descriptionOptions?.length === 0}
      />
      <FormFieldItem
        labelWidth={labelWidth}
        label="Descrizione Ulteriore"
        name={'checkDescriptionDetail'}
        component={SelectInput}
        placeholder={detailOptions?.length > 0 ? 'Seleziona...' : ''}
        options={detailOptions}
        disabled={detailOptions?.length === 0}
      />
    </FormFieldsContainer>
  );
}
