import * as React from 'react';
import { TeamOutlined } from '@ant-design/icons';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { Section } from 'client/ui/section/Section';
import { DocumentalUserSelectInput } from 'client/components/schema/documental/DocumentalUserSelectInput';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { DocumentalOfficeSelectInput } from 'client/components/schema/documental/DocumentalOfficeSelectInput';
import { DocumentalUserRoleSelectInput } from 'client/components/schema/documental/DocumentalUserRoleSelectInput';

export interface ContractReferentsSectionProps {
  contract: ContractDto;
}

export function ContractReferentsSection(props: ContractReferentsSectionProps) {
  const { contract } = props;
  return (
    <Section icon={<TeamOutlined />} title="Referenti" hasPadding>
      <FormFieldsContainer>
        <FormFieldItem
          // TODO: collegare Ufficio PiTre
          inline
          label="Ufficio che ha espresso l'esigenza"
          component={DocumentalOfficeSelectInput}
          name="requirementOfficeCode"
          idFieldName="requirementOfficeId"
          placeholder="Ufficio che ha espresso l'esigenza"
          fallbackOffice={contract.requirementOfficeMigrated}
        />
      </FormFieldsContainer>

      {/** Referenti */}
      <FormFieldsContainer columns={2}>
        {/* Referente Amministrativo */}
        <FormFieldItem
          inline
          shownUsername={contract?.adminReferent}
          name="adminReferentDocumentalCode"
          label="Amministrativo"
          component={DocumentalUserSelectInput}
          placeholder="Codice Utente"
        />
        <FormFieldItem
          inline
          component={DocumentalUserRoleSelectInput}
          userCodeName="adminReferentDocumentalCode"
          name="adminReferentRoleCode"
          label="Ruolo"
          roleFieldName={'adminReferentRole'}
          userRoleLabel={contract.adminReferentRole}
          allowClear
        />
        {/* Responsabile Unico Procedimento */}
        <FormFieldItem
          inline
          shownUsername={contract?.rupReferent}
          label="RUP"
          name="rupReferentDocumentalCode"
          component={DocumentalUserSelectInput}
          placeholder="Codice Utente"
        />
        <FormFieldItem
          inline
          component={DocumentalUserRoleSelectInput}
          userCodeName="rupReferentDocumentalCode"
          name="rupReferentRoleCode"
          label="Ruolo"
          roleFieldName={'rupReferentRole'}
          userRoleLabel={contract.rupReferentRole}
          allowClear
        />
        {/* Direttore Esecuzione Contratto */}
        <FormFieldItem
          inline
          shownUsername={contract?.decReferent}
          label="DEC"
          name="decReferentDocumentalCode"
          component={DocumentalUserSelectInput}
          placeholder="Codice Utente"
        />
        <FormFieldItem
          inline
          component={DocumentalUserRoleSelectInput}
          userCodeName="decReferentDocumentalCode"
          name="decReferentRoleCode"
          label="Ruolo"
          roleFieldName={'decReferentRole'}
          userRoleLabel={contract.decReferentRole}
          allowClear
        />
      </FormFieldsContainer>
    </Section>
  );
}
