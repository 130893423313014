import { Button, message, Space } from 'antd';
import { RelationEditor } from 'client/components/form/relations/useRelationEditor';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { CurrencyInput } from 'client/ui/form/input/CurrencyInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { DecimalInput } from 'client/ui/form/input/DecimalInput';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { PercentageInput } from 'client/ui/form/input/PercentageInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import {
  InvoiceLineDto,
  InvoiceLineSchema
} from 'common/dto/generated/InvoiceLineDto';
import { IEconomicEntryNode } from 'common/schema/economic-entry/createTreeFromEconomicEntries';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import { FormikContextType } from 'formik';
import * as React from 'react';
import { EconomicEntrySelectInput } from '../../economy/economic-entry/EconomicEntrySelectInput';

export interface IInvoiceLineEditModalProps {
  invoice: InvoiceDto;
  editor: RelationEditor<InvoiceLineDto>;
  editable?: boolean;
}

export function InvoiceLineEditModal(props: IInvoiceLineEditModalProps) {
  const { editor, invoice } = props;

  return (
    <>
      <FormikAugmented<InvoiceLineDto>
        enableReinitialize
        initialValues={editor.handle.current ?? new InvoiceLineDto()}
        validationSchema={InvoiceLineSchema}
        validationContext={{
          machineActions: InvoiceMachine.actionsFor(invoice)
        }}
        onSubmit={async values => {
          if (!props.editable) return;
          try {
            const input = await values.validate();

            if (values.economicEntry && input.economicEntryId) {
              input.economicEntry = values.economicEntry;
            }

            const type = editor.handle.apply(input);
            if (type === 'updated') {
              message.success('Linea modificata');
            } else {
              message.success('Linea aggiunta');
            }
          } catch (e) {
            message.error(
              'Si è verificato un errore nella gestione della Linea'
            );
            console.error(e);
          }
        }}
      >
        <FormikForm nested editable={props.editable} helpInTooltips>
          <ModalDialog
            title={
              props.editable && editor.handle.isNew
                ? 'Aggiungi Linea di Fattura'
                : props.editable
                ? 'Modifica Linea di Fattura'
                : 'Dettaglio Linea di Fattura'
            }
            visible={editor.handle.isVisible}
            onCancel={editor.handle.cancel}
            footer={
              <Space>
                <Button onClick={editor.handle.cancel}>Annulla</Button>
                {props.editable && <FormikSendButton>Salva</FormikSendButton>}
              </Space>
            }
          >
            <FormFieldsContainer columns={3}>
              <FormFieldItem
                component={NumberInput}
                name="number"
                label="Numero"
                placeholder="Numero"
              />
              <FormFieldItem
                colSpan={3}
                component={TextAreaInput}
                name="description"
                label="Descrizione"
                placeholder="Descrizione"
                rows={3}
              />
            </FormFieldsContainer>

            <FormFieldsContainer columns={3}>
              <FormFieldItem
                component={DecimalInput}
                name="quantity"
                label="Quantità"
                placeholder="Quantità"
              />
              <FormFieldItem
                component={TextInput}
                name="measureUnit"
                label="Unità di misura"
                placeholder="Unità di misura"
              />
              <FormFieldItem
                component={CurrencyInput}
                name="priceUnit"
                label="Prezzo Unitario"
                placeholder="Prezzo Unitario"
              />
              <div>{/* Colonna Vuota */}</div>
              <FormFieldItem
                component={PercentageInput}
                name="vatRate"
                label="IVA"
                placeholder="IVA"
              />
              <FormFieldItem
                component={CurrencyInput}
                name="priceTotal"
                label="Prezzo Totale"
                placeholder="Prezzo Totale"
              />
            </FormFieldsContainer>

            <FormFieldsContainer title="Dati Contabilizzazione" columns={4}>
              <FormFieldItem
                inline
                colSpan={2}
                component={EconomicEntrySelectInput}
                dropdownMatchSelectWidth={600}
                name="economicEntryId"
                label="Codice Conto Economico"
                placeholder="Codice Conto Economico"
                onAfterSelect={(
                  formik: FormikContextType<InvoiceLineDto>,
                  node?: IEconomicEntryNode
                ) => {
                  if (node?.type === 'Leaf') {
                    formik.setFieldValue('economicEntry', node?.entry);
                  }
                }}
              />
              <FormFieldItem
                inline
                component={DatePickerInput}
                type="date-only"
                name="periodStartDate"
                label="Inizio Periodo"
                placeholder="Inizio Periodo"
              />
              <FormFieldItem
                inline
                component={DatePickerInput}
                type="date-only"
                name="periodEndDate"
                label="Fine Periodo"
                placeholder="Fine Periodo"
              />
            </FormFieldsContainer>
          </ModalDialog>
        </FormikForm>
      </FormikAugmented>
    </>
  );
}
