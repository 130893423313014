import * as React from 'react';
import clx from 'classnames';
import './InvoiceRevisionTableIndicator.scss';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { getRevisionData } from '../revision/getRevisionData';

export interface InvoiceRevisionTableIndicatorProps {
  invoice: InvoiceDto;
}

/**
 * Indicatore visivo per fattura cartacea nelle tabelle fattura
 */
export function InvoiceRevisionTableIndicator(
  props: InvoiceRevisionTableIndicatorProps
) {
  // TODO Funzione per ottenere i dati della revisione
  const revision = getRevisionData(props.invoice);
  return (
    <div
      onClick={e => e.stopPropagation()}
      className={clx('invoice-revision-table-indicator')}
    >
      {revision.date ? (
        <CheckCircleFilled className={clx('icon', 'revisioned')} />
      ) : (
        <ExclamationCircleFilled className={clx('icon')} />
      )}
    </div>
  );
}
