import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaNotRequired, SchemaValidate } from "cli/generate/decorators/SchemaDecorators";
import { EconomicSemester } from 'common/schema/economic-statement/EconomicSemester';

/**
 * Richiesta di simulazione del conto economico.
 */
@GenerateDto()
class EconomicStatementSimulationInput {
  @SchemaLabel('Anno')
  year!: number;
  @SchemaLabel('Semestre')
  semester!: EconomicSemester;
  @SchemaLabel('Seleziona solo fatture pagate')
  @SchemaNotRequired()
  @SchemaValidate(schema => schema.default(false))
  onlyPaid!: boolean
}

/**
 * Rappresentazione DTO della classe EconomicStatementSimulationInput 
 * Hash: 35d6d6620dcd8b12a99aeea4d2e925e5
 */
@ValidationSchema(() => EconomicStatementSimulationInputSchema)
export class EconomicStatementSimulationInputDto {
  @ApiProperty({ type: Number, description: 'Anno' })
  year!: number;
  @ApiProperty({ enum: ['1', '2', 'Both'], description: 'Semestre' })
  semester!: EconomicSemester;
  @ApiProperty({ required: false, type: Boolean, description: 'Seleziona solo fatture pagate' })
  onlyPaid!: boolean;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<EconomicStatementSimulationInputDto>) {
    if (values != null) {
      Object.assign(this, values instanceof EconomicStatementSimulationInputDto ? values : plainToClass(EconomicStatementSimulationInputDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await EconomicStatementSimulationInputSchema.validate(classToPlain(this), options);
    return new EconomicStatementSimulationInputDto(validated);
  }
}

export const EconomicStatementSimulationInputSchema = yup
  .object({
    year: yup.number().required().label('Anno'),
    semester: yup.string().oneOfEnum(EconomicSemester).required().label('Semestre'),
    onlyPaid: yup.boolean().default(false).label('Seleziona solo fatture pagate')
  })
  .noUnknown()
  .meta({ schemaName: "EconomicStatementSimulationInputSchema" })
  .required();
