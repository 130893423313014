import { apiClient } from 'client/core/network/apiClient';
import {
  PaginatedPccExportDto,
  PccExportDto
} from 'common/dto/generated/PccExportDto';
import { PaginatedPccRowDto } from 'common/dto/generated/PccRowDto';
import { PccExportQueryDto } from 'common/dto/query/PccExportQueryDto';
import { PccRowQueryDto } from 'common/dto/query/PccRowQueryDto';

export const PccExportApi = {
  list: (opts: { query: PccExportQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedPccExportDto)
      .get(`/pcc/exports`, { params: opts.query }),

  find: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(PccExportDto)
      .get(`/pcc/exports/${opts.id}`),

  lines: {
    list: (opts: { id: number; query: PccRowQueryDto }) =>
      apiClient
        .requestWithResponseType(PaginatedPccRowDto)
        .get(`/pcc/exports/${opts.id}/rows`, { params: opts.query })
  },

  response: {
    upload: (opts: { data: FormData; id: number }) =>
      apiClient
        .requestWithResponseType(PccExportDto)
        .post(`/pcc/exports/${opts.id}/response`, opts.data, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
  }
};
