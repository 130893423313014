import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { UserDto } from './UserDto';

/**
 * Rappresentazione DTO della classe InvoiceExport definita in: src/server/schema/invoice/invoice/invoice-export/InvoiceExport.entity.ts
 * Hash: ae776ac1be0c2787da6f3c96207243a4
 */
@ValidationSchema(() => InvoiceExportSchema)
export class InvoiceExportDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ type: String, description: 'Percorso di salvataggio' })
  path!: string;
  @ApiProperty({ type: String, description: 'Nome file' })
  fileName!: string;
  @ApiProperty({ type: String, format: 'date', description: 'Data inizio' })
  fromDate!: Date;
  @ApiProperty({ type: String, format: 'date', description: 'Data fine' })
  toDate!: Date;
  @ApiProperty({ required: false, type: Number, description: 'ID autore' })
  authorId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  author?: UserDto | null;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceExportDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceExportDto ? values : plainToClass(InvoiceExportDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await InvoiceExportSchema.validate(classToPlain(this), options);
    return new InvoiceExportDto(validated);
  }
}

/** Interfaccia simmetrica al DTO InvoiceExportDto */
export type IInvoiceExportType = IDto<InvoiceExportDto>;

/**
 * DTO Paginato della classe InvoiceExport
 */
export class PaginatedInvoiceExportDto {
  @ApiProperty({ type: [InvoiceExportDto] })
  @Type(() => InvoiceExportDto)
  items!: InvoiceExportDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const InvoiceExportSchema = yup
  .object({
    id: yup.number(),
    path: yup.string().required().label('Percorso di salvataggio'),
    fileName: yup.string().required().label('Nome file'),
    fromDate: yup.date().dateOnlyFormat().required().label('Data inizio'),
    toDate: yup.date().dateOnlyFormat().required().label('Data fine'),
    authorId: yup.number().nullable().label('ID autore')
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceExportSchema" })
  .required();
