import React from 'react';
import { Link } from 'react-router-dom';
import { useModuleConfigOption } from '../config/ClientConfigModule';
import { Typography } from 'antd';
import { LinkButton } from 'client/ui/link/LinkButton';
import { ArrowRightOutlined } from '@ant-design/icons';
import qs from 'qs';
import { DOCUMENTAL_LINK_TARGET } from './DocumentalDossierLink';

const { Text } = Typography;

interface ProtocolLinkField {
  protocolUid?: number | null;
  protocolNumber?: number | null;
  protocolYear?: number | null;
}

interface DocumentalProtocolLinkProps {
  protocolData: ProtocolLinkField;
}

export function DocumentalProtocolLink(props: DocumentalProtocolLinkProps) {
  const { protocolNumber, protocolYear, protocolUid } = props.protocolData;
  const documentalUrlPath = useModuleConfigOption(
    'pitre.bridge',
    'documentalDirectLink'
  );
  const documentalIdAdm = useModuleConfigOption('pitre.bridge', 'idAdm');
  const documentalCodeRegister = useModuleConfigOption(
    'pitre.bridge',
    'codeRegister'
  );

  if (!documentalUrlPath || (!protocolUid && !protocolNumber)) {
    return (
      <Text type="danger">
        {protocolNumber ?? '--'}/{protocolYear ?? '--'}
      </Text>
    );
  }

  if (!protocolUid) {
    return (
      <a
        href={`${documentalUrlPath.value}${qs.stringify(
          {
            idAmministrazione: documentalIdAdm?.value,
            codeRegister: documentalCodeRegister?.value,
            numProto: protocolNumber,
            numAnnoProto: protocolYear,
            from: 'record'
          },
          { addQueryPrefix: true }
        )}`}
        target={DOCUMENTAL_LINK_TARGET}
      >
        {protocolNumber ?? '--'}/{protocolYear ?? '--'} <ArrowRightOutlined />
      </a>
    );
  }

  return (
    <a
      href={`${documentalUrlPath.value}${qs.stringify(
        {
          idObj: protocolUid,
          from: 'record'
        },
        { addQueryPrefix: true }
      )}`}
      target={DOCUMENTAL_LINK_TARGET}
    >
      {protocolNumber ?? '--'}/{protocolYear ?? '--'} <ArrowRightOutlined />
    </a>
  );
}
