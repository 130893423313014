import React from 'react';
import { Link } from 'react-router-dom';
import { useModuleConfigOption } from '../config/ClientConfigModule';
import { Typography } from 'antd';
import qs from 'qs';

const { Text } = Typography;

export const DOCUMENTAL_LINK_TARGET = '_gedop';

interface DossierLinkField {
  dossierUid?: string | number | null;
  dossierNumber?: string | number | null;
  dossierYear?: number | null;
  dossierCode?: string | null;
}
// dossierUid?: string | null | undefined;
// @ApiProperty({ required: false, type: Number })
// dossierYear?: number | null | undefined;
// @ApiProperty({ required: false, type: String })
// dossierNumber?: string | null | undefined;

interface DocumentalDossierLinkProps {
  dossierData: DossierLinkField;
}

export function DocumentalDossierLink(props: DocumentalDossierLinkProps) {
  const {
    dossierNumber,
    dossierYear,
    dossierUid,
    dossierCode
  } = props.dossierData;

  const documentalUrlPath = useModuleConfigOption(
    'pitre.bridge',
    'documentalDirectLink'
  );

  if (!documentalUrlPath || (!dossierUid && !dossierCode)) {
    return (
      <Text type="danger">
        {dossierNumber ?? '--'}/{dossierYear ?? '--'}
      </Text>
    );
  }

  // Versione con link a Fascicolo tramite codice i.e. "1.3.2/3021/2021"
  if (dossierCode) {
    return (
      <a
        href={`${documentalUrlPath.value}${qs.stringify(
          {
            idObj: dossierCode,
            from: 'project'
          },
          { addQueryPrefix: true }
        )}`}
        target={DOCUMENTAL_LINK_TARGET}
      >
        {dossierCode}
      </a>
    );
  }

  // TODO: Al momento non gestiamo il link a P3 con UID
  return null;
  // // Versione con link a Fascicolo tramite system ID
  // return (
  //   <Link
  //     to={{
  //       pathname: documentalUrlPath.value,
  //       search: `idProject=${dossierUid}&from=project`
  //     }}
  //     target={DOCUMENTAL_LINK_TARGET}
  //   >
  //     {dossierNumber ?? '--'}/{dossierYear ?? '--'}
  //   </Link>
  // );
}
