import { CloseOutlined } from '@ant-design/icons';
import { Button, ButtonProps, message, Space } from 'antd';
import { OptionProps } from 'antd/lib/select';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ConfigEnumInput } from 'client/ui/form/input/configurable/ConfigEnumInput';
import { SelectOption } from 'client/ui/form/input/SelectInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import {
  InvoiceAcceptanceDto,
  InvoiceAcceptanceSchema
} from 'common/dto/query/InvoiceAcceptanceDto';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import { AcceptanceState } from 'common/schema/invoice/InvoiceTypes';
import * as React from 'react';
import { useState } from 'react';
import { InvoiceApi } from '../InvoiceApi';

export interface IInvoiceRejectModalProps extends Omit<ButtonProps, 'onClick'> {
  invoice: InvoiceDto;
}

export function InvoiceRejectModal(props: IInvoiceRejectModalProps) {
  const { ...buttonProps } = props;

  const [visible, setVisible] = useState(false);

  const [accept] = useApiMutation(InvoiceApi.accept, {
    invalidates: [InvoiceApi.find, InvoiceApi.notifications.list],
    data: { id: props.invoice.id }
  });

  return (
    <>
      <Button
        {...buttonProps}
        ghost
        danger
        size="large"
        icon={<CloseOutlined />}
        onClick={() => setVisible(true)}
      />
      <FormikAugmented<InvoiceAcceptanceDto>
        enableReinitialize
        initialValues={
          new InvoiceAcceptanceDto({ acceptance: AcceptanceState.Rejected })
        }
        validationSchema={InvoiceAcceptanceSchema}
        validationContext={{
          machineActions: InvoiceMachine.actionsFor(props.invoice)
        }}
        onSubmit={async values => {
          try {
            const input = await values.validate();

            await accept({
              data: { input }
            });
            message.success(`La fattura è stata rifiutata con successo.`);
            setVisible(false);
          } catch (e) {
            message.error("Si è verificato un errore durante l'operazione.");
            console.error(e);
          }
        }}
      >
        <FormikForm editable nested helpInTooltips>
          <ModalDialog
            title={`Sei sicuro di voler rifiutare la fattura?`}
            size="small"
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={
              <Space>
                <Button onClick={() => setVisible(false)}>Annulla</Button>
                <FormikSendButton>Rifiuta</FormikSendButton>
              </Space>
            }
          >
            <FormFieldsContainer columns={3}>
              <FormFieldItem
                renderOption={(option: SelectOption) => (
                  <div
                    style={{
                      height: 'auto',
                      whiteSpace: 'normal',
                      overflow: 'unset'
                    }}
                  >
                    {option.label}
                  </div>
                )}
                className="reject-reasons-select-input"
                colSpan={3}
                component={ConfigEnumInput}
                feature="sdi.values"
                enumKey="rejectReasonsScheme"
                label="Motivazione"
                name="description"
                placeholder="Motivazione rifiuto"
              />
            </FormFieldsContainer>
          </ModalDialog>
        </FormikForm>
      </FormikAugmented>
    </>
  );
}
