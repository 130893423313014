import { useCurrentUser } from 'client/components/auth/AuthModule';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { CurrencyInput } from 'client/ui/form/input/CurrencyInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { PermissionLogic } from 'common/permissions/PermissionLogic';
import { Roles } from 'common/permissions/Roles';
import {
  DebtReasonOptions,
  DebtStateOptions,
  ExpenseNatureOptions
} from 'common/schema/accounting-line/AccountingLineLabels';
import * as React from 'react';
import { DebtStateTag } from '../view/DebtStateTag';

export interface IAccountingLineFormFieldsProps {}

export function AccountingLineFormFields(
  props: IAccountingLineFormFieldsProps
) {
  const user = useCurrentUser();
  const canEditDates = PermissionLogic.has(user, 'accounting.line.edit:dates');

  return (
    <>
      <FormFieldsContainer
        title="Contabilizzazione"
        removeTopTitleSpacing
        columns={3}
      >
        <FormFieldItem
          labelWidth="width-140"
          component={CurrencyInput}
          label="Importo"
          name="amount"
          placeholder="Importo"
        />
        <FormFieldItem
          labelWidth="width-140"
          label="Stato del Debito"
          component={SelectInput}
          options={DebtStateOptions}
          renderOption={(option: any) => <DebtStateTag state={option.value} />}
          name="debtState"
        />
        <FormFieldItem
          labelWidth="width-140"
          label="Capitolo di Spesa"
          component={NumberInput}
          name="expenseChapter"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={3}>
        <FormFieldItem
          labelWidth="width-140"
          colSpan={2}
          label="Causale del Debito"
          component={SelectInput}
          options={DebtReasonOptions}
          allowClear
          name="debtReason"
        />
        <FormFieldItem
          labelWidth="width-140"
          label="Natura di Spesa"
          component={SelectInput}
          options={ExpenseNatureOptions}
          name="expenseNature"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          labelWidth="width-140"
          label="Descrizione"
          component={TextAreaInput}
          name="description"
        />
      </FormFieldsContainer>

      <FormFieldsContainer title="Pagamento" columns={2}>
        <FormFieldItem
          labelWidth="width-140"
          component={DatePickerInput}
          type="from"
          label="Data di Pagamento"
          name="paymentDate"
          readOnly={!canEditDates}
        />
        <FormFieldItem
          labelWidth="width-140"
          label="Pagamento"
          component={CheckboxInput}
          disabled
          children="Pagamento Eseguito"
          name="paymentExecuted"
          readOnly={!canEditDates}
        />
        <FormFieldItem
          labelWidth="width-140"
          colSpan={2}
          component={TextInput}
          label="Termini"
          placeholder="Termini..."
          name="terms"
          readOnly={!canEditDates}
        />
        <FormFieldItem
          labelWidth="width-140"
          colSpan={2}
          label="Note"
          placeholder="Note"
          component={TextAreaInput}
          name="notes"
          readOnly={!canEditDates}
        />
      </FormFieldsContainer>
    </>
  );
}
