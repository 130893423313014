import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize, SchemaValidate } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { AccountingLineState, DebtState } from "common/schema/accounting-line/AccountingLineTypes";
import { AcceptanceState } from 'common/schema/invoice/InvoiceTypes';
import { yup } from 'common/validation/initYup';
import { AccountingLineColumnSort } from './types/AccountingLineQueryTypes';

@GenerateDto()
class AccountingLineQuery {
  @SchemaPageNumber()
  pageNumber!: number;
  @SchemaPageSize()
  pageSize!: number;
  @SchemaLabel('Descrizione')
  description?: string | null;
  @SchemaLabel("Stato del debito")
  debtState?: DebtState | null;
  @SchemaLabel("Stati del debito")
  debtStates?: DebtState[] | null;
  @SchemaLabel("Stato della linea")
  state?: AccountingLineState | null;
  @SchemaLabel("Esecuzione pagamento")
  paymentExecuted?: boolean | null;
  @SchemaLabel("Ordinamento")
  orderBy?: AccountingLineColumnSort = AccountingLineColumnSort.createdAt;
  @SchemaLabel("Direzione di Ordinamento")
  orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;
  @SchemaLabel('Stato comunicazione scadenza')
  pccExportedPayment?: boolean | null;
  @SchemaLabel('Stato comunicazione pagamento')
  pccExportedExpiration?: boolean | null;
  @SchemaLabel('Stato comunicazione contabilizzazione')
  pccExportedAccounting?: boolean | null;
  @SchemaLabel('Stato Accettazione fattura collegata')
  invoiceAcceptance?: AcceptanceState | null;
  @SchemaLabel('Ricerca delle linee di contabilizzazione collegabili ad un mandato')
  canBeLinkedToMandate?: boolean | null;
  @SchemaLabel('Mandato collegato')
  currentMandateId?: number | null;
  @SchemaLabel('Nome del fornitore')
  supplierName?: string | null
  @SchemaLabel('CIG contratto collegato')
  @SchemaValidate((schema: yup.StringSchema) => schema.trim())
  cigCode?: string | null
  @SchemaLabel('CUP contratto collegato')
  cupCode?: string | null
  @SchemaLabel('Referente Amministrativo contratto collegato')
  adminReferentId?: number | null;

  @SchemaLabel('da data di scadenza')
  expireDateFrom?: Date | null;
  @SchemaLabel('a data di scadenza')
  expireDateTo?: Date | null;

  @SchemaLabel('Numero Fattura')
  invoiceNumber?: string | null;
  @SchemaLabel('Anno Fattura')
  invoiceYear?: number | null;

  /**
   * Esplicita che le linee richieste devono essere comunicabili per il pagamento a PCC.
   * In questo modo si filtrano le linee di fatture che hanno tutti i campi necessari (come il contratto) valorizzati.
   */
  @SchemaLabel('Comunicabile pagamento a PCC')
  needsPccPayment?: boolean | null;
}

/**
 * Rappresentazione DTO della classe AccountingLineQuery 
 * Hash: 79d335824c7c505fbf0b2ad977f14e82
 */
@ValidationSchema(() => AccountingLineQuerySchema)
export class AccountingLineQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, type: String, description: 'Descrizione' })
  description?: string | null | undefined;
  @ApiProperty({ required: false, enum: ['LIQ', 'SOSP', 'NOLIQ', 'LIQdaSOSP', 'LIQdaNL', 'SOSPdaLIQ', 'SOSPdaNL', 'NLdaLIQ', 'NLdaSOSP', 'ADEGLIQ'], description: 'Stato del debito' })
  debtState?: DebtState | null | undefined;
  @ApiProperty({ required: false, enum: ['LIQ', 'SOSP', 'NOLIQ', 'LIQdaSOSP', 'LIQdaNL', 'SOSPdaLIQ', 'SOSPdaNL', 'NLdaLIQ', 'NLdaSOSP', 'ADEGLIQ'], description: 'Stati del debito' })
  debtStates?: DebtState[] | null | undefined;
  @ApiProperty({ required: false, enum: ['NotPayable', 'ToShip', 'ToPay', 'Paid'], description: 'Stato della linea' })
  state?: AccountingLineState | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Esecuzione pagamento' })
  paymentExecuted?: boolean | null | undefined;
  @ApiProperty({ required: false, enum: ['createdAt', 'debtState', 'invoice.expiresAt', 'id', 'description', 'amount'], description: 'Ordinamento' })
  orderBy?: AccountingLineColumnSort | undefined = AccountingLineColumnSort.createdAt;
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;
  @ApiProperty({ required: false, type: Boolean, description: 'Stato comunicazione scadenza' })
  pccExportedPayment?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Stato comunicazione pagamento' })
  pccExportedExpiration?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Stato comunicazione contabilizzazione' })
  pccExportedAccounting?: boolean | null | undefined;
  @ApiProperty({ required: false, enum: ['Accepted', 'Rejected', 'Indeterminate'], description: 'Stato Accettazione fattura collegata' })
  invoiceAcceptance?: AcceptanceState | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Ricerca delle linee di contabilizzazione collegabili ad un mandato' })
  canBeLinkedToMandate?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Mandato collegato' })
  currentMandateId?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome del fornitore' })
  supplierName?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'CIG contratto collegato' })
  cigCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'CUP contratto collegato' })
  cupCode?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Referente Amministrativo contratto collegato' })
  adminReferentId?: number | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'da data di scadenza' })
  expireDateFrom?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'a data di scadenza' })
  expireDateTo?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Numero Fattura' })
  invoiceNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Anno Fattura' })
  invoiceYear?: number | null | undefined;
  /**
   * Esplicita che le linee richieste devono essere comunicabili per il pagamento a PCC.
   * In questo modo si filtrano le linee di fatture che hanno tutti i campi necessari (come il contratto) valorizzati.
   */
  @ApiProperty({ required: false, type: Boolean, description: 'Comunicabile pagamento a PCC' })
  needsPccPayment?: boolean | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<AccountingLineQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof AccountingLineQueryDto ? values : plainToClass(AccountingLineQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await AccountingLineQuerySchema.validate(classToPlain(this), options);
    return new AccountingLineQueryDto(validated);
  }
}

export const AccountingLineQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    description: yup.string().nullable().label('Descrizione'),
    debtState: yup.string().oneOfEnum(DebtState).nullable().label('Stato del debito'),
    debtStates: yup.array(yup.string().oneOfEnum(DebtState).required()).nullable().label('Stati del debito'),
    state: yup.string().oneOfEnum(AccountingLineState).nullable().label('Stato della linea'),
    paymentExecuted: yup.boolean().nullable().label('Esecuzione pagamento'),
    orderBy: yup.string().oneOfEnum(AccountingLineColumnSort).default(AccountingLineColumnSort.createdAt).label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento'),
    pccExportedPayment: yup.boolean().nullable().label('Stato comunicazione scadenza'),
    pccExportedExpiration: yup.boolean().nullable().label('Stato comunicazione pagamento'),
    pccExportedAccounting: yup.boolean().nullable().label('Stato comunicazione contabilizzazione'),
    invoiceAcceptance: yup.string().oneOfEnum(AcceptanceState).nullable().label('Stato Accettazione fattura collegata'),
    canBeLinkedToMandate: yup.boolean().nullable().label('Ricerca delle linee di contabilizzazione collegabili ad un mandato'),
    currentMandateId: yup.number().nullable().label('Mandato collegato'),
    supplierName: yup.string().nullable().label('Nome del fornitore'),
    cigCode: yup.string().nullable().trim().label('CIG contratto collegato'),
    cupCode: yup.string().nullable().label('CUP contratto collegato'),
    adminReferentId: yup.number().nullable().label('Referente Amministrativo contratto collegato'),
    expireDateFrom: yup.date().nullable().label('da data di scadenza'),
    expireDateTo: yup.date().nullable().label('a data di scadenza'),
    invoiceNumber: yup.string().nullable().label('Numero Fattura'),
    invoiceYear: yup.number().nullable().label('Anno Fattura'),
    needsPccPayment: yup.boolean().nullable().label('Comunicabile pagamento a PCC')
  })
  .noUnknown()
  .meta({ schemaName: "AccountingLineQuerySchema" })
  .required();
