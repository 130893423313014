import * as React from 'react';
import { Typography, message, Tag, Tooltip, Space } from 'antd';
const { Title, Text } = Typography;
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { ISODate } from 'client/ui/display/date/ISODate';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { ShipmentStateTag } from 'client/components/schema/shipment/view/ShipmentStateTag';
import { ShipmentDto, ShipmentSchema } from 'common/dto/generated/ShipmentDto';

import { FormikForm } from 'client/ui/form/FormikForm';
import { ShipmentGeneralSection } from './sections/ShipmentGeneralSection';
import { ShipmentMandateSection } from './sections/ShipmentMandateSection';
import { ShipmentIntegrationsSection } from './sections/ShipmentIntegrationsSection';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { RouteComponentProps } from 'react-router';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { ShipmentApi } from 'client/components/schema/shipment/ShipmentApi';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useCallback, useEffect, useState } from 'react';
import { EditAndSaveButton } from 'client/ui/form/button/EditAndSaveButton';
import { ShipmentState } from 'common/schema/shipment/ShipmentTypes';
import { ShipmentOriginalSection } from './sections/ShipmentOriginalSection';
import { ShipmentIntegrateButton } from './sections/component/ShipmentIntegrateButton';
import { truncate } from 'lodash';
import { ValidationAction } from 'common/validation/ValidationAction';
import { ShipmentSendButton } from './ShipmentSendButton';
import { StateTag } from 'client/ui/state/StateTag';
import { mapShipmentFilterToOptions } from '../list/mapShipmentFilterToOptions';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { ShipmentMachine } from 'common/schema/shipment/ShipmentMachine';
import qs from 'qs';
import { PageHeadingSummary } from 'client/ui/layout/page/heading/PageHeadingSummary';
import { ShipmentDossierName } from 'client/components/schema/shipment/view/ShipmentDossierName';
import {
  useModuleConfigEnum,
  useModuleConfigOption
} from 'client/components/schema/config/ClientConfigModule';
import { ShipmentDuplicateTag } from 'client/components/schema/shipment/view/ShipmentDuplicateTag';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { ClockCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { ActivityLogTable } from 'client/components/schema/activity-log/table/ActivityLogTable';
import { Section } from 'client/ui/section/Section';
import { ActivityLogApi } from 'client/components/schema/activity-log/ActivityLogApi';

interface Params {
  id: string;
  filter?: string;
}

export interface ShipmentPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina delle  Spedizioni
 */
export function ShipmentPage(props: ShipmentPageProps) {
  // Prendo l'ID della spedizione
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const urlQuery = qs.parse(props.location.search.substring(1));

  const mandateId: number | null = urlQuery.mandateId
    ? parseInt(urlQuery.mandateId.toString())
    : null;

  const kindWithMandate = useModuleConfigEnum(
    'shipment.check.values',
    'kindWithMandate'
  );

  const defaultNodeClassification = useModuleConfigOption(
    'pitre.bridge',
    'codeNodeClassification'
  );

  if (!kindWithMandate) {
    console.warn('Configurazione provvedimenti con mandati obbligatori non presente'); // prettier-ignore
    message.warning('Configurazione provvedimenti con mandati obbligatori non presente'); // prettier-ignore
  }

  const { response, error, loading, refetch } = useApiQuery(ShipmentApi.find, {
    skip: id == null,
    data: {
      id: id!
    }
  });

  const shipment =
    id != null
      ? response?.data
      : new ShipmentDto({
          state: ShipmentState.InProgress,
          isIntegration: false,
          recipient: 'UBRRAC',
          classification: defaultNodeClassification?.value
        });

  // Se sto creando una spedizione e vengo dalla pagina di un mandato
  // assegno quel mandato in automatico
  if (id == null) {
    shipment!.mandateId = mandateId;
  }

  const [editable, setEditable] = useState(id == null);

  // Resetta alla modalità non editabile al cambio di pagina
  useEffect(() => {
    setEditable(id == null);
  }, [id]);

  const [create] = useApiMutation(ShipmentApi.create, {
    invalidates: [ShipmentApi.find, ActivityLogApi.list]
  });
  const [update] = useApiMutation(ShipmentApi.update, {
    invalidates: [ShipmentApi.find, ActivityLogApi.list]
  });

  const deletable = ShipmentMachine.can(shipment, 'delete', true);
  const [deleteShipment] = useApiMutation(ShipmentApi.softDelete, {
    invalidates: [ActivityLogApi.list]
  });

  const isEditable = ShipmentMachine.can(shipment, 'edit');

  const handleDelete = useCallback(async () => {
    if (!id) return;
    if (!deletable) return;

    if (!deletable.allowed) {
      message.error(deletable.message);
      return;
    }

    try {
      await deleteShipment({ data: { id } });
      message.success('Spedizione eliminata con successo.');
      props.history.push('/shipping/all');
    } catch (e) {
      message.error(
        "Errore durante l'eliminazione della spedizione. Riprovare."
      );
    }
  }, [id, deletable]);

  const breadcrumbTitle = loading
    ? '...'
    : shipment?.id
    ? truncate(shipment.subject, { length: 33 })
    : 'Nuova Spedizione';

  const pageOptions = mapShipmentFilterToOptions(props.match.params.filter);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/shipping/all', title: `Spedizioni UBRRAC` });
  useBreadcrumbItem({
    path: `/shipping/${pageOptions.filter}`,
    title: pageOptions.title
  });
  useBreadcrumbItem({
    priority: 20,
    path: props.location.pathname,
    title: breadcrumbTitle,
    menuKey: 'shipping',
    sideMenuKey: `shipment-${pageOptions.filter}`
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere alla spedizione"
        error={error}
      />
    );
  }

  if (!shipment) return <LoadingPage />;
  // if (!shipment || loading || mandateRequest.loading) return <LoadingPage />;

  return (
    <CorePageLayout>
      <FormikAugmented<ShipmentDto>
        initialValues={shipment}
        enableReinitialize
        onReset={() => {
          setEditable(false);
        }}
        validationSchema={ShipmentSchema}
        validationContext={{
          action: id ? ValidationAction.edit : ValidationAction.create,
          machineActions: ShipmentMachine.actionsFor(shipment),
          kindWithMandate: kindWithMandate?.members.map(member => member.text)
        }}
        validateOnChange={false}
        onSubmit={async (rawValues: ShipmentDto) => {
          try {
            const input = await rawValues.validate({
              context: {
                action: id ? ValidationAction.edit : ValidationAction.create,
                machineActions: ShipmentMachine.actionsFor(shipment),
                kindWithMandate: kindWithMandate?.members.map(
                  member => member.text
                )
              }
            });

            if (id) {
              await update({ data: { input } });
              message.success('Spedizione modificata con successo.');
            } else {
              const result = await create({
                data: { input }
              });
              message.success('Spedizione creata con successo.');
              props.history.push(
                `/shipping/${pageOptions.filter}/${result.data!.id}`
              );
            }
            setEditable(false);
          } catch (e) {
            message.error(
              'Si è verificato un errore durante il salvataggio della spedizione.'
            );
          }
        }}
      >
        <FormikForm editable={editable} helpInTooltips showVisualFeedback>
          <PageHeading
            title={
              <PageHeadingTitle>
                {shipment.id
                  ? shipment.description ?? shipment.subject
                  : 'Nuova Spedizione'}
              </PageHeadingTitle>
            }
            subTitle={
              shipment.shipDate && (
                <span>
                  spedito il{' '}
                  <ISODate date={shipment.shipDate} format="DD/MM/YYYY" />
                </span>
              )
            }
            tags={
              <Space
                direction="horizontal"
                size={0}
                align="center"
                style={{ paddingRight: '8px' }}
              >
                {shipment.isIntegration && (
                  <StateTag color="purple" children="Integrazione" />
                )}
                {id == null ? (
                  <Tag>Non ancora salvato</Tag>
                ) : (
                  <ShipmentStateTag state={shipment.state} />
                )}
                {shipment.isDuplicated && <ShipmentDuplicateTag />}
              </Space>
            }
            breadcrumbRender={() => <BreadcrumbsContainer />}
            extra={
              <EditAndSaveButton
                hideEditButton={!isEditable}
                key="edit"
                isNew={id == null}
                name="Spedizione"
                state={[editable, setEditable]}
                whenSaved={
                  <>
                    <ShipmentIntegrateButton
                      key="integrate"
                      shipment={shipment}
                      disabled={editable}
                    />
                    <ShipmentSendButton
                      key="send"
                      shipment={shipment}
                      editable={editable}
                    />
                    <Tooltip
                      title={
                        id != null && !deletable?.allowed && deletable?.message
                      }
                    >
                      <ButtonDeleteConfirm
                        title="Sei sicuro di voler eliminare la spedizione?"
                        loading={loading}
                        size="middle"
                        disabled={id == null || !deletable?.allowed}
                        buttonIcon={<DeleteOutlined />}
                        buttonDanger
                        buttonType="primary"
                        onConfirm={handleDelete}
                      />
                    </Tooltip>
                  </>
                }
              />
            }
          >
            <PageHeadingSummary
              column={4}
              items={[
                {
                  label: 'Numero Spedizione',
                  value: shipment.number
                },
                {
                  label: 'Fascicolo UBRRAC',
                  value: <ShipmentDossierName shipment={shipment} />
                },
                {
                  label: 'Data Creazione',
                  value: <ISODate date={shipment.createdAt} />
                },
                {
                  label: 'Data Ultima Modifica',
                  value: <ISODate date={shipment.updatedAt} />
                }
              ]}
            />
          </PageHeading>
          <PageContent fixedWidht>
            {/* DESCRIZIONE SPEDIZIONE e PISTE DI CONTROLLO */}
            <ShipmentGeneralSection />

            {/* Descrizione Spedizione Originale */}
            <ShipmentOriginalSection
              originalShipment={shipment.originalShipment}
            />

            {/* Dati del Mandato  */}
            <ShipmentMandateSection shipment={shipment} />

            {/* Altre spedizioni di integrazione  */}
            <ShipmentIntegrationsSection
              shipmentId={shipment.id}
              disabled={shipment.isIntegration}
            />

            {/* Sezione Cronologia attività */}
            {id && (
              <Section
                icon={<ClockCircleOutlined />}
                title="Cronologia Attività"
              >
                <ActivityLogTable entity="shipment" entityId={id} />
              </Section>
            )}
          </PageContent>
        </FormikForm>
      </FormikAugmented>
    </CorePageLayout>
  );
}
