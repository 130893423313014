import * as React from 'react';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { useEffect, useState } from 'react';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { ContractQueryDto } from 'common/dto/query/ContractQueryDto';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { SorterResult } from 'antd/lib/table/interface';
import { getDateRange, getFilter } from 'client/ui/table/TableColumnFilter';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { ContractColumns } from 'client/components/schema/contract/table/ContractColumns';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { PlusOutlined } from '@ant-design/icons';
import { LinkButton } from 'client/ui/link/LinkButton';
import { mapContractsFilterToOptions } from './FilterToOptionsContracts';
import { TableSticky } from 'client/ui/table/TableSticky';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { AdminReferentFilter } from 'client/components/config/AdminReferentFilter';

interface Params {
  filter: string;
}

export interface ContractListPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di elenco dei Contratti
 */
export function ContractListPage(props: ContractListPageProps) {
  const [options, setOptions] = useState(
    mapContractsFilterToOptions(props.match.params.filter)
  );

  const [query, setQuery] = useState<ContractQueryDto>(
    new ContractQueryDto(
      getPaginationUrlQuery(props.location.search, {
        state: options.query.state,
        pageNumber: 1,
        pageSize: 10
      })
    )
  );

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/contracts/all', title: `Contratti` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: options.title,
    menuKey: 'contracts',
    sideMenuKey: options.filter
  });

  const { response, loading, error } = useApiQuery(ContractApi.list, {
    data: { query }
  });

  const contracts = response?.data.items;

  const columns = getPaginationUrlColumns<ContractDto, ContractQueryDto>(
    ContractColumns,
    query,
    { registrationDate: ['fromDate', 'toDate'] }
  );

  useEffect(() => {
    const nextOptions = mapContractsFilterToOptions(props.match.params.filter);
    setOptions(nextOptions);

    setQuery(
      new ContractQueryDto(
        getPaginationUrlQuery(props.location.search, {
          state: nextOptions.query.state,
          pageNumber: 1,
          pageSize: 10
        })
      )
    );
  }, [props.match, props.location.search, options.filter]);

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco dei contratti"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle>{options.title}</PageHeadingTitle>}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={
          <>
            <AdminReferentFilter />
            <LinkButton
              to="/contracts/all/create"
              type="primary"
              icon={<PlusOutlined />}
            >
              Nuovo Contratto
            </LinkButton>
          </>
        }
      />
      <PageContent noPadding noSpace>
        <TableSticky<ContractDto>
          loading={loading || !contracts}
          rowKey="id"
          onRow={record => ({
            style: {
              cursor: 'pointer'
            },
            onClick: () => {
              props.history.push(`/contracts/${options.filter}/${record.id}`);
            }
          })}
          columns={columns}
          dataSource={contracts}
          size="middle"
          onChange={(pagination, filters, sorter) => {
            const { field, order } = sorter as SorterResult<ContractDto>;
            const dateRange = getDateRange(filters.registrationDate);
            const subjectSearch =
              filters.extendedSubject && filters.extendedSubject.length > 0
                ? filters.extendedSubject[0].toString()
                : undefined;
            const supplierName =
              filters.supplierName && filters.supplierName.length > 0
                ? filters.supplierName[0].toString()
                : null;
            const cigCode =
              filters.cigCode && filters.cigCode.length > 0
                ? filters.cigCode[0].toString()
                : null;
            const contractNumber =
              filters.number && filters.number.length > 0
                ? filters.number[0].toString()
                : null;
            const adminReferentName = getFilter(
              filters,
              'adminReferentName'
            ) as string;

            const contractQuery = new ContractQueryDto({
              ...query,
              pageNumber: pagination.current || query.pageNumber,
              pageSize: pagination.pageSize || query.pageSize,
              orderBy: order ? field?.toString() : 'id',
              orderByDirection: order
                ? ColumnSortDirections[order]
                : ColumnSortDirections.descend,
              fromDate: dateRange.from,
              toDate: dateRange.to,
              extendedSubject: subjectSearch,
              number: contractNumber,
              cigCode,
              supplierName,
              adminReferentName
            });

            setPaginationUrlQuery(props, contractQuery);
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
