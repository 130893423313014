import { Button, Card, Col, message, Row, Space } from 'antd';
import { RelationEditor } from 'client/components/form/relations/useRelationEditor';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import {
  AccountingLineDto,
  AccountingLineSchema
} from 'common/dto/generated/AccountingLineDto';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import { Formik } from 'formik';
import * as React from 'react';
import { AccountingLineFormFields } from './AccountingLineFormFields';

export interface IAccountingLineEditDrawerProps {
  invoice: InvoiceDto;
  editor: RelationEditor<AccountingLineDto>;
  editable?: boolean;
}

export function AccountingLineEditDrawer(
  props: IAccountingLineEditDrawerProps
) {
  const { editor } = props;

  return (
    <>
      <FormikAugmented
        enableReinitialize
        initialValues={editor.handle.current ?? new AccountingLineDto()}
        validationSchema={AccountingLineSchema}
        validationContext={{
          machineActions: InvoiceMachine.actionsFor(props.invoice)
        }}
        validateOnChange={false}
        onSubmit={async values => {
          if (!props.editable) return;
          try {
            const input = await values.validate();
            const type = editor.handle.apply(input);
            if (type === 'updated') {
              message.success('Linea modificata');
            } else {
              message.success('Linea aggiunta');
            }
          } catch (e) {
            message.error(
              'Si è verificato un errore nella gestione della Linea'
            );
            console.error(e);
          }
        }}
      >
        <FormikForm nested editable={props.editable} helpInTooltips>
          <ModalDialog
            title={
              props.editable && editor.handle.isNew
                ? 'Crea Linea di Contabilizzazione'
                : props.editable
                ? 'Modifica Linea di Contabilizzazione'
                : 'Dettaglio Linea di Contabilizzazione'
            }
            visible={editor.handle.isVisible}
            onCancel={editor.handle.cancel}
            footer={
              <Space>
                <Button onClick={editor.handle.cancel}>Annulla</Button>
                {props.editable && <FormikSendButton>Salva</FormikSendButton>}
              </Space>
            }
          >
            <FormErrorsNotification />
            <AccountingLineFormFields />
          </ModalDialog>
        </FormikForm>
      </FormikAugmented>
    </>
  );
}
