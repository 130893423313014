import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from "client/ui/table/TableColumnSort";



@GenerateDto()
class InvoiceExportQuery {
    @SchemaPageNumber()
    pageNumber!: number;
    @SchemaPageSize()
    pageSize!: number;
    
    @SchemaLabel("Da data inizio")
    fromDateStart?: Date | null;
    @SchemaLabel("A data inizio")
    fromDateEnd?: Date | null;

    @SchemaLabel("Da data fine")
    toDateStart?: Date | null;
    @SchemaLabel("A data fine")
    toDateEnd?: Date | null;

    @SchemaLabel("Ordinamento")
    orderBy?: string = 'createdAt';

    @SchemaLabel("Direzione di Ordinamento")
    orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;
}

/**
 * Rappresentazione DTO della classe InvoiceExportQuery 
 * Hash: ad2ea942cfb66bfe38ff04d86a615481
 */
@ValidationSchema(() => InvoiceExportQuerySchema)
export class InvoiceExportQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Da data inizio' })
  fromDateStart?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'A data inizio' })
  fromDateEnd?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Da data fine' })
  toDateStart?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'A data fine' })
  toDateEnd?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Ordinamento' })
  orderBy?: string | undefined = 'createdAt';
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceExportQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceExportQueryDto ? values : plainToClass(InvoiceExportQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await InvoiceExportQuerySchema.validate(classToPlain(this), options);
    return new InvoiceExportQueryDto(validated);
  }
}

export const InvoiceExportQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    fromDateStart: yup.date().nullable().label('Da data inizio'),
    fromDateEnd: yup.date().nullable().label('A data inizio'),
    toDateStart: yup.date().nullable().label('Da data fine'),
    toDateEnd: yup.date().nullable().label('A data fine'),
    orderBy: yup.string().default('createdAt').label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento')
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceExportQuerySchema" })
  .required();
