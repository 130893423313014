import * as React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import './MainMenu.scss';
import {
  AuditOutlined,
  BarsOutlined,
  CalculatorOutlined,
  DownloadOutlined,
  FileOutlined,
  FileSyncOutlined,
  MailOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useCurrentMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { usePermissions } from 'client/components/auth/AuthModule';
import { LogoutButton } from 'client/components/auth/logout/LogoutButton';
import { InvoiceExportModal } from '../invoicing/invoices/export/InvoiceExportModal';

export interface MainMenuProps {}

/**
 * Menu principale dell'applicazione
 */
export function MainMenu(props: MainMenuProps) {
  const currentPathKey = useCurrentMenuKey();
  const permissions = usePermissions();

  return (
    <Menu
      selectedKeys={currentPathKey}
      theme="dark"
      mode="horizontal"
      className="main-menu"
      style={{ lineHeight: '34px' }}
    >
      <Menu.Item key="invoicing" icon={<FileOutlined />}>
        <Link to="/invoicing/invoices/all">Fatture</Link>
      </Menu.Item>
      <Menu.Item key="accounting" icon={<CalculatorOutlined />}>
        <Link to="/accounting/lines/all">Contabilizzazione</Link>
      </Menu.Item>
      <Menu.Item key="mandates" icon={<FileSyncOutlined />}>
        <Link to="/shipping/mandates/all">Mandati</Link>
      </Menu.Item>
      <Menu.Item key="shipping" icon={<MailOutlined />}>
        <Link to="/shipping/all">Spedizioni UBRRAC</Link>
      </Menu.Item>
      {permissions.has('economic-entry.read') && (
        <Menu.Item key="economy" icon={<BarsOutlined />}>
          <Link to="/economy/economic-simulations">Piano dei Conti</Link>
        </Menu.Item>
      )}
      <Menu.Item key="contracts" icon={<AuditOutlined />}>
        <Link to="/contracts/all">Contratti</Link>
      </Menu.Item>
      <Menu.Item key="export">
        <InvoiceExportModal isMenu />
        {/* <Link to="/invoicing/invoices/all">Esporta Fatture</Link> */}
      </Menu.Item>

      {permissions.has('users.read') && (
        <Menu.Item key="users" icon={<UserOutlined />}>
          <Link to="/users">Utenti</Link>
        </Menu.Item>
      )}
      <LogoutButton />
    </Menu>
  );
}
