import { DownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { ISODate } from 'client/ui/display/date/ISODate';
import React from 'react';
import { Link } from 'react-router-dom';
import { Tag, Typography } from 'antd';

const { Text } = Typography;
import { GeneratedIcon } from './view/GeneratedIcon';
import { EconomicStatementDto } from 'common/dto/generated/EconomicStatementDto';
import { QueueTaskState } from 'common/schema/queue-task/QueueTaskState';
import { AnchorAPILink } from 'client/ui/link/AnchorAPILink';
import { getEconomicStatementDownloadLink } from 'common/schema/economic-statement/getEconomicStatementDownloadLink';
import { EconomySemesterTag } from '../simulation/semester/EconomySemesterTag';
import { NameOrError } from './view/NameOrError';
import { ReportKindTag } from './view/ReportKindTag';
//ReportTypeTag

export const EconomicStatementColumns: ColumnsType<EconomicStatementDto> = [
  {
    title: '',
    dataIndex: 'state',
    width: 40,
    render: (_, record) => <GeneratedIcon state={record.state} />
  },
  {
    title: 'Periodo',
    key: 'period',
    width: 180,
    render: (_, record) => (
      <EconomySemesterTag year={record.year} semester={record.semester} />
    )
  },
  {
    title: 'Tipo',
    key: 'reportKind',
    width: 100,
    render: (_, record) => <ReportKindTag kind={record.reportKind} />
  },
  {
    title: 'Fatture',
    key: 'onlyPaid',
    width: 90,
    render: (_, record) =>
      record.onlyPaid ? <Tag>Pagate</Tag> : <Tag>Accettate</Tag>
  },
  {
    title: 'Nome',
    dataIndex: 'title',
    render: (_, record) => (
      <NameOrError title={record.title} state={record.state} />
    )
  },
  {
    title: 'Data',
    dataIndex: 'createdAt',
    width: 180,
    render: (_, record) => (
      <ISODate date={record.createdAt} format="DD/MM/YYYY HH:mm:ss" />
    )
  },
  {
    title: 'Utente',
    key: 'authorName',
    width: 200,
    render: (_, record) => record.author.name
  },
  {
    title: '',
    dataIndex: 'filePath',
    align: 'right',
    width: 150,
    render: (_, record) =>
      record.state === QueueTaskState.Completed ? (
        <AnchorAPILink href={getEconomicStatementDownloadLink(record.id)}>
          <DownloadOutlined /> Scarica Report
        </AnchorAPILink>
      ) : (
        <Text type="secondary">
          <DownloadOutlined /> Scarica Report
        </Text>
      )
  }
];
