import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { SDIChargeabilityType } from 'common/schema/invoice/VATSummaryTypes';

/**
 * Rappresentazione DTO della classe InvoiceVATSummary definita in: src/server/schema/invoice/invoice-vat-summary/InvoiceVATSummary.entity.ts
 * Hash: 3ea47fc56bf6f737e7bb92a0aaa11132
 */
@ValidationSchema(() => InvoiceVATSummarySchema)
export class InvoiceVATSummaryDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: Number })
  invoiceId!: number;
  @ApiProperty({ type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Aliquota IVA' })
  @TypeTransformDecimal()
  vatRate!: Decimal;
  @ApiProperty({ type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Imposta' })
  @TypeTransformDecimal()
  vatAmount!: Decimal;
  @ApiProperty({ type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Importo Imponibile' })
  @TypeTransformDecimal()
  vatTaxableAmount!: Decimal;
  @ApiProperty({ required: false, enum: ['I', 'D', 'S'], description: 'Esigibilità IVA' })
  chargeability?: SDIChargeabilityType | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceVATSummaryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceVATSummaryDto ? values : plainToClass(InvoiceVATSummaryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await InvoiceVATSummarySchema.validate(classToPlain(this), options);
    return new InvoiceVATSummaryDto(validated);
  }
}

/** Interfaccia simmetrica al DTO InvoiceVATSummaryDto */
export type IInvoiceVATSummaryType = IDto<InvoiceVATSummaryDto>;

/**
 * DTO Paginato della classe InvoiceVATSummary
 */
export class PaginatedInvoiceVATSummaryDto {
  @ApiProperty({ type: [InvoiceVATSummaryDto] })
  @Type(() => InvoiceVATSummaryDto)
  items!: InvoiceVATSummaryDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const InvoiceVATSummarySchema = yup
  .object({
    id: yup.number(),
    invoiceId: yup.number(),
    vatRate: yup.mixed().required().needsAction('edit.vat-summary-and-lines').label('Aliquota IVA'),
    vatAmount: yup.mixed().required().needsAction('edit.vat-summary-and-lines').label('Imposta'),
    vatTaxableAmount: yup.mixed().required().needsAction('edit.vat-summary-and-lines').label('Importo Imponibile'),
    chargeability: yup.string().oneOfEnum(SDIChargeabilityType).nullable().needsAction('edit.lines.accounting').label('Esigibilità IVA')
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceVATSummarySchema" })
  .required();
