import * as React from 'react';
import clx from 'classnames';
import { Tag, Tooltip, TooltipProps } from 'antd';
import { TagProps } from 'antd/lib/tag';
import './DoubleTag.scss';

export interface TooltipTagProps extends TagProps {
  tooltipMessage?: string;
}

export interface DoubleTagProps {
  left: TooltipTagProps;
  right: TooltipTagProps;
}

/**
 * Tag per mostrare un doppio stato
 */
export function DoubleTag(props: DoubleTagProps) {
  const {
    left: { tooltipMessage: leftTooltipMessage, ...leftProps },
    right: { tooltipMessage: rightTooltipMessage, ...rightProps }
  } = props;

  return (
    <>
      <Tooltip title={leftTooltipMessage}>
        <Tag
          className={clx('double-tag', {
            'double-tag-left': rightProps.visible
          })}
          {...leftProps}
        />
      </Tooltip>
      <Tooltip title={rightTooltipMessage}>
        <Tag
          className={clx('double-tag', {
            'double-tag-right': leftProps.visible
          })}
          {...rightProps}
        />
      </Tooltip>
    </>
  );
}
