import * as React from 'react';
import { Section } from 'client/ui/section/Section';
import { ArrowRightOutlined, MailOutlined } from '@ant-design/icons';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import { Button, Table } from 'antd';
import { Link } from 'react-router-dom';
import { ShipmentOriginalDetailColumns } from 'client/components/schema/shipment/table/ShipmentOriginalDetailColumns';

export interface IShipmentOriginalSectionProps {
  originalShipment: ShipmentDto | null | undefined;
}

/**
 * Sezione Dati Spedizione Originale della Pagina delle Spedizioni
 */
export function ShipmentOriginalSection(props: IShipmentOriginalSectionProps) {
  const { originalShipment } = props;

  if (!originalShipment) return null;

  return (
    <Section icon={<MailOutlined />} title="Spedizione Orignale">
      <Table
        columns={[
          ...ShipmentOriginalDetailColumns,
          {
            title: '',
            dataIndex: 'azioni',
            align: 'right',
            width: 100,
            render: (_, record) => (
              <Link to={`/shipping/all/${originalShipment.id}`}>
                Vai <ArrowRightOutlined />
              </Link>
            )
          }
        ]}
        footer={() => 'Oggetto: ' + originalShipment.subject}
        rowKey="id"
        dataSource={[originalShipment]}
        size="middle"
        pagination={false}
      />
    </Section>
  );
}
