import { LinkOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import React, { useCallback, useState } from 'react';
import { MandateApi } from '../MandateApi';

interface IMandateSiabImportButtonProps {}

export function MandateSiabImportButton(props: IMandateSiabImportButtonProps) {
  const [siabImport, siabImportStatus] = useApiMutation(
    MandateApi.siab.import,
    { invalidates: [MandateApi.list] }
  );

  const [visible, setVisible] = useState(false);

  const handleOkClick = useCallback(async () => {
    try {
      await siabImport({});
      message.success('Mandati aggiornati con successo');
      setVisible(false);
    } catch (e) {
      console.error(e);
      message.error("Errore durante l'aggiornamento dei mandati");
    }
  }, [setVisible]);

  return (
    <>
      <Button
        icon={<RetweetOutlined />}
        type="default"
        onClick={() => setVisible(true)}
      >
        Aggiorna Mandati
      </Button>
      <ModalDialog
        size="small"
        title="Vuoi aggiornare i mandati?"
        visible={visible}
        onCancel={() => setVisible(false)}
        maskClosable={!siabImportStatus.loading}
        footer={[
          <Button
            key="back"
            onClick={() => setVisible(false)}
            disabled={siabImportStatus.loading}
          >
            Annulla
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={async () => await handleOkClick()}
            loading={siabImportStatus.loading}
          >
            Aggiorna
          </Button>
        ]}
      >
        I mandati verrano aggiornati in base ai dati ricevuti da SIAB.
      </ModalDialog>
    </>
  );
}
