import { Tag, TreeSelect } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { TreeSelectInputProps } from 'client/ui/form/input/TreeSelectInput';
import { AsyncText } from 'client/ui/form/text/AsyncText';
import { EconomicEntryDto } from 'common/dto/generated/EconomicEntryDto';
import {
  createTreeFromEconomicEntries,
  ICreateEconomicEntriesTreeOptions,
  IEconomicEntryNode,
  IEconomicEntryTree
} from 'common/schema/economic-entry/createTreeFromEconomicEntries';
import { FormikContextType, useField, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { EconomicEntryApi } from './EconomicEntryApi';

export interface IEconomicEntrySelectInputProps<T extends object>
  extends Omit<TreeSelectInputProps<T>, 'name' | 'multiple'> {
  name: string;
  /** In caso siano già caricate le liste */
  initialTree?: IEconomicEntryTree;
  /** Opzioni per l'albero */
  treeOptions?: ICreateEconomicEntriesTreeOptions;
  /** Alla selezione */
  onAfterSelect?: (
    formik: FormikContextType<T>,
    node: IEconomicEntryNode
  ) => any;
}

/**
 * Permette la selezione di una lista di Conti Economici
 * @param props
 */
export function EconomicEntrySelectInput<T extends object>(
  props: IEconomicEntrySelectInputProps<T>
) {
  const {
    name,
    initialTree,
    treeOptions,
    onAfterSelect,
    ...otherProps
  } = props;

  const formik = useFormikContext<T>();
  const [field, , helper] = useField<string | null>(name);

  const [tree, setTree] = useState(initialTree);
  const { response, loading } = useApiQuery(EconomicEntryApi.list, {
    data: {},
    onLoad: response => {
      setTree(createTreeFromEconomicEntries(response.data.items, treeOptions));
    }
  });

  const handleOnChange = useCallback(
    (value: string) => {
      helper.setValue(value);
      props.onAfterSelect?.(formik, tree?.getNode(value)!);
    },
    [helper, tree]
  );

  return (
    <TreeSelect
      loading={loading}
      {...otherProps}
      showSearch
      treeNodeFilterProp="searchText"
      placeholder={props.placeholder ?? 'Seleziona i conti economici'}
      showArrow={true}
      onClear={() => helper.setValue(null)}
      treeData={tree?.roots}
      value={field.value ?? undefined}
      onChange={handleOnChange}
    />
  );
}

// TODO/MED Fa una query per ogni riga di fattura, dobbiamo modificarlo per quel caso
EconomicEntrySelectInput.renderFieldValue = (
  value: number | null,
  props: IEconomicEntrySelectInputProps<any>
) => {
  if (!value) return '';

  return (
    <Tag
      children={
        <AsyncText
          query={{
            apiFn: EconomicEntryApi.find,
            options: { data: { id: value } }
          }}
          responseTransform={(entry: EconomicEntryDto) => {
            return `${entry.code} - ${entry.title}`;
          }}
        />
      }
    />
  );
};
