import { Tag, TagProps } from 'antd';
import { StateTag } from 'client/ui/state/StateTag';
import { AccountingLineStateLabel } from 'common/schema/accounting-line/AccountingLineLabels';
import { AccountingLineState } from 'common/schema/accounting-line/AccountingLineTypes';
import * as React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

export interface IAccountingLineStateTagProps {
  state: AccountingLineState;
}

function stateToProps(state: AccountingLineState): TagProps {
  switch (state) {
    case AccountingLineState.NotPayable:
      return { color: 'default', children: AccountingLineStateLabel[state] };
    case AccountingLineState.ToShip:
      return { color: 'purple', children: AccountingLineStateLabel[state] };
    case AccountingLineState.ToPay:
      return { color: 'blue', children: AccountingLineStateLabel[state] };
    case AccountingLineState.Paid:
      return { color: 'green', children: AccountingLineStateLabel[state] };
  }
  assertNever(state);
}

/**
 * Tag di stato delle linee di contabilizzazione
 */
export function AccountingLineStateTag(props: IAccountingLineStateTagProps) {
  if (!props.state) return null;
  return <StateTag {...stateToProps(props.state)} />;
}
