import {
  ArrowRightOutlined,
  FullscreenOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';
import { ISODate } from 'client/ui/display/date/ISODate';
import {
  IInvoiceLineType,
  InvoiceLineDto
} from 'common/dto/generated/InvoiceLineDto';
import Decimal from 'decimal.js-light';
import React from 'react';

export const InvoiceLineColumns: ColumnsType<InvoiceLineDto> = [
  {
    title: 'N.',
    dataIndex: 'number'
  },
  {
    title: 'Descrizione',
    dataIndex: 'description'
  },
  // {
  //   title: 'Prezzo Unit.',
  //   dataIndex: 'priceUnit',
  //   render: (price: Decimal) => <CurrencyRender value={price} />
  // },
  {
    title: 'Totale',
    dataIndex: 'priceTotal',
    align: 'right',
    render: (price: Decimal) => <CurrencyRender value={price} />
  },
  {
    title: 'IVA',
    dataIndex: 'vatRate',
    width: 60,
    render: (vatRate: Decimal) => `${vatRate.toString()}%`
  },
  {
    title: 'Periodo',
    dataIndex: 'periodStartDate',
    width: 220,
    render: (_, record) => (
      <span>
        <ISODate date={record.periodStartDate} />{' '}
        {record.periodEndDate && <ArrowRightOutlined />}{' '}
        <ISODate date={record.periodEndDate} />
      </span>
    )
  },
  {
    title: 'Conto',
    dataIndex: 'economicEntry',
    render: (_, record) =>
      record.economicEntry ? <Tag>{record.economicEntry.code}</Tag> : null
  },
  {
    dataIndex: 'invalidEconomicEntryCode',
    width: 20,
    render: (_, record) =>
      record.invalidEconomicEntryCode ? (
        <Tooltip
          title={`Il riferimento amministrazione ${record.economicEntryCode} non corrisponde a nessun codice di conto economico`}
        >
          <WarningOutlined style={{ color: '#faad14' }} />
        </Tooltip>
      ) : null
  }
];
