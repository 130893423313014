import { LinkOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import Text from 'antd/lib/typography/Text';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { BottomDrawer } from 'client/ui/drawer/BottomDrawer';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { AnchorLinkButton } from 'client/ui/link/AnchorLinkButton';
import { MandateDto } from 'common/dto/generated/MandateDto';
import {
  MandateQueryDto,
  MandateQuerySchema
} from 'common/dto/query/MandateQueryDto';
import { Formik, useField } from 'formik';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { MandateApi } from '../../mandate/MandateApi';
import { MandatesColumns } from '../../mandate/table/MandatesColumns';

interface IMandateDrawerProps {
  shipmentId?: number | null;
  isOpen: boolean;
  onClose: () => void;
  onSelect: (mandate: MandateDto) => void;
}

/**
 * Drawer per la selezione dei mandati da collegare alle spedizioni Ubbrac
 */
export function MandateDrawer(props: IMandateDrawerProps) {
  const { isOpen, onClose } = props;
  const [, , mandateHelper] = useField<MandateDto>('mandate');
  const [, , idHelper] = useField<number>('mandateId');

  const baseQuery = {
    pageNumber: 1,
    pageSize: 5,
    notShipped: true,
    excludeShipmentId: props.shipmentId
  };

  // Ricerca mandati
  const [query, setQuery] = useState(new MandateQueryDto(baseQuery));

  const { response, loading, error } = useApiQuery(MandateApi.list, {
    data: { query }
  });
  const mandates = response?.data.items;

  // Aggiornamento
  const handleSelect = useCallback(
    (mandate: MandateDto) => {
      props.onSelect(mandate);
      idHelper.setValue(mandate.id);
      onClose();
    },
    [onClose]
  );

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const formContext = useFormikFormContext();
  if (!formContext.editable) return null;

  const columns: ColumnsType<MandateDto> = [
    ...MandatesColumns,
    {
      title: '',
      dataIndex: 'azioni',
      align: 'right',
      render: (_: any, record: MandateDto) => (
        <Button
          type="link"
          size="small"
          icon={<LinkOutlined />}
          onClick={() => handleSelect(record)}
        >
          Collega
        </Button>
      )
    }
  ];

  return (
    <>
      <Formik
        initialValues={query}
        validate={schemaToValidator(MandateQuerySchema)}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async rawValues => {
          const values = await rawValues.validate();

          setQuery(
            new MandateQueryDto({
              ...query,
              ...values
            })
          );
        }}
        onReset={() => {
          setQuery(new MandateQueryDto(baseQuery));
        }}
      >
        <BottomDrawer
          title="Associazione del mandato alla spedizione UBRRAC"
          onClose={handleCancel}
          visible={isOpen}
          key="bottom"
          footer={
            <Space style={{ float: 'right' }}>
              <Button key="back" onClick={handleCancel}>
                Annulla
              </Button>
            </Space>
          }
          sider={
            <FormikForm editable={true} helpInTooltips showVisualFeedback>
              <Space direction="vertical">
                <Text strong>Cerca Mandato</Text>
                <FormFieldsContainer>
                  <FormFieldItem
                    labelWidth="width-70"
                    component={NumberInput}
                    label="Numero"
                    name="mandateNumber"
                    placeholder="Numero mandato"
                  />
                  <FormFieldItem
                    labelWidth="width-70"
                    component={NumberInput}
                    label="Anno"
                    name="mandateYear"
                    placeholder="Anno mandato"
                  />
                </FormFieldsContainer>
                <Space style={{ float: 'right' }}>
                  <Button htmlType="reset">Reset</Button>
                  <FormikSendButton icon={<SearchOutlined />} type="primary">
                    Cerca
                  </FormikSendButton>
                </Space>
              </Space>
            </FormikForm>
          }
        >
          <Table
            rowKey="id"
            columns={columns}
            dataSource={mandates}
            size="small"
            onChange={(pagination, filters, sorter) => {
              setQuery(
                new MandateQueryDto({
                  ...query,
                  pageNumber: pagination.current ?? query.pageNumber,
                  pageSize: pagination.pageSize ?? query.pageSize
                })
              );
            }}
            pagination={{
              size: 'small',
              position: ['bottomRight'],
              showSizeChanger: true,
              total: response?.data.meta.total,
              pageSize: query.pageSize,
              current: query.pageNumber,
              pageSizeOptions: ['5', '10', '20', '30', '40']
            }}
          />
        </BottomDrawer>
      </Formik>
    </>
  );
}
