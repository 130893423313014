import * as React from 'react';
import { Table, Result, Card } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { ArrowRightOutlined, TableOutlined } from '@ant-design/icons';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ISODate } from 'client/ui/display/date/ISODate';
import { EconomicSimulationItemColumns } from 'client/components/schema/economy/simulation/table/EconomicSimulationItemColumns';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { EconomicSimulationApi } from 'client/components/schema/economy/simulation/EconomicSimulationApi';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { useState } from 'react';
import { EconomicStatementSimulationItemsQueryDto } from 'common/dto/query/EconomicStatementSimulationItemsQueryDto';
import { EconomySemesterTag } from 'client/components/schema/economy/simulation/semester/EconomySemesterTag';
import { Section } from 'client/ui/section/Section';
import { SimulationStatistic } from 'client/components/schema/economy/statement/view/SimulationStatistic';
import { QueueTaskState } from 'common/schema/queue-task/QueueTaskState';
import { TableSticky } from 'client/ui/table/TableSticky';
import { SorterResult } from 'antd/lib/table/interface';
import { EconomicStatementSimulationDto } from 'common/dto/generated/EconomicStatementSimulationDto';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { EconomicStatementSimulationItemDto } from 'common/dto/generated/EconomicStatementSimulationItemDto';
import { getDateRange, getFilter } from 'client/ui/table/TableColumnFilter';

export interface Params {
  id: string;
}

export interface EconomicSimulationPageProps
  extends RouteComponentProps<Params> {}

/**
 * Pagina della singola simulazione di Conto Economico
 * contiene l'elenco delle fatture che hanno delle linee di fattura
 * con dati necessari al conto economico mancanti
 */
export function EconomicSimulationPage(props: EconomicSimulationPageProps) {
  const id = parseInt(props.match.params.id, 10);

  // Gestione API
  const { response, error, loading } = useApiQuery(EconomicSimulationApi.find, {
    data: { id: id! }
  });
  const simulation = response?.data;

  const [search, setSearch] = useState(false);

  // Gestione API elenco items
  const [query, setQuery] = useState(
    new EconomicStatementSimulationItemsQueryDto({
      pageNumber: 1,
      pageSize: 50
    })
  );
  const itemsResult = useApiQuery(EconomicSimulationApi.items.list, {
    data: { id: id!, query }
  });

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/economy/economic-simulations',
    title: `Conto Economico`
  });
  useBreadcrumbItem({
    path: '/economy/economic-simulations',
    title: 'Simulazioni di Conto Economico'
  });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: simulation?.title ?? '...',
    menuKey: 'economy',
    sideMenuKey: 'economic-simulations'
  });

  if (error || itemsResult.error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare il dettaglio della simulazione del conto economico"
        error={error || itemsResult.error}
      />
    );
  }

  if (!simulation || !itemsResult.response?.data) return <LoadingPage />;
  // console.log('simulazione:', simulation);
  // console.log('itemsResult:', itemsResult.response?.data);
  // state === QueueTaskState.Failed
  return (
    <CorePageLayout>
      <PageHeading
        title={
          <PageHeadingTitle>
            Anomalie Simulazione del <ISODate date={simulation.createdAt} />
          </PageHeadingTitle>
        }
        tags={
          <EconomySemesterTag
            year={simulation.year}
            semester={simulation.semester}
          />
        }
        extra={
          <SimulationStatistic
            total={simulation.totalLines}
            repaired={simulation.repairedLinesCount}
            warning={itemsResult.response.data.meta.total}
          />
        }
        breadcrumbRender={() => <BreadcrumbsContainer />}
      />
      {simulation.state === QueueTaskState.Failed ? (
        <PageContent>
          <Card>
            <Result
              status="error"
              title="Errore nella generazione del Report."
              subTitle="Si è verificato un errore durante la generazione della simulazione di conto economico."
            />
          </Card>
        </PageContent>
      ) : itemsResult.response.data.meta.total > 0 || search ? (
        <PageContent fixedWidht>
          {/* <Section icon={<TableOutlined />} title="Elenco Anomalie"> */}
          <TableSticky<EconomicStatementSimulationItemDto>
            rowKey="id"
            columns={[
              ...EconomicSimulationItemColumns,
              {
                title: '',
                align: 'right',
                width: 80,
                render: (_, record) => (
                  <Link to={`/invoicing/invoices/all/${record.invoiceId}`}>
                    Fattura <ArrowRightOutlined />
                  </Link>
                )
              }
            ]}
            dataSource={itemsResult.response.data.items}
            size="small"
            onChange={(pagination, filter, sorter) => {
              const {
                field,
                order
              } = sorter as SorterResult<EconomicStatementSimulationItemDto>;

              const invoiceNumber = getFilter(filter, 'invoiceNumber');
              const [protocolNumber, protocolYear] = filter.protocol
                ? filter.protocol
                : [];
              const invoiceDateRange = getDateRange(filter.invoiceDate);
              const supplierName = getFilter(filter, 'supplierName');
              const adminReferentName = getFilter(filter, 'adminReferentName');
              const cigCode = getFilter(filter, 'cigCode');

              setSearch(true);
              setQuery(
                new EconomicStatementSimulationItemsQueryDto({
                  invoiceNumber: invoiceNumber?.toString(),
                  protocolNumber: protocolNumber?.toString(),
                  protocolYear: protocolYear?.toString(),
                  fromDate: invoiceDateRange.from,
                  toDate: invoiceDateRange.to,
                  supplierName: supplierName?.toString(),
                  adminReferentName: adminReferentName?.toString(),
                  cigCode: cigCode?.toString(),
                  pageNumber: pagination.current ?? query.pageNumber,
                  pageSize: pagination.pageSize ?? query.pageSize,
                  orderBy: order ? field?.toString() : 'id',
                  orderByDirection: order
                    ? ColumnSortDirections[order]
                    : ColumnSortDirections.descend
                })
              );
            }}
            pagination={{
              size: 'small',
              position: ['bottomRight'],
              showSizeChanger: true,
              total: itemsResult.response.data.meta.total,
              pageSize: query.pageSize,
              current: query.pageNumber,
              pageSizeOptions: ['5', '10', '20', '30', '40']
            }}
          />
          {/* </Section> */}
        </PageContent>
      ) : (
        <PageContent>
          <Card>
            <Result
              status="success"
              title="Nessuna anomalia riscontrata!"
              subTitle="Tutte le fatture analizzate sono complete dei dati necessari alla generazione del conto economico."
            />
          </Card>
        </PageContent>
      )}
    </CorePageLayout>
  );
}
