import React from 'react';
import clx from 'classnames';
import { Menu } from 'antd';
const { SubMenu } = Menu;
import { useThemeConfig } from 'client/components/schema/config/ClientConfigModule';
import { BarsOutlined, TableOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useCurrentSideMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { usePermissions } from 'client/components/auth/AuthModule';

export interface EconomySideMenuProps {
  classNames?: string;
}

/**
 * Menu laterale della sezione Conto Ecnomico.
 */
export function EconomySideMenu(props: EconomySideMenuProps) {
  const { options } = useThemeConfig();
  const currentPathKey = useCurrentSideMenuKey();
  const permissions = usePermissions();

  return (
    <Menu
      className={props.classNames}
      mode="inline"
      defaultSelectedKeys={['economic-entries']}
      selectedKeys={currentPathKey}
      defaultOpenKeys={['report']}
    >
      <SubMenu key="report" title="Piano dei Conti" icon={<TableOutlined />}>
        <Menu.Item key="economic-simulations">
          <Link to="/economy/economic-simulations">Simulazioni</Link>
        </Menu.Item>
        {permissions.has('economy.read') && (
          <Menu.Item key="economic-statements">
            <Link to="/economy/economic-statements">Conto Economico</Link>
          </Menu.Item>
        )}
      </SubMenu>
      {permissions.has('economic-entry.write') && (
        <Menu.Item key="economic-entries" icon={<BarsOutlined />}>
          <Link to="/economy/economic-entries">Gestione Codici</Link>
        </Menu.Item>
      )}
    </Menu>
  );
}
