import * as React from 'react';
import { Section } from 'client/ui/section/Section';
import { MailOutlined, NotificationOutlined } from '@ant-design/icons';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { shipmentPriorityOptions } from 'common/schema/shipment/ShipmentPriorityOptions';
import { ShipmentCheckSelect } from './component/ShipmentCheckSelect';
import { ShipmentDescriptionItem } from './ShipmentSubjectItem';

export interface ShipmentGeneralSectionProps {}

/**
 * Sezione Dati Spedizione della Pagina delle Spedizioni
 */
export function ShipmentGeneralSection(props: ShipmentGeneralSectionProps) {
  const labelWidth: ILabelWidth = 'width-140';

  return (
    <Section icon={<MailOutlined />} title="Dati Spedizione" hasPadding>
      {/* DESCRIZIONE SPEDIZIONE */}
      <FormFieldsContainer columns={1}>
        <FormFieldItem
          label="Oggetto"
          name={'subject'}
          component={TextAreaInput}
          placeholder="Oggetto"
          rows={3}
        />
        <ShipmentDescriptionItem />
      </FormFieldsContainer>
      <FormFieldsContainer columns={2}>
        <FormFieldItem
          label="Destinatario"
          name={'recipient'}
          component={TextInput}
          placeholder="Destinatario"
        />
        <FormFieldItem
          label="Priorità"
          name={'priority'}
          component={SelectInput}
          placeholder="Seleziona..."
          options={shipmentPriorityOptions}
        />
        <FormFieldItem
          label="Classificazione"
          name={'classification'}
          component={TextInput}
        />
      </FormFieldsContainer>
      {/* PISTE DI CONTROLLO */}
      <ShipmentCheckSelect labelWidth={labelWidth} />
    </Section>
  );
}
