import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
const { Text } = Typography;
import { ArrowRightOutlined } from '@ant-design/icons';
import { ContractDto } from 'common/dto/generated/ContractDto';

interface ContractLinkProps {
  contract?: ContractDto | null | undefined;
}

export function ContractLink(props: ContractLinkProps) {
  const id = props.contract?.id;
  const number = props.contract?.number;

  if (!id) {
    return <Text type="danger">{number ?? '--'}</Text>;
  }

  return (
    <Link to={`/contracts/all/${id}`}>
      {number ? number : `#${id}`} <ArrowRightOutlined />
    </Link>
  );
}
