import {
  DisconnectOutlined,
  IdcardOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Button, Input, message, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import {
  SupplierEditHandle,
  SupplierEditModal
} from 'client/components/schema/supplier/edit/SupplierEditModal';
import { SupplierApi } from 'client/components/schema/supplier/SupplierApi';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { Section } from 'client/ui/section/Section';
import { SectionButton } from 'client/ui/section/SectionButton';
import { SupplierDto } from 'common/dto/generated/SupplierDto';
import { SupplierQueryDto } from 'common/dto/query/SupplierQueryDto';
import { useFormikContext } from 'formik';
import React, { useCallback, useRef, useState } from 'react';

export interface SuppliersTableProps {}

export function ContractSuppliersSection(props: SuppliersTableProps) {
  const editor = useRelationEditor<SupplierDto>('suppliers');
  const inputSelectName = 'supplierSelectInput';

  const [selected, setSelected] = useState<SupplierDto | null>(null);
  const formContext = useFormikFormContext();
  const formik = useFormikContext();
  const editModalRef = useRef<SupplierEditHandle>(null);

  const editable = formContext.editable;

  const isAlreadySelected = useCallback(
    (supplierId: number) => {
      return (
        editor.items.findIndex(supplier => supplier.id === supplierId) >= 0
      );
    },
    [editor.items]
  );

  const handleTitle = useCallback(() => {
    return (
      <Input.Group compact style={{ display: 'flex' }}>
        <AsyncSelectInput
          style={{ flex: '1 1 auto' }}
          name={inputSelectName}
          placeholder="Seleziona Contraente da aggiungere"
          size="small"
          query={{
            apiFn: SupplierApi.list,
            options: (formik, search) => ({
              data: {
                query: new SupplierQueryDto({
                  pageNumber: 1,
                  pageSize: 20,
                  fullname: search
                })
              }
            })
          }}
          dropdownMatchSelectWidth={false}
          showSearch
          refreshOnSearch
          allowClear
          responseTransform={data => data.items}
          responseMeta={data => ({
            shown: data.items.length,
            total: data.meta.total
          })}
          optionTransform={(supplier: SupplierDto) => ({
            label: supplier.getName(),
            value: supplier.id
          })}
          onAfterSelect={option => {
            setSelected(option);
          }}
        />

        <Button
          key="1"
          icon={<PlusOutlined />}
          // type="primary"
          style={{ flex: '0 1 100px' }}
          size="small"
          disabled={!selected}
          onClick={() => {
            if (!selected) return;
            formik.setFieldValue(inputSelectName, null);
            setSelected(null);
            if (isAlreadySelected(selected.id)) {
              message.info('Contraente già associato al contratto.');
            } else {
              editor.insert(0, selected);
            }
          }}
        >
          Aggiungi
        </Button>
      </Input.Group>
    );
  }, [selected, editor]);

  const supplierColumns: ColumnsType<SupplierDto> = [
    {
      title: 'Denominazione',
      render: (value, record) => record.getName()
    },
    {
      title: 'Partita IVA',
      render: (value, record) => record.getFiscalId()
    },
    {
      title: 'Codice Fiscale',
      render: (value, record) => record.getFiscalNumber()
    },
    {
      title: 'Indirizzo',
      dataIndex: 'hqAddress'
    },
    {
      title: 'Comune',
      render: (value, record) => record.getCityWithProvince()
    },
    // {
    //   title: 'CAP',
    //   dataIndex: 'hqZip'
    // },
    // {
    //   title: 'Nazione',
    //   dataIndex: 'hqCountry'
    // },
    // TODO: Inserire pulsante visualizzazione dettaglio contraente
    ...(editable
      ? [
          {
            title: 'Azioni',
            render: (value: any, record: SupplierDto) => (
              <Button
                icon={<DisconnectOutlined />}
                children="Scollega"
                size="small"
                type="link"
                disabled={!editable}
                danger
                onClick={() => editor.remove(record)}
              />
            )
          }
        ]
      : [])
  ];

  return (
    <Section
      icon={<IdcardOutlined />}
      title="Contraenti"
      extra={
        editable && (
          <>
            <SectionButton
              key="add"
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={e => {
                editModalRef.current?.edit(new SupplierDto());
                e.stopPropagation();
              }}
            >
              Nuovo Contraente
            </SectionButton>
          </>
        )
      }
    >
      <Table
        columns={supplierColumns}
        rowKey="id"
        dataSource={editor.items}
        size="middle"
        pagination={false}
        // pagination={{
        //   size: 'small',
        //   position: ['bottomRight'],
        //   showSizeChanger: true,
        //   pageSizeOptions: ['5', '10', '20', '30', '40']
        // }}
        title={editable ? handleTitle : undefined}
      />
      <SupplierEditModal
        editable={editable}
        ref={editModalRef}
        onSelect={selected => {
          editor.add(selected);
        }}
      />
    </Section>
  );
}
