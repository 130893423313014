import { ColumnsType } from 'antd/lib/table';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceColumns } from './InvoiceColumns';

export const ContractInvoiceColumns: ColumnsType<InvoiceDto> = [
  InvoiceColumns.state,
  InvoiceColumns.supplierName,
  InvoiceColumns.supplierFiscal,
  InvoiceColumns.invoiceTypeCode,
  InvoiceColumns.totalAmount
];
