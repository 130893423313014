import React from 'react';
import { Model003Action } from 'server/schema/pcc/model003/Model003Types';
import { assertNever } from 'server/utils/typings/assertNever';
import {
  IPccExport003ButtonProps,
  PccExport003Button
} from './PccExport003Button';

export interface IDynamicPccActionButtonProps
  extends Omit<IPccExport003ButtonProps, 'text'> {}

function getPccActionButtonText(action: Model003Action) {
  switch (action) {
    case Model003Action.CO:
      return 'Genera CSV Contabilizzazione';
    case Model003Action.CP:
      return 'Genera CSV Pagamento';
    case Model003Action.CS:
      return 'Genera CSV Scadenza';
    default:
      return assertNever(action);
  }
}

/**
 * Componente che gestisce il bottone per l'esportazione delle linee di fattura
 * in base all'azione richiesta.
 * (Model003)
 */
export function DynamicPccExport003Button(props: IDynamicPccActionButtonProps) {
  if (!props.action) {
    return null;
  }

  const text = getPccActionButtonText(props.action);

  return <PccExport003Button {...props} text={text} />;
}
