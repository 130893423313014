import { Col, Descriptions, Row, Statistic } from 'antd';
import { DescriptionsItemProps } from 'antd/lib/descriptions/Item';
import Decimal from 'decimal.js-light';
import * as React from 'react';
import './PageHeadingSummary.scss';
import { renderDecimalValue } from './renderDecimalValue';

export interface SummaryItemProps {
  label: React.ReactNode;
  value?: React.ReactNode;
  labelStyle?: DescriptionsItemProps['labelStyle'];
  /**
   * Numero di colonne che deve occupare l'elemento
   */
  span?: number;
}
export interface SummaryValueProps {
  label: React.ReactNode;
  value?: string | number | Decimal | null;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

export interface PageHeadingSummaryProps {
  /**
   * Numero colonne del Sommario (<Descriptions /> di Ant)
   * di default 2
   */
  column?: number;
  /**
   * Lista di elementi del Sommario (<Descriptions.Item /> di Ant)
   * con label e valore (eventualmente span)
   */
  items: SummaryItemProps[];
  /**
   * Lista di elementi con valori (<Statistic /> di Ant)
   * posizionati a destra in evidenza
   */
  values?: SummaryValueProps[];
}

/**
 * Permette di aggiungere un Sommario all'intestazione
 * della pagina per mostrare ulteriori dati riassuntivi dell'elemento,
 * posizionato sotto il titolo pagina e i pulsanti azione,
 *
 * children di <PageHeading />
 */
export function PageHeadingSummary(props: PageHeadingSummaryProps) {
  return (
    <Row align="top" wrap={false} className="page-heading-summary">
      <Col flex="auto">
        <Descriptions size="small" column={props.column ?? 2}>
          {props.items.map((item, y) => (
            <Descriptions.Item
              key={y}
              label={item.label}
              span={item.span ?? 1}
              children={item.value}
            />
          ))}
        </Descriptions>
      </Col>
      {props.values && (
        <Col className="page-heading-summary-values">
          {props.values.map((value, i) => (
            <Statistic
              key={i}
              title={value.label}
              value={renderDecimalValue(value.value)}
              prefix={value.prefix}
              suffix={value.suffix}
              decimalSeparator=","
              groupSeparator="."
            />
          ))}
        </Col>
      )}
    </Row>
  );
}
