import { apiClient } from 'client/core/network/apiClient';
import { PccExportDto } from 'common/dto/generated/PccExportDto';
import { Model002CreateQueryDto } from 'common/dto/query/Model002CreateQueryDto';
import { Model003CreateQueryDto } from 'common/dto/query/Model003CreateQueryDto';

export const PccApi = {
  model003: {
    create: (opts: { input: Model003CreateQueryDto }) =>
      apiClient
        .requestWithResponseType(PccExportDto)
        .post(`/pcc/model003`, opts.input)
  },

  model002: {
    create: (opts: { input: Model002CreateQueryDto }) =>
      apiClient
        .requestWithResponseType(PccExportDto)
        .post(`/pcc/model002`, opts.input)
  }
};
