import { apiClient } from 'client/core/network/apiClient';
import {
  PaginatedSupplierDto,
  SupplierDto
} from 'common/dto/generated/SupplierDto';
import { SupplierQueryDto } from 'common/dto/query/SupplierQueryDto';

export const SupplierApi = {
  list: (opts: { query: SupplierQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedSupplierDto)
      .get(`/suppliers`, { params: opts.query }),
  find: (opts: { id: number }) =>
    apiClient.requestWithResponseType(SupplierDto).get(`/suppliers/${opts.id}`),
  create: (opts: { input: SupplierDto }) =>
    apiClient
      .requestWithResponseType(SupplierDto)
      .post(`/suppliers`, opts.input),
  update: (opts: { id: number; input: SupplierDto }) =>
    apiClient
      .requestWithResponseType(SupplierDto)
      .put(`/suppliers/${opts.id}`, opts.input)
};
