import { FolderOpenOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { ContractAttachmentsColumns } from 'client/components/schema/contract/table/ContractAttachmentsColumns';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { Section } from 'client/ui/section/Section';
import { ContractAttachmentDto } from 'common/dto/generated/ContractAttachmentDto';
import { ContractAttachmentQueryDto } from 'common/dto/query/ContractAttachmentQueryDto';
import React, { useState } from 'react';

export interface IContractAttachmentsSectionProps {
  contractId: number;
}

export function ContractAttachmentsSection(
  props: IContractAttachmentsSectionProps
) {
  const { contractId } = props;

  const [query, setQuery] = useState<ContractAttachmentQueryDto>(
    new ContractAttachmentQueryDto({ pageSize: 30 })
  );

  const { response, loading } = useApiQuery(ContractApi.attachments.list, {
    skip: contractId == null,
    data: {
      contractId: contractId!,
      query: query
    }
  });

  const attachments = response?.data.items ?? [];
  const total = response?.data.meta.total ?? 0;

  return (
    <Section icon={<FolderOpenOutlined />} title="Allegati">
      <Table
        rowKey="id"
        loading={loading}
        columns={ContractAttachmentsColumns}
        // scroll={{ x: true }}
        dataSource={attachments}
        size="middle"
        onChange={(pagination, filters, sorter) => {
          setQuery(
            new ContractAttachmentQueryDto({
              ...query,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize
            })
          );
        }}
        pagination={
          total <= query.pageSize
            ? false
            : {
                size: 'small',
                position: ['bottomRight'],
                showSizeChanger: true,
                total: total,
                pageSize: query.pageSize,
                current: query.pageNumber,
                pageSizeOptions: ['5', '10', '20', '30', '40']
              }
        }
      />
    </Section>
  );
}
