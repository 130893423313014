import { UploadOutlined } from '@ant-design/icons';
import { getPccResponseDownloadLink } from 'client/components/schema/pcc/getPccResponseDownloadLink';
import { ISODate } from 'client/ui/display/date/ISODate';
import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { AnchorAPILink } from 'client/ui/link/AnchorAPILink';
import { Section } from 'client/ui/section/Section';
import { PccResponseDto } from 'common/dto/generated/PccResponseDto';
import React from 'react';

interface IPccResponseSectionProps {
  response?: PccResponseDto | null;
}

export function PccResponseSection(props: IPccResponseSectionProps) {
  const { response } = props;

  if (!response) {
    return null;
  }

  return (
    <Section
      icon={<UploadOutlined />}
      title="Importazione Risposta PCC"
      hasPadding
    >
      <FormFieldsContainer columns={2}>
        <DataItem
          label="Data"
          value={
            <ISODate format="DD/MM/YYYY HH:mm:ss" date={response.createdAt} />
          }
        />
        <DataItem label="Autore" value={response.uploadAuthor?.name} />
        <DataItem
          label="Risposta PCC"
          value={
            <AnchorAPILink
              href={getPccResponseDownloadLink(response.pccExportId)}
            >
              {response.fileName}
            </AnchorAPILink>
          }
        />
      </FormFieldsContainer>
    </Section>
  );
}
