import { SelectOption } from 'client/ui/form/input/SelectInput';
import {
  AcceptanceState,
  InvoiceState
} from 'common/schema/invoice/InvoiceTypes';

export enum InvoiceExportResultTypes {
  all = 'all',
  partial = 'partial'
}

export const InvoiceStateOptions: SelectOption[] = [
  { value: InvoiceState.ToValidate, label: 'Da associare' },
  { value: InvoiceState.Validated, label: 'Da Esitare | Protocollare' },
  { value: InvoiceState.ToAccount, label: 'Da contabilizzare' },
  { value: InvoiceState.Rejected, label: 'Rifiutata' },
  { value: InvoiceState.Accounted, label: 'Contabilizzata' },
  { value: InvoiceState.PaidPartial, label: 'Pagata Parzialmente' },
  { value: InvoiceState.Paid, label: 'Pagata' }
];

export const SdiStateOptions: SelectOption[] = [
  { value: AcceptanceState.Accepted, label: 'Accettata' },
  { value: AcceptanceState.Rejected, label: 'Rifiutata' },
  { value: AcceptanceState.Indeterminate, label: 'Indeterminato' }
];

export interface IInvoiceExportFieldsSelection {
  invoice: {
    id?: boolean;
    currency?: boolean;
    totalAmount?: boolean;
    totalVatAmount?: boolean;
    totalVatTaxableAmount?: boolean;
    invoiceType?: boolean;
    supplier?: boolean;
    vatNumber?: boolean;
    fiscalNumber?: boolean;
    issuedNumber?: boolean;
    issuedDate?: boolean;
    acceptance?: boolean;
    expiresAt?: boolean;
    protocol?: boolean;
    protocolDate?: boolean;
    receivedAt?: boolean;
    testingDate?: boolean;
    executionDate?: boolean;
    suspendedReason?: boolean;
    references?: boolean;
  };
  contract: {
    subject?: boolean;
    suppliers?: boolean;
    totalAmount?: boolean;
    netAmount?: boolean;
    cigCode?: boolean;
    cupCode?: boolean;
    documentYear?: boolean;
    documentNumber?: boolean;
    expireDate?: boolean;
    paymentTermsDays?: boolean;
    testingTermsDays?: boolean;
    anacProcedureKind?: boolean;
    requirementOfficeCode?: boolean;
    adminReferent?: boolean;
    rupReferent?: boolean;
    decReferent?: boolean;
    contractType?: boolean;
    state?: boolean;
    economicEntries?: boolean;
  };
  accounting: {
    amount?: boolean;
    state?: boolean;
    debtState?: boolean;
    debtReason?: boolean;
    paymentDate?: boolean;
    expenseNature?: boolean;
    pccExportedAccounting?: boolean;
    pccExportedPayment?: boolean;
  };
  lines: {
    economicEntryCode?: boolean;
    periodStartDate?: boolean;
    periodEndDate?: boolean;
    priceTotal?: boolean;
    vatRate?: boolean;
  };
  suspending: {
    suspendedFromDate?: boolean;
    suspendedToDate?: boolean;
    suspendedReason?: boolean;
  };
  mandate: {
    mandateNumber?: boolean;
    camicia?: boolean;
    expenseChapter?: boolean;
    beneficiary?: boolean;
    amount?: boolean;
    mandateYear?: boolean;
    originYear?: boolean;
    sicogeState?: boolean;
    gebilaState?: boolean;
    signatureDate?: boolean;
    validateDate?: boolean;
    paymentDate?: boolean;
    managementPlan?: boolean;
    commitmentType?: boolean;
    commitmentNumber?: boolean;
    shipDate?: boolean;
  };
}
