import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import type { EconomicStatementSimulationItemDetails } from 'common/schema/economic-statement-simulation/EconomicStatementSimulationTypes';
import { InvoiceDto } from './InvoiceDto';
import { InvoiceLineDto } from './InvoiceLineDto';

/**
 * Rappresentazione DTO della classe EconomicStatementSimulationItem definita in: src/server/schema/economy/economic-statement/simulation-row/EconomicStatementSimulationItem.entity.ts
 * Hash: 6acb3fbfbd9f51c13845795e37ae3bb0
 */
@ValidationSchema(() => EconomicStatementSimulationItemSchema)
export class EconomicStatementSimulationItemDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ type: String })
  description!: string;
  @ApiProperty({ type: [Object] })
  details!: EconomicStatementSimulationItemDetails;
  @ApiProperty({ required: false, type: Number })
  simulationId!: number;
  @ApiProperty({ required: false, type: Number })
  lineId!: number;
  @ApiProperty({ required: false, type: () => InvoiceLineDto })
  @Type(() => InvoiceLineDto)
  line!: InvoiceLineDto;
  @ApiProperty({ required: false, type: Number })
  invoiceId!: number;
  @ApiProperty({ required: false, type: () => InvoiceDto })
  @Type(() => InvoiceDto)
  invoice!: InvoiceDto;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<EconomicStatementSimulationItemDto>) {
    if (values != null) {
      Object.assign(this, values instanceof EconomicStatementSimulationItemDto ? values : plainToClass(EconomicStatementSimulationItemDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await EconomicStatementSimulationItemSchema.validate(classToPlain(this), options);
    return new EconomicStatementSimulationItemDto(validated);
  }
}

/** Interfaccia simmetrica al DTO EconomicStatementSimulationItemDto */
export type IEconomicStatementSimulationItemType = IDto<EconomicStatementSimulationItemDto>;

/**
 * DTO Paginato della classe EconomicStatementSimulationItem
 */
export class PaginatedEconomicStatementSimulationItemDto {
  @ApiProperty({ type: [EconomicStatementSimulationItemDto] })
  @Type(() => EconomicStatementSimulationItemDto)
  items!: EconomicStatementSimulationItemDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const EconomicStatementSimulationItemSchema = yup
  .object({
    id: yup.number(),
    simulationId: yup.number(),
    lineId: yup.number(),
    invoiceId: yup.number()
  })
  .noUnknown()
  .meta({ schemaName: "EconomicStatementSimulationItemSchema" })
  .required();
