import { PageHeadingSummary } from 'client/ui/layout/page/heading/PageHeadingSummary';
import { MandateDto } from 'common/dto/generated/MandateDto';
import * as React from 'react';

export interface MandateHeaderContentProps {
  order: MandateDto;
}

/**
 * Contenuto supplementare dell'intestazione della pagina,
 * posizionato sotto il titolo pagina e i pulsanti azione,
 * per mostrare ulteriori dati riassuntivi dell'elemento.
 * * Per pagina Ordine di Pagamento | Mandato
 */
export function MandateHeaderContent(props: MandateHeaderContentProps) {
  const { order } = props;
  return (
    <PageHeadingSummary
      items={[
        { label: 'Numero Mandato', value: order.mandateNumber },
        { label: 'Numero Impegno', value: order.commitmentNumber },
        { label: 'Beneficiario', value: order.beneficiary, span: 2 },
        { label: 'Causale', value: order.reason, span: 2 }
      ]}
      values={[
        { label: 'Importo', value: order.amount, suffix: '€' },
        { label: 'Importo Netto', value: order.netAmount, suffix: '€' }
      ]}
    />
  );
}
