import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Space } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import {
  useModuleConfigOption,
  useModuleConfigOptions,
  useModulesConfig
} from 'client/components/schema/config/ClientConfigModule';
import { PccApi } from 'client/components/schema/pcc/PccApi';
import { PccExportApi } from 'client/components/schema/pcc/PccExportApi';
import { apiClient } from 'client/core/network/apiClient';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { UploadInputWrapper } from 'client/ui/form/input/UploadInputWrapper';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import React, { useCallback, useState } from 'react';

const B_TO_MB = 1024 * 1024; // Byte in MegaByte

interface IPccResponseModalButtonProps {
  id: number;
}

/**
 * Bottone che apre la modal per l'importazione della risposta da PCC
 */
export function PccResponseModalButton(props: IPccResponseModalButtonProps) {
  const { id } = props;
  const [isVisible, setIsVisible] = useState(false);
  const maxFileSizeOption = useModuleConfigOption('pcc.export', 'maxFileSize');

  let maxFileSize: number;

  // Se per errore non venisse configurata la dimensione massima,
  // impostala a 5MB e segnala con un warning
  if (!maxFileSizeOption || !maxFileSizeOption.value) {
    console.warn('Dimensione massima upload non configurata');
    message.warning('Dimensione massima upload non configurata');
    maxFileSize = 5;
  } else {
    maxFileSize = parseInt(maxFileSizeOption.value);
  }

  const [file, setFile] = useState<RcFile | null>(null);
  const [upload, uploadState] = useApiMutation(PccExportApi.response.upload, {
    invalidates: [PccExportApi.find, PccExportApi.lines.list]
  });

  const handleUpload = useCallback(async () => {
    if (!file) {
      message.error('Nessun file selezionato');
      return;
    }
    const data = new FormData();

    data.append('pccResponse', file);
    try {
      const result = await upload({ data: { data, id } });
      if (result.data.withError) {
        message.warning('Alcune linee presentano un errore');
      } else {
        message.success('Esportazione completata correttamente');
      }
      setIsVisible(false);
    } catch (e) {
      console.error(e);
    }
  }, [file]);

  const handleCancel = useCallback(() => {
    if (uploadState.loading) return;
    setIsVisible(false);
  }, [uploadState, setIsVisible]);

  return (
    <>
      <Button
        type="primary"
        icon={<UploadOutlined />}
        onClick={() => setIsVisible(true)}
      >
        Upload Risposta PCC
      </Button>
      {/* MODAL per l'upload */}
      <ModalDialog
        title="Upload risposta PCC"
        visible={!!id && isVisible}
        maskClosable={!uploadState.loading}
        onCancel={handleCancel}
        footer={
          <Space>
            <Button onClick={handleCancel} disabled={uploadState.loading}>
              Annulla
            </Button>
            <Button
              type="primary"
              onClick={handleUpload}
              loading={uploadState.loading}
            >
              Carica Risposta
            </Button>
          </Space>
        }
      >
        <Dragger
          fileList={file ? [file] : []}
          beforeUpload={file => {
            // SIZE CHECK
            if (maxFileSize > 0 && file.size / B_TO_MB > maxFileSize) {
              const fileSize = Math.ceil((file.size / B_TO_MB) * 100) / 100;
              message.error(
                `Il file ${file.name} è troppo grande (${fileSize} MB). Dimensione massima: ${maxFileSize} MB`
              );
              return false;
            }

            // Set del file
            setFile(file);
            return false;
          }}
          onRemove={() => setFile(null)}
          name="pccUpload"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-hint">Premi o Trascina per Caricare</p>
        </Dragger>
      </ModalDialog>
    </>
  );
}
