import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';
import { InvoiceVATSummaryDto } from 'common/dto/generated/InvoiceVATSummaryDto';
import Decimal from 'decimal.js-light';
import { SDIChargeabilityOptions } from 'common/schema/invoice/VatSummaryLabels';
import { SDIChargeabilityTag } from '../view/SDIChargeabilityTag';

export const InvoiceVatSummaryColumns: ColumnsType<InvoiceVATSummaryDto> = [
  {
    dataIndex: 'vatRate',
    title: 'Aliquota IVA',
    render: (rate: Decimal) => `${rate.toString()}%`
  },
  {
    dataIndex: 'vatTaxableAmount',
    title: 'Importo Imponibile',
    align: 'right',
    render: (_, summary) => <CurrencyRender value={summary.vatTaxableAmount} />
  },
  {
    dataIndex: 'vatAmount',
    title: 'Imposta',
    align: 'right',
    render: (_, summary) => <CurrencyRender value={summary.vatAmount} />
  },
  {
    dataIndex: 'chargeability',
    title: 'Esigibilità IVA',
    render: (_, summary) => (
      <SDIChargeabilityTag chargeability={summary.chargeability} />
    )
  }
];
