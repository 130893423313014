import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";
import { Model003Action } from "server/schema/pcc/model003/Model003Types";
import { InvoiceQueryDto } from './InvoiceQueryDto';
import { AccountingLineQueryDto } from './AccountingLineQueryDto';

@GenerateDto()
class Model003CreateQuery {
    @SchemaLabel('Fatture/Linee Fatture')
    sourceIds?: number[] | null;
    @SchemaLabel('Query Fatture/Linee Fatture')
    query?: InvoiceQueryDto | AccountingLineQueryDto | null;
    @SchemaLabel('Azione PCC')
    action!: Model003Action
}

/**
 * Rappresentazione DTO della classe Model003CreateQuery 
 * Hash: 38926e2bc15f1a81166e7c12fa46d3d2
 */
@ValidationSchema(() => Model003CreateQuerySchema)
export class Model003CreateQueryDto {
  @ApiProperty({ required: false, type: [Number], description: 'Fatture/Linee Fatture' })
  sourceIds?: number[] | null | undefined;
  @ApiProperty({ required: false, description: 'Query Fatture/Linee Fatture' })
  query?: InvoiceQueryDto | AccountingLineQueryDto | null | undefined;
  @ApiProperty({ enum: ['CO', 'CP', 'CS'], description: 'Azione PCC' })
  action!: Model003Action;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<Model003CreateQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof Model003CreateQueryDto ? values : plainToClass(Model003CreateQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await Model003CreateQuerySchema.validate(classToPlain(this), options);
    return new Model003CreateQueryDto(validated);
  }
}

export const Model003CreateQuerySchema = yup
  .object({
    sourceIds: yup.array(yup.number().required()).nullable().label('Fatture/Linee Fatture'),
    query: yup.mixed().nullable().label('Query Fatture/Linee Fatture'),
    action: yup.string().oneOfEnum(Model003Action).required().label('Azione PCC')
  })
  .noUnknown()
  .meta({ schemaName: "Model003CreateQuerySchema" })
  .required();
