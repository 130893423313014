import { AuditOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { DocumentalUserSelectInput } from 'client/components/schema/documental/DocumentalUserSelectInput';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Section } from 'client/ui/section/Section';
import { Roles, RoleName, RoleOptions } from 'common/permissions/Roles';
import { useField } from 'formik';
import React from 'react';

export interface IUserDataSectionProps {}

export function UserDataSection(props: IUserDataSectionProps) {
  const [isPasswordEnabled, ,] = useField('enablePassword');
  const formikContext = useFormikFormContext();
  const editable = formikContext.editable;

  return (
    <Section icon={<AuditOutlined />} title="Dati Utente" hasPadding>
      <FormFieldsContainer columns={1}>
        <FormFieldItem
          component={SelectInput}
          label="Ruolo"
          name="role"
          placeholder="Ruolo utente"
          options={RoleOptions}
        />
        <FormFieldItem
          component={DocumentalUserSelectInput}
          label="Utente PiTre"
          name="documentalUserCode"
          placeholder="Codice utente"
        />
        <FormFieldItem
          component={TextInput}
          label="Login"
          prefix={<UserOutlined style={{ color: '#d9d9d9' }} />}
          name="login"
          placeholder="Login utente"
        />
        <FormFieldItem
          inline
          component={CheckboxInput}
          label="Autenticazione con password"
          name="enablePassword"
        />
        {editable && isPasswordEnabled.value && (
          <FormFieldItem
            prefix={<LockOutlined style={{ color: '#d9d9d9' }} />}
            component={TextInput}
            type="password"
            label="Password"
            name="password"
          />
        )}
      </FormFieldsContainer>
    </Section>
  );
}
