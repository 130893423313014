import { apiClient } from 'client/core/network/apiClient';
import {
  DocumentalSearchDocumentResult,
  IDocumentalRoles
} from 'common/dto/documental/DocumentalBridgeDto';
import {
  DocumentalCorrespondentDto,
  PaginatedDocumentalCorrespondentDto
} from 'common/dto/documental/DocumentalCorrespondentDto';
import { DocumentalUserRolesQueryDto } from 'common/dto/documental/DocumentalUserRolesQueryDto';
import { DocumentalCorrespondentQueryDto } from 'common/dto/query/DocumentalCorrespondentQueryDto';
import { DocumentalUsersQueryDto } from 'common/dto/query/DocumentalUsersQueryDto';
import { SearchDocumentalQueryDto } from 'common/dto/query/SearchDocumentalQueryDto';

export const DocumentalApi = {
  /** Recupera le informazioni di un documento sul Documentale */
  find: (opts: { query: SearchDocumentalQueryDto }) =>
    apiClient
      .request()
      .get<DocumentalSearchDocumentResult>(`/documental/document`, {
        params: opts.query
      }),

  correspondent: {
    /**
     * Filtri:
     * - USERS: boolean -> utenti di tipo persone
     * - TYPE: 'EXTERNAL' | 'INTERNAL' | 'GLOBAL' -> tipo di corrispondenti
     * - CODE: string -> codice rubrica (o parte di esso)
     * - EXACT_CODE: string -> codice rubrica esatto
     * - MAIL: string -> filtra per indirizzo email
     * @param opts
     */
    list: (opts: { query: DocumentalUsersQueryDto }) =>
      apiClient
        .requestWithResponseType(PaginatedDocumentalCorrespondentDto)
        .get(`/documental/correspondents`, { params: opts.query }),

    find: (opts: { code: string; query: DocumentalCorrespondentQueryDto }) =>
      apiClient
        .requestWithResponseType(DocumentalCorrespondentDto)
        .get(`/documental/correspondents/${opts.code}`, { params: opts.query })
  },

  getRole: (opts: { query: DocumentalUserRolesQueryDto }) =>
    apiClient.request().get<IDocumentalRoles>(`/documental/users/roles`, {
      params: opts.query
    })
};
