import * as React from 'react';
import { CalculatorOutlined, InboxOutlined } from '@ant-design/icons';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Section } from 'client/ui/section/Section';
import { EconomicEntryMultipleSelectInput } from 'client/components/schema/economy/economic-entry/EconomicEntryMultipleSelectInput';
import { EconomicEntrySingleSelectInput } from 'client/components/schema/economy/economic-entry/EconomicEntrySingleSelectInput';
import { ConfigEnumInput } from 'client/ui/form/input/configurable/ConfigEnumInput';

export interface ContractAccountingSectionProps {}

export function ContractAccountingSection(
  props: ContractAccountingSectionProps
) {
  return (
    <Section
      icon={<CalculatorOutlined />}
      title="Dati Registrazione Contabile"
      hasPadding
    >
      {/* CODICI DI CONTO ECONOMICO ASSOCIATI */}
      <FormFieldsContainer>
        <FormFieldItem
          inline
          label="Codice di Conto Economico"
          component={EconomicEntrySingleSelectInput}
          name="economicEntries"
          placeholder="Seleziona il Codice di conto economico"
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={3}>
        <FormFieldItem
          inline
          component={CheckboxInput}
          label={null}
          children="Atto Esente da imposta di Bollo"
          name="hasStampDuty"
          defaultChecked={false}
        />
        <FormFieldItem
          inline
          component={CheckboxInput}
          label={null}
          children="Atto Esente Registrazione"
          name="hasRegistration"
          defaultChecked={false}
        />
        <FormFieldItem
          inline
          component={CheckboxInput}
          label={null}
          children="Restituzione Duplo Cartaceo"
          name="hasPaperCopyReturn"
          defaultChecked={false}
        />
        {/* <FormFieldItem
          inline
          component={CheckboxInput}
          label="Presentazione Titolo in Originale"
          // TODO Attenzione inserire campo
          name="needOriginalCopy"
          defaultChecked={false}
        /> */}
      </FormFieldsContainer>

      {/** Modalità di pagamento */}
      <FormFieldsContainer title="Pagamento">
        {/* <FormFieldItem
          labelWidth="width-180"
          label="Modalità di Pagamento"
          component={TextInput}
          name="paymentType"
          placeholder="Modalità di Pagamento"
        /> */}
        <FormFieldItem
          labelWidth="width-180"
          label="Modalità di Pagamento"
          placeholder="Modalità di Pagamento"
          component={ConfigEnumInput}
          feature="contract.values"
          enumKey="paymentType"
          dropdownMatchSelectWidth={600}
          name="paymentType"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={3}>
        <FormFieldItem
          labelWidth="width-180"
          label="Identificativo Negozio"
          component={TextInput}
          name="negotiationId"
          placeholder="Identificativo Negozio"
        />
        <FormFieldItem
          inline
          label="Valore Negozio"
          component={TextInput}
          name="negotiationValue"
          placeholder="Valore Negozio"
        />
        <FormFieldItem
          inline
          label="Codice Negozio"
          component={TextInput}
          name="negotiationCode"
          placeholder="Codice Negozio"
        />
      </FormFieldsContainer>
    </Section>
  );
}
