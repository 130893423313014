import { InfoBoxActions } from 'client/ui/info-box/InfoBoxActions';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import React from 'react';
import { useCallback } from 'react';
import { Button, message, Modal, Space, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { InvoiceLogic } from 'common/schema/invoice/InvoiceLogic';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { InvoiceApi } from '../InvoiceApi';

export interface IInvoiceRevisionInfoBoxProps {
  invoice: InvoiceDto;
}

/**
 * InfoBox per la Revisione della fattura prima dell'invio a PCC
 */
export function InvoiceRevisionInfoBox(props: IInvoiceRevisionInfoBoxProps) {
  const [revision] = useApiMutation(InvoiceApi.actions.revision, {
    invalidates: [InvoiceApi.find, InvoiceApi.notifications.list],
    data: { id: props.invoice.id }
  });

  const handleAccept = useCallback(async () => {
    Modal.confirm({
      title: `Sei sicuro di voler segnare la fattura come Revisionata?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Revisiona',
      cancelText: 'Annulla',
      content: `La fattura non sarà indicata come 'Revisionata' nell'elenco delle fatture 'Scadute non Pagate'.`,
      async onOk() {
        try {
          await revision({});
          message.success('La fattura è stata revisionata con successo.');
        } catch (e) {
          message.error("Si è verificato un errore durante l'operazione.");
          console.error(e);
        }
      },
      onCancel() {}
    });
  }, [revision]);

  // TODO Funzione per mostrare il box solo se la fattura deve essere valutata
  // Da capire se tutti gli utenti possono Revisionarla???
  // if (!InvoiceMachine.can(props.invoice, 'accounting.accept-or-reject'))
  //   return null;

  // Mostrare il box solo se la fattura necessita di essere revisionata
  const visible = InvoiceLogic.needRevision(props.invoice);
  if (!visible) return null;

  return (
    <InfoBoxActions
      title="Revisione Fattura"
      text="Verifica dei dati di contabilizzazione della fattura prima dell'invio a PCC."
      actionButton={{
        children: 'Revisiona',
        onClick: async e => await handleAccept()
      }}
    />
  );
}
