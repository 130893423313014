import React, { useState } from 'react';
import { Button, message, Space } from 'antd';
import { FormikForm } from 'client/ui/form/FormikForm';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { IEconomicEntryTree } from 'common/schema/economic-entry/createTreeFromEconomicEntries';
import {
  EconomicEntryDto,
  EconomicEntrySchema
} from 'common/dto/generated/EconomicEntryDto';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { EconomicEntryApi } from 'client/components/schema/economy/economic-entry/EconomicEntryApi';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { EconomicEntrySelectInput } from 'client/components/schema/economy/economic-entry/EconomicEntrySelectInput';
import { EconomicEntryDefaultCode } from 'common/schema/economic-entry/EconomicEntryDefaultCode';

export interface EconomicEntryEditModalProps {
  /** Albero corrente */
  tree: IEconomicEntryTree;
  /** Selezionato */
  selected: EconomicEntryDto | null;
  /** Chiusura */
  onClose: (entry?: EconomicEntryDto) => void;
}

/**
 * Modal per l'inserimento e la modifica delle voci di conto economico.
 */
export function EconomicEntryEditModal(props: EconomicEntryEditModalProps) {
  const { selected, tree } = props;

  const [update] = useApiMutation(EconomicEntryApi.update, {
    data: { id: selected?.id },
    invalidates: [EconomicEntryApi.list]
  });
  const [create] = useApiMutation(EconomicEntryApi.create, {
    data: {},
    invalidates: [EconomicEntryApi.list]
  });

  if (!selected) return null;

  return (
    <FormikAugmented<EconomicEntryDto>
      initialValues={selected}
      validationSchema={EconomicEntrySchema}
      validationContext={{
        machineActions: []
      }}
      onSubmit={async values => {
        try {
          const input = await values.validate();
          if (selected.id) {
            await update({ data: { id: selected.id, input } });
          } else {
            await create({ data: { input } });
          }
          message.success('La voce di conto economico è stata salvata');
          props.onClose(input);
        } catch (e) {
          message.error('Si è verificato un errore nel salvataggio');
          console.error(e);
        }
      }}
      onReset={() => {}}
    >
      <FormikForm nested editable helpInTooltips>
        <ModalDialog
          size="medium"
          title={
            (selected.id ? 'Modifica' : 'Nuova') + ' voce di Conto Economico'
          }
          visible={selected != null}
          onCancel={() => props.onClose()}
          footer={
            <Space>
              <Button onClick={() => props.onClose()}>Annulla</Button>
              <FormikSendButton>Salva</FormikSendButton>
            </Space>
          }
        >
          <FormFieldsContainer
            title="Voce di Conto Economico"
            removeTopTitleSpacing
          >
            <FormFieldItem
              component={TextInput}
              label="Codice"
              name="code"
              placeholder="Codice"
              readOnly={selected.code === EconomicEntryDefaultCode.NoCompetence}
            />
          </FormFieldsContainer>
          <FormFieldsContainer title="Posizione e tipologia dell'elemento nella lista">
            <FormFieldItem
              label="Posizione"
              component={EconomicEntrySelectInput}
              name="pathHint"
              placeholder="Posizione in cui mostrare la Voce"
              componentProps={{
                treeOptions: {
                  canSelectNodes: true
                },
                onAfterSelect: (formik, value) => {
                  formik.setFieldValue(
                    'path',
                    value.type === 'Leaf' ? value.entry.path : value.path
                  );
                }
              }}
            />
            <FormFieldItem
              component={TextInput}
              label="Descrizione"
              name="path"
              placeholder="Codice completo, es. BENI DI CONSUMO\Beni..."
            />
          </FormFieldsContainer>
          <FormFieldsContainer title="Corrispettivo Codice di Spesa PCM">
            <FormFieldItem
              component={NumberInput}
              label="Codice PCM"
              name="pcmCode"
              placeholder="Codice"
            />
          </FormFieldsContainer>
        </ModalDialog>
      </FormikForm>
    </FormikAugmented>
  );
}
