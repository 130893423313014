import { NotificationOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { InvoiceApi } from 'client/components/schema/invoice/InvoiceApi';
import { NotificationColumns } from 'client/components/schema/notification-sdi/table/NotificationColumns';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { Section } from 'client/ui/section/Section';
import { NotificationDto } from 'common/dto/generated/NotificationDto';
import { NotificationQueryDto } from 'common/dto/query/NotificationQueryDto';
import { useField } from 'formik';
import React, { useState } from 'react';

interface IInvoiceNotificationSectionProps {
  invoiceId: number | null;
}

export function InvoiceNotificationSection(
  props: IInvoiceNotificationSectionProps
) {
  const { invoiceId } = props;

  const [query, setQuery] = useState<NotificationQueryDto>(
    new NotificationQueryDto({ pageNumber: 1, pageSize: 10 })
  );

  const { response, loading } = useApiQuery(InvoiceApi.notifications.list, {
    skip: invoiceId == null,
    data: {
      invoiceId: invoiceId!,
      query
    }
  });

  const notifications = response?.data.items;

  return (
    <Section icon={<NotificationOutlined />} title="Notifiche SDI">
      <Table
        loading={loading}
        columns={NotificationColumns}
        rowKey="id"
        dataSource={notifications}
        size="middle"
        onChange={(pagination, filters, sorter) => {
          setQuery(
            new NotificationQueryDto({
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize
            })
          );
        }}
        pagination={{
          size: 'small',
          position: ['bottomRight'],
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.pageSize,
          current: query.pageNumber,
          pageSizeOptions: ['2', '10', '20', '30', '40']
        }}
      />
    </Section>
  );
}
