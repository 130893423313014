import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize } from "cli/generate/decorators/SchemaDecorators";
import { AcceptanceState, InvoiceState } from "common/schema/invoice/InvoiceTypes";
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { InvoiceColumnSort } from './types/InvoiceQueryTypes';

@GenerateDto()
class InvoiceQuery {
  @SchemaPageNumber()
  pageNumber!: number;
  @SchemaPageSize()
  pageSize!: number;
  
  @SchemaLabel('Stato fatture')
  state?: InvoiceState | null;
  @SchemaLabel('Stato accettazione fatture')
  acceptance?: AcceptanceState | null;
  @SchemaLabel('Fatture cartacee')
  paper?: boolean | null;
  @SchemaLabel('Anno di emissione fattura')
  issuedYear?: number | null;
  @SchemaLabel('da protocollare')
  toProtocol?: boolean | null;

  @SchemaLabel("Data inizio")
  issuedDateFrom?: Date | null;
  @SchemaLabel("Data fine")
  issuedDateTo?: Date | null;

  @SchemaLabel("da data scadenza")
  expireDateFrom?: Date | null;
  @SchemaLabel("a data scadenza")
  expireDateTo?: Date | null;

  @SchemaLabel("Ordinamento")
  orderBy?: InvoiceColumnSort = InvoiceColumnSort.receivedAt;

  @SchemaLabel("Direzione di Ordinamento")
  orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;
  
  @SchemaLabel('Denominazione')
  supplierName?: string | null;

  @SchemaLabel('Numero Fattura')
  issuedNumber?: string | null;

  @SchemaLabel('Numero Protocollo')
  protocolNumber?: string | null;
  @SchemaLabel('Anno Protocollo')
  protocolYear?: string | null;
  @SchemaLabel("Data Protocollo: Da")
  protocolDateFrom?: Date | null;
  @SchemaLabel("Data Protocollo: A")
  protocolDateTo?: Date | null;

  @SchemaLabel('CF/P.IVA')
  supplierFiscalNumber?: string | null;

  @SchemaLabel('CIG')
  cigCode?: string | null;
  @SchemaLabel('CUP')
  cupCode?: string | null;

  // Se la fattura è da esportare a PCC per comunicazione ricezione
  @SchemaLabel('Da Esportare a PCC')
  needsPccReception?: boolean | null;

  // Se la fattura presenta linee di contabilizzazione da 
  // comunicare la contabilizzazione a PCC
  @SchemaLabel('Con linee da Contabilizzare per PCC')
  needsPccAccounting?: boolean | null;
  
  // Se la fattura presenta linee di contabilizzazione da 
  // comunicare la scadenza a PCC
  @SchemaLabel('Con linee da Comunicare la scadenza a PCC')
  needsPccExpiration?: boolean | null;

  @SchemaLabel('Fatture Scadute non Contabilizzate oppure Contabilizzate e non Pagate')
  expiredNotPaid?: boolean | null
}

/**
 * Rappresentazione DTO della classe InvoiceQuery 
 * Hash: 85c28807a098790edc40dad73bf17eef
 */
@ValidationSchema(() => InvoiceQuerySchema)
export class InvoiceQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, enum: ['ToValidate', 'Validated', 'ToAccount', 'Rejected', 'Accounted', 'PaidPartial', 'Paid'], description: 'Stato fatture' })
  state?: InvoiceState | null | undefined;
  @ApiProperty({ required: false, enum: ['Accepted', 'Rejected', 'Indeterminate'], description: 'Stato accettazione fatture' })
  acceptance?: AcceptanceState | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Fatture cartacee' })
  paper?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Anno di emissione fattura' })
  issuedYear?: number | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'da protocollare' })
  toProtocol?: boolean | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data inizio' })
  issuedDateFrom?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data fine' })
  issuedDateTo?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'da data scadenza' })
  expireDateFrom?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'a data scadenza' })
  expireDateTo?: Date | null | undefined;
  @ApiProperty({ required: false, enum: ['id', 'receivedAt', 'issuedDate', 'issuedYear', 'expiresAt', 'issuedNumber', 'invoiceTypeCode', 'totalVatTaxableAmount', 'supplierName', 'protocol'], description: 'Ordinamento' })
  orderBy?: InvoiceColumnSort | undefined = InvoiceColumnSort.receivedAt;
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;
  @ApiProperty({ required: false, type: String, description: 'Denominazione' })
  supplierName?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Numero Fattura' })
  issuedNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Numero Protocollo' })
  protocolNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Anno Protocollo' })
  protocolYear?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Protocollo: Da' })
  protocolDateFrom?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Protocollo: A' })
  protocolDateTo?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'CF/P.IVA' })
  supplierFiscalNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'CIG' })
  cigCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'CUP' })
  cupCode?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Da Esportare a PCC' })
  needsPccReception?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Con linee da Contabilizzare per PCC' })
  needsPccAccounting?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Con linee da Comunicare la scadenza a PCC' })
  needsPccExpiration?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Fatture Scadute non Contabilizzate oppure Contabilizzate e non Pagate' })
  expiredNotPaid?: boolean | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceQueryDto ? values : plainToClass(InvoiceQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await InvoiceQuerySchema.validate(classToPlain(this), options);
    return new InvoiceQueryDto(validated);
  }
}

export const InvoiceQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    state: yup.string().oneOfEnum(InvoiceState).nullable().label('Stato fatture'),
    acceptance: yup.string().oneOfEnum(AcceptanceState).nullable().label('Stato accettazione fatture'),
    paper: yup.boolean().nullable().label('Fatture cartacee'),
    issuedYear: yup.number().nullable().label('Anno di emissione fattura'),
    toProtocol: yup.boolean().nullable().label('da protocollare'),
    issuedDateFrom: yup.date().nullable().label('Data inizio'),
    issuedDateTo: yup.date().nullable().label('Data fine'),
    expireDateFrom: yup.date().nullable().label('da data scadenza'),
    expireDateTo: yup.date().nullable().label('a data scadenza'),
    orderBy: yup.string().oneOfEnum(InvoiceColumnSort).default(InvoiceColumnSort.receivedAt).label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento'),
    supplierName: yup.string().nullable().label('Denominazione'),
    issuedNumber: yup.string().nullable().label('Numero Fattura'),
    protocolNumber: yup.string().nullable().label('Numero Protocollo'),
    protocolYear: yup.string().nullable().label('Anno Protocollo'),
    protocolDateFrom: yup.date().nullable().label('Data Protocollo: Da'),
    protocolDateTo: yup.date().nullable().label('Data Protocollo: A'),
    supplierFiscalNumber: yup.string().nullable().label('CF/P.IVA'),
    cigCode: yup.string().trim().nullable().label('CIG'),
    cupCode: yup.string().nullable().label('CUP'),
    needsPccReception: yup.boolean().nullable().label('Da Esportare a PCC'),
    needsPccAccounting: yup.boolean().nullable().label('Con linee da Contabilizzare per PCC'),
    needsPccExpiration: yup.boolean().nullable().label('Con linee da Comunicare la scadenza a PCC'),
    expiredNotPaid: yup.boolean().nullable().label('Fatture Scadute non Contabilizzate oppure Contabilizzate e non Pagate')
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceQuerySchema" })
  .required();
