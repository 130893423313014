import { ApiProperty } from '@nestjs/swagger';

export class PaginatedMetaDto {
  @ApiProperty({ type: Number })
  page!: number;
  @ApiProperty({ type: Number })
  total!: number;
  @ApiProperty({ type: Number })
  size!: number;
}

type IPaginatedMetaDto = ClassToInterface<PaginatedMetaDto>;
