import { AuditOutlined, DeleteOutlined } from '@ant-design/icons';
import { message, Tag } from 'antd';
import { usePermissions } from 'client/components/auth/AuthModule';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { UserApi } from 'client/components/schema/user/UserApi';
import { UserRoleTag } from 'client/components/schema/user/view/UserRoleTag';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { EditAndSaveButton } from 'client/ui/form/button/EditAndSaveButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TextInput } from 'client/ui/form/input/TextInput';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { Section } from 'client/ui/section/Section';
import { UserDto, UserSchema } from 'common/dto/generated/UserDto';
import { ValidationAction } from 'common/validation/ValidationAction';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { UserDataSection } from './UserDataSection';
import { UserTokenSection } from './UserTokenSection';

interface Props {
  id: string;
}

export interface IUserPageProps extends RouteComponentProps<Props> {}

export function UserPage(props: IUserPageProps) {
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const [editable, setEditable] = useState(id == null);
  const permission = usePermissions();

  // Resetta alla modalità non editabile al cambio di pagina
  useEffect(() => {
    setEditable(id == null);
  }, [id]);

  // Gestione API
  const { response, error, loading, refetch } = useApiQuery(UserApi.find, {
    skip: id == null,
    data: { id: id! }
  });
  const user =
    id == null ? new UserDto({ enablePassword: false }) : response?.data;

  const [create] = useApiMutation(UserApi.create, {
    invalidates: [UserApi.find]
  });
  const [update] = useApiMutation(UserApi.update, {
    invalidates: [UserApi.find]
  });
  const [softDelete] = useApiMutation(UserApi.softDelete, {
    invalidates: [],
    data: { id: id! }
  });

  const handleDelete = useCallback(async () => {
    if (!id) return;
    try {
      await softDelete({ data: { id } });
      message.success('Utente eliminato con successo.');
      props.history.push('/users');
    } catch (e) {
      message.error("Si è verificato un errore durante l'eliminazione.");
    }
  }, [id]);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/users',
    title: 'Utenti'
  });
  useBreadcrumbItem({
    priority: 50,
    path: props.location.pathname,
    title: id ? user?.name : 'Nuovo Utente',
    sideMenuKey: 'all',
    menuKey: 'users'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile visualizzare l'utente richiesto. Riprovare. "
        error={error}
      />
    );
  }

  if (!user) return <LoadingPage />;

  return (
    <CorePageLayout>
      <FormikAugmented<UserDto>
        initialValues={user}
        enableReinitialize
        onReset={() => {
          setEditable(false);
        }}
        onSubmit={async rawValues => {
          try {
            const input = await rawValues.validate();
            if (id) {
              await update({ data: { input } });
              message.success('Utente modificato con successo. ');
            } else {
              const result = await create({
                data: { input }
              });
              message.success('Utente creato con successo. ');
              props.history.push(`/users/${result.data!.id}`);
            }
          } catch (e) {
            message.error(
              "Si è verificato un errore durante il salvataggio dell'utente."
            );
          }
        }}
        validationSchema={UserSchema}
        validationContext={{
          context: {
            action: id ? ValidationAction.edit : ValidationAction.create
          }
        }}
        validateOnChange={false}
      >
        <FormikForm editable={editable} helpInTooltips showVisualFeedback>
          <PageHeading
            title={
              <PageHeadingTitle
                children={id ? `${user.name ?? 'Utente'}` : 'Nuovo Utente'}
              />
            }
            breadcrumbRender={() => <BreadcrumbsContainer />}
            extra={
              permission.has('users.write') && (
                <>
                  <EditAndSaveButton
                    isNew={id == null}
                    name="Utente"
                    state={[editable, setEditable]}
                    whenSaved={
                      <ButtonDeleteConfirm
                        title="Sei sicuro di voler eliminare l'utente?"
                        loading={loading}
                        size="middle"
                        disabled={id == null}
                        buttonIcon={<DeleteOutlined />}
                        buttonDanger
                        buttonType="primary"
                        onConfirm={handleDelete}
                      />
                    }
                  />
                </>
              )
            }
            tags={
              id == null ? (
                <Tag>Non ancora salvato</Tag>
              ) : (
                <UserRoleTag role={user.role} />
              )
            }
          />
          <PageContent fixedWidht>
            {/** DATI UTENTE (login, password, ...) */}
            <UserDataSection />

            {/** INFORMAZIONI UTENTE (nome, codice fiscale, ...) */}
            <Section icon={<AuditOutlined />} title="Informazioni" hasPadding>
              <FormFieldsContainer columns={1}>
                <FormFieldItem
                  component={TextInput}
                  label="Nome"
                  name="name"
                  placeholder="Nome e Cognome"
                />
                <FormFieldItem
                  component={TextInput}
                  label="Codice Fiscale"
                  name="fiscalCode"
                  placeholder="Inserire Codice Fiscale"
                />
              </FormFieldsContainer>
            </Section>
            {/** Gestione generazione token */}
            <UserTokenSection user={user} />
          </PageContent>
        </FormikForm>
      </FormikAugmented>
    </CorePageLayout>
  );
}
