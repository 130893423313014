/** Stato Fattura */
export enum InvoiceState {
  /** Da Associare (ex Da Valutare) */
  ToValidate = 'ToValidate',
  /** Da Esitare|Protoc. (ex Valutata) */
  Validated = 'Validated',
  /** Da Contabilizzare */
  ToAccount = 'ToAccount',
  /** Rifiutata */
  Rejected = 'Rejected',
  /** Contabilizzata */
  Accounted = 'Accounted',
  /** Pagata parzialmente */
  PaidPartial = 'PaidPartial',
  /** Pagata interamente */
  Paid = 'Paid'
}

/** Tipo di Riferimento contrattuale */
export enum InvoiceReferenceType {
  /** Contratto */
  Contract = 'Contract',
  /** Lettera di Commessa */
  OrderLetter = 'OrderLetter',
  /** Convenzione */
  Agreement = 'Agreement',
  /** Ordine di Acquisto */
  PurchaseOrder = 'PurchaseOrder',
  /** Ricezione */
  Reception = 'Reception'
}

/** Stato dell'accettazione */
export enum AcceptanceState {
  /** Accettata */
  Accepted = 'Accepted',
  /** Rifiutata */
  Rejected = 'Rejected',
  /** Da Accettare */
  Indeterminate = 'Indeterminate'
}

export enum ForceExportRule {
  Create = 'AG',
  Substitute = 'SO'
}
