import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { PccRowReferenceType, PccRowState } from 'common/schema/pcc/pcc-row/PccRowTypes';
import { PccExportDto } from './PccExportDto';

/**
 * Rappresentazione DTO della classe PccRow definita in: src/server/schema/pcc/pcc-row/PccRow.entity.ts
 * Hash: 22d4cbe0c48a7f2ad2cc757e7354c10d
 */
@ValidationSchema(() => PccRowSchema)
export class PccRowDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, enum: ['invoice', 'accountingLine'], description: 'Tipo riga esportazione' })
  type?: PccRowReferenceType | null | undefined;
  @ApiProperty({ type: String, description: 'Hash Identificativo' })
  hash!: string;
  @ApiProperty({ required: false, type: String, description: 'Descrittore riga' })
  label?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'ID Sorgente' })
  sourceId?: number | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Id Esportazione PCC' })
  pccExportId!: number;
  @ApiProperty({ required: false, type: () => PccExportDto, description: 'Esportazione PCC' })
  @Type(() => PccExportDto)
  pccExport!: PccExportDto;
  @ApiProperty({ required: false, enum: ['toImport', 'error', 'notFound', 'imported'], description: 'Stato esportazione riga' })
  state?: PccRowState | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice errore' })
  errorCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Descrizione errore' })
  errorDescription?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<PccRowDto>) {
    if (values != null) {
      Object.assign(this, values instanceof PccRowDto ? values : plainToClass(PccRowDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await PccRowSchema.validate(classToPlain(this), options);
    return new PccRowDto(validated);
  }
}

/** Interfaccia simmetrica al DTO PccRowDto */
export type IPccRowType = IDto<PccRowDto>;

/**
 * DTO Paginato della classe PccRow
 */
export class PaginatedPccRowDto {
  @ApiProperty({ type: [PccRowDto] })
  @Type(() => PccRowDto)
  items!: PccRowDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const PccRowSchema = yup
  .object({
    id: yup.number(),
    type: yup.string().oneOfEnum(PccRowReferenceType).nullable().label('Tipo riga esportazione'),
    hash: yup.string().required().label('Hash Identificativo'),
    label: yup.string().nullable().label('Descrittore riga'),
    sourceId: yup.number().nullable().label('ID Sorgente'),
    pccExportId: yup.number().label('Id Esportazione PCC'),
    state: yup.string().oneOfEnum(PccRowState).default(PccRowState.ToImport).label('Stato esportazione riga'),
    errorCode: yup.string().nullable().label('Codice errore'),
    errorDescription: yup.string().nullable().label('Descrizione errore')
  })
  .noUnknown()
  .meta({ schemaName: "PccRowSchema" })
  .required();
