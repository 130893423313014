import * as React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';

interface AnchorAPILinkProps extends Omit<ButtonProps, 'href'> {
  href: string;
  /**
   * Props aggiuntive del tah<a> HTML
   */
  anchorProps?: Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>;
  /**
   * Permette di escludere il Button di Ant
   */
  showAsButton?: boolean;
}

/**
 * Tag <a> HTML da utilzzare per le chamate API
 * con stile Button di Ant (opzionale)
 *
 * Se occorre implementare nel componente il refresh token
 */
export function AnchorAPILink(props: AnchorAPILinkProps) {
  const { showAsButton, target, href, anchorProps, ...buttonProps } = props;

  const aIcon = !showAsButton && props.icon ? <>{props.icon} </> : null;

  return showAsButton ? (
    <a href={href} target={target} {...anchorProps}>
      <Button {...buttonProps} />
    </a>
  ) : (
    <a href={href} target={target} {...anchorProps}>
      {aIcon}
      {props.children}
    </a>
  );
}
