import { apiClient } from 'client/core/network/apiClient';
import {
  NotificationDto,
  PaginatedNotificationDto
} from 'common/dto/generated/NotificationDto';
import { NotificationQueryDto } from 'common/dto/query/NotificationQueryDto';

export const NotificationApi = {
  list: (opts: { query: NotificationQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedNotificationDto)
      .get(`/notifications`, { params: opts.query }),
  find: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(NotificationDto)
      .get(`/notifications/${opts.id}`)
};
