import { FullscreenOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { AnchorLinkButton } from 'client/ui/link/AnchorLinkButton';
import React from 'react';

interface IEconomicEntryRowActionsProps {
  handleEdit: (e: any) => void;
  handleDelete: (e: any) => void;
}

export function EconomicEntryRowActions(props: IEconomicEntryRowActionsProps) {
  const { handleEdit, handleDelete } = props;

  return (
    <Row justify="center">
      <Col>
        <AnchorLinkButton
          linkOnly
          icon={<FullscreenOutlined />}
          onClick={handleEdit}
          children="Modifica"
          style={{ marginRight: '15px' }}
        />
      </Col>
      <Col>
        <ButtonDeleteConfirm
          title={
            'Sei sicuro di voler eliminare questo codice di conto economico?'
          }
          onConfirm={handleDelete}
          buttonType="link"
          buttonDanger
        />
      </Col>
    </Row>
  );
}
