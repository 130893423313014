import { ContractState } from 'common/schema/contract/ContractTypes';
import * as React from 'react';

export function mapContractsFilterToOptions(filter: string | undefined) {
  switch (filter) {
    case 'in-progress':
      return {
        filter: filter,
        title: 'Contratti in Lavorazione',
        query: { state: ContractState.InProgress }
      };
    case 'registered':
      return {
        filter: filter,
        title: 'Contratti Registrati',
        query: { state: ContractState.Registered }
      };
    case 'signing':
      return {
        filter: filter,
        title: 'Contratti alla Firma',
        query: { state: ContractState.Signing }
      };
    case 'stipulated':
      return {
        filter: filter,
        title: 'Contratti Stipulati',
        query: { state: ContractState.Stipulated }
      };
    case 'canceled':
      return {
        filter: filter,
        title: 'Contratti Annullati',
        query: { state: ContractState.Canceled }
      };
    default:
      return {
        filter: 'all',
        title: 'Tutti i Contratti',
        query: { state: null }
      };
  }
}
