import { useNetworkContext } from '../context/NetworkContext';
import { ApiGet } from './useApiQuery';

export function useApiInvalidator() {
  const network = useNetworkContext();
  const invalidates = (queries: ApiGet<any, any>[]) => {
    network.invalidator.next({ queries });
  };
  return { network, invalidates };
}
