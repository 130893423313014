import * as React from 'react';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { Button, message, Modal, Space, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { InvoiceApi } from '../InvoiceApi';
import { AcceptanceState } from 'common/schema/invoice/InvoiceTypes';
import { useCallback, useState } from 'react';
import { InvoiceAcceptanceDto } from 'common/dto/query/InvoiceAcceptanceDto';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import { InfoBoxActions } from 'client/ui/info-box/InfoBoxActions';
import { InvoiceRejectModal } from './InvoiceRejectModal';
export interface IAcceptanceInfoBoxProps {
  invoice: InvoiceDto;
}

/**
 * Info Box con pulsanti per Accettazione / Rifiuto di una Fattura
 */
export function AcceptanceInfoBox(props: IAcceptanceInfoBoxProps) {
  const [accept] = useApiMutation(InvoiceApi.accept, {
    invalidates: [InvoiceApi.find, InvoiceApi.notifications.list],
    data: { id: props.invoice.id }
  });

  const handleAccept = useCallback(async () => {
    Modal.confirm({
      title: `Sei sicuro di voler accettare la fattura?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Accetta',
      cancelText: 'Annulla',
      content: `La fattura sarà accettata ${
        props.invoice.isSDI() ? ' e notificata a SDI.' : '.'
      }`,
      async onOk() {
        try {
          await accept({
            data: {
              input: new InvoiceAcceptanceDto({
                acceptance: AcceptanceState.Accepted
              })
            }
          });
          message.success('La fattura è stata accettata con successo.');
        } catch (e) {
          message.error("Si è verificato un errore durante l'operazione.");
          console.error(e);
        }
      },
      onCancel() {}
    });
  }, [accept]);

  if (!InvoiceMachine.can(props.invoice, 'accounting.accept-or-reject'))
    return null;

  return (
    <InfoBoxActions
      title="Accettazione Fattura"
      text="È possibile procedere alla contabilizzazione solo per le fatture Accettate."
      secondaryButton={
        <InvoiceRejectModal
          invoice={props.invoice}
          key="modal"
          children="Rifiuta"
        />
      }
      actionButton={{
        children: 'Accetta',
        onClick: async e => await handleAccept()
      }}
    />
  );
}
