import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Menu, message, Modal, Tooltip } from 'antd';
import { ContractApi } from 'client/components/schema/contract/ContractApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { ContractStateChangeDto } from 'common/dto/query/ContractStateChangeDto';
import { ActionCanResult } from 'common/fsm/EntityMachine';
import { ContractStateOptions } from 'common/schema/contract/ContractLabels';
import { ContractMachine } from 'common/schema/contract/ContractMachine';
import { ContractState } from 'common/schema/contract/ContractTypes';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { ContractStateTag } from '../../components/schema/contract/view/ContractStateTag';

export interface IContractStateChangeProps {
  contract: ContractDto;
}

export function ContractStateChange(props: IContractStateChangeProps) {
  const [changeState] = useApiMutation(ContractApi.changeState, {
    invalidates: [ContractApi.find],
    data: { id: props.contract.id }
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback(
    async (rawValues: ContractStateChangeDto) => {
      if (isOpen) return;
      setIsOpen(true);
      console.log(isOpen);
      const transition = checkTransition(rawValues.state);
      if (!transition.allowed) {
        message.error(
          transition.message ??
            `Impossibile modificare lo stato in ${getContractStateLabel(
              rawValues.state
            )}?`
        );
        return;
      }

      Modal.confirm({
        title: `Sei sicuro di voler modificare lo stato del contratto a ${getContractStateLabel(
          rawValues.state
        )}?`,
        icon: <ExclamationCircleOutlined />,
        okText: 'Conferma',
        cancelText: 'Annulla',
        content: `Lo stato del contratto verrà modificato a ${getContractStateLabel(
          rawValues.state
        )}.`,
        async onOk() {
          try {
            const input = await rawValues.validate();

            await changeState({
              data: { input: input }
            });
            message.success(
              'Lo stato del contratto è stato modificato con successo'
            );
            setIsOpen(false);
          } catch (e) {
            message.error("Si è verificato un errore durante l'operazione.");
            console.error(e);
          }
        },
        onCancel() {
          setIsOpen(false);
        }
      });
    },
    [changeState, isOpen, setIsOpen]
  );

  function createHandler(state: ContractState) {
    return (e: any) =>
      handleClick(new ContractStateChangeDto({ state: state }));
  }

  function getContractStateLabel(state: ContractState) {
    return ContractStateOptions.find(s => s.value === state)?.label;
  }

  /**
   * Controlla che sia possibile la transazione di stato.
   */
  function checkTransition(state: ContractState): ActionCanResult {
    switch (state) {
      case ContractState.Stipulated:
        return ContractMachine.can(
          props.contract,
          'transition.stipulated',
          true
        );
      default:
        return { allowed: true };
    }
  }

  const renderMenuItem = (state: ContractState) => {
    const isCurrent = props.contract.state == state;
    const transition = checkTransition(state);
    return (
      <Menu.Item
        key={state}
        onClick={createHandler(state)}
        disabled={isCurrent}
        style={{ height: '40px', lineHeight: '40px' }}
      >
        <ContractStateTag
          state={state}
          style={{ opacity: isCurrent || !transition.allowed ? 0.6 : 1 }}
        />
      </Menu.Item>
    );
  };

  return (
    <Menu>
      {renderMenuItem(ContractState.InProgress)}
      {renderMenuItem(ContractState.Registered)}
      {renderMenuItem(ContractState.Signing)}
      {renderMenuItem(ContractState.Stipulated)}
      {renderMenuItem(ContractState.Canceled)}
    </Menu>
  );
}
