import { ColumnProps } from 'antd/lib/table';
import { Input, Button, DatePicker, Col, Row, Space } from 'antd';
import React, { Key } from 'react';
import CheckboxGroup, { CheckboxOptionType } from 'antd/lib/checkbox/Group';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';

export enum FilterInputType {
  Text = 'text',
  Date = 'date',
  CheckBox = 'checkBox',
  DateRange = 'dateRange',
  Number = 'number',
  /** Filtro per ricercare una fattura per numero/anno */
  Invoice = 'invoice'
}

export function createColumnFilter<T extends {}>(
  inputType: FilterInputType,
  params?: { options?: CheckboxOptionType[] }
): Partial<ColumnProps<T>> {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <div>
            {inputType === FilterInputType.CheckBox && (
              <CheckboxGroup
                options={params?.options}
                value={...selectedKeys!}
                onChange={e =>
                  setSelectedKeys!(e ? e.map(v => v.toString()) : [])
                }
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
            )}
            {inputType === FilterInputType.Date && (
              <DatePicker
                placeholder="GG/MM/YYYY"
                format="DD/MM/YYYY"
                value={
                  selectedKeys!.length > 0
                    ? moment(selectedKeys![0], [
                        moment.ISO_8601,
                        'YYYY-MM-DD',
                        'DD-MM-YYYY'
                      ])
                    : null
                }
                onChange={e =>
                  setSelectedKeys!(e ? [e.format('DD/MM/YYYY')] : [])
                }
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
            )}
            {inputType === FilterInputType.Text && (
              <Input
                placeholder="Cerca"
                value={selectedKeys![0]}
                onChange={e =>
                  setSelectedKeys!(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => {
                  confirm!({ closeDropdown: true });
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
            )}
            {inputType === FilterInputType.Number && (
              <Input
                type="number"
                placeholder="Cerca"
                value={selectedKeys![0]}
                onChange={e =>
                  setSelectedKeys!(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => {
                  confirm!({ closeDropdown: true });
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
            )}
            {inputType === FilterInputType.DateRange && (
              <Space direction="vertical">
                <DatePicker
                  placeholder="Da: GG/MM/AAAAA"
                  format="DD/MM/YYYY"
                  value={
                    selectedKeys[0]
                      ? moment(selectedKeys[0], [
                          moment.ISO_8601,
                          'YYYY-MM-DD',
                          'DD-MM-YYYY'
                        ])
                      : null
                  }
                  onChange={e => {
                    const newKeys: any[] = [...selectedKeys];
                    newKeys[0] = e?.format('YYYY-MM-DD') ?? null;
                    setSelectedKeys!(newKeys);
                  }}
                  style={{ width: 188, display: 'block' }}
                />
                <DatePicker
                  placeholder="A: GG/MM/AAAA"
                  format="DD/MM/YYYY"
                  value={
                    selectedKeys[1]
                      ? moment(selectedKeys[1], [
                          moment.ISO_8601,
                          'YYYY-MM-DD',
                          'DD-MM-YYYY'
                        ])
                      : null
                  }
                  onChange={e => {
                    const newKeys: any[] = [...selectedKeys];
                    newKeys[1] = e?.format('YYYY-MM-DD') ?? null;
                    setSelectedKeys!(newKeys);
                  }}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
              </Space>
            )}
            {inputType === FilterInputType.Invoice && (
              <Space direction="vertical" size="small">
                <Input
                  placeholder="Numero"
                  value={selectedKeys![0]}
                  onChange={e => {
                    const newKeys: any[] = [...selectedKeys];
                    newKeys[0] = e.target.value;
                    setSelectedKeys!(newKeys);
                  }}
                  onPressEnter={() => {
                    confirm!({ closeDropdown: true });
                  }}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Input
                  placeholder="Anno"
                  type="number"
                  value={selectedKeys![1]}
                  onChange={e => {
                    const newKeys: any[] = [...selectedKeys];
                    newKeys[1] = e.target.value;
                    setSelectedKeys!(newKeys);
                  }}
                  onPressEnter={() => {
                    confirm!({ closeDropdown: true });
                  }}
                  style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
              </Space>
            )}
          </div>
          <Button
            type="primary"
            onClick={() => {
              confirm!({ closeDropdown: true });
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Cerca
          </Button>
          <Button
            onClick={() => {
              clearFilters!();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    )
  };
}

export interface IDateRangeFilter {
  from?: Date | null;
  to?: Date | null;
}

/**
 * Preso l'array dal filtro di tipo DateRange, restituisce un oggetto con il
 * range di date richiesto.
 * @param array array risultante dal filtro di tipo DateRange su una column.
 * @returns un'oggetto con il range di date nel formato fornito
 */
export function getDateRange(
  array: any[] | undefined | null
): IDateRangeFilter {
  if (!array || array.length === 0) {
    return {};
  }

  return {
    from:
      array.length > 0 && array[0]
        ? moment(array[0], 'YYYY-MM-DD').startOf('day').toDate()
        : null,
    to:
      array.length > 1 && array[1]
        ? moment(array[1], 'YYYY-MM-DD').endOf('day').toDate()
        : null
  };
}

export function getInvoiceFilter(
  array: any[] | undefined | null
): { invoiceNumber: string | null; invoiceYear: number | null } {
  if (!array || array.length === 0) {
    return { invoiceNumber: null, invoiceYear: null };
  }
  return { invoiceNumber: array[0] ?? null, invoiceYear: array[1] ?? null };
}

/**
 * Ritorna il valore del filtro richiesto
 * @key chiave del campo del filtro (dataIndex)
 */
export function getFilter(
  filters: Record<string, (boolean | React.Key)[] | null>,
  key: string
): boolean | Key | null {
  return filters[key] ? filters[key]![0] : null;
}
