import { Tag, TagProps } from 'antd';
import { StateTag } from 'client/ui/state/StateTag';
import { DebtState } from 'common/schema/accounting-line/AccountingLineTypes';
import * as React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

export interface IDebtStateTagProps {
  state: DebtState;
}

function stateToProps(state: DebtState): TagProps {
  switch (state) {
    case DebtState.LIQ:
      return { color: 'green', children: 'LIQ' };
    case DebtState.SOSP:
      return { color: 'orange', children: 'SOSP' };
    case DebtState.NOLIQ:
      return { children: 'NOLIQ' };
    case DebtState.LIQdaSOSP:
      return { color: 'green', children: 'LIQ da SOSP' };
    case DebtState.LIQdaNL:
      return { color: 'green', children: 'LIQ da NOLIQ' };
    case DebtState.SOSPdaLIQ:
      return { color: 'orange', children: 'SOSP da LIQ' };
    case DebtState.SOSPdaNL:
      return { color: 'orange', children: 'SOSP da NOLIQ' };
    case DebtState.NLdaLIQ:
      return { children: 'NOLIQ da LIQ' };
    case DebtState.NLdaSOSP:
      return { children: 'NOLIQ da SOSP' };
    case DebtState.ADEGLIQ:
      return { color: 'green', children: 'ADEG LIQ' };
    default:
      assertNever(state);
  }
}
/**
 * Tag di stato del Debito delle linee di contabilizzazione
 */
export function DebtStateTag(props: IDebtStateTagProps) {
  return <StateTag {...stateToProps(props.state)} />;
}
