import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaPageNumber, SchemaPageSize } from "cli/generate/decorators/SchemaDecorators";

@GenerateDto()
class EconomicStatementSimulationQuery {
  @SchemaPageNumber()
  pageNumber!: number;
  @SchemaPageSize()
  pageSize!: number;
}

/**
 * Rappresentazione DTO della classe EconomicStatementSimulationQuery 
 * Hash: 67ce4e237565dffd12c2ab35173cb289
 */
@ValidationSchema(() => EconomicStatementSimulationQuerySchema)
export class EconomicStatementSimulationQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<EconomicStatementSimulationQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof EconomicStatementSimulationQueryDto ? values : plainToClass(EconomicStatementSimulationQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await EconomicStatementSimulationQuerySchema.validate(classToPlain(this), options);
    return new EconomicStatementSimulationQueryDto(validated);
  }
}

export const EconomicStatementSimulationQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina')
  })
  .noUnknown()
  .meta({ schemaName: "EconomicStatementSimulationQuerySchema" })
  .required();
