import * as React from 'react';
import { Section } from 'client/ui/section/Section';
import {
  ArrowRightOutlined,
  DisconnectOutlined,
  FileSyncOutlined,
  LinkOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { MandateDto } from 'common/dto/generated/MandateDto';
import { useEffect, useState } from 'react';
import { MandateDrawer } from 'client/components/schema/shipment/mandate/MandateDrawer';
import { useFormikContext } from 'formik';
import { SectionButton } from 'client/ui/section/SectionButton';
import { Space, Table } from 'antd';
import { MandatesColumns } from 'client/components/schema/mandate/table/MandatesColumns';
import { ShipmentState } from 'common/schema/shipment/ShipmentTypes';
import { ShipmentMachine } from 'common/schema/shipment/ShipmentMachine';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { MandateApi } from 'client/components/schema/mandate/MandateApi';

export interface ShipmentMandateSectionProps {
  shipment: ShipmentDto;
}

/**
 * Sezione Dati Mandato della Pagina delle Spedizioni
 */
export function ShipmentMandateSection(props: ShipmentMandateSectionProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { shipment } = props;

  // Prendo l'id del mandato
  // (dalla spedizione originale se si tratta di un'integrazione)
  const mandateId = shipment.isIntegration
    ? shipment.originalShipment?.mandateId
    : shipment.mandateId;

  const { response, loading } = useApiQuery(MandateApi.find, {
    skip: !mandateId,
    data: { id: mandateId! }
  });

  const [mandate, setMandate] = useState<MandateDto | null | undefined>(
    response?.data
  );

  useEffect(() => {
    setMandate(response?.data);
  }, [response?.data]);

  const actionable = ShipmentMachine.can(shipment, 'edit', true);

  const formik = useFormikContext<ShipmentDto>();

  const formContext = useFormikFormContext();

  return (
    <>
      <Section
        icon={<FileSyncOutlined />}
        title="Dati Mandato"
        extra={
          shipment.state == ShipmentState.InProgress &&
          !shipment.isIntegration &&
          formContext.editable &&
          actionable.allowed && (
            <Space>
              {mandate ? (
                <SectionButton
                  type="default"
                  danger
                  icon={<DisconnectOutlined />}
                  onClick={e => {
                    const { mandate, ...otherValues } = formik.values;
                    formik.setValues(
                      new ShipmentDto({ ...otherValues, mandateId: null })
                    );
                    setMandate(null);
                    e.stopPropagation();
                  }}
                >
                  Scollega Mandato
                </SectionButton>
              ) : (
                <SectionButton
                  icon={<LinkOutlined />}
                  onClick={e => {
                    setIsOpen(true);
                    e.stopPropagation();
                  }}
                >
                  Collega Mandato
                </SectionButton>
              )}
            </Space>
          )
        }
      >
        <Table
          columns={[
            ...MandatesColumns,
            {
              title: '',
              dataIndex: 'azioni',
              align: 'right',
              width: 100,
              render: (_, record) =>
                mandate ? (
                  <Link to={`/shipping/mandates/all/${mandate.id}`}>
                    Vai <ArrowRightOutlined />
                  </Link>
                ) : null
            }
          ]}
          rowKey="id"
          dataSource={mandate ? [mandate] : []}
          size="middle"
          pagination={false}
          loading={loading}
        />
      </Section>

      <MandateDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        shipmentId={shipment.id}
        onSelect={(selected: MandateDto) => {
          const { mandate, ...otherValues } = formik.values;
          formik.setValues(
            new ShipmentDto({ ...otherValues, mandateId: selected.id })
          );
          setMandate(selected);
        }}
      />
    </>
  );
}
