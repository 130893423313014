import { ArrowRightOutlined, FileOutlined } from '@ant-design/icons';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { InvoiceBatchApi } from 'client/components/schema/invoice-batch/InvoiceBatchApi';
import { InvoiceColumns } from 'client/components/schema/invoice/table/InvoiceColumns';
import { InvoiceToEvaluateColumns } from 'client/components/schema/invoice/table/InvoiceToEvaluateColumns';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { Section } from 'client/ui/section/Section';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceQueryDto } from 'common/dto/query/InvoiceQueryDto';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export interface BatchInvoiceSectionProps {
  batchId: number | null;
}

const BatchInvoiceListColumns: ColumnsType<InvoiceDto> = [
  InvoiceColumns.receivedDate,
  InvoiceColumns.state,
  InvoiceColumns.cig,
  InvoiceColumns.cup,
  InvoiceColumns.invoiceTypeCode,
  InvoiceColumns.issuedNumber,
  InvoiceColumns.totalAmount
];

export function BatchInvoiceSection(props: BatchInvoiceSectionProps) {
  const { batchId } = props;

  const [query, setQuery] = useState<InvoiceQueryDto>(new InvoiceQueryDto());

  const { response, loading } = useApiQuery(InvoiceBatchApi.invoices.list, {
    skip: batchId == null,
    data: {
      id: batchId!,
      query: query
    }
  });

  const invoices = response?.data.items ?? [];

  return (
    <Section icon={<FileOutlined />} title="Fatture">
      <Table
        rowKey="id"
        loading={loading}
        columns={[
          ...BatchInvoiceListColumns,
          {
            title: '',
            dataIndex: 'azioni',
            align: 'right',
            width: 60,
            render: (_, record) => (
              <Link to={`/invoicing/invoices/all/${record.id}`}>
                Vai <ArrowRightOutlined />
              </Link>
            )
          }
        ]}
        dataSource={invoices}
        size="middle"
        onChange={(pagination, filters, sorter) => {
          setQuery(
            new InvoiceQueryDto({
              ...query,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize
            })
          );
        }}
        pagination={false}
        // pagination={{
        //   size: 'small',
        //   position: ['bottomRight'],
        //   showSizeChanger: true,
        //   total: total,
        //   pageSize: query.pageSize,
        //   current: query.pageNumber,
        //   pageSizeOptions: ['5', '10', '20', '30', '40']
        // }}
      />
    </Section>
  );
}
