import React from 'react';
import { Typography } from 'antd';
const { Text } = Typography;
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import { Link } from 'react-router-dom';
import { ShipmentDossierName } from './ShipmentDossierName';
import { StateTag } from 'client/ui/state/StateTag';

export interface IShipmentOriginalDossierProps {
  shipment: ShipmentDto;
}

/**
 * Fascicolo iniziale Spedizione
 */
export function ShipmentOriginalDossier(props: IShipmentOriginalDossierProps) {
  const { shipment } = props;

  return shipment.isIntegration && shipment.originalShipment?.dossierUid ? (
    <ShipmentDossierName shipment={shipment.originalShipment} />
  ) : shipment.dossierUid ? (
    <ShipmentDossierName shipment={shipment} />
  ) : (
    <StateTag color="orange">Da Creare</StateTag>
  );
}
