import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { Formik } from 'formik';
import React from 'react';
import {
  useModuleConfigOptions,
  useModulesConfig
} from '../config/ClientConfigModule';

export interface IDocumentalRegisterSelectInputProps
  extends SelectInputProps<any> {
  label: string;
}

/**
 * Permette di selezionare un registro da una lista
 * inserita in configurazione (modulo 'PiTreAllowedRegisters')
 */
export function DocumentalRegisterSelectInput(
  props: IDocumentalRegisterSelectInputProps
) {
  const registers = useModuleConfigOptions('pitre.allowed.registers');
  const registerOptions = registers.map(register => ({
    value: register.value!,
    label: register.name
  }));

  return (
    <FormFieldItem
      component={SelectInput}
      {...props}
      options={registerOptions}
    />
  );
}
