import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";

@GenerateDto()
class MandateFindQuery {
  @SchemaLabel("Numero mandato")
  mandateNumber!: number;
  @SchemaLabel("Anno mandato")
  mandateYear!: number;
  @SchemaLabel("Numero impegno")
  commitmentNumber?: string | null;
}

/**
 * Rappresentazione DTO della classe MandateFindQuery 
 * Hash: 3bd8e6c7e09e0c8b175608bfdd376d96
 */
@ValidationSchema(() => MandateFindQuerySchema)
export class MandateFindQueryDto {
  @ApiProperty({ type: Number, description: 'Numero mandato' })
  mandateNumber!: number;
  @ApiProperty({ type: Number, description: 'Anno mandato' })
  mandateYear!: number;
  @ApiProperty({ required: false, type: String, description: 'Numero impegno' })
  commitmentNumber?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<MandateFindQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof MandateFindQueryDto ? values : plainToClass(MandateFindQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await MandateFindQuerySchema.validate(classToPlain(this), options);
    return new MandateFindQueryDto(validated);
  }
}

export const MandateFindQuerySchema = yup
  .object({
    mandateNumber: yup.number().required().label('Numero mandato'),
    mandateYear: yup.number().required().label('Anno mandato'),
    commitmentNumber: yup.string().nullable().label('Numero impegno')
  })
  .noUnknown()
  .meta({ schemaName: "MandateFindQuerySchema" })
  .required();
