import { Tag, Typography } from 'antd';
const { Paragraph } = Typography;
import { ColumnProps } from 'antd/lib/table';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';
import { ISODate } from 'client/ui/display/date/ISODate';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { ContractDto } from 'common/dto/generated/ContractDto';
import React from 'react';
import { ContractStateTag } from '../view/ContractStateTag';

export const ContractColumns: ColumnProps<ContractDto>[] = [
  {
    title: 'Numero',
    dataIndex: 'number',
    sorter: true,
    width: 100,
    ...createColumnFilter(FilterInputType.Number)
  },
  {
    title: 'Data',
    dataIndex: 'registrationDate',
    key: 'registrationDate',
    ...createColumnFilter(FilterInputType.DateRange),
    sorter: true,
    width: 120,
    render: (_, record) => <ISODate date={record.registrationDate} />
  },
  {
    title: 'Stato',
    dataIndex: 'stato',
    width: 120,
    render: (_, record) => <ContractStateTag state={record.state} />
  },
  {
    title: 'Oggetto',
    dataIndex: 'subject',
    key: 'extendedSubject',
    width: 400,
    ...createColumnFilter(FilterInputType.Text)
  },
  {
    title: 'CIG',
    dataIndex: 'cigCode',
    key: 'cigCode',
    width: 120,
    ...createColumnFilter(FilterInputType.Text)
  },
  {
    title: 'Importo Netto',
    dataIndex: 'netAmount',
    key: 'netAmount',
    sorter: true,
    width: 140,
    align: 'right',
    render: (_, record) => <CurrencyRender value={record.netAmount} />
  },
  {
    title: 'Contraenti',
    dataIndex: 'suppliers',
    key: 'supplierName',
    width: 320,
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => (
      <>
        {record.suppliers.map(supplier => (
          <Paragraph
            style={{ marginBottom: '1px', marginTop: '1px' }}
            key={supplier.id}
          >
            {supplier.getName()}
          </Paragraph>
        ))}
      </>
    )
  },
  {
    title: 'Referente Amministrativo',
    key: 'adminReferentName',
    dataIndex: 'adminReferentName',
    width: 150,
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => <>{record.adminReferent?.name ?? '-'}</>
  }
];
