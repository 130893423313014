import { SelectProps } from 'antd';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import {
  IDocumentalRole,
  IDocumentalRoles
} from 'common/dto/documental/DocumentalBridgeDto';
import { DocumentalUserRolesQueryDto } from 'common/dto/documental/DocumentalUserRolesQueryDto';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { useField, useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { DocumentalApi } from './DocumentalApi';

interface Props extends SelectProps<any> {
  name: string;
  /** Nome del field in cui viene salvato lo user code (nome.cognome) */
  userCodeName: string;
  /** Nome del field per la label del ruolo  */
  roleFieldName: string;
  /** Campo con la label del ruolo */
  userRoleLabel?: string | null;
}

export function DocumentalUserRoleSelectInput(props: Props) {
  const {
    userCodeName,
    name,
    placeholder,
    roleFieldName,
    ...otherProps
  } = props;
  const formik = useFormikContext<ContractDto>();
  const [userCodeField] = useField(userCodeName);

  useFormikFieldChange(() => {
    formik.setFieldValue(name, null);
    formik.setFieldValue(roleFieldName, null);
  }, [userCodeName]);

  return (
    <AsyncSelectInput
      {...otherProps}
      disabled={!userCodeField.value}
      name={name}
      placeholder={placeholder}
      query={{
        apiFn: DocumentalApi.getRole,
        options: {
          skip: !userCodeField.value,
          data: {
            query: new DocumentalUserRolesQueryDto({
              userCode: userCodeField.value
            })
          }
        }
      }}
      responseTransform={(roles: any) => roles.roles as IDocumentalRole[]}
      optionTransform={(role: IDocumentalRole) => ({
        label: role.code + ' | ' + role.description,
        value: role.code
      })}
      onAfterSelect={option => {
        if (!option) return;
        formik.setFieldValue(roleFieldName, option.description);
      }}
      dropdownMatchSelectWidth={false}
    />
  );
}

DocumentalUserRoleSelectInput.renderFieldValue = (
  value?: string | null,
  props?: Props
) => {
  if (!props?.userRoleLabel) return value ?? '-';
  return props?.userRoleLabel;
};
