import * as React from 'react';
import { useBreadcrumbs } from './BreadcrumbContext';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import { HomeOutlined } from '@ant-design/icons';

export interface IBreadcrumbsProps {}

// TODO prompt per prevenire la perdita in caso di pressione accidentale durante l'edit
/**
 * Componente che visualizza l'elenco dei breadcrumb registrati.
 */
export function Breadcrumbs(props: IBreadcrumbsProps) {
  const items = useBreadcrumbs();

  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <HomeOutlined />
      </BreadcrumbItem>
      {items.map(item => (
        <Breadcrumb.Item key={item.path || item.key}>
          {item.path ? <Link to={item.path}>{item.title}</Link> : item.title}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}
