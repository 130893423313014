import { TextProps } from 'antd/lib/typography/Text';
import {
  ApiGet,
  ApiQueryOptions,
  useApiQuery
} from 'client/core/network/hooks/useApiQuery';
import * as React from 'react';
import { Typography } from 'antd';
import { useEffect, useState } from 'react';
import { CloseSquareOutlined, LoadingOutlined } from '@ant-design/icons';

const { Text } = Typography;

export interface IAsyncTextProps<A, R> extends TextProps {
  query: {
    apiFn: ApiGet<A, R>;
    options: ApiQueryOptions<A, R>;
  };
  responseTransform?: (response: R) => string;
}

export function AsyncText<A, R>(props: IAsyncTextProps<A, R>) {
  const { query, responseTransform, ...otherProps } = props;

  const [value, setValue] = useState<string>();

  const { response, loading, error } = useApiQuery(query.apiFn, query.options);
  const transformResponse =
    responseTransform ??
    function (i: any): string {
      return i;
    };

  useEffect(() => {
    if (response?.data) {
      const newValue = transformResponse(response.data);
      setValue(newValue);
    }
  }, [response?.data]);

  if (loading) return <LoadingOutlined />;
  if (error) return <CloseSquareOutlined />;
  return <Text {...otherProps}>{value}</Text>;
}
