import * as React from 'react';
import { useState } from 'react';
import { Button, Table } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { SupplierQueryDto } from 'common/dto/query/SupplierQueryDto';
import { SupplierListColumns } from 'client/components/schema/supplier/table/SupplierListColumns';
import { SupplierApi } from 'client/components/schema/supplier/SupplierApi';
import { LinkButton } from 'client/ui/link/LinkButton';
import { PlusOutlined } from '@ant-design/icons';
import { SupplierDto } from 'common/dto/generated/SupplierDto';
import { TableSticky } from 'client/ui/table/TableSticky';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { SorterResult } from 'antd/lib/table/interface';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { useEffect } from 'react';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';
import { SupplierColumnSort } from 'common/dto/query/types/SupplierCustomColumnSort';

interface Params {}

export interface SupplierListPageProps extends RouteComponentProps<Params> {}

export function SupplierListPage(props: SupplierListPageProps) {
  const [query, setQuery] = useState<SupplierQueryDto>(
    new SupplierQueryDto(
      getPaginationUrlQuery(props.location.search, {
        pageNumber: 1,
        pageSize: 20
      })
    )
  );

  const { response, loading, error } = useApiQuery(SupplierApi.list, {
    data: { query }
  });

  const suppliers = response?.data.items;

  const columns = getPaginationUrlColumns<SupplierDto, SupplierQueryDto>(
    SupplierListColumns,
    query
  );

  useEffect(() => {
    setQuery(
      new SupplierQueryDto(
        getPaginationUrlQuery(props.location.search, {
          pageNumber: 1,
          pageSize: 20
        })
      )
    );
  }, [props.location.search]);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/contracts/list', title: `Contratti` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: `Contraenti`,
    menuKey: 'contracts',
    sideMenuKey: 'supplier'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco dei Contraenti"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle children="Elenco Contraenti" />}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={
          <LinkButton
            to="/contracts/supplier/create"
            type="primary"
            icon={<PlusOutlined />}
          >
            Nuovo Contraente
          </LinkButton>
        }
      />
      <PageContent noPadding noSpace>
        <TableSticky
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={suppliers}
          size="middle"
          onChange={(pagination, filters, sorter) => {
            const { field, order } = sorter as SorterResult<SupplierDto>;

            const fullname =
              filters.fullname && filters.fullname.length > 0
                ? filters.fullname[0].toString()
                : null;
            const fiscalIdCode = filters.fiscalIdCode
              ? filters.fiscalIdCode[0].toString()
              : null;

            const supplierQuery = new SupplierQueryDto({
              ...query,
              orderBy: field?.toString() as SupplierColumnSort,
              orderByDirection: order ? ColumnSortDirections[order] : undefined,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize,
              fullname,
              fiscalIdCode
            });

            setPaginationUrlQuery(props, supplierQuery);
          }}
          onRow={(record: SupplierDto) => ({
            style: {
              cursor: 'pointer'
            },
            onClick: () => {
              props.history.push(`/contracts/supplier/${record.id}`);
            }
          })}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
