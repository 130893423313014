import { Tag, TagProps } from 'antd';
import { StateTag } from 'client/ui/state/StateTag';
import { SDIChargeabilityType } from 'common/schema/invoice/VATSummaryTypes';
import * as React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

export interface ISDIChargeabilityTagProps {
  chargeability: SDIChargeabilityType | null | undefined;
  compact?: boolean;
}

function mapToTagProps(
  value: SDIChargeabilityType | null | undefined,
  compact?: boolean
): TagProps {
  switch (value) {
    case null:
    case undefined:
      return { children: 'N.A.', color: 'default' };

    case SDIChargeabilityType.Deferred:
      return {
        children: compact ? 'DIFF' : 'Differita',
        color: 'magenta'
      };

    case SDIChargeabilityType.Immediate:
      return {
        children: compact ? 'IMM' : 'Immediata',
        color: 'blue'
      };

    case SDIChargeabilityType.Split:
      return {
        children: compact ? 'S.P.' : 'Scissione dei Pagamenti',
        color: 'green'
      };
  }
  assertNever(value);
}

export function SDIChargeabilityTag(props: ISDIChargeabilityTagProps) {
  return <StateTag {...mapToTagProps(props.chargeability, props.compact)} />;
}
