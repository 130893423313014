import { PccExportColumns } from 'client/components/schema/accounting-line/report/table/PCCExportColumns';
import { PccModelType } from 'server/schema/pcc/pcc-model-base/PccTypes';
import { Model003Action } from 'server/schema/pcc/model003/Model003Types';

export function mapPccExportFilterToOptions(filter: string | undefined) {
  switch (filter) {
    case 'shipped-reception':
      return {
        title: 'Comunicazioni di Ricezione Fatture Cartacee',
        columns: PccExportColumns,
        filter: filter,
        query: {
          action: null,
          model: PccModelType.Reception
        }
      };

    case 'shipped-accounting':
      return {
        title: 'Comunicazioni di Contabilizzazione a PCC',
        columns: PccExportColumns,
        filter: filter,
        query: {
          action: Model003Action.CO,
          model: PccModelType.Operation
        }
      };
    case 'shipped-payment':
      return {
        title: 'Comunicazioni di Pagamento a PCC',
        columns: PccExportColumns,
        filter: filter,
        query: {
          action: Model003Action.CP,
          model: PccModelType.Operation
        }
      };
    case 'shipped-expiration':
      return {
        title: 'Comunicazioni di Scadenza a PCC',
        columns: PccExportColumns,
        filter: filter,
        query: {
          action: Model003Action.CS,
          model: PccModelType.Operation
        }
      };

    default:
      return {
        title: 'File di Risposta PCC',
        columns: PccExportColumns,
        filter: 'all',
        query: {
          action: null,
          model: null
        }
      };
  }
}
