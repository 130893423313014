import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { InvoiceExportCreateQueryDto } from 'common/dto/query/InvoiceExportCreateQueryDto';
import { IInvoiceExportFieldsSelection } from 'common/dto/query/types/InvoiceExportQueryTypes';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

export interface IExportGroupCheckboxProps {
  group: string;
}

/**
 * Ritorna vero se _tutti_ i campi dell'oggetto fields passato sono
 * valorizzati come `checked` (e.g. tutti veri o tutti falsi)
 */
function testCheckedState(
  fields: any | undefined,
  group: keyof IInvoiceExportFieldsSelection,
  checked: boolean
) {
  return Object.values(fields?.[group] ?? {}).every(field => field === checked);
}

/**
 * Ritorna vero se la checkbox di gruppo è indeterminata.
 * Ovvero se i campi non sono né tutti veri né tutti falsi, ma un misto
 */
function isIndeterminate(
  fields: any | undefined,
  group: keyof IInvoiceExportFieldsSelection
) {
  return !(
    testCheckedState(fields, group, true) ||
    testCheckedState(fields, group, false)
  );
}

/**
 * Checkbox che per gestire la modifica massiva dei gruppi di checkbox
 * per i fields di selezione di export.
 */
export function ExportGroupCheckbox(props: IExportGroupCheckboxProps) {
  const group = props.group as keyof IInvoiceExportFieldsSelection;

  const formik = useFormikContext<InvoiceExportCreateQueryDto>();

  const [indeterminate, setIndeterminate] = useState(
    isIndeterminate(formik.values.fields, group)
  );
  const [checked, setChecked] = useState(
    testCheckedState(formik.values.fields, group, true)
  );

  const handleChange = (e: CheckboxChangeEvent) => {
    const value = e.target.checked;
    if (!formik.values.fields) return;

    // Imposto il valore della checkbox di gruppo
    setChecked(value);

    // rimuovo l'indeterminato (essendo stata premuta sarà o true o false per forza)
    setIndeterminate(false);

    // Creo un oggetto che è la copia del fields del gruppo, ma tutti
    // con lo stesso valore della checkbox di gruppo.
    const newValue = {} as any;
    Object.keys(formik.values.fields[group]).forEach(key => {
      newValue[key] = value;
    });

    // Imposto l'oggetto appena creato come nuovo fields
    // (più veloce che modificare i formik fields uno alla volta)
    formik.setFieldValue(`fields.${group}`, newValue);
  };

  // Al cambio di una checkbox del gruppo, gestisco il valore della checkbox di gruppo.
  useEffect(() => {
    setIndeterminate(isIndeterminate(formik.values.fields, group));
    setChecked(testCheckedState(formik.values.fields, group, true));
  }, [formik.values.fields?.[group]]);

  return (
    <>
      <Checkbox
        name={`group.${group}`}
        indeterminate={indeterminate}
        onChange={handleChange}
        checked={checked}
      />
    </>
  );
}
