import { Roles, RoleName } from './Roles';
import { Role } from './Role';
import { User } from 'server/schema/user/user/User.entity';
import { InvoiceState } from 'common/schema/invoice/InvoiceTypes';
import { UserDto } from 'common/dto/generated/UserDto';
import { assertNever } from 'server/utils/typings/assertNever';
import { PermissionsType } from './Permissions';

export class RolesLogic {
  /** Elenco dei ruoli disponibili per la selezione lato Backend */
  static getAdminRoles() {
    return [Roles.SuperAdmin, Roles.System, Roles.Admin];
  }

  static isAdminRole(role: RoleName) {
    return this.getAdminRoles().some(r => r.name === role);
  }

  static getAdminRoleOptions() {
    return this.getAdminRoles().map(role => ({
      value: role.name,
      label: role.displayName
    }));
  }

  static canUserRead(
    user: UserDto | null | undefined,
    state: InvoiceState | 'toAccept' | 'toProtocol'
  ) {
    if (!user || !user.role) return false;
    return Roles[user.role].hasPermission(`invoice.access:${state}`);
  }

  static getInvoiceStateAccess(user: Pick<UserDto, 'role'> | null | undefined) {
    if (!user) return [];
    return Roles[user.role].permissions
      .filter(p => p.includes('invoice.access:'))
      .map(permission => permission.split(':')[1]);
  }

  static getDefaultAdminReferentFilter(user: UserDto | null) {
    switch (user?.role) {
      case Roles.Admin.name:
        return true;
      case Roles.DocumentalUser.name:
      case Roles.ProtocolUser.name:
      case Roles.SuperAdmin.name:
      case Roles.System.name:
        return false;
      default:
        return false;
    }
  }
}
