import { Button, Card, Col, Drawer, message, Modal, Row, Space } from 'antd';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ConfigEnumInput } from 'client/ui/form/input/configurable/ConfigEnumInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { SupplierDto, SupplierSchema } from 'common/dto/generated/SupplierDto';
import { Formik } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { SupplierApi } from '../SupplierApi';
import { SupplierFormFieldItems } from './SupplierFormFieldItems';

export interface ISupplierEditModalProps {
  editable?: boolean;
  onSelect?: (supplier: SupplierDto) => void;
}

/**
 * Handle per l'apertura di una modal da parte del componente padre.
 * Tramite `ref={..}` esponiamo questo handle al _Parent_ in modo tale che possa
 * comandarla dall'esterno.
 */
export type SupplierEditHandle = {
  edit: (supplier: SupplierDto) => void;
};

export const SupplierEditModal = React.forwardRef(
  (
    props: ISupplierEditModalProps,
    ref: React.ForwardedRef<SupplierEditHandle>
  ) => {
    // Linea aperta
    const [current, setCurrent] = useState<SupplierDto | null>(null);

    // Implentiamo l'Handle definito sopra
    useImperativeHandle(ref, () => ({
      edit: (supplier: SupplierDto) => {
        setCurrent(supplier);
      }
    }));

    const isNew = current == null || current.id == null;
    const visible = current != null;
    const handleCancel = useCallback(() => {
      setCurrent(null);
    }, []);

    const [create] = useApiMutation(SupplierApi.create, {
      invalidates: [SupplierApi.list]
    });
    const [update] = useApiMutation(SupplierApi.update, {
      data: { id: current?.id }
    });

    if (!current) return null;

    return (
      <>
        <Formik
          enableReinitialize
          initialValues={current}
          validate={schemaToValidator(SupplierSchema)}
          onSubmit={async values => {
            if (!props.editable) return;
            try {
              const input = await values.validate();
              if (isNew) {
                const result = await create({ data: { input } });
                message.success('Fornitore aggiunto con successo');
                props.onSelect?.(result.data);
              } else {
                const result = await update({
                  data: { id: current.id, input }
                });
                message.success('Fornitore aggiornato con successo');
                props.onSelect?.(result.data);
              }
              setCurrent(null);
            } catch (e) {
              message.error(
                'Si è verificato un errore nella gestione del fornitore'
              );
              console.error(e);
            }
          }}
        >
          <FormikForm editable={props.editable} helpInTooltips>
            <ModalDialog
              title={
                props.editable && isNew
                  ? 'Crea Anagrafica Fornitore'
                  : props.editable
                  ? 'Modifica Anagrafica Fornitore'
                  : 'Dettaglio Anagrafica Fornitore'
              }
              visible={visible}
              onCancel={handleCancel}
              footer={
                <Space>
                  <Button onClick={handleCancel}>Annulla</Button>
                  {props.editable && <FormikSendButton>Salva</FormikSendButton>}
                </Space>
              }
            >
              <SupplierFormFieldItems />
            </ModalDialog>
          </FormikForm>
        </Formik>
      </>
    );
  }
);
