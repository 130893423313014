import {
  CalculatorOutlined,
  DeleteOutlined,
  FullscreenOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Button, message, Table } from 'antd';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import { AccountingLineEditDrawer } from 'client/components/schema/accounting-line/edit/AccountingLineEditDrawer';
import { InvoiceAccountingLineColumns } from 'client/components/schema/accounting-line/table/InvoiceAccountingLineColumns';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { AccountingCongruenceTag } from 'client/components/schema/accounting-line/view/AccountingCongruenceTag';
import { useFormikFieldValidation } from 'client/ui/form/hooks/validation/useFormikFieldValidation';
import { Section } from 'client/ui/section/Section';
import { SectionButton } from 'client/ui/section/SectionButton';
import { AccountingLineDto } from 'common/dto/generated/AccountingLineDto';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import * as React from 'react';
import { useCallback } from 'react';
import { AccountingLineMachine } from 'common/schema/accounting-line/AccountingLineMachine';
import { AccountingToProtocolAlertTag } from 'client/components/schema/accounting-line/view/AccountingToProtocolAlertTag';
import { InvoiceState } from 'common/schema/invoice/InvoiceTypes';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';

export interface IInvoiceAccountingLinesSectionProps {
  invoice: InvoiceDto;
}

/**
 * Modifica delle linee di contabilizzazione per una singola fattura
 */
export function InvoiceAccountingLinesSection(
  props: IInvoiceAccountingLinesSectionProps
) {
  const { invoice } = props;
  const editor = useRelationEditor<AccountingLineDto>('accountingLines');
  const validation = useFormikFieldValidation('accountingLines');

  const isLineEditable = useCallback(
    (line?: AccountingLineDto | null) => {
      return (
        validation.editable && AccountingLineMachine.can(line, 'edit.general')
      );
    },
    [validation.editable]
  );

  // se la fattura non è protocollata mostriamo un messaggio di avviso
  const state = props.invoice.state;
  const accountingAlert = InvoiceMachine.can(invoice, 'edit.accounting', true);

  return (
    <Section
      icon={<CalculatorOutlined />}
      title={
        <>
          Contabilizzazione{' '}
          <AccountingCongruenceTag congruence={invoice.accountingCongruence} />
          <AccountingToProtocolAlertTag result={accountingAlert} />
        </>
      }
      collapsed={!accountingAlert.allowed}
      extra={
        validation.editable ? (
          <SectionButton
            key="add"
            icon={<PlusOutlined />}
            onClick={e => {
              editor.handle.open(new AccountingLineDto());
              e.stopPropagation();
            }}
          >
            Aggiungi Linea Contabile
          </SectionButton>
        ) : null
      }
    >
      <Table
        rowKey="id"
        columns={[
          ...InvoiceAccountingLineColumns,
          {
            title: '',
            dataIndex: 'azioni',
            width: 105,
            align: 'right',
            render: (_, record) => (
              <span>
                <Button
                  size="small"
                  type="link"
                  children={isLineEditable(record) ? 'Modifica' : 'Dettaglio'}
                  icon={<FullscreenOutlined />}
                  onClick={() => editor.handle.open(record)}
                />
                {isLineEditable(record) && (
                  <ButtonDeleteConfirm
                    title="Sei sicuro di voler eliminare la linea?"
                    buttonIcon={<DeleteOutlined />}
                    buttonDanger
                    onConfirm={() => editor.remove(record)}
                    buttonType="link"
                  />
                )}
              </span>
            )
          }
        ]}
        dataSource={editor.items}
        size="middle"
        pagination={false}
      />
      <AccountingLineEditDrawer
        invoice={props.invoice}
        editor={editor}
        editable={isLineEditable(editor.handle.current)}
      />
    </Section>
  );
}
