import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';

/**
 * Rappresentazione DTO della classe EconomicEntry definita in: src/server/schema/economy/economic-entry/EconomicEntry.entity.ts
 * Hash: 4210c71f2934675d7d3548899e0f45a4
 */
@ValidationSchema(() => EconomicEntrySchema)
export class EconomicEntryDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ type: String, description: 'Codice DPC' })
  code!: string;
  @ApiProperty({ required: false, type: String, description: 'Codice PCM' })
  pcmCode!: string;
  @ApiProperty({ type: String, description: 'Titolo' })
  title!: string;
  @ApiProperty({ type: String, description: 'Descrizione' })
  path!: string;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  deleteAt?: Date | null;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<EconomicEntryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof EconomicEntryDto ? values : plainToClass(EconomicEntryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await EconomicEntrySchema.validate(classToPlain(this), options);
    return new EconomicEntryDto(validated);
  }
}

/** Interfaccia simmetrica al DTO EconomicEntryDto */
export type IEconomicEntryType = IDto<EconomicEntryDto>;

/**
 * DTO Paginato della classe EconomicEntry
 */
export class PaginatedEconomicEntryDto {
  @ApiProperty({ type: [EconomicEntryDto] })
  @Type(() => EconomicEntryDto)
  items!: EconomicEntryDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const EconomicEntrySchema = yup
  .object({
    id: yup.number(),
    code: yup.string().required().label('Codice DPC'),
    pcmCode: yup.string().default('').label('Codice PCM'),
    path: yup.string().required().label('Descrizione')
  })
  .noUnknown()
  .meta({ schemaName: "EconomicEntrySchema" })
  .required();
