import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { SDIChargeabilityType } from 'common/schema/invoice/VATSummaryTypes';
import { AmountsCongruenceType } from 'common/schema/mandate/AmountsCongruenceType';
import { SicogeState, GebilaState, MandateState } from 'common/schema/mandate/MandateTypes';
import { AccountingLineDto, AccountingLineSchema } from './AccountingLineDto';

/**
 * Rappresentazione DTO della classe Mandate definita in: src/server/schema/accounting/mandate/Mandate.entity.ts
 * Hash: f5942c12254ef9d052965e7a916db933
 */
@ValidationSchema(() => MandateSchema)
export class MandateDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: String })
  migrationId?: string | null | undefined;
  @ApiProperty({ enum: ['Pending', 'Paid', 'Canceled', 'Manual', 'Imported'] })
  state!: MandateState;
  @ApiProperty({ required: false, type: String })
  description?: string | null | undefined;
  @ApiProperty({ required: false, type: Number })
  authorId!: number;
  @ApiProperty({ required: false, type: String })
  repertory?: string | null | undefined;
  @ApiProperty({ required: false, type: Number })
  managementPlan?: number | null | undefined;
  @ApiProperty({ required: false, type: Number })
  mandateNumber?: number | null | undefined;
  @ApiProperty({ required: false, type: Number })
  mandateYear?: number | null | undefined;
  @ApiProperty({ required: false, type: String })
  originYear?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  commitmentType?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  commitmentNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date' })
  mandateDate?: Date | null | undefined;
  @ApiProperty({ type: Boolean })
  isSiabImported!: boolean;
  @ApiProperty({ required: false, type: String, format: 'date' })
  validateDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date' })
  signatureDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date' })
  paymentDate?: Date | null | undefined;
  @ApiProperty({ type: Boolean })
  paymentExecuted!: boolean;
  @ApiProperty({ required: false, type: String })
  reason?: string | null | undefined;
  @ApiProperty({ required: false, type: Number })
  camicia?: number | null | undefined;
  @ApiProperty({ required: false, type: Number })
  expenseChapter?: number | null | undefined;
  @ApiProperty({ required: false, type: String })
  notes?: string | null | undefined;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$' })
  @TypeTransformDecimal()
  netAmount?: Decimal | null | undefined;
  @ApiProperty({ type: String, pattern: '^-?\\d+(.\\d{1,8})?$' })
  @TypeTransformDecimal()
  amount!: Decimal;
  @ApiProperty({ required: false, type: String })
  balance?: string | null | undefined;
  @ApiProperty({ required: false, enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 19, 99, 0, 90, 91] })
  sicogeState?: SicogeState | null | undefined;
  @ApiProperty({ required: false, enum: [0, 1, 2, 3, 5, 6, 7, 8, 9, 10] })
  gebilaState?: GebilaState | null | undefined;
  @ApiProperty({ required: false, type: String })
  beneficiary?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  importedAt!: Date;
  @ApiProperty({ required: false, type: () => [AccountingLineDto] })
  @Type(() => AccountingLineDto)
  accountingLines!: AccountingLineDto[];
  @ApiProperty({ enum: ['ToAssign', 'CongruentAmounts', 'IncongruentAmounts'] })
  amountsCongruence!: AmountsCongruenceType;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<MandateDto>) {
    if (values != null) {
      Object.assign(this, values instanceof MandateDto ? values : plainToClass(MandateDto, values));
    }
  }

  /**
   * Ottiene il totale delle linee di contabilizzazione
   */
  getAccountingLinesTotal() {
    if (!this.accountingLines) {
      return new Decimal(0);
    }

    return this.accountingLines
    .map(line => line.amount)
    .reduce((total, amount) => total.plus(amount), new Decimal(0));
  }

  /**
   * Ottiene l'esigibilità iva delle linee di contabilizzazione
   */
  getChargeability() {
    const chargeabilities = (this.accountingLines ?? [])
            .filter(line => line.invoice.chargeability != null)
            .map(line => line.invoice.chargeability);
    const areChargeabilitiesCongruent = chargeabilities.every(
            chargeability => chargeability === chargeabilities[0]
          );
    if (!areChargeabilitiesCongruent) {
      throw new Error(
        'Le esigibilità IVA delle linee di contabilizzazione non sono congruenti.'
      );
    }

    return chargeabilities[0];
  }

  /**
   * Verifica che l'importo delle linee di contabilizzazione sia congruente
   * con l'importo del mandato con o senza IVA a seconda del tipo di esigibilità
   */
  getAmountsCongruence() {
    if (!this.accountingLines || this.accountingLines.length < 1) {
      return AmountsCongruenceType.ToAssign;
    }

    const linesTotal = this.getAccountingLinesTotal();
    const chargeability = this.getChargeability();
    if (chargeability === SDIChargeabilityType.Split && !this.netAmount) {
      return AmountsCongruenceType.ToAssign;
    }

    const amount = chargeability === SDIChargeabilityType.Split
              ? this.netAmount!
              : this.amount;
    if (linesTotal.greaterThan(amount)) {
      throw new Error(
        "L'importo totale delle linee di contabilizzazione è superiore all'importo del mandato."
      );
    }

    const isTotalCongruent = linesTotal.equals(amount);
    return isTotalCongruent
    ? AmountsCongruenceType.CongruentAmounts
    : AmountsCongruenceType.IncongruentAmounts;
  }

  async validate(options?: any) {
    const validated = await MandateSchema.validate(classToPlain(this), options);
    return new MandateDto(validated);
  }
}

/** Interfaccia simmetrica al DTO MandateDto */
export type IMandateType = IDto<MandateDto>;

/**
 * DTO Paginato della classe Mandate
 */
export class PaginatedMandateDto {
  @ApiProperty({ type: [MandateDto] })
  @Type(() => MandateDto)
  items!: MandateDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const MandateSchema = yup
  .object({
    id: yup.number(),
    description: yup.string().nullable(),
    authorId: yup.number(),
    repertory: yup.string().nullable(),
    managementPlan: yup.number().nullable(),
    mandateNumber: yup.number().nullable(),
    mandateYear: yup.number().nullable(),
    originYear: yup.string().nullable(),
    commitmentType: yup.string().nullable(),
    commitmentNumber: yup.string().nullable(),
    mandateDate: yup.date().dateOnlyFormat().nullable(),
    isSiabImported: yup.boolean().required(),
    validateDate: yup.date().dateOnlyFormat().nullable(),
    signatureDate: yup.date().dateOnlyFormat().nullable(),
    paymentDate: yup.date().dateOnlyFormat().nullable(),
    paymentExecuted: yup.boolean().required(),
    reason: yup.string().nullable(),
    camicia: yup.number().nullable(),
    expenseChapter: yup.number().nullable(),
    notes: yup.string().nullable(),
    netAmount: yup.mixed().nullable(),
    amount: yup.mixed().required(),
    balance: yup.string().nullable(),
    sicogeState: yup.number().oneOfEnum(SicogeState).nullable(),
    gebilaState: yup.number().oneOfEnum(GebilaState).nullable(),
    beneficiary: yup.string().nullable(),
    accountingLines: yup.array(AccountingLineSchema)
  })
  .noUnknown()
  .meta({ schemaName: "MandateSchema" })
  .required();
