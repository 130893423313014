import React from 'react';
import { Typography } from 'antd';
const { Text, Paragraph } = Typography;
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import { Link } from 'react-router-dom';

export interface ShipmentDescriptionProps {
  shipment: ShipmentDto;
}

/**
 * Descrizione della spedizione (inclunde il protocollo)
 */
export function ShipmentDescription(props: ShipmentDescriptionProps) {
  const { shipment } = props;
  return (
    <Text>
      {shipment.protocolUid && shipment.isIntegration && (
        <Text code>
          <Link to={`/url/to/pitre/protocol/${shipment.protocolUid}`}>
            {shipment.protocolNumber}/{shipment.protocolYear}
          </Link>
        </Text>
      )}
      <Paragraph ellipsis={{ rows: 3 }}> {shipment.description}</Paragraph>
    </Text>
  );
}
