import React, { useState } from 'react';
import { Modal, Button, Space, message } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { FormikForm } from 'client/ui/form/FormikForm';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import {
  EconomicStatementSimulationInputDto,
  EconomicStatementSimulationInputSchema
} from 'common/dto/query/EconomicStatementSimulationInputDto';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { EconomicSemesterOptions } from 'common/schema/economic-statement/EconomicSemester';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { EconomicSimulationApi } from 'client/components/schema/economy/simulation/EconomicSimulationApi';
import { logger } from 'client/core/logger/logger';
import { QueueTaskState } from 'common/schema/queue-task/QueueTaskState';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';

export interface EconomicSimulationModalProps
  extends Omit<ButtonProps, 'onClick'> {}

/**
 * Modal per l'avvio della procedura di simulazione
 */
export function EconomicSimulationModal(props: EconomicSimulationModalProps) {
  const { ...buttonProps } = props;

  const [visible, setVisible] = useState(false);

  const [create, createState] = useApiMutation(EconomicSimulationApi.create, {
    data: {},
    invalidates: [EconomicSimulationApi.list]
  });

  return (
    <>
      <Button {...buttonProps} onClick={() => setVisible(true)} />
      <FormikAugmented<EconomicStatementSimulationInputDto>
        initialValues={
          new EconomicStatementSimulationInputDto({ onlyPaid: true })
        }
        validationSchema={EconomicStatementSimulationInputSchema}
        validationContext={{}}
        onSubmit={async values => {
          try {
            const input = await values.validate();
            const result = await create({ data: { input } });
            if (result.data.state === QueueTaskState.Completed) {
              message.success(`Simulazione eseguita con successo`);
            } else {
              message.error(
                `Si è verificato un errore durante la creazione della simulazione`
              );
            }
            setVisible(false);
          } catch (e) {
            logger.error(e);
            message.error(
              `Si è verificato un errore durante la creazione della simulazione`
            );
          }
        }}
      >
        <ModalDialog
          size="small"
          title="Nuova Simulazione di Conto Economico"
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={
            <Space>
              <Button onClick={() => setVisible(false)}>Chiudi</Button>
              <FormikSendButton>Avvia</FormikSendButton>
            </Space>
          }
        >
          <FormikForm editable helpInTooltips>
            <FormFieldsContainer
              title="Selezione dell'anno di competenza della simulazione"
              removeTopTitleSpacing
            >
              <FormFieldItem
                labelWidth="width-140"
                component={NumberInput}
                label="Anno di Competenza"
                name="year"
                placeholder="Anno (YYYY)"
              />
              <FormFieldItem
                labelWidth="width-140"
                component={SelectInput}
                options={EconomicSemesterOptions}
                label="Semestre"
                name="semester"
                placeholder="Semestre"
              />
              <FormFieldItem
                labelWidth="width-140"
                component={CheckboxInput}
                label="Solo fatture pagate"
                name="onlyPaid"
              />
            </FormFieldsContainer>
          </FormikForm>
        </ModalDialog>
      </FormikAugmented>
    </>
  );
}
