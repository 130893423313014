import * as React from 'react';
import { Button, Table, Typography, Breadcrumb, Tag } from 'antd';
const { Title, Text } = Typography;
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import {
  FullscreenOutlined,
  PlusOutlined,
  RightOutlined
} from '@ant-design/icons';
import { EconomicEntryColumns } from 'client/components/schema/economy/economic-entry/table/EconomicEntryColumns';
import { EconomicEntryEditModal } from './EconomicEntryEditModal';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { RouteComponentProps } from 'react-router-dom';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { EconomicEntryApi } from 'client/components/schema/economy/economic-entry/EconomicEntryApi';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { useState } from 'react';
import {
  createTreeFromEconomicEntries,
  EmptyEconomicEntriesTree,
  IEconomicEntryTree
} from 'common/schema/economic-entry/createTreeFromEconomicEntries';
import { EconomicEntryDto } from 'common/dto/generated/EconomicEntryDto';
import { useCallback } from 'react';
import { TableSticky } from 'client/ui/table/TableSticky';
import { EconomicEntryRowActions } from './EconomicEntryRowActions';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';

export interface Params {}

export interface EconomicEntryListPageProps
  extends RouteComponentProps<Params> {}

/** Stili per l'icona > / v */
const expandIconStyle = {
  marginRight: '15px',
  marginLeft: '5px',
  transition: 'transform 0.24s'
};

/**
 * Pagina di gestione dei Codici di Conto Economico
 */
export function EconomicEntryListPage(props: EconomicEntryListPageProps) {
  const [tree, setTree] = useState<IEconomicEntryTree>(
    EmptyEconomicEntriesTree
  );
  const { response, loading, error } = useApiQuery(EconomicEntryApi.list, {
    data: {},
    onLoad: response =>
      setTree(createTreeFromEconomicEntries(response.data.items))
  });

  // Editing
  const [selected, setSelected] = useState<EconomicEntryDto | null>(null);

  const handleEdit = useCallback((entry: EconomicEntryDto | null) => {
    return (e: any) => {
      setSelected(
        // Nuova voce
        entry == null ? new EconomicEntryDto({}) : entry
      );
    };
  }, []);

  const [deleteEconomicEntry] = useApiMutation(EconomicEntryApi.delete, {
    data: {},
    invalidates: [EconomicEntryApi.list]
  });

  const handleDelete = useCallback((entry: EconomicEntryDto | null) => {
    return async (e: any) => {
      if (!entry) return;
      await deleteEconomicEntry({ data: { id: entry.id } });
    };
  }, []);

  // Breadcrumb
  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/economy/economic-statements',
    title: `Conto Economico`
  });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Gestione Codici',
    menuKey: 'economy',
    sideMenuKey: 'economic-entries'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare le voci di conto economico"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={
          <PageHeadingTitle children="Gestione dei Codici di Conto Economico" />
        }
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={[
          <Button
            onClick={handleEdit(null)}
            icon={<PlusOutlined />}
            type="primary"
            children="Nuovo Codice"
          />
        ]}
      />
      <PageContent noPadding noSpace>
        <TableSticky
          rowKey="id"
          columns={[
            ...EconomicEntryColumns,
            {
              key: 'actions',
              title: '',
              align: 'right',
              width: 250,
              render: (_, record) =>
                record.type === 'Leaf' ? (
                  <EconomicEntryRowActions
                    handleEdit={handleEdit(record.entry)}
                    handleDelete={handleDelete(record.entry)}
                  />
                ) : null
            }
          ]}
          loading={loading}
          dataSource={tree.roots}
          size="small"
          pagination={false}
          expandable={{
            defaultExpandAllRows: true,
            indentSize: 28,
            expandRowByClick: true,
            rowExpandable: record => record.type === 'Node',
            expandIcon: props =>
              props.expandable ? (
                <RightOutlined
                  style={expandIconStyle}
                  rotate={props.expanded ? 90 : 0}
                />
              ) : null
          }}
        />
      </PageContent>
      <EconomicEntryEditModal
        tree={tree}
        selected={selected}
        onClose={entry => {
          setSelected(null);
        }}
      />
    </CorePageLayout>
  );
}
