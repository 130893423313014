import React from 'react';
import clx from 'classnames';
import { Menu } from 'antd';
const { SubMenu } = Menu;
import { useThemeConfig } from 'client/components/schema/config/ClientConfigModule';
import { AuditOutlined, IdcardOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useCurrentSideMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';

export interface ContractsSideMenuProps {
  classNames?: string;
}

/**
 * Menu laterale della sezione Contratti.
 */
export function ContractsSideMenu(props: ContractsSideMenuProps) {
  const currentPathKey = useCurrentSideMenuKey();

  return (
    <Menu
      className={props.classNames}
      mode="inline"
      selectedKeys={currentPathKey}
      defaultOpenKeys={['contracts']}
    >
      <SubMenu key="contracts" title="Contratti" icon={<AuditOutlined />}>
        <Menu.Item key="all">
          <Link to="/contracts/all">Tutti</Link>
        </Menu.Item>
        <Menu.Item key="in-progress">
          <Link to="/contracts/in-progress">in Lavorazione</Link>
        </Menu.Item>
        <Menu.Item key="registered">
          <Link to="/contracts/registered">Registrati</Link>
        </Menu.Item>
        <Menu.Item key="signing">
          <Link to="/contracts/signing">alla Firma</Link>
        </Menu.Item>
        <Menu.Item key="stipulated">
          <Link to="/contracts/stipulated">Stipulati</Link>
        </Menu.Item>
        <Menu.Item key="canceled">
          <Link to="/contracts/canceled">Annullati</Link>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="supplier" icon={<IdcardOutlined />}>
        <Link to="/contracts/supplier">Contraenti</Link>
      </Menu.Item>
    </Menu>
  );
}
