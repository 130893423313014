import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { InvoiceReferenceType } from 'common/schema/invoice/InvoiceTypes';

/**
 * Rappresentazione DTO della classe InvoiceReference definita in: src/server/schema/invoice/invoice-reference/InvoiceReference.entity.ts
 * Hash: 7d73d4926815ff69508480dbe548efd8
 */
@ValidationSchema(() => InvoiceReferenceSchema)
export class InvoiceReferenceDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ enum: ['Contract', 'OrderLetter', 'Agreement', 'PurchaseOrder', 'Reception'] })
  type!: InvoiceReferenceType;
  @ApiProperty({ required: false, type: Number })
  invoiceId!: number;
  @ApiProperty({ required: false, type: String })
  documentCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  cigCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  cupCode?: string | null | undefined;
  @ApiProperty({ required: false, type: Number })
  contractId?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Commessa/Convenzione' })
  sdiReferenceCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date' })
  sdiReferenceDate?: Date | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceReferenceDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceReferenceDto ? values : plainToClass(InvoiceReferenceDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await InvoiceReferenceSchema.validate(classToPlain(this), options);
    return new InvoiceReferenceDto(validated);
  }
}

/** Interfaccia simmetrica al DTO InvoiceReferenceDto */
export type IInvoiceReferenceType = IDto<InvoiceReferenceDto>;

/**
 * DTO Paginato della classe InvoiceReference
 */
export class PaginatedInvoiceReferenceDto {
  @ApiProperty({ type: [InvoiceReferenceDto] })
  @Type(() => InvoiceReferenceDto)
  items!: InvoiceReferenceDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const InvoiceReferenceSchema = yup
  .object({
    id: yup.number(),
    type: yup.string().oneOfEnum(InvoiceReferenceType).required(),
    invoiceId: yup.number(),
    documentCode: yup.string().nullable(),
    cigCode: yup.string().trim().nullable(),
    cupCode: yup.string().nullable(),
    contractId: yup.number().nullable(),
    sdiReferenceCode: yup.string().nullable().label('Codice Commessa/Convenzione'),
    sdiReferenceDate: yup.date().dateOnlyFormat().nullable()
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceReferenceSchema" })
  .required();
