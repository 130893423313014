import {
  DeleteOutlined,
  FullscreenOutlined,
  PicCenterOutlined,
  PlusOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import { InvoiceLineEditModal } from 'client/components/schema/invoice-line/edit/InvoiceLineEditModal';
import { InvoiceLineMassiveEditModal } from 'client/components/schema/invoice-line/edit/InvoiceLineMassiveEditModal';
import { InvoiceLineColumns } from 'client/components/schema/invoice-line/table/InvoiceLineColumns';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { Section } from 'client/ui/section/Section';
import { SectionButton } from 'client/ui/section/SectionButton';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceLineDto } from 'common/dto/generated/InvoiceLineDto';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import * as React from 'react';
import { useState } from 'react';

export interface IInvoceLinesSectionProps {
  invoice: InvoiceDto;
}

export function InvoceLinesSection(props: IInvoceLinesSectionProps) {
  const { invoice } = props;

  const editor = useRelationEditor<InvoiceLineDto>('lines');
  const formContext = useFormikFormContext();

  const [massiveEditModalVisible, setMassiveEditModalVisible] = useState(false);

  // Posso aggiungere o rimuovere linee solo se non è protocollata
  const canAddOrRemove = InvoiceMachine.can(
    invoice,
    'edit.vat-summary-and-lines'
  );

  return (
    <Section
      icon={<PicCenterOutlined />}
      title="Beni/Servizi | Dettaglio Linee e Periodo di Competenza"
      extra={
        <Space>
          {formContext.editable && (
            <SectionButton
              type="default"
              icon={<SettingOutlined />}
              onClick={e => {
                setMassiveEditModalVisible(true);
                e.stopPropagation();
              }}
            >
              Modifica Massiva
            </SectionButton>
          )}
          {formContext.editable && canAddOrRemove && (
            <SectionButton
              icon={<PlusOutlined />}
              onClick={e => {
                editor.handle.open(new InvoiceLineDto());
                e.stopPropagation();
              }}
            >
              Aggiungi Linea Fattura
            </SectionButton>
          )}
        </Space>
      }
    >
      <Table
        rowKey="id"
        columns={[
          ...InvoiceLineColumns,
          {
            title: '',
            key: 'actions',
            align: 'right',
            width: 200,
            render: (_, record) => (
              <>
                <span>
                  <Button
                    size="small"
                    type="link"
                    children={formContext.editable ? 'Modifica' : 'Dettaglio'}
                    icon={<FullscreenOutlined />}
                    onClick={() => {
                      editor.handle.open(record);
                    }}
                  />
                </span>
                {formContext.editable && canAddOrRemove && (
                  <span>
                    <Button
                      size="small"
                      type="link"
                      children={'Elimina'}
                      icon={<DeleteOutlined />}
                      disabled={!formContext.editable}
                      danger
                      onClick={() => editor.remove(record)}
                    />
                  </span>
                )}
              </>
            )
          }
        ]}
        dataSource={editor.items}
        size="small"
        pagination={{
          size: 'small',
          position: ['bottomRight'],
          showSizeChanger: true,
          defaultPageSize: 30,
          pageSizeOptions: ['20', '30', '50', '100']
        }}
      />
      <InvoiceLineEditModal
        invoice={invoice}
        editable={formContext.editable}
        editor={editor}
      />
      <InvoiceLineMassiveEditModal
        invoice={invoice}
        editable={formContext.editable}
        editor={editor}
        visible={massiveEditModalVisible}
        setVisible={setMassiveEditModalVisible}
      />
    </Section>
  );
}
