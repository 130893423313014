import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaValidate } from "cli/generate/decorators/SchemaDecorators";
import { AcceptanceState } from "common/schema/invoice/InvoiceTypes";

@GenerateDto()
class InvoiceAcceptance {
  @SchemaLabel('Stato Accettazione Fattura')
  acceptance!: AcceptanceState;
  @SchemaLabel('Motivazione Rifiuto Fattura')
  @SchemaValidate(schema =>
    schema.when(['acceptance'], {
      is: (acceptance: AcceptanceState) => acceptance === AcceptanceState.Rejected,
      then: schema => schema.required()
    })
  )
  description?: string | null
}

/**
 * Rappresentazione DTO della classe InvoiceAcceptance 
 * Hash: 2c0f543096f5565b65871c23c501d1f4
 */
@ValidationSchema(() => InvoiceAcceptanceSchema)
export class InvoiceAcceptanceDto {
  @ApiProperty({ enum: ['Accepted', 'Rejected', 'Indeterminate'], description: 'Stato Accettazione Fattura' })
  acceptance!: AcceptanceState;
  @ApiProperty({ required: false, type: String, description: 'Motivazione Rifiuto Fattura' })
  description?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceAcceptanceDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceAcceptanceDto ? values : plainToClass(InvoiceAcceptanceDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await InvoiceAcceptanceSchema.validate(classToPlain(this), options);
    return new InvoiceAcceptanceDto(validated);
  }
}

export const InvoiceAcceptanceSchema = yup
  .object({
    acceptance: yup.string().oneOfEnum(AcceptanceState).required().label('Stato Accettazione Fattura'),
    description: yup.string().nullable().when(['acceptance'], {
          is: (acceptance: AcceptanceState) => acceptance === AcceptanceState.Rejected,
          then: schema => schema.required()
        }).label('Motivazione Rifiuto Fattura')
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceAcceptanceSchema" })
  .required();
