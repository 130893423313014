import { apiClient } from 'client/core/network/apiClient';
import {
  InvoiceDto,
  PaginatedInvoiceDto
} from 'common/dto/generated/InvoiceDto';
import {
  InvoiceExportDto,
  PaginatedInvoiceExportDto
} from 'common/dto/generated/InvoiceExportDto';
import { PaginatedNotificationDto } from 'common/dto/generated/NotificationDto';
import { InvoiceAcceptanceDto } from 'common/dto/query/InvoiceAcceptanceDto';
import { InvoiceQueryDto } from 'common/dto/query/InvoiceQueryDto';
import { InvoiceExportCreateQueryDto } from 'common/dto/query/InvoiceExportCreateQueryDto';
import { NotificationQueryDto } from 'common/dto/query/NotificationQueryDto';
import { ProtocolInvoiceOptionsDto } from 'common/dto/query/ProtocolInvoiceOptionsDto';
import { InvoiceExportQueryDto } from 'common/dto/query/InvoiceExportQueryDto';
import { adminReferentFilterHeader } from 'common/schema/contract/adminReferentFilterHeader';
import { ConfigStorage } from 'client/components/config/ConfigStorage';
import { ProtocolResponseBag } from 'common/dto/response-bag/ProtocolResponseBag';

export const InvoiceApi = {
  list: (opts: { query: InvoiceQueryDto }) =>
    apiClient.requestWithResponseType(PaginatedInvoiceDto).get(`/invoices`, {
      params: opts.query,
      headers: {
        [adminReferentFilterHeader]: ConfigStorage.getValue(
          'adminReferentFilter'
        )
      }
    }),
  find: (opts: { id: number }) =>
    apiClient.requestWithResponseType(InvoiceDto).get(`/invoices/${opts.id}`),
  create: (opts: { input: InvoiceDto }) =>
    apiClient.requestWithResponseType(InvoiceDto).post(`/invoices`, opts.input),
  update: (opts: { id: number; input: InvoiceDto }) =>
    apiClient
      .requestWithResponseType(InvoiceDto)
      .put(`/invoices/${opts.id}`, opts.input),
  accept: (opts: { id: number; input: InvoiceAcceptanceDto }) =>
    apiClient
      .requestWithResponseType(InvoiceDto)
      .put(`/invoices/${opts.id}/accept`, opts.input),

  actions: {
    protocol: (opts: { id: number; input: ProtocolInvoiceOptionsDto }) =>
      apiClient
        .request()
        .post<ProtocolResponseBag>(
          `/invoices/${opts.id}/actions/protocol`,
          opts.input
        ),
    /** Termine della fase di valutazione */
    validate: (opts: { id: number }) =>
      apiClient.request().post(`/invoices/${opts.id}/actions/validate`),
    /** Segna una fattura come revisionata */
    revision: (opts: { id: number }) =>
      apiClient.request().post(`/invoices/${opts.id}/actions/revision`)
  },

  notifications: {
    list: (opts: { invoiceId: number; query: NotificationQueryDto }) =>
      apiClient
        .requestWithResponseType(PaginatedNotificationDto)
        .get(`/invoices/${opts.invoiceId}/notifications`, {
          params: opts.query
        })
  },

  export: {
    create: (opts: { input: InvoiceExportCreateQueryDto }) =>
      apiClient
        .requestWithResponseType(InvoiceExportDto)
        .post(`/invoices-export`, opts.input, { timeout: 30_000 }),

    find: (opts: { id: number }) =>
      apiClient
        .requestWithResponseType(InvoiceExportDto)
        .get(`invoices-export/${opts.id}`),

    list: (opts: { query: InvoiceExportQueryDto }) =>
      apiClient
        .requestWithResponseType(PaginatedInvoiceExportDto)
        .get(`/invoices-export`, { params: opts.query })
  }
};
