export enum MandateState {
  Pending = 'Pending',
  Paid = 'Paid',
  Canceled = 'Canceled',
  Manual = 'Manual',
  Imported = 'Imported',
  Shipped = 'Shipped'
}

export enum SicogeState {
  /* Inviato alla firma */
  ToSign = 1,
  /* Firmato */
  Sign = 2,
  /* Inviato in Ragioneria */
  Send = 3,
  /* Validato R.G.S. */
  RgsValidate = 4,
  /* Pagato */
  Paid = 5,
  /* Non trattato in chiusura */
  Untreated = 6,
  /* Per stipendio */
  Salary = 7,
  /* Con richiamo in corso*/
  RecallInProgress = 8,
  /* Richiamato */
  Recall = 9,
  /* Restituito per richiamo */
  ReturnedForRecall = 10,
  /* Non restituito */
  NotReturned = 11,
  /* Riscontri di ragioneria */
  AccountingFeedbacks = 12,
  /* Annullato */
  Cancelled = 13,
  /* Restituito per riscontri automatici */
  AutomaticallyReturned = 14,
  /* Restituito per notifica BKI */
  BkiReturned = 15,
  /* Proveniente da trasporto */
  FromTransport = 16,
  /* Trasportato */
  Transported = 17,
  /* Perento */
  Expired = 19,
  /* Provvisorio elaborato con errore */
  Error = 99,
  /* In Elaborazione SICOGE */
  Sicoge = 0,
  /* Trasmesso manualmente */
  HandTransmitted = 90,
  /* Prec 2007 */
  Before2007 = 91
}

export enum GebilaState {
  /* Da approvare */
  ToApprove = 0,
  /* Approvato */
  Approved = 1,
  /* Annullato */
  Cancelled = 2,
  /* Respinto */
  Rejected = 3,
  /* Incompleto */
  Incomplet = 5,
  /* Completo */
  Complete = 6,
  /* Inviato a SICOGE */
  Sended = 7,
  /* Non conforme XSD */
  NonCompliant = 8,
  /* Elenco in preparazione */
  ListInProgress = 9,
  /* Elenco Inviato */
  ListSended = 10
}

export const sicogeStateOptions: { state: SicogeState; label: string }[] = [
  { state: SicogeState.ToSign, label: 'Inviato alla firma' },
  { state: SicogeState.Sign, label: 'Firmato' },
  { state: SicogeState.Send, label: 'Inviato in Ragioneria' },
  { state: SicogeState.RgsValidate, label: 'Validato R.G.S.' },
  { state: SicogeState.Paid, label: 'Pagato' },
  { state: SicogeState.Untreated, label: 'Non trattato in chiusura' },
  { state: SicogeState.Salary, label: 'Per stipendio' },
  { state: SicogeState.RecallInProgress, label: 'Con richiamo in corso' },
  { state: SicogeState.Recall, label: 'Richiamato' },
  { state: SicogeState.ReturnedForRecall, label: 'Restituito per richiamo' },
  { state: SicogeState.NotReturned, label: 'Non restituito' },
  { state: SicogeState.AccountingFeedbacks, label: 'Riscontri di ragioneria' },
  { state: SicogeState.Cancelled, label: 'Annullato' },
  {
    state: SicogeState.AutomaticallyReturned,
    label: 'Restituito per riscontri automatici'
  },
  { state: SicogeState.BkiReturned, label: 'Restituito per notifica BKI' },
  { state: SicogeState.FromTransport, label: 'Proveniente da trasporto' },
  { state: SicogeState.Transported, label: 'Trasportato' },
  { state: SicogeState.Expired, label: 'Perento' },
  { state: SicogeState.Error, label: 'Provvisorio elaborato con errore' },
  { state: SicogeState.Sicoge, label: 'In Elaborazione SICOGE' },
  { state: SicogeState.HandTransmitted, label: 'Trasmesso manualmente' },
  { state: SicogeState.Before2007, label: 'Prec 2007' }
];

export const gebilaStateOptions: { state: GebilaState; label: string }[] = [
  { state: GebilaState.ToApprove, label: 'Da approvare' },
  { state: GebilaState.Approved, label: 'Approvato' },
  { state: GebilaState.Cancelled, label: 'Annullato' },
  { state: GebilaState.Rejected, label: 'Respinto' },
  { state: GebilaState.Incomplet, label: 'Incompleto' },
  { state: GebilaState.Complete, label: 'Completo' },
  { state: GebilaState.Sended, label: 'Inviato a SICOGE' },
  { state: GebilaState.NonCompliant, label: 'Non conforme XSD' },
  { state: GebilaState.ListInProgress, label: 'Elenco in preparazione' },
  { state: GebilaState.ListSended, label: 'Elenco Inviato' }
];
