import {
  CalculatorOutlined,
  DisconnectOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Button, Table } from 'antd';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import { MandateAccountingLineColumns } from 'client/components/schema/accounting-line/table/MandateAccountingLineColumns';
import { AccountingLinesDrawer } from 'client/components/schema/mandate/accounting-lines/AccountingLinesDrawer';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { Section } from 'client/ui/section/Section';
import { SectionButton } from 'client/ui/section/SectionButton';
import { AccountingLineDto } from 'common/dto/generated/AccountingLineDto';
import { MandateDto } from 'common/dto/generated/MandateDto';
import * as React from 'react';
import { useState } from 'react';

export interface IMandateAccountingLinesSectionProps {
  mandate: MandateDto;
}

/**
 * Collegamento e visualizzazione delle linee contabili di un Mandato.
 */
export function MandateAccountingLinesSection(
  props: IMandateAccountingLinesSectionProps
) {
  const [isOpen, setIsOpen] = useState(false);
  const editor = useRelationEditor<AccountingLineDto>('accountingLines');

  const formContext = useFormikFormContext();

  if (!props.mandate.isSiabImported) return null;

  return (
    <div id="mandateAccountingLineTable">
      <Section
        icon={<CalculatorOutlined />}
        title="Linee di Contabilizzazione Associate"
        extra={
          formContext.editable && (
            <SectionButton
              icon={<PlusOutlined />}
              onClick={e => {
                setIsOpen(true);
                e.stopPropagation();
              }}
            >
              Gestisci Linee
            </SectionButton>
          )
        }
      >
        <Table
          columns={[
            ...MandateAccountingLineColumns,
            {
              title: '',
              dataIndex: 'azioni',
              align: 'right',
              render: (_, record) => (
                <Button
                  icon={<DisconnectOutlined />}
                  children="Scollega"
                  size="small"
                  type="link"
                  disabled={!formContext.editable}
                  danger
                  onClick={() => editor.remove(record)}
                />
              )
            }
          ]}
          rowKey="id"
          dataSource={editor.items}
          size="middle"
          pagination={false}
        />
      </Section>
      <AccountingLinesDrawer
        mandate={props.mandate}
        editor={editor}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
}
