import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";


@GenerateDto()
class DocumentalUserRolesQuery {
    @SchemaLabel('Codice utente')
    userCode!: string;
}

/**
 * Rappresentazione DTO della classe DocumentalUserRolesQuery 
 * Hash: 02b6e4cd965741d89d342e81faf64268
 */
@ValidationSchema(() => DocumentalUserRolesQuerySchema)
export class DocumentalUserRolesQueryDto {
  @ApiProperty({ type: String, description: 'Codice utente' })
  userCode!: string;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<DocumentalUserRolesQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof DocumentalUserRolesQueryDto ? values : plainToClass(DocumentalUserRolesQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await DocumentalUserRolesQuerySchema.validate(classToPlain(this), options);
    return new DocumentalUserRolesQueryDto(validated);
  }
}

export const DocumentalUserRolesQuerySchema = yup
  .object({
    userCode: yup.string().required().label('Codice utente')
  })
  .noUnknown()
  .meta({ schemaName: "DocumentalUserRolesQuerySchema" })
  .required();
