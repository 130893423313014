import { Tag, TagProps } from 'antd';
import { StateTag } from 'client/ui/state/StateTag';
import { EconomicReportKind } from 'common/schema/economic-statement/EconomicReportKind';
import * as React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

export interface IReportKindTagProps {
  kind: EconomicReportKind;
}

function stateToProps(kind: EconomicReportKind): TagProps {
  switch (kind) {
    case EconomicReportKind.Standard:
      return { children: 'Standard' };
    case EconomicReportKind.Detail:
      return { color: 'blue', children: 'Dettaglio' };
  }
  assertNever(kind);
}
/**
 * Tag del tipo di Report di Conto Economico
 */
export function ReportKindTag(props: IReportKindTagProps) {
  return <StateTag {...stateToProps(props.kind)} />;
}
