import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';

@GenerateDto()
class UserQuery {
    @SchemaPageNumber()
    pageNumber!: number;
    @SchemaPageSize()
    pageSize!: number;

    @SchemaLabel('Nome Utente')
    name?: string | null;
    @SchemaLabel('Codice utente documentale')
    documentalUserCode?: string | null;
    @SchemaLabel('Esclude gli utenti amministratori') // salta gli ADMIN
    excludeAdminRoles?: boolean | null;

    @SchemaLabel("Ordinamento")
    orderBy?: string = 'id';
    @SchemaLabel("Direzione di Ordinamento")
    orderByDirection?: ColumnSortDirections = ColumnSortDirections.ascend;
}

/**
 * Rappresentazione DTO della classe UserQuery 
 * Hash: d8f64bac1fb122610b13465f0c931bb7
 */
@ValidationSchema(() => UserQuerySchema)
export class UserQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, type: String, description: 'Nome Utente' })
  name?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice utente documentale' })
  documentalUserCode?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Esclude gli utenti amministratori' })
  excludeAdminRoles?: boolean | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Ordinamento' })
  orderBy?: string | undefined = 'id';
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.ascend;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<UserQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof UserQueryDto ? values : plainToClass(UserQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await UserQuerySchema.validate(classToPlain(this), options);
    return new UserQueryDto(validated);
  }
}

export const UserQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    name: yup.string().nullable().label('Nome Utente'),
    documentalUserCode: yup.string().nullable().label('Codice utente documentale'),
    excludeAdminRoles: yup.boolean().nullable().label('Esclude gli utenti amministratori'),
    orderBy: yup.string().default('id').label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.ascend).label('Direzione di Ordinamento')
  })
  .noUnknown()
  .meta({ schemaName: "UserQuerySchema" })
  .required();
