import * as React from 'react';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { PlusOutlined } from '@ant-design/icons';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { RouteComponentProps } from 'react-router-dom';
import { EconomicStatementColumns } from 'client/components/schema/economy/statement/EconomicStatementColumns';
import { EconomicStatementModal } from './EconomicStatementModal';
import { EconomicStatementQueryDto } from 'common/dto/query/EconomicStatementQueryDto';
import { useState } from 'react';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { EconomicStatementApi } from 'client/components/schema/economy/statement/EconomicStatementApi';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { TableSticky } from 'client/ui/table/TableSticky';
import { usePermissions } from 'client/components/auth/AuthModule';

export interface Params {}

export interface EconomicStatmentListPageProps
  extends RouteComponentProps<Params> {}

/**
 * Pagina di Elenco dei Report di Conto Economico
 */
export function EconomicStatementListPage(
  props: EconomicStatmentListPageProps
) {
  const [query, setQuery] = useState(
    new EconomicStatementQueryDto({ pageNumber: 1, pageSize: 30 })
  );
  const { response, error, loading } = useApiQuery(EconomicStatementApi.list, {
    data: { query }
  });

  const permissions = usePermissions();

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/economy/economic-statements',
    title: `Conto Economico`
  });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Report di Conto Economico',
    menuKey: 'economy',
    sideMenuKey: 'economic-statements'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco dei report del conto economico"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle children="Elenco Report di Conto Economico" />}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={
          permissions.has('economy.generate') && (
            <EconomicStatementModal
              icon={<PlusOutlined />}
              key="modal"
              type="primary"
              children="Nuovo Report"
            />
          )
        }
      />
      <PageContent noPadding noSpace>
        <TableSticky
          rowKey="id"
          loading={loading}
          columns={EconomicStatementColumns}
          dataSource={response?.data.items ?? []}
          size="small"
          onChange={pagination => {
            setQuery(
              new EconomicStatementQueryDto({
                pageNumber: pagination.current ?? query.pageNumber,
                pageSize: pagination.pageSize ?? query.pageSize
              })
            );
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
