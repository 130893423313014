import Checkbox from 'antd/lib/checkbox/Checkbox';
import Text from 'antd/lib/typography/Text';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useApiInvalidator } from 'client/core/network/hooks/useApiInvalidator';
import { UserDto } from 'common/dto/generated/UserDto';
import { RolesLogic } from 'common/permissions/RolesLogic';
import React, { useEffect, useState } from 'react';
import { useCurrentUser } from '../auth/AuthModule';
import { AccountingLineApi } from '../schema/accounting-line/AccountingLineApi';
import { ContractApi } from '../schema/contract/ContractApi';
import { InvoiceApi } from '../schema/invoice/InvoiceApi';
import { UserApi } from '../schema/user/UserApi';
import { ConfigStorage } from './ConfigStorage';

export interface IAdminReferentFilterProps {}

/**
 * Recupera il valore presente nel local storage. Se non lo trova,
 * usa quello di default del ruolo dell'utente.
 */
function getDefaultFilterValue(user: UserDto | null) {
  const storageValue = ConfigStorage.getValue('adminReferentFilter');
  return storageValue == null
    ? RolesLogic.getDefaultAdminReferentFilter(user)
    : storageValue;
}

export function AdminReferentFilter(props: IAdminReferentFilterProps) {
  const user = useCurrentUser();
  const [value, setValue] = useState(getDefaultFilterValue(user));
  const { invalidates } = useApiInvalidator();

  useEffect(() => {
    const defaultValue = getDefaultFilterValue(user);
    setValue(defaultValue);
  }, [user]);

  const onChange = (event: any) => {
    const checked = event.target.checked;
    setValue(checked);
    ConfigStorage.saveValue('adminReferentFilter', checked);
    invalidates([ContractApi.list, InvoiceApi.list, AccountingLineApi.list]);
  };

  return (
    <>
      <Checkbox
        name="adminReferentFilter"
        onChange={onChange}
        checked={value}
      />
      <Text>Solo Referente Amministrativo</Text>
    </>
  );
}
