import { Modal } from 'antd';
import { ProtocolResponseBag } from 'common/dto/response-bag/ProtocolResponseBag';
import React, { useCallback, useEffect, useState } from 'react';

export interface IInvoiceProtocolErrorAlertProps {
  bag: ProtocolResponseBag | null;
}

export function InvoiceProtocolErrorAlert(
  props: IInvoiceProtocolErrorAlertProps
) {
  const { bag } = props;

  const [visible, setVisible] = useState(true);
  const hideModal = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <>
      <Modal
        title="Si sono verificati degli errori"
        visible={visible && !!bag?.errors && bag.errors.length > 0}
        footer={null}
        onCancel={hideModal}
        maskClosable={false}
      >
        <p>
          La protocollazione è andata a buon fine ma si sono verificati i
          seguenti errori:
        </p>
        <ul>
          {bag?.errors?.map((error, index) => (
            <li key={index}>{error.message}</li>
          ))}
        </ul>
      </Modal>
    </>
  );
}
