import { MandateState } from 'common/schema/mandate/MandateTypes';
import * as React from 'react';

export function mapMandateFilterToOptions(filter: string | undefined) {
  switch (filter) {
    case 'imported':
      return {
        filter: filter,
        title: 'Mandati Importati',
        subtitle: 'Mandati importati da SIAB senza linee contabili associate',
        query: {
          state: MandateState.Imported
        }
      };
    case 'pending':
      return {
        filter: filter,
        title: 'Mandati Pendenti',
        subtitle: 'Mandati importati da SIAB in attesa di esito',
        query: {
          state: MandateState.Pending
        }
      };
    case 'shipped':
      return {
        filter: filter,
        title: 'Mandati Spediti',
        subtitle:
          'Mandati pendenti che sono agganciati a una Spedizione inviata',
        query: {
          state: MandateState.Shipped
        }
      };
    case 'paid':
      return {
        filter: filter,
        title: 'Mandati Pagati',
        subtitle: 'Mandati importati da SIAB con pagamento effettuato',
        query: {
          state: MandateState.Paid
        }
      };
    case 'canceled':
      return {
        filter: filter,
        title: 'Mandati Annullati',
        subtitle: 'Mandati importati da SIAB annullati',
        query: {
          state: MandateState.Canceled
        }
      };
    case 'manual':
      return {
        filter: filter,
        title: 'Mandati Registrati a Mano',
        subtitle: 'Mandati registrati a mano',
        query: {
          state: MandateState.Manual
        }
      };

    default: {
      return {
        filter: 'all',
        title: 'Tutti i mandati',
        subtitle: 'Tutti i mandati importati da SIAB',
        query: {}
      };
    }
  }
}
