import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { UserDto } from './UserDto';

/**
 * Rappresentazione DTO della classe PccResponse definita in: src/server/schema/pcc/pcc-response/PccResponse.entity.ts
 * Hash: e7d0bd5fb46c6d4ae054a3def2bd8307
 */
@ValidationSchema(() => PccResponseSchema)
export class PccResponseDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ type: String, description: 'Nome file' })
  fileName!: string;
  @ApiProperty({ type: String, description: 'Percorso di salvataggio' })
  path!: string;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data creazione' })
  createdAt!: Date;
  @ApiProperty({ type: Number, description: 'ID Esportazione Pcc connessa' })
  pccExportId!: number;
  @ApiProperty({ required: false, type: Number, description: 'Id Autore (upload)' })
  uploadAuthorId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => UserDto, description: 'Autore (upload)' })
  @Type(() => UserDto)
  uploadAuthor?: UserDto | null;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<PccResponseDto>) {
    if (values != null) {
      Object.assign(this, values instanceof PccResponseDto ? values : plainToClass(PccResponseDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await PccResponseSchema.validate(classToPlain(this), options);
    return new PccResponseDto(validated);
  }
}

/** Interfaccia simmetrica al DTO PccResponseDto */
export type IPccResponseType = IDto<PccResponseDto>;

/**
 * DTO Paginato della classe PccResponse
 */
export class PaginatedPccResponseDto {
  @ApiProperty({ type: [PccResponseDto] })
  @Type(() => PccResponseDto)
  items!: PccResponseDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const PccResponseSchema = yup
  .object({
    id: yup.number(),
    fileName: yup.string().required().label('Nome file'),
    path: yup.string().required().label('Percorso di salvataggio'),
    pccExportId: yup.number().required().label('ID Esportazione Pcc connessa'),
    uploadAuthorId: yup.number().nullable().label('Id Autore (upload)')
  })
  .noUnknown()
  .meta({ schemaName: "PccResponseSchema" })
  .required();
