import { apiClient } from 'client/core/network/apiClient';
import { PaginatedResult } from 'common/dto/shared/PaginatedResult';
import { PaginatedUserDto, UserDto } from 'common/dto/generated/UserDto';
import { UserQueryDto } from 'common/dto/query/UserQueryDto';
import { UserListQueryDto } from 'common/dto/query/UserListQueryDto';

export interface CreateServiceTokenResult {
  serviceToken: string;
  serviceTokenJwt: string;
}

export const UserApi = {
  findCurrentUser: (opts: {}) =>
    apiClient.requestWithResponseType(UserDto).get(`/users/current`),
  find: (opts: { id: number }) =>
    apiClient.requestWithResponseType(UserDto).get(`/users/${opts.id}`),
  create: (opts: { input: UserDto }) =>
    apiClient.requestWithResponseType(UserDto).post(`/users`, opts.input),
  update: (opts: { input: UserDto }) =>
    apiClient
      .requestWithResponseType(UserDto)
      .put(`/users/${opts.input.id}`, opts.input),
  list: (opts: { query: UserQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedUserDto)
      .get(`/users`, { params: opts.query }),

  listAll: (opts: { query: UserListQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedUserDto)
      .get(`/users/all`, { params: opts.query }),

  softDelete: (opts: { id: number }) =>
    apiClient.request().delete(`/users/${opts.id}`),

  /** Crea un service token */
  createServiceToken: (opts: { id: number }) =>
    apiClient
      .request()
      .post<CreateServiceTokenResult>(`/users/${opts.id}/service-token`)

  /** Elenco di utenti per le select */
  // listSelectable: (opts: { query: IUsersQueryDto }) =>
  //   apiClient.get<PaginatedResult<IUser>>(`/users/projections/selectable`, {
  //     params: opts.query
  //   }),
  // delete: (opts: { id: number }) => apiClient.delete(`/users/${opts.id}`),
};
