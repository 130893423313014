import { InvoiceAcceptedColumns } from 'client/components/schema/invoice/table/InvoiceAcceptedColumns';
import { InvoiceAllColumns } from 'client/components/schema/invoice/table/InvoiceAllColumns';
import { InvoiceIsPaperColumns } from 'client/components/schema/invoice/table/InvoiceIsPaperColumns';
import { InvoiceRejectedColumns } from 'client/components/schema/invoice/table/InvoiceRejectedColumns';
import { InvoiceToEvaluateColumns } from 'client/components/schema/invoice/table/InvoiceToEvaluateColumns';
import { InvoiceToAccountColumns } from 'client/components/schema/invoice/table/InvoiceToAccountColumns';
import { InvoiceToProtocolColumns } from 'client/components/schema/invoice/table/InvoiceToProtocolColumns';
import {
  AcceptanceState,
  InvoiceState
} from 'common/schema/invoice/InvoiceTypes';
import { InvoicePaidColumns } from 'client/components/schema/invoice/table/InvoicePaidColumns';
import { InvoiceExpiredNotPaidColumns } from 'client/components/schema/invoice/table/InvoiceExpiredNotPaidColumns';

export function mapInvoiceFilterToOptions(filter: string | undefined) {
  switch (filter) {
    case 'to-evaluate':
      return {
        filter: filter,
        title: 'Fatture da Associare',
        columns: InvoiceToEvaluateColumns,
        query: {
          state: InvoiceState.ToValidate
        }
      };

    case 'to-protocol':
      return {
        filter: filter,
        title: 'Fatture da Protocollare',
        columns: InvoiceToProtocolColumns,
        query: {
          toProtocol: true,
          acceptance: AcceptanceState.Accepted
        }
      };

    case 'to-account':
      return {
        filter: filter,
        title: 'Fatture da Contabilizzare',
        columns: InvoiceToAccountColumns,
        query: {
          state: InvoiceState.ToAccount
        }
      };

    case 'expired-not-paid':
      return {
        filter: filter,
        title: 'Fatture Scadute non Pagate',
        columns: InvoiceExpiredNotPaidColumns,
        query: {
          expiredNotPaid: true
        }
      };

    case 'partial-paid':
      return {
        filter: filter,
        title: 'Fatture Pagate Parzialmente',
        columns: InvoicePaidColumns,
        query: {
          state: InvoiceState.PaidPartial
        }
      };

    case 'paid':
      return {
        filter: filter,
        title: 'Fatture Pagate',
        columns: InvoicePaidColumns,
        query: {
          state: InvoiceState.Paid
        }
      };

    case 'accounted':
      return {
        filter: filter,
        title: 'Fatture Contabilizzate',
        columns: InvoiceToAccountColumns,
        query: {
          state: InvoiceState.Accounted
        }
      };

    case 'to-outcome':
      return {
        filter: filter,
        title: 'Fatture da Accettare/Rifiutare',
        columns: InvoiceAcceptedColumns,
        query: {
          acceptance: AcceptanceState.Indeterminate
        }
      };

    case 'accepted':
      return {
        filter: filter,
        title: 'Fatture Accettate',
        columns: InvoiceAcceptedColumns,
        query: {
          acceptance: AcceptanceState.Accepted
        }
      };

    case 'rejected':
      return {
        filter: filter,
        title: 'Fatture Rifiutate',
        columns: InvoiceRejectedColumns,
        query: {
          acceptance: AcceptanceState.Rejected
        }
      };

    case 'is-paper':
      return {
        filter: filter,
        title: 'Fatture Cartacee',
        columns: InvoiceIsPaperColumns,
        query: {
          paper: true
        }
      };

    default:
      return {
        filter: 'all',
        title: 'Tutte le fatture',
        columns: InvoiceAllColumns,
        query: { state: null }
      };
  }
}
