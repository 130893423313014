import * as React from 'react';
import { Button, Table, message, Modal } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  MailOutlined
} from '@ant-design/icons';
import { ISODate } from 'client/ui/display/date/ISODate';
import { MandateHeaderContent } from './MandateHeaderContent';
import { MandateStateTag } from 'client/components/schema/mandate/view/MandateStateTag';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { useCallback, useState } from 'react';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { MandateApi } from 'client/components/schema/mandate/MandateApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { EditAndSaveButton } from 'client/ui/form/button/EditAndSaveButton';
import { MandateSchema } from 'common/dto/generated/MandateDto';
import { FormikForm } from 'client/ui/form/FormikForm';
import { Formik } from 'formik';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { MandateAccountingLinesSection } from './sections/MandateAccountingLinesSection';
import { MandateSiabSection } from './sections/MandateSiabSection';
import { mapMandateFilterToOptions } from '../list/mapMandateFilterToOptions';
import { LinkButton } from 'client/ui/link/LinkButton';
import { AmountsCongruenceType } from 'common/schema/mandate/AmountsCongruenceType';
import { AmountsCongruenceTag } from 'client/components/schema/mandate/view/AmountsCongruenceTag';
import { ShipmentSection } from './sections/ShipmentSection';
import { AccountingLineApi } from 'client/components/schema/accounting-line/AccountingLineApi';
import { ShipmentApi } from 'client/components/schema/shipment/ShipmentApi';
import { ShipmentQueryDto } from 'common/dto/query/ShipmentQueryDto';
import { ActivityLogTable } from 'client/components/schema/activity-log/table/ActivityLogTable';
import { Section } from 'client/ui/section/Section';
import { ActivityLogApi } from 'client/components/schema/activity-log/ActivityLogApi';
import { MandateAlertInfoBox } from './MandateAlertInfoBox';

interface Params {
  id: string;
  filter?: string;
}

export interface MandatePageProps extends RouteComponentProps<Params> {}

/**
 * Pagina del Mandato di pagamento
 */
export function MandatePage(props: MandatePageProps) {
  const [editable, setEditable] = useState(false);

  const id = props.match.params.id ? parseInt(props.match.params.id, 10) : null;
  if (id == null) {
    throw new Error('Non è stato fornito nessun ID');
  }

  // Gestione API
  const { response } = useApiQuery(MandateApi.find, {
    skip: id == null,
    data: { id: id! }
  });
  const mandate = response?.data;

  const shipmentRequest = useApiQuery(ShipmentApi.list, {
    skip: id == null,
    data: {
      query: new ShipmentQueryDto({ mandateId: id, pageNumber: 1, pageSize: 1 })
    }
  });

  const [update] = useApiMutation(MandateApi.update, {
    invalidates: [MandateApi.find, AccountingLineApi.list, ActivityLogApi.list],
    data: { id: id! }
  });

  const pageOptions = mapMandateFilterToOptions(props.match.params.filter);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/shipping/mandates', title: `Mandati` });
  useBreadcrumbItem({
    path: `/shipping/mandates/${pageOptions.filter}`,
    title: pageOptions.title
  });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: `n.${mandate?.mandateNumber}/${mandate?.mandateYear}`,
    menuKey: 'mandates',
    sideMenuKey: `mandates-${pageOptions.filter}`
  });

  const handleConfirmAccountingIncogruent = useCallback(
    () =>
      new Promise((resolve, reject) => {
        Modal.confirm({
          style: { top: '150px' },
          title: 'Importi incongruenti',
          icon: <ExclamationCircleOutlined />,
          okText: 'Prosegui',
          cancelText: 'Annulla',
          content:
            "Il totale delle linee di contabilizzazione è inferiore all'importo del mandato. Annullare per modificare le linee di contabilizzazione o proseguire con gli importi incongruenti",
          onOk() {
            resolve(true);
          },
          onCancel() {
            resolve(false);
          }
        });
      }),
    []
  );

  const handleError = useCallback(
    async (error: any) =>
      new Promise((resolve, reject) => {
        Modal.error({
          style: { top: '150px' },
          title: 'Errore',
          content: error.message,
          onOk() {
            resolve(false);
          }
        });
      }),
    []
  );

  if (!mandate) return <LoadingPage />;

  return (
    <CorePageLayout>
      <Formik
        initialValues={mandate}
        enableReinitialize
        validate={schemaToValidator(MandateSchema)}
        onReset={() => {
          setEditable(false);
        }}
        onSubmit={async rawValues => {
          try {
            let input = await rawValues.validate();
            try {
              const amountsCongruence = input.getAmountsCongruence();

              if (
                mandate.amountsCongruence !==
                  AmountsCongruenceType.IncongruentAmounts &&
                amountsCongruence === AmountsCongruenceType.IncongruentAmounts
              ) {
                const isConfirmed = await handleConfirmAccountingIncogruent();
                if (!isConfirmed) return;
              }
            } catch (e) {
              await handleError(e);
              return;
            }

            await update({ data: { id, input } });
            message.success('Mandato aggiornato con successo. ');
            setEditable(false);
          } catch (e) {
            message.error(
              "Si è verificato un errore durante l'aggiornamento del mandato."
            );
            console.error(e);
          }
        }}
      >
        <FormikForm editable={editable} helpInTooltips showVisualFeedback>
          <PageHeading
            title={
              <PageHeadingTitle>
                Mandato n.{mandate.mandateNumber}/{mandate.mandateYear}
              </PageHeadingTitle>
            }
            breadcrumbRender={() => <BreadcrumbsContainer />}
            subTitle={
              <span>
                importato il{' '}
                <ISODate date={mandate.importedAt} format="DD/MM/YYYY" />
              </span>
            }
            tags={[
              <MandateStateTag mandate={mandate} key="state" />,
              <AmountsCongruenceTag congruence={mandate.amountsCongruence} />
            ]}
            extra={
              <EditAndSaveButton
                name="Mandato"
                state={[editable, setEditable]}
                whenSaved={
                  <>
                    {shipmentRequest.response?.data.meta.total === 0 && (
                      <LinkButton
                        icon={<MailOutlined />}
                        to={`/shipping/all/create?mandateId=${id}`}
                        disabled={shipmentRequest.loading}
                      >
                        Spedisci UBRRAC
                      </LinkButton>
                    )}
                  </>
                }
              />
            }
          >
            <MandateHeaderContent order={mandate} />
          </PageHeading>
          <PageContent fixedWidht>
            {/* Alert collegamento linee */}
            <MandateAlertInfoBox mandate={mandate} />

            {/* Sezione SIAB */}
            <MandateSiabSection mandate={mandate} />

            {/* Sezione Linee Contabili */}
            <MandateAccountingLinesSection mandate={mandate} />

            {/* Sezione Spedizioni */}
            <ShipmentSection mandate={mandate} />

            {/* Sezione Cronologia attività */}
            {id && (
              <Section
                icon={<ClockCircleOutlined />}
                title="Cronologia Attività"
              >
                <ActivityLogTable entity="mandate" entityId={id} />
              </Section>
            )}
          </PageContent>
        </FormikForm>
      </Formik>
    </CorePageLayout>
  );
}
