import * as React from 'react';
import { useEffect, useState } from 'react';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { RouteComponentProps } from 'react-router-dom';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { InvoiceQueryDto } from 'common/dto/query/InvoiceQueryDto';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { InvoiceApi } from 'client/components/schema/invoice/InvoiceApi';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { LinkButton } from 'client/ui/link/LinkButton';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { SorterResult } from 'antd/lib/table/interface';
import { getDateRange } from 'client/ui/table/TableColumnFilter';
import { mapInvoiceFilterToOptions } from './mapInvoiceFilterToOptions';
import { TableSticky } from 'client/ui/table/TableSticky';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { AdminReferentFilter } from 'client/components/config/AdminReferentFilter';
import { PermissionLogic } from 'common/permissions/PermissionLogic';

interface Params {
  filter?: string;
}

export interface InvoicesListPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di elenco delle fatture
 */
export function InvoicesListPage(props: InvoicesListPageProps) {
  const { match } = props;
  const currentUser = useCurrentUser();
  const [filterOptions, setFilterOptions] = useState(
    mapInvoiceFilterToOptions(match.params.filter)
  );

  const query = new InvoiceQueryDto(
    getPaginationUrlQuery(props.location.search, {
      ...filterOptions.query,
      pageNumber: 1,
      pageSize: 20
    })
  );

  const columns = getPaginationUrlColumns<InvoiceDto, InvoiceQueryDto>(
    filterOptions.columns,
    query,
    {
      issuedDate: ['issuedDateFrom', 'issuedDateTo'],
      expiresAt: ['expireDateFrom', 'expireDateTo'],
      protocolDate: ['protocolDateFrom', 'protocolDateTo'],
      protocol: ['protocolNumber', 'protocolYear']
    }
  );

  const { response, loading, error } = useApiQuery(InvoiceApi.list, {
    data: {
      query
    }
  });
  const invoices = response?.data?.items;

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/invoicing/invoices/all', title: `Fatture` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: filterOptions.title,
    sideMenuKey: props.match.params.filter ?? 'all',
    menuKey: 'invoicing'
  });

  // Aggiorno le opzioni
  useEffect(() => {
    const filterOptions = mapInvoiceFilterToOptions(match.params.filter);
    setFilterOptions(filterOptions);
  }, [match.params.filter]);

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco delle fatture"
        description={
          currentUser?.role === 'DocumentalUser'
            ? "Con il ruolo assegnato 'Utente Documentale' non è possibile effettuare operazioni di consultazione sulle fatture. Contattare l'amministratore di sistema (Codice: UP100)."
            : undefined
        }
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle children={filterOptions.title} />}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        subTitle="Incluse Note di Credito"
        extra={
          <>
            <AdminReferentFilter />
            {PermissionLogic.has(currentUser, 'invoice.create') && (
              <LinkButton
                to="/invoicing/invoices/is-paper/create"
                icon={<PlusOutlined />}
                key="new"
                type="primary"
              >
                Nuova Fattura Cartacea
              </LinkButton>
            )}
          </>
        }
      />
      <PageContent noPadding noSpace>
        <TableSticky
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={invoices}
          size="middle"
          onRow={(record: InvoiceDto) => ({
            style: {
              cursor: 'pointer'
            },
            onClick: () => {
              props.history.push(
                `/invoicing/invoices/${filterOptions.filter}/${record.id}`
              );
            }
          })}
          onChange={(pagination, filters, sorter) => {
            const { field, order } = sorter as SorterResult<InvoiceDto>;

            // Date
            const issuedDateRange = getDateRange(filters.issuedDate);
            const expirationDateRange = getDateRange(filters.expiresAt);
            const protocolDateRange = getDateRange(filters.protocolDate);

            // Protocollo
            const [protocolNumber, protocolYear] = filters.protocol
              ? filters.protocol
              : [];

            // Fornitore
            const supplierName = filters.supplierName
              ? filters.supplierName[0].toString()
              : null;
            const supplierFiscalNumber = filters.supplierFiscalNumber
              ? filters.supplierFiscalNumber[0].toString()
              : null;

            // CIG e CUP
            const cigCode = filters.cigCode
              ? filters.cigCode[0].toString()
              : null;
            const cupCode = filters.cupCode
              ? filters.cupCode[0].toString()
              : null;

            // Numero Fattura
            const issuedNumber = filters.issuedNumber
              ? filters.issuedNumber[0].toString()
              : null;

            const invoiceQuery = {
              ...filterOptions.query,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize,
              orderBy: order ? field?.toString() : 'id',
              orderByDirection: order
                ? ColumnSortDirections[order]
                : ColumnSortDirections.descend,
              issuedDateFrom: issuedDateRange.from,
              issuedDateTo: issuedDateRange.to,
              expireDateFrom: expirationDateRange.from,
              expireDateTo: expirationDateRange.to,
              protocolDateFrom: protocolDateRange.from,
              protocolDateTo: protocolDateRange.to,
              issuedNumber,
              supplierName,
              supplierFiscalNumber,
              cigCode,
              cupCode,
              protocolNumber: protocolNumber?.toString(),
              protocolYear: protocolYear?.toString()
            };

            setPaginationUrlQuery(props, invoiceQuery);
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
