import { IShipmentType } from 'common/dto/generated/ShipmentDto';
import { EntityMachine } from 'common/fsm/EntityMachine';
import { yup } from 'common/validation/initYup';
import { Shipment } from 'server/schema/accounting/shipment/Shipment.entity';
import { ShipmentPriority, ShipmentState } from './ShipmentTypes';

export const ShipmentMachine = EntityMachine.for<IShipmentType>()
  .withState(shipment => shipment.state)
  .withAction('edit', {
    allowed: shipment =>
      shipment.state === ShipmentState.InProgress && !shipment.dossierUid
  })
  .withAction('delete', {
    allowed: shipment =>
      shipment.state === ShipmentState.InProgress && !shipment.mandateId,
    message: shipment => {
      if (shipment.state === ShipmentState.Shipped)
        return 'Una spedizione inviata non può essere eliminata';
      if (shipment.mandateId)
        return 'Una spedizione a cui è collegato un mandato non può essere eliminata';
      return 'La spedizione non può essere eliminata.';
    }
  })
  .withAction('action.dossier', {
    skip: shipment => !!shipment.dossierUid,
    schema: yup.object({
      checkKind: yup
        .string()
        .typeError('Il campo ${label} è un campo richiesto')
        .required()
        .label('Tipo Provvedimento')
    })
  })
  // NB: il check viene fatto anche via API da P3 (notify-send). In caso di modifica
  // dell'action, valutare l'impatto sull'API.
  .withAction('action.ship', {
    allowed: shipment =>
      shipment.state === ShipmentState.InProgress &&
      !!shipment.dossierNumber &&
      !!shipment.dossierYear,
    message: () => 'È necessario fascicolare la spedizione'
  })
  .withAction('action.integrate', {
    allowed: shipment =>
      !shipment.isIntegration && shipment.state === ShipmentState.Shipped,
    message: shipment =>
      shipment.isIntegration
        ? 'È possibile Integrare solo la Spedizione Originale'
        : shipment.state !== ShipmentState.Shipped
        ? 'Non è possibile Integrare una Spedizione ancora non inviata'
        : 'Impossibile integrare la spedizione'
  })
  .withTransition(ShipmentState.InProgress, ShipmentState.Shipped);
