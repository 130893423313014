import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { usePermissions } from 'client/components/auth/AuthModule';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { UserListColums } from 'client/components/schema/user/table/UserListColumns';
import { UserApi } from 'client/components/schema/user/UserApi';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { LinkButton } from 'client/ui/link/LinkButton';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { TableSticky } from 'client/ui/table/TableSticky';
import { UserDto } from 'common/dto/generated/UserDto';
import { UserQueryDto } from 'common/dto/query/UserQueryDto';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';

interface Props {}

interface IUserListPageProps extends RouteComponentProps<Props> {}

export function UserListPage(props: IUserListPageProps) {
  const [query, setQuery] = useState<UserQueryDto>(
    new UserQueryDto(
      getPaginationUrlQuery(props.location.search, {
        pageNumber: 1,
        pageSize: 20
      })
    )
  );

  const { response, loading, error } = useApiQuery(UserApi.list, {
    data: { query }
  });

  const users = response?.data.items;
  const permission = usePermissions();

  const columns = getPaginationUrlColumns<UserDto, UserQueryDto>(
    UserListColums,
    query
  );

  useEffect(() => {
    setQuery(
      new UserQueryDto(
        getPaginationUrlQuery(props.location.search, {
          pageNumber: 1,
          pageSize: 20
        })
      )
    );
  }, [props.location.search]);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Utenti',
    sideMenuKey: 'all',
    menuKey: 'users'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco degli utenti. Riprovare."
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle children={'Lista Utenti'} />}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={
          permission.has('users.write') && (
            <LinkButton
              type="primary"
              icon={<PlusOutlined />}
              to={`/users/create`}
            >
              Nuovo Utente
            </LinkButton>
          )
        }
      />
      <PageContent noPadding noSpace>
        <TableSticky
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={users}
          size="middle"
          onRow={(record: UserDto) => ({
            style: {
              cursor: 'pointer'
            },
            onClick: () => {
              props.history.push(`/users/${record.id}`);
            }
          })}
          onChange={(pagination, filters, sorter) => {
            const { field, order } = sorter as SorterResult<UserDto>;

            const nameFilter = filters['name']
              ? filters['name'][0].toString()
              : null;
            const codeFilter = filters['documentalUserCode']
              ? filters['documentalUserCode'][0].toString()
              : null;

            const userQuery = new UserQueryDto({
              ...query,
              name: nameFilter,
              documentalUserCode: codeFilter,
              orderBy: order ? field?.toString() : 'id',
              orderByDirection: order
                ? ColumnSortDirections[order]
                : ColumnSortDirections.descend,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize
            });

            setPaginationUrlQuery(props, userQuery);
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
