import { ColumnsType } from 'antd/lib/table';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { MandateDto } from 'common/dto/generated/MandateDto';
import { omit } from 'lodash';
import React from 'react';
import { MandateStateTag } from '../view/MandateStateTag';

export const MandatesFilterableColumns: ColumnsType<MandateDto> = [
  {
    title: 'Stato',
    dataIndex: 'isSiabImported',
    width: 100,
    render: (_: any, record) => <MandateStateTag mandate={record} />
  },
  {
    title: 'Numero',
    dataIndex: 'mandateNumber',
    className: 'bold-data',
    width: 100,
    sorter: true,
    ...createColumnFilter(FilterInputType.Text)
  },
  {
    title: 'Anno',
    dataIndex: 'mandateYear',
    width: 90,
    sorter: true,
    ...createColumnFilter(FilterInputType.Text)
  },

  {
    title: 'Beneficiario',
    dataIndex: 'beneficiary',
    ...createColumnFilter(FilterInputType.Text)
  },
  {
    title: 'Importo',
    dataIndex: 'amount',
    width: 140,
    align: 'right',
    render: amount => <CurrencyRender value={amount} />
  },

  {
    title: 'Importo Netto',
    dataIndex: 'netAmount',
    width: 140,
    align: 'right',
    render: netAmount => <CurrencyRender value={netAmount} />
  }
];

export const MandatesColumns = MandatesFilterableColumns.map(column =>
  omit(column, ['sorter', 'filterDropdown'])
);
