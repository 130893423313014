import { ColumnsType } from 'antd/lib/table';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceFilteredColumns } from './InvoiceColumns';

export const InvoiceToProtocolColumns: ColumnsType<InvoiceDto> = [
  InvoiceFilteredColumns.isPaper,
  { ...InvoiceFilteredColumns.receivedAt, fixed: 'left' },
  InvoiceFilteredColumns.issuedDate,
  InvoiceFilteredColumns.expiresAt,
  InvoiceFilteredColumns.state,
  InvoiceFilteredColumns.supplierName,
  InvoiceFilteredColumns.supplierFiscal,
  InvoiceFilteredColumns.issuedNumber,
  InvoiceFilteredColumns.invoiceTypeCode,
  InvoiceFilteredColumns.totalAmount
];
