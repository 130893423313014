import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';

import React from 'react';

export function createInvoiceProtocolColumnFilter<T extends {}>(): Partial<
  ColumnProps<T>
> {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          <Space direction="vertical">
            <Input
              type="number"
              min={0}
              placeholder="Numero Protocollo"
              value={selectedKeys![0]}
              onChange={e => {
                const newKeys: any[] = [...selectedKeys];
                newKeys[0] =
                  e.target.value == null || e.target.value == ''
                    ? null
                    : e.target.value == null || e.target.value;
                setSelectedKeys!(newKeys);
              }}
              onPressEnter={() => {
                confirm!({ closeDropdown: true });
              }}
              style={{ width: 188, display: 'block' }}
            />
            <Input
              min={0}
              type="number"
              placeholder="Anno Protocollo"
              value={selectedKeys![1]}
              onChange={e => {
                const newKeys: any[] = [...selectedKeys];
                newKeys[1] =
                  e.target.value == null || e.target.value == ''
                    ? null
                    : e.target.value == null || e.target.value;
                setSelectedKeys!(newKeys);
              }}
              onPressEnter={() => {
                confirm!({ closeDropdown: true });
              }}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
          </Space>
        </div>
        <Button
          type="primary"
          onClick={() => {
            confirm!({ closeDropdown: true });
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Cerca
        </Button>
        <Button
          onClick={() => {
            clearFilters!();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    )
  };
}
