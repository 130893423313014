import React from 'react';
import { LoginForm } from './LoginForm';
import { RouteComponentProps, Redirect } from 'react-router';
import { useAuth } from '../AuthModule';
import { logger } from 'client/core/logger/logger';
import { Col, Row } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';

interface Props extends RouteComponentProps {}

/**
 * Disponibile soltanto se l'utente non ha ancora effettuato il login.
 * In caso di successo, lo reindirizza alla pagina corretta.
 */
export const LoginPage = (props: Props) => {
  const { logged } = useAuth();

  // Calcolo l'origine
  const { from } = (props.location.state as any) || { from: { pathname: '/' } };

  logger.log(`Redirect utente dopo login: ->`, from);
  if (logged) return <Redirect to={from} />;

  return (
    <PageLayout>
      <Row justify="center" align="middle" style={{ width: '100%' }}>
        <Col xs={20} md={12}>
          <LoginForm />
        </Col>
      </Row>
    </PageLayout>
  );
};
