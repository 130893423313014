import { apiClient } from 'client/core/network/apiClient';
import {
  EconomicStatementSimulationDto,
  PaginatedEconomicStatementSimulationDto
} from 'common/dto/generated/EconomicStatementSimulationDto';
import { PaginatedEconomicStatementSimulationItemDto } from 'common/dto/generated/EconomicStatementSimulationItemDto';
import { EconomicStatementSimulationInputDto } from 'common/dto/query/EconomicStatementSimulationInputDto';
import { EconomicStatementSimulationItemsQueryDto } from 'common/dto/query/EconomicStatementSimulationItemsQueryDto';
import { EconomicStatementSimulationQueryDto } from 'common/dto/query/EconomicStatementSimulationQueryDto';

export const EconomicSimulationApi = {
  find: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(EconomicStatementSimulationDto)
      .get(`/economic-statement-simulations/${opts.id}`),

  create: (opts: { input: EconomicStatementSimulationInputDto }) =>
    apiClient
      .requestWithResponseType(EconomicStatementSimulationDto)
      .post(`/economic-statement-simulations/`, opts.input),

  list: (opts: { query: EconomicStatementSimulationQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedEconomicStatementSimulationDto)
      .get('/economic-statement-simulations', { params: opts.query }),

  items: {
    list: (opts: {
      id: number;
      query: EconomicStatementSimulationItemsQueryDto;
    }) =>
      apiClient
        .requestWithResponseType(PaginatedEconomicStatementSimulationItemDto)
        .get(`/economic-statement-simulations/${opts.id}/items`, {
          params: opts.query
        })
  }
};
