import { FileDoneOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { PccApi } from 'client/components/schema/pcc/PccApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { InvoiceQueryDto } from 'common/dto/query/InvoiceQueryDto';
import { Model002CreateQueryDto } from 'common/dto/query/Model002CreateQueryDto';
import React from 'react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export interface IPccExport002ButtonProps {
  query: InvoiceQueryDto | null;
  invoices: number[];
  text: string;
  selectAll: boolean;
}

/**
 * Genera il CSV con le fatture (cartacee) da comunicare come ricevute a PCC.
 * Redirect alla pagina di dettaglio esportazione.
 * (Modello 002)
 */
export function PccExport002Button(props: IPccExport002ButtonProps) {
  const [createModel, createModelState] = useApiMutation(
    PccApi.model002.create,
    {}
  );
  const { invoices, text, query, selectAll } = props;
  const history = useHistory();

  const handleGenerate = useCallback(async () => {
    if (!selectAll && (!invoices || invoices.length === 0)) {
      message.warning('Nessuna fattura selezionata.');
      return;
    }

    const input = new Model002CreateQueryDto({
      invoiceIds: !selectAll ? invoices : undefined,
      query: selectAll ? query : undefined
    });

    try {
      const modelResponse = await createModel({ data: { input } });
      message.success('File esportazione generato correttamente.');
      history.push(`/accounting/pcc-exports/all/${modelResponse.data.id}`);
    } catch (e) {
      message.error('Si è verificato un errore.');
    }
  }, [invoices, query, selectAll]);

  return (
    <Button
      type="primary"
      icon={<FileDoneOutlined />}
      onClick={handleGenerate}
      disabled={(!invoices || invoices.length === 0) && !selectAll}
      loading={createModelState.loading}
    >
      {text}
    </Button>
  );
}
