import { apiClient } from 'client/core/network/apiClient';
import {
  EconomicStatementDto,
  PaginatedEconomicStatementDto
} from 'common/dto/generated/EconomicStatementDto';
import { EconomicStatementInputDto } from 'common/dto/query/EconomicStatementInputDto';
import { EconomicStatementQueryDto } from 'common/dto/query/EconomicStatementQueryDto';

export const EconomicStatementApi = {
  create: (opts: { input: EconomicStatementInputDto }) =>
    apiClient
      .requestWithResponseType(EconomicStatementDto)
      .post(`/economic-statements/`, opts.input),

  list: (opts: { query: EconomicStatementQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedEconomicStatementDto)
      .get('/economic-statements', { params: opts.query })
};
