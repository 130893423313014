import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaNotRequired, SchemaValidate } from "cli/generate/decorators/SchemaDecorators";
import { EconomicReportKind } from "common/schema/economic-statement/EconomicReportKind";
import { EconomicSemester } from "common/schema/economic-statement/EconomicSemester";

@GenerateDto()
class EconomicStatementInput {
  @SchemaLabel('Anno')
  year!: number;
  @SchemaLabel('Semestre')
  semester!: EconomicSemester;
  @SchemaLabel('Tipo Report')
  reportKind!: EconomicReportKind;
  @SchemaLabel('Seleziona solo fatture pagate')
  @SchemaNotRequired()
  @SchemaValidate(schema => schema.default(false))
  onlyPaid!: boolean
}

/**
 * Rappresentazione DTO della classe EconomicStatementInput 
 * Hash: e462b697370b1551fef0033ad2bbf24e
 */
@ValidationSchema(() => EconomicStatementInputSchema)
export class EconomicStatementInputDto {
  @ApiProperty({ type: Number, description: 'Anno' })
  year!: number;
  @ApiProperty({ enum: ['1', '2', 'Both'], description: 'Semestre' })
  semester!: EconomicSemester;
  @ApiProperty({ enum: ['Standard', 'Detail'], description: 'Tipo Report' })
  reportKind!: EconomicReportKind;
  @ApiProperty({ required: false, type: Boolean, description: 'Seleziona solo fatture pagate' })
  onlyPaid!: boolean;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<EconomicStatementInputDto>) {
    if (values != null) {
      Object.assign(this, values instanceof EconomicStatementInputDto ? values : plainToClass(EconomicStatementInputDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await EconomicStatementInputSchema.validate(classToPlain(this), options);
    return new EconomicStatementInputDto(validated);
  }
}

export const EconomicStatementInputSchema = yup
  .object({
    year: yup.number().required().label('Anno'),
    semester: yup.string().oneOfEnum(EconomicSemester).required().label('Semestre'),
    reportKind: yup.string().oneOfEnum(EconomicReportKind).required().label('Tipo Report'),
    onlyPaid: yup.boolean().default(false).label('Seleziona solo fatture pagate')
  })
  .noUnknown()
  .meta({ schemaName: "EconomicStatementInputSchema" })
  .required();
