import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { Model003Action } from 'server/schema/pcc/model003/Model003Types';
import { PccModelType } from 'server/schema/pcc/pcc-model-base/PccTypes';
import { PccResponseDto } from './PccResponseDto';
import { UserDto } from './UserDto';

/**
 * Rappresentazione DTO della classe PccExport definita in: src/server/schema/pcc/pcc-export/PccExport.entity.ts
 * Hash: fce8ad6ed5463c7a14c9d4d2ba6c2657
 */
@ValidationSchema(() => PccExportSchema)
export class PccExportDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ enum: ['002', '003'], description: 'Tipo Modello' })
  model!: PccModelType;
  @ApiProperty({ required: false, enum: ['CO', 'CP', 'CS'], description: 'Tipo Comunicazione' })
  type?: Model003Action | null | undefined;
  @ApiProperty({ type: String, description: 'Percorso di salvataggio' })
  path!: string;
  @ApiProperty({ type: String, description: 'Nome file' })
  fileName!: string;
  @ApiProperty({ required: false, type: Number, description: 'ID autore' })
  authorId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  author?: UserDto | null;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data creazione' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: Number, description: 'Id Risposta PCC' })
  responseId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => PccResponseDto, description: 'Risposta PCC' })
  @Type(() => PccResponseDto)
  response?: PccResponseDto | null;
  @ApiProperty({ required: false, type: Boolean, description: 'Presenza di errori' })
  withError!: boolean;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<PccExportDto>) {
    if (values != null) {
      Object.assign(this, values instanceof PccExportDto ? values : plainToClass(PccExportDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await PccExportSchema.validate(classToPlain(this), options);
    return new PccExportDto(validated);
  }
}

/** Interfaccia simmetrica al DTO PccExportDto */
export type IPccExportType = IDto<PccExportDto>;

/**
 * DTO Paginato della classe PccExport
 */
export class PaginatedPccExportDto {
  @ApiProperty({ type: [PccExportDto] })
  @Type(() => PccExportDto)
  items!: PccExportDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const PccExportSchema = yup
  .object({
    id: yup.number(),
    model: yup.string().oneOfEnum(PccModelType).required().label('Tipo Modello'),
    type: yup.string().oneOfEnum(Model003Action).nullable().when('model', (model: PccModelType, schema: any) =>
          model === PccModelType.Operation
            ? schema.required()
            : schema.notRequired().strip()
        ).label('Tipo Comunicazione'),
    path: yup.string().required().label('Percorso di salvataggio'),
    fileName: yup.string().required().label('Nome file'),
    authorId: yup.number().nullable().label('ID autore'),
    responseId: yup.number().nullable().label('Id Risposta PCC'),
    withError: yup.boolean().default(false).label('Presenza di errori')
  })
  .noUnknown()
  .meta({ schemaName: "PccExportSchema" })
  .required();
