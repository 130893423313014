import { FormOutlined } from '@ant-design/icons';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { Section } from 'client/ui/section/Section';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import React from 'react';

export interface InvoiceNotesSectionProps {
  invoice: InvoiceDto;
}

/**
 * Mostra le note della fattura
 */
export function InvoiceNotesSection(props: InvoiceNotesSectionProps) {
  const { invoice } = props;

  return (
    <Section icon={<FormOutlined />} title="Note" hasPadding>
      <FormFieldsContainer>
        <FormFieldItem
          inline
          component={TextAreaInput}
          label={null}
          name="notes"
          placeholder="Note"
          rows={3}
        />
      </FormFieldsContainer>
    </Section>
  );
}
