import * as React from 'react';
import { DataItem } from 'client/ui/form/field/DataItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { InvoiceTransmissionDataDto } from 'common/dto/generated/InvoiceTransmissionDataDto';
import { Divider } from 'antd';

export interface ITransmissionBatchViewProps {
  transmission: InvoiceTransmissionDataDto;
  sdiId: string;
}

export function TransmissionBatchView(props: ITransmissionBatchViewProps) {
  const { transmission, sdiId } = props;
  if (!transmission)
    return <FormFieldsContainer title="Trasmissione"> </FormFieldsContainer>;
  return (
    <>
      <FormFieldsContainer title="Trasmissione">
        <DataItem
          label="Partita IVA"
          value={transmission.senderIdCountry + transmission.senderIdCode}
        />
        <DataItem label="Progressivo" value={transmission.progressive} />
        <DataItem label="Formato Trasm." value={transmission.format} />
        <DataItem label="Cod Destinatario" value={transmission.recipientCode} />
      </FormFieldsContainer>
      <FormFieldsContainer title="Identificativo SDI">
        <DataItem label="Numero" value={sdiId} />
      </FormFieldsContainer>
    </>
  );
}
