import { ArrowRightOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { PccRowStateTag } from 'client/components/schema/pcc/PccRowStateTag';
import { StateTag } from 'client/ui/state/StateTag';
import { PccRowDto } from 'common/dto/generated/PccRowDto';
import { PccRowReferenceType } from 'common/schema/pcc/pcc-row/PccRowTypes';
import React from 'react';
import { Link } from 'react-router-dom';

export const PccRowColumns: ColumnsType<PccRowDto> = [
  {
    title: 'Identificatore',
    dataIndex: 'label'
  },
  {
    title: 'Stato',
    dataIndex: 'state',
    render: (_, record) => <PccRowStateTag line={record} />
  },
  {
    title: 'Codice Errore',
    dataIndex: 'errorCode',
    render: (_, record) =>
      record.errorCode ? (
        <StateTag color="red">{record.errorCode}</StateTag>
      ) : (
        ''
      )
  },
  {
    title: 'Descrizione Errore',
    dataIndex: 'errorDescription'
  },
  {
    title: '',
    align: 'right',
    width: 80,
    render: (_, record) =>
      record.sourceId && (
        <Link
          to={
            record.type === PccRowReferenceType.Invoice
              ? `/invoicing/invoices/all/${record.sourceId}`
              : `/accounting/lines/all/${record.sourceId}`
          }
        >
          Vai <ArrowRightOutlined />
        </Link>
      )
  }
];
