import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { InvoiceClientDataDto, InvoiceClientDataSchema } from './InvoiceClientDataDto';
import { InvoiceIssuerDataDto, InvoiceIssuerDataSchema } from './InvoiceIssuerDataDto';
import { InvoiceSupplierDataDto, InvoiceSupplierDataSchema } from './InvoiceSupplierDataDto';
import { InvoiceTransmissionDataDto, InvoiceTransmissionDataSchema } from './InvoiceTransmissionDataDto';
import { InvoiceDto, InvoiceSchema } from './InvoiceDto';

/**
 * Rappresentazione DTO della classe InvoiceBatch definita in: src/server/schema/invoice/invoice-batch/InvoiceBatch.entity.ts
 * Hash: 0aac7a0b500a5c4408423440632e0328
 */
@ValidationSchema(() => InvoiceBatchSchema)
export class InvoiceBatchDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: String })
  migrationId?: string | null | undefined;
  @ApiProperty({ type: String })
  sdiId!: string;
  @ApiProperty({ type: String })
  sdiFileName!: string;
  @ApiProperty({ required: false, type: String, format: 'date' })
  receivedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: () => [InvoiceDto] })
  @Type(() => InvoiceDto)
  invoices!: InvoiceDto[];
  @ApiProperty({ type: () => InvoiceClientDataDto })
  @Type(() => InvoiceClientDataDto)
  client!: InvoiceClientDataDto;
  @ApiProperty({ required: false, type: () => InvoiceIssuerDataDto })
  @Type(() => InvoiceIssuerDataDto)
  issuer?: InvoiceIssuerDataDto | null;
  @ApiProperty({ type: () => InvoiceSupplierDataDto })
  @Type(() => InvoiceSupplierDataDto)
  supplier!: InvoiceSupplierDataDto;
  @ApiProperty({ type: () => InvoiceTransmissionDataDto })
  @Type(() => InvoiceTransmissionDataDto)
  transmission!: InvoiceTransmissionDataDto;
  @ApiProperty({ type: String })
  filePath!: string;
  @ApiProperty({ type: String })
  metadataFilePath!: string;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceBatchDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceBatchDto ? values : plainToClass(InvoiceBatchDto, values));
    }
  }

  isPkcs7Signed() {
    return this.filePath.endsWith('.p7m');
  }

  async validate(options?: any) {
    const validated = await InvoiceBatchSchema.validate(classToPlain(this), options);
    return new InvoiceBatchDto(validated);
  }
}

/** Interfaccia simmetrica al DTO InvoiceBatchDto */
export type IInvoiceBatchType = IDto<InvoiceBatchDto>;

/**
 * DTO Paginato della classe InvoiceBatch
 */
export class PaginatedInvoiceBatchDto {
  @ApiProperty({ type: [InvoiceBatchDto] })
  @Type(() => InvoiceBatchDto)
  items!: InvoiceBatchDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const InvoiceBatchSchema = yup
  .object({
    id: yup.number(),
    sdiId: yup.string().required(),
    sdiFileName: yup.string().required(),
    invoices: yup.array(InvoiceSchema),
    client: InvoiceClientDataSchema.required(),
    issuer: InvoiceIssuerDataSchema.nullable(),
    supplier: InvoiceSupplierDataSchema.required(),
    transmission: InvoiceTransmissionDataSchema.required(),
    filePath: yup.string().required(),
    metadataFilePath: yup.string().required()
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceBatchSchema" })
  .required();
