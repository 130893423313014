import Text from 'antd/lib/typography/Text';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { ForceExportRule } from 'common/schema/invoice/InvoiceTypes';
import React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

function getLabel(value: ForceExportRule) {
  switch (value) {
    case ForceExportRule.Create:
      return 'Crea nuova fattura';
    case ForceExportRule.Substitute:
      return 'Sostituisci';
    default:
      assertNever(value);
  }
}

export interface IForceExportRuleSelectInputProps {}

export function ForceExportRuleSelectInput(
  props: IForceExportRuleSelectInputProps
) {
  const {} = props;
  /**
   * HELP TEXT:
   *  Forza l’immissione dei dati riferiti ad una fattura che, in fase di
   *     elaborazione, sia stata esitata con un warning (c.d. fatture sospette).
   */

  // TODO Limitare Substitute solo nel caso in cui la fattura da sostituire sia nello stato di PRESENTATA o RICEVUTA (?)
  return (
    <FormFieldItem
      component={SelectInput}
      labelWidth="width-140"
      options={[
        {
          label: getLabel(ForceExportRule.Create),
          value: ForceExportRule.Create
        },
        {
          label: getLabel(ForceExportRule.Substitute),
          value: ForceExportRule.Substitute
        }
      ]}
      label="Forzatura Immissione"
      name="forceExportRule"
      placeholder="Regola forzatura immissione PCC"
      allowClear
    />
  );
}
