import { ColumnsType } from 'antd/lib/table';
import { ISODate } from 'client/ui/display/date/ISODate';
import React from 'react';
import { InvoiceBatchDto } from 'common/dto/generated/InvoiceBatchDto';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { SupplierAddress } from './SupplierAddress';

export const InvoiceBatchColumns: ColumnsType<InvoiceBatchDto> = [
  {
    title: 'Data',
    dataIndex: 'createdAt',
    width: 120,
    sorter: true,
    render: (_, batch) => <ISODate date={batch.receivedAt} />
  },
  {
    title: 'P. IVA - CF',
    dataIndex: 'supplierFiscalNumber',
    ...createColumnFilter(FilterInputType.Text),
    width: 180,
    render: (_, record) => record.supplier?.getFiscalNumber()
  },
  {
    title: 'Cedente/Prestatore',
    dataIndex: 'supplierName',
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => record.supplier?.getName()
  },
  {
    title: 'Indirizzo',
    dataIndex: 'data',
    render: (_, record) => <SupplierAddress supplier={record.supplier} />
  }
];
