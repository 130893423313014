import { IInvoiceType } from 'common/dto/generated/InvoiceDto';
import { EntityMachine } from 'common/fsm/EntityMachine';
import { yup } from 'common/validation/initYup';
import { InvoiceLogic } from './InvoiceLogic';
import { AcceptanceState, InvoiceState } from './InvoiceTypes';

/** Schema per la validazione/protocollazione di una fattura */
const protocolOrValidateSchema = yup.object({
  contractId: yup
    .number()
    .allowEmptyString()
    .required('La fattura deve avere un contratto associato'),
  lines: yup
    .array(yup.object())
    .min(1, 'Deve essere presente almeno una Linea di fattura'),
  vatSummaries: yup
    .array(yup.object())
    .min(1, 'Deve essere presente almeno una voce Riepilogo IVA')
});
// Ignora la validazione quando la fattura è Rifiutata.
// Al momento è commentato perché le fatture rifiutate _non_ possono essere
// protocollate.
//
// .when('$record.state', {
//   is: (state: InvoiceState) => state === InvoiceState.Rejected,
//   then: schema => yup.object({})
// });

export const InvoiceMachine = EntityMachine.for<IInvoiceType>()
  .withState(invoice => invoice.state)
  .withAction('edit.general', {
    allowed: invoice => !invoice.isSDI() && invoice.protocolNumber == null
  })
  /**
   * È possibile modificare il contratto fino a quando non è protocollata
   */
  .withAction('edit.contract', {
    allowed: invoice => invoice.protocolNumber == null
  })
  /**
   * Valutazione
   */
  .withAction('state.validate', {
    skip: invoice => invoice.state !== InvoiceState.ToValidate,
    schema: protocolOrValidateSchema,
    schemaMessage: (invoice, error) => error.errors.join(', ')
  })
  /**
   * Protocollazione
   */
  .withAction('edit.protocol', {
    skip: invoice =>
      invoice.id == null ||
      invoice.protocolNumber != null ||
      invoice.state === InvoiceState.ToValidate ||
      invoice.acceptance !== AcceptanceState.Accepted,
    schema: protocolOrValidateSchema
  })
  .withAction('edit.protocol:partial', {
    // Salta la protocollazione parziale se è stata già protocollata o non è salvata
    skip: invoice =>
      invoice.isFullyProtocolled ||
      invoice.id == null ||
      invoice.protocolNumber == null ||
      invoice.state === InvoiceState.ToValidate ||
      invoice.acceptance !== AcceptanceState.Accepted,
    schema: protocolOrValidateSchema
  })
  .withAction('edit.lines.accounting')
  .withAction('edit.accounting', {
    allowed: invoice =>
      invoice.acceptance === AcceptanceState.Accepted &&
      invoice.protocolNumber != null &&
      invoice.executionDate != null &&
      invoice.testingDate != null,
    message: invoice => InvoiceLogic.getInvoiceAccountingAlert(invoice)
  })
  .withAction('edit.execution', {
    allowed: invoice => invoice.acceptance === AcceptanceState.Accepted
  })
  .withAction('edit.expiresAt', {
    allowed: () => false
  })
  .withAction('xml.download', {
    allowed: invoice => invoice.generatedFilePath != null
  })
  .withAction('accounting.accept-or-reject', {
    allowed: invoice => invoice.acceptance === AcceptanceState.Indeterminate
  })
  /** Modifica delle linee di fattura e il riepilogo IVA */
  .withAction('edit.vat-summary-and-lines', {
    allowed: invoice => InvoiceLogic.canEditVatSummariesAndLines(invoice)
  });
