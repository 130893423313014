import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { EconomicSemester } from 'common/schema/economic-statement/EconomicSemester';
import { UserDto } from './UserDto';
import { QueueTaskState } from 'common/schema/queue-task/QueueTaskState';

/**
 * Rappresentazione DTO della classe EconomicStatementSimulation definita in: src/server/schema/economy/economic-statement/simulation/EconomicStatementSimulation.entity.ts
 * Hash: 031895696eff405f10dd2d7e4392ca32
 */
@ValidationSchema(() => EconomicStatementSimulationSchema)
export class EconomicStatementSimulationDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ type: String })
  title!: string;
  @ApiProperty({ type: Number })
  year!: number;
  @ApiProperty({ enum: ['1', '2', 'Both'] })
  semester!: EconomicSemester;
  @ApiProperty({ required: false, enum: ['Running', 'Completed', 'Failed'] })
  state!: QueueTaskState;
  @ApiProperty({ required: false, type: Boolean })
  onlyPaid!: boolean;
  @ApiProperty({ required: false, type: Number })
  authorId!: number;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  author!: UserDto;
  @ApiProperty({ type: Number })
  totalLines!: number;
  @ApiProperty({ required: false, type: Number })
  repairedLinesCount?: number | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<EconomicStatementSimulationDto>) {
    if (values != null) {
      Object.assign(this, values instanceof EconomicStatementSimulationDto ? values : plainToClass(EconomicStatementSimulationDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await EconomicStatementSimulationSchema.validate(classToPlain(this), options);
    return new EconomicStatementSimulationDto(validated);
  }
}

/** Interfaccia simmetrica al DTO EconomicStatementSimulationDto */
export type IEconomicStatementSimulationType = IDto<EconomicStatementSimulationDto>;

/**
 * DTO Paginato della classe EconomicStatementSimulation
 */
export class PaginatedEconomicStatementSimulationDto {
  @ApiProperty({ type: [EconomicStatementSimulationDto] })
  @Type(() => EconomicStatementSimulationDto)
  items!: EconomicStatementSimulationDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const EconomicStatementSimulationSchema = yup
  .object({
    id: yup.number(),
    title: yup.string().required(),
    year: yup.number().required(),
    semester: yup.string().oneOfEnum(EconomicSemester).required(),
    state: yup.string().oneOfEnum(QueueTaskState).default(QueueTaskState.Running),
    onlyPaid: yup.boolean().default(false),
    authorId: yup.number()
  })
  .noUnknown()
  .meta({ schemaName: "EconomicStatementSimulationSchema" })
  .required();
