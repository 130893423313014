import * as React from 'react';
import { Button, Typography, Breadcrumb, Row, Col } from 'antd';
const { Title, Text } = Typography;
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { Section } from 'client/ui/section/Section';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import {
  ArrowRightOutlined,
  DownloadOutlined,
  NotificationOutlined
} from '@ant-design/icons';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { DataItem } from 'client/ui/form/field/DataItem';
import { ISODate } from 'client/ui/display/date/ISODate';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  notificationCodeLabels,
  notificationTypeLabels
} from 'common/schema/notification/NotificationLabels';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { NotificationApi } from 'client/components/schema/notification-sdi/NotificationApi';
import { NotificationDto } from 'common/dto/generated/NotificationDto';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { resolveApiPath } from 'client/core/appConfig';
import { base64 } from 'client/core/encoding/base64';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { LinkButton } from 'client/ui/link/LinkButton';
import { AnchorLinkButton } from 'client/ui/link/AnchorLinkButton';
import { AnchorAPILink } from 'client/ui/link/AnchorAPILink';

interface Params {
  id: string;
}

export interface SdiNotificationPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina della Notifica SDI
 */
export function SdiNotificationPage(props: SdiNotificationPageProps) {
  const labelWidth: ILabelWidth = 'width-140';

  const id = props.match.params.id ? parseInt(props.match.params.id, 10) : null;

  if (id == null) {
    throw new Error('Non è stato fornito nessun ID della notifica');
  }

  // Gestione API
  const { response, error, loading, refetch } = useApiQuery(
    NotificationApi.find,
    {
      data: { id: id }
    }
  );
  const notification = response?.data;

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/invoicing/invoices/all',
    title: `Fatture`
  });
  useBreadcrumbItem({
    priority: 10,
    path: '/invoicing/notifications',
    title: `Notifiche SDI`
  });
  useBreadcrumbItem({
    path: props.location.pathname,
    priority: 20,
    title: `Notifica #${notification?.sdiId}`,
    menuKey: 'invoicing',
    sideMenuKey: 'sdi-detail'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere alla notifica"
        error={error}
      />
    );
  }

  if (!notification) return <LoadingPage />;

  return (
    <CorePageLayout>
      <PageHeading
        title={
          <PageHeadingTitle>
            Notifica {notificationTypeLabels[notification.type]}
          </PageHeadingTitle>
        }
        breadcrumbRender={() => <BreadcrumbsContainer />}
        subTitle={
          <span>
            del{' '}
            <ISODate
              date={notification.createdAt}
              format="DD/MM/YYYY HH:mm:ss"
            />
          </span>
        }
        extra={[
          <AnchorAPILink
            showAsButton
            key="xml"
            href={resolveApiPath(
              `/notifications/${notification.id}/xml/download`
            )}
            anchorProps={{ target: '_blank' }}
            icon={<DownloadOutlined />}
            disabled={!notification.filePath}
          >
            Visualizza XML
          </AnchorAPILink>,
          <LinkButton
            key="batch"
            to={`/invoicing/batches/${notification.batchId}`}
          >
            Vai al Lotto <ArrowRightOutlined />
          </LinkButton>
        ]}
      />

      <PageContent fixedWidht>
        <Section
          icon={<NotificationOutlined />}
          title="Dettaglio Notifica"
          hasPadding
        >
          <Row gutter={24}>
            <Col span={18} offset={3}>
              <FormFieldsContainer>
                <DataItem
                  labelWidth={labelWidth}
                  label="Tipo Notifica"
                  value={notificationTypeLabels[notification.type]}
                  hideIfEmpty
                />
                {notification.code && (
                  <DataItem
                    labelWidth={labelWidth}
                    label="Codice Notifica"
                    value={notificationCodeLabels[notification.code]}
                    hideIfEmpty
                  />
                )}
                <DataItem
                  strong
                  labelWidth={labelWidth}
                  label="Descrizione"
                  value={notification.description}
                  hideIfEmpty
                />
                <DataItem
                  labelWidth={labelWidth}
                  label="Identificativo SDI"
                  value={notification.sdiId}
                  hideIfEmpty
                />
                <DataItem
                  labelWidth={labelWidth}
                  label="Nome File"
                  value={notification.sdiFileName}
                  hideIfEmpty
                />
                <DataItem
                  labelWidth={labelWidth}
                  label="ID Messaggio"
                  value={notification.sdiMessageId}
                  hideIfEmpty
                />
                <DataItem
                  labelWidth={labelWidth}
                  label="Data Ricezione"
                  value={<ISODate date={notification.receivedAt} />}
                  hideIfEmpty
                />
                <DataItem
                  labelWidth={labelWidth}
                  label="Data Notifica"
                  value={<ISODate date={notification.createdAt} />}
                  hideIfEmpty
                />
                <DataItem
                  labelWidth={labelWidth}
                  label="Note"
                  value={notification.notes}
                  hideIfEmpty
                />
                {/* TODO Reinserire */}
                {/* <DataItem
                  labelWidth={labelWidth}
                  label="Lotto di riferimento"
                  value={sdi.batch.sdiId}
                  hideIfEmpty
                /> */}
                <DataItem
                  labelWidth={labelWidth}
                  label="Numero Fattura"
                  value={notification.invoiceNumber}
                  hideIfEmpty
                />
                <DataItem
                  labelWidth={labelWidth}
                  label="Anno Fattura"
                  value={notification.invoiceYear}
                  hideIfEmpty
                />
              </FormFieldsContainer>
            </Col>
          </Row>
        </Section>
      </PageContent>
    </CorePageLayout>
  );
}
