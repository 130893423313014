import { TagProps } from 'antd';
import { StateTag } from 'client/ui/state/StateTag';
import { ShipmentStateOptions } from 'common/schema/shipment/ShipmentLabels';
import { ShipmentState } from 'common/schema/shipment/ShipmentTypes';
import * as React from 'react';

export interface ShipmentStateTagProps extends TagProps {
  state: ShipmentState;
}

/**
 * Visualizzazione come Tag dello stato della spedizione
 */
export function ShipmentStateTag(props: ShipmentStateTagProps) {
  const option = ShipmentStateOptions.find(
    option => option.value === props.state
  );
  return (
    <StateTag color={props.color ?? option?.color} children={option?.label} />
  );
}
