import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";
import { PaginatedMetaDto } from '../shared/PaginatedDto';




@GenerateDto()
class DocumentalCorrespondent {
    @SchemaLabel('Nome utente documentale')
    name?: string | null;
    @SchemaLabel('Cognome utente documentale')
    surname?: string | null;
    @SchemaLabel('Codice utente documentale')
    code!: string;
    @SchemaLabel('Id utente documentale')
    id!: string;
    @SchemaLabel('Descrizione')
    description!: string;
}

/**
 * Rappresentazione DTO della classe DocumentalCorrespondent 
 * Hash: a86db05b1c53fd7fc83cbf992a5baedf
 */
@ValidationSchema(() => DocumentalCorrespondentSchema)
export class DocumentalCorrespondentDto {
  @ApiProperty({ required: false, type: String, description: 'Nome utente documentale' })
  name?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Cognome utente documentale' })
  surname?: string | null | undefined;
  @ApiProperty({ type: String, description: 'Codice utente documentale' })
  code!: string;
  @ApiProperty({ type: String, description: 'Id utente documentale' })
  id!: string;
  @ApiProperty({ type: String, description: 'Descrizione' })
  description!: string;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<DocumentalCorrespondentDto>) {
    if (values != null) {
      Object.assign(this, values instanceof DocumentalCorrespondentDto ? values : plainToClass(DocumentalCorrespondentDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await DocumentalCorrespondentSchema.validate(classToPlain(this), options);
    return new DocumentalCorrespondentDto(validated);
  }
}

/**
 * DTO Paginato della classe DocumentalCorrespondent
 * NB: Aggiunto a mano.
 */
 export class PaginatedDocumentalCorrespondentDto {
  @ApiProperty({ type: [DocumentalCorrespondentDto] })
  @Type(() => DocumentalCorrespondentDto)
  items!: DocumentalCorrespondentDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const DocumentalCorrespondentSchema = yup
  .object({
    name: yup.string().nullable().label('Nome utente documentale'),
    surname: yup.string().nullable().label('Cognome utente documentale'),
    code: yup.string().required().label('Codice utente documentale'),
    id: yup.string().required().label('Id utente documentale'),
    description: yup.string().required().label('Descrizione')
  })
  .noUnknown()
  .meta({ schemaName: "DocumentalCorrespondentSchema" })
  .required();
