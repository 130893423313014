import { ArrowRightOutlined, ExportOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { CurrencyRender } from 'client/ui/currency/CurrencyRender';
import { ISODate } from 'client/ui/display/date/ISODate';
import { createTypedColumns } from 'client/ui/table/createTypedColumns';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { AccountingLineDto } from 'common/dto/generated/AccountingLineDto';
import {
  AccountingLineState,
  DebtState
} from 'common/schema/accounting-line/AccountingLineTypes';
import Decimal from 'decimal.js-light';
import React from 'react';
import { Link } from 'react-router-dom';
import { SDIChargeabilityTag } from '../../invoice-vat-summary/view/SDIChargeabilityTag';
import { AccountingLineStateTag } from '../view/AccountingLineStateTag';
import { DebtStateTag } from '../view/DebtStateTag';
import { PccCommunicationsState } from '../view/PccCommunicationsState';

/**
 * Colonne di base per tabelle Linee Contabili
 */
export const AccountingLineColumns = createTypedColumns(AccountingLineDto, {
  state: {
    title: 'Stato',
    dataIndex: 'state',
    width: 100,
    render: (state: AccountingLineState) => (
      <AccountingLineStateTag state={state} />
    )
  },
  expiresAt: {
    title: 'Scadenza',
    dataIndex: 'invoice.expiresAt',
    width: 120,
    sorter: true,
    ...createColumnFilter(FilterInputType.DateRange),
    render: (_, record: AccountingLineDto) =>
      record.invoice.expiresAt && <ISODate date={record.invoice.expiresAt} />
  },
  amount: {
    title: 'Importo',
    dataIndex: 'amount',
    align: 'right',
    width: 150,
    sorter: true,
    render: (amount: Decimal) => <CurrencyRender value={amount} />
  },
  expenseNature: {
    title: <Tooltip title="Natura di Spesa">NS</Tooltip>,
    dataIndex: 'expenseNature',
    width: 40
  },
  pcc: {
    title: 'PCC',
    width: 70,
    render: (_, record: AccountingLineDto) => (
      <PccCommunicationsState line={record} />
    )
  },

  debtState: {
    title: 'Stato Debito',
    dataIndex: 'debtState',
    width: 132,
    sorter: true,
    render: (state: DebtState) => <DebtStateTag state={state} />
  },
  mandate: {
    title: 'O/P',
    dataIndex: 'op',
    render: (_: any, record: AccountingLineDto) =>
      record.mandate ? (
        <Link to={`/shipping/mandates/all/${record.mandate.id}`}>
          {record.mandate.mandateNumber}/{record.mandate.mandateYear}{' '}
          <ArrowRightOutlined />
        </Link>
      ) : null
  },
  paymentDate: {
    title: 'Data Pag.',
    dataIndex: 'paymentDate',
    width: 100,
    render: (_, record: AccountingLineDto) =>
      record.paymentDate && <ISODate date={record.paymentDate} />
  },
  expenseChapter: {
    title: 'Cap.',
    dataIndex: 'expenseChapter',
    width: 60
  },
  description: {
    title: 'Descrizione',
    dataIndex: 'description',
    width: 200,
    sorter: true,
    ...createColumnFilter(FilterInputType.Text)
  },
  supplierName: {
    title: 'Fornitore',
    dataIndex: 'supplierName',
    render: (_, record: AccountingLineDto) =>
      record.invoice?.supplier?.getName()
  },
  invoiceNumber: {
    title: 'Fattura',
    dataIndex: 'invoiceNumber',
    render: (_, record) => (
      <Link to={`/invoicing/invoices/all/${record.invoiceId}`}>
        {record.invoice.issuedNumber}/{record.invoice.issuedYear}{' '}
        <ArrowRightOutlined />
      </Link>
    )
  },
  debtReason: { title: 'Causale', dataIndex: 'debtReason', width: 100 },
  reference: {
    title: 'Riferimento',
    dataIndex: 'invoiceId',
    ...createColumnFilter(FilterInputType.Invoice),
    render: (_, record: AccountingLineDto) => (
      <div>
        Fattura n.{record.invoice.issuedNumber}/{record.invoice.issuedYear}
        {/* TODO Fornitore? Link Fattura? */}
      </div>
    )
  },
  invoiceChargeability: {
    title: 'Esigibilità',
    dataIndex: 'invoice.chargeability',
    render: (_, record) => (
      <SDIChargeabilityTag chargeability={record.invoice.chargeability} />
    )
  },
  invoiceChargeabilityCompact: {
    title: 'Esigibilità',
    dataIndex: 'invoice.chargeability',
    render: (_, record) => (
      <SDIChargeabilityTag
        chargeability={record.invoice.chargeability}
        compact
      />
    )
  },
  actions: {
    title: '',
    align: 'right',
    width: 90,
    render: (_, record) => (
      <Link to={`/accounting/lines/all/${record.id}`}>
        Vai <ArrowRightOutlined />
      </Link>
    )
  }
});
