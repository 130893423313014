import * as React from 'react';
import { useCallback, useState } from 'react';
import { message, Tag, Modal, Space } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import {
  ArrowRightOutlined,
  ClockCircleOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { FormikForm } from 'client/ui/form/FormikForm';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { Section } from 'client/ui/section/Section';
import { InvoiceHeaderContent } from './InvoiceHeaderContent';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { RouteComponentProps } from 'react-router';
import { InvoiceApi } from 'client/components/schema/invoice/InvoiceApi';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { InvoiceStateTag } from 'client/components/schema/invoice/view/InvoiceStateTag';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { InvoiceDto, InvoiceSchema } from 'common/dto/generated/InvoiceDto';
import { InvoceLinesSection } from './sections/InvoiceLinesSection';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { InvoiceAccountingLinesSection } from './sections/InvoiceAccountingLinesSection';
import { InvoiceVatSummariesSection } from './sections/InvoiceVatSummariesSection';
import { InvoceContractSection } from './sections/InvoiceContractSection';
import { AcceptanceInfoBox } from 'client/components/schema/invoice/acceptance/AcceptanceInfoBox';
import { EditAndSaveButton } from 'client/ui/form/button/EditAndSaveButton';
import { resolveApiPath } from 'client/core/appConfig';
import { InvoiceNotificationSection } from './sections/InvoiceNotificationSection';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { InvoiceAttachmentSection } from './sections/InvoiceAttachmentSection';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import { InvoiceSuspensionSection } from './sections/InvoiceSuspensionSection';
import { AccountingCongruenceType } from 'common/schema/invoice/AccountingCongruenceType';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { mapInvoiceFilterToOptions } from '../list/mapInvoiceFilterToOptions';
import { AnchorAPILink } from 'client/ui/link/AnchorAPILink';
import { PaperInvoiceIcon } from './views/PaperInvoiceIcon';
import { InvoiceValidateInfoBox } from 'client/components/schema/invoice/validate/InvoiceValidateInfoBox';
import { InvoiceGeneralDataSection } from './sections/InvoiceGeneralDataSection';
import { InvoiceNotesSection } from './sections/InvoiceNotesSection';
import { LinkButton } from 'client/ui/link/LinkButton';
import { ActivityLogTable } from 'client/components/schema/activity-log/table/ActivityLogTable';
import { InvoiceProtocolInfoBox } from 'client/components/schema/invoice/protocol/InvoiceProtocolInfoBox';
import { PccInvoiceCommunicationsState } from 'client/components/schema/invoice/view/PccInvoiceCommunicationsState';
import { InvoiceRevisionInfoBox } from 'client/components/schema/invoice/revision/InvoiceRevisionInfoBox';
import { ActivityLogApi } from 'client/components/schema/activity-log/ActivityLogApi';
import { InvoicePartialProtocolInfoBox } from 'client/components/schema/invoice/protocol/InvoicePartialProtocolInfoBox';

interface Params {
  id: string;
  filter?: string;
}

export interface InvoicePageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di dettaglio della Fattura
 */
export function InvoicePage(props: InvoicePageProps) {
  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const [editable, setEditable] = useState(id == null);

  // Gestione API
  const { response, error, loading, refetch } = useApiQuery(InvoiceApi.find, {
    skip: id == null,
    data: { id: id! }
  });
  const invoice =
    id == null ? new InvoiceDto({ isPaper: true }) : response?.data;

  const [create] = useApiMutation(InvoiceApi.create, {
    invalidates: [InvoiceApi.find, ActivityLogApi.list]
  });
  const [update] = useApiMutation(InvoiceApi.update, {
    invalidates: [InvoiceApi.find, ActivityLogApi.list],
    data: { id: id! }
  });

  const pageOptions = mapInvoiceFilterToOptions(props.match.params.filter);

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({ path: '/invoicing/invoices/all', title: `Fatture` });
  useBreadcrumbItem({
    path: `/invoicing/invoices/${pageOptions.filter}`,
    title: pageOptions.title
  });
  useBreadcrumbItem({
    priority: 20,
    path: props.location.pathname,
    title: id ? `Fattura #${id}` : 'Nuova Fattura',
    sideMenuKey: pageOptions.filter,
    menuKey: 'invoicing'
  });

  const handleConfirmAccountingIncogruent = useCallback(
    () =>
      new Promise((resolve, reject) => {
        Modal.confirm({
          style: { top: '150px' },
          title: 'Importi incongruenti',
          icon: <ExclamationCircleOutlined />,
          okText: 'Prosegui',
          cancelText: 'Annulla',
          content:
            "Il totale delle linee di contabilizzazione non corrisponde all'importo della fattura. Annullare per modificare l'importo delle linee di contabilizzazione o proseguire con gli importi incongruenti",
          onOk() {
            resolve(true);
          },
          onCancel() {
            resolve(false);
          }
        });
      }),
    []
  );

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere alla fattura"
        error={error}
      />
    );
  }

  if (!invoice) return <LoadingPage />;

  return (
    <CorePageLayout>
      <FormikAugmented<InvoiceDto>
        initialValues={invoice}
        enableReinitialize
        validationSchema={InvoiceSchema}
        validationContext={{
          machineActions: InvoiceMachine.actionsFor(invoice),
          isClient: true
        }}
        onReset={() => {
          setEditable(false);
        }}
        onSubmit={async rawValues => {
          try {
            let input = await rawValues.validate({
              context: { isClient: true }
            });
            // Mostra la modal se in precedenza gli importi non erano incongruenti
            // e ora lo sono
            if (
              invoice.accountingCongruence !==
                AccountingCongruenceType.IncongruentAmounts &&
              input.getAccountingCongruence() ===
                AccountingCongruenceType.IncongruentAmounts
            ) {
              const isConfirmed = await handleConfirmAccountingIncogruent();
              if (!isConfirmed) return;
            }

            if (id == null) {
              const result = await create({
                data: { input }
              });
              message.success('Fattura registrata con successo. ');
              props.history.push(
                `/invoicing/invoices/${pageOptions.filter}/${result.data!.id}`
              );
            } else {
              await update({ data: { id, input } });
              message.success('Fattura aggiornata con successo. ');
            }
            setEditable(false);
          } catch (e) {
            message.error(
              'Si è verificato un errore durante la creazione della fattura.'
            );
            console.error(e);
          }
        }}
      >
        <FormikForm editable={editable} helpInTooltips showVisualFeedback>
          <PageHeading
            title={
              <PageHeadingTitle icon={invoice.isPaper && <PaperInvoiceIcon />}>
                {id == null
                  ? 'Nuova Fattura'
                  : `Fattura n.${invoice.issuedNumber} del ${invoice.issuedDate}`}
              </PageHeadingTitle>
            }
            breadcrumbRender={() => <BreadcrumbsContainer />}
            tags={
              id == null ? (
                <Tag>Non ancora salvata</Tag>
              ) : (
                <Space>
                  {invoice.isPaper && (
                    <PccInvoiceCommunicationsState
                      key="pcc"
                      pccExported={invoice.pccExported}
                    />
                  )}
                  <InvoiceStateTag
                    key="state"
                    state={invoice.state}
                    acceptance={invoice.acceptance}
                  />
                </Space>
              )
            }
            extra={
              <EditAndSaveButton
                isNew={id == null}
                name="Fattura"
                state={[editable, setEditable]}
                whenSaved={
                  <>
                    {InvoiceMachine.can(invoice, 'xml.download') && (
                      <AnchorAPILink
                        showAsButton
                        icon={<DownloadOutlined />}
                        disabled={!invoice.generatedFilePath}
                        target="_blank"
                        href={resolveApiPath(
                          `/invoices/${invoice.id}/xml/download`
                        )}
                      >
                        Visualizza XML
                      </AnchorAPILink>
                    )}
                    {invoice.batchId && (
                      <LinkButton
                        key="batch"
                        to={`/invoicing/batches/${invoice.batchId}`}
                      >
                        Vai al Lotto <ArrowRightOutlined />
                      </LinkButton>
                    )}
                  </>
                }
              />
            }
          >
            <InvoiceHeaderContent invoice={invoice} />
          </PageHeading>

          <PageContent fixedWidht>
            {/*  Messaggi di azione sulla Fattura   */}
            <AcceptanceInfoBox invoice={invoice} />
            <InvoiceValidateInfoBox invoice={invoice} />
            <InvoiceProtocolInfoBox invoice={invoice} />
            <InvoicePartialProtocolInfoBox invoice={invoice} />
            <InvoiceRevisionInfoBox invoice={invoice} />

            <InvoiceGeneralDataSection invoice={invoice} />
            <InvoceContractSection invoice={invoice} />
            <InvoiceSuspensionSection invoice={invoice} />
            <InvoceLinesSection invoice={invoice} />
            <InvoiceAccountingLinesSection invoice={invoice} />
            <InvoiceVatSummariesSection invoice={invoice} />
            {!invoice.isPaper && <InvoiceAttachmentSection invoice={invoice} />}
            <InvoiceNotesSection invoice={invoice} />
            {invoice.isSDI() && <InvoiceNotificationSection invoiceId={id} />}

            {id && (
              <Section
                icon={<ClockCircleOutlined />}
                title="Cronologia Attività"
              >
                <ActivityLogTable entity="invoice" entityId={id} />
              </Section>
            )}
          </PageContent>
        </FormikForm>
      </FormikAugmented>
    </CorePageLayout>
  );
}
