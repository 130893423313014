import { Tag } from 'antd';
import { DoubleTag } from 'client/ui/tag/DoubleTag';
import {
  EconomicSemester,
  EconomicSemesterLogic
} from 'common/schema/economic-statement/EconomicSemester';
import * as React from 'react';

export interface IEconomySemesterTagProps {
  year: number;
  semester: EconomicSemester;
}

/**
 * Tag per visualizzare anno e semestre di una simulazione o di un report
 */
export function EconomySemesterTag(props: IEconomySemesterTagProps) {
  const { year, semester } = props;
  return (
    <DoubleTag
      left={{
        children: year.toString(),
        style: {
          fontWeight: 600
        }
      }}
      right={{ children: EconomicSemesterLogic.render(semester) }}
    />
  );
}
