import { FileDoneOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { PccApi } from 'client/components/schema/pcc/PccApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { AccountingLineQueryDto } from 'common/dto/query/AccountingLineQueryDto';
import { InvoiceQueryDto } from 'common/dto/query/InvoiceQueryDto';
import { Model003CreateQueryDto } from 'common/dto/query/Model003CreateQueryDto';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Model003Action } from 'server/schema/pcc/model003/Model003Types';

export interface IPccExport003ButtonProps {
  /** Array degli ID delle fatture/linee di contabilizzazione */
  items: number[];
  /** Query per indicare le fatture/linee da contabilizzare */
  query: InvoiceQueryDto | AccountingLineQueryDto | null;
  action: Model003Action;
  text: string;
  selectAll: boolean;
}

/**
 * Bottone per la generazione del csv per l'esportazione
 * delle linee attraverso PCC.
 * @param props
 */
export function PccExport003Button(props: IPccExport003ButtonProps) {
  const [createModel, createModelState] = useApiMutation(
    PccApi.model003.create,
    {}
  );
  const { items, action, query, text, selectAll } = props;
  const history = useHistory();

  // Deve essere selezionata almeno una riga (o tutte le righe)
  const isDisabled = (!items || items.length === 0) && !selectAll;

  /**
   * Genera il CSV con le fatture (linee contabili) da comunicare a PCC
   * in base all'azione richiesta.
   * Redirect alla pagina di dettaglio esportazione.
   * (Modello 003)
   */
  const handleGenerate = useCallback(async () => {
    if (isDisabled) {
      message.warning('Nessuna linea selezionata.');
      return;
    }
    const options = { ...query, pageSize: undefined, pageNumber: undefined };
    const input = new Model003CreateQueryDto({
      sourceIds: !selectAll ? items : undefined,
      query: selectAll ? options : undefined,
      action
    });
    try {
      const modelResponse = await createModel({ data: { input } });
      message.success('File esportazione generato correttamente.');
      history.push(`/accounting/pcc-exports/all/${modelResponse.data.id}`);
    } catch (e) {
      console.error(e);
      message.error('Si è verificato un errore.');
    }
  }, [items, query, selectAll, isDisabled]);

  return (
    <Button
      type="primary"
      icon={<FileDoneOutlined />}
      onClick={handleGenerate}
      disabled={isDisabled}
      loading={createModelState.loading}
    >
      {text}
    </Button>
  );
}
