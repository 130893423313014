import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { NotificationType, NotificationCode, NotificationDirection } from 'common/schema/notification/NotificationTypes';

/**
 * Rappresentazione DTO della classe Notification definita in: src/server/schema/invoice/notification/Notification.entity.ts
 * Hash: 46346e47a580271f27ce0fe02e14a927
 */
@ValidationSchema(() => NotificationSchema)
export class NotificationDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: String })
  migrationId?: string | null | undefined;
  @ApiProperty({ enum: ['Incoming', 'Outgoing'] })
  direction!: NotificationDirection;
  @ApiProperty({ type: String })
  sdiId!: string;
  @ApiProperty({ type: String })
  sdiFileName!: string;
  @ApiProperty({ required: false, type: Number })
  batchId?: number | null | undefined;
  @ApiProperty({ required: false, type: Number })
  invoiceId?: number | null | undefined;
  @ApiProperty({ required: false, type: String })
  invoiceNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: Number })
  invoiceYear?: number | null | undefined;
  @ApiProperty({ required: false, type: Number })
  invoicePosition?: number | null | undefined;
  @ApiProperty({ required: false, type: Number })
  sdiMessageId?: number | null | undefined;
  @ApiProperty({ enum: ['EC', 'SE', 'DT', 'MT', 'NONE'] })
  type!: NotificationType;
  @ApiProperty({ required: false, enum: ['EN00', 'EN01', 'EC01', 'EC02'] })
  code?: NotificationCode | null | undefined;
  @ApiProperty({ required: false, type: String })
  description?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  notes?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date' })
  receivedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String })
  filePath?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<NotificationDto>) {
    if (values != null) {
      Object.assign(this, values instanceof NotificationDto ? values : plainToClass(NotificationDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await NotificationSchema.validate(classToPlain(this), options);
    return new NotificationDto(validated);
  }
}

/** Interfaccia simmetrica al DTO NotificationDto */
export type INotificationType = IDto<NotificationDto>;

/**
 * DTO Paginato della classe Notification
 */
export class PaginatedNotificationDto {
  @ApiProperty({ type: [NotificationDto] })
  @Type(() => NotificationDto)
  items!: NotificationDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const NotificationSchema = yup
  .object({
    id: yup.number(),
    direction: yup.string().oneOfEnum(NotificationDirection).required(),
    sdiId: yup.string().required(),
    sdiFileName: yup.string().required(),
    batchId: yup.number().nullable(),
    invoiceId: yup.number().nullable(),
    invoiceNumber: yup.string().nullable(),
    invoiceYear: yup.number().nullable(),
    invoicePosition: yup.number().nullable(),
    sdiMessageId: yup.number().nullable(),
    type: yup.string().oneOfEnum(NotificationType).required(),
    code: yup.string().oneOfEnum(NotificationCode).nullable(),
    description: yup.string().nullable(),
    notes: yup.string().nullable(),
    filePath: yup.string().nullable()
  })
  .noUnknown()
  .meta({ schemaName: "NotificationSchema" })
  .required();
