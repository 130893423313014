import React from 'react';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

const { Text } = Typography;

export interface MandateReferenceProps {
  shipment: ShipmentDto;
}

/**
 * Numero e Anno del Mandato con link
 */
export function MandateReference(props: MandateReferenceProps) {
  const { shipment } = props;

  return shipment.mandateId && shipment.mandate ? (
    <Link to={`/shipping/mandates/all/${shipment.mandateId}`}>
      {shipment.mandate!.mandateNumber}/{shipment.mandate!.mandateYear}
    </Link>
  ) : (
    <Text>-</Text>
  );
}
