import * as React from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { ConfigEnumInput } from 'client/ui/form/input/configurable/ConfigEnumInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { SelectInput, SelectOption } from 'client/ui/form/input/SelectInput';
import { CountryCodeOptions } from 'common/schema/country-codes/CountryCodeOptions';
import { SelectProps } from 'antd';

export interface SupplierFormFieldItemsProps {}

/**
 * Campi impaginati dell'anagrafica Fornitore
 */
export function SupplierFormFieldItems(props: SupplierFormFieldItemsProps) {
  const labelWidth: ILabelWidth = 'width-140';
  return (
    <>
      <FormFieldsContainer
        title="Denominazione Fornitore"
        removeTopTitleSpacing
      >
        <FormFieldItem
          labelWidth={labelWidth}
          component={TextInput}
          label="Denominazione"
          name="name"
          placeholder="Denominazione"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={2}>
        <FormFieldItem
          labelWidth={labelWidth}
          label="Nome"
          placeholder="Nome"
          component={TextInput}
          name="contactName"
        />
        <FormFieldItem
          // labelWidth={labelWidth}
          label="Cognome"
          placeholder="Cognome"
          component={TextInput}
          name="contactSurname"
        />
      </FormFieldsContainer>

      <FormFieldsContainer title="Dati Fiscali" columns={2}>
        <FormFieldItem
          labelWidth={labelWidth}
          component={ConfigEnumInput}
          dropdownMatchSelectWidth={800}
          label="Regime Fiscale"
          placeholder="Regime Fiscale..."
          name="fiscalScheme"
          feature="sdi.values"
          enumKey="fiscalScheme"
        />
        <FormFieldItem
          // labelWidth={labelWidth}
          component={TextInput}
          label="Codice Fiscale"
          placeholder="Codice Fiscale"
          name="fiscalNumber"
        />

        <FormFieldItem
          labelWidth={labelWidth}
          component={SelectInput}
          dropdownMatchSelectWidth={true}
          options={CountryCodeOptions}
          showSearch
          optionFilterProp="value"
          label="Sigla Nazione IVA"
          placeholder="Sigla Nazione IVA"
          name="fiscalIdCountry"
        />
        <FormFieldItem
          // labelWidth={labelWidth}
          component={TextInput}
          label="Codice IVA"
          placeholder="Codice IVA"
          name="fiscalIdCode"
        />
      </FormFieldsContainer>

      <FormFieldsContainer title="Indirizzo Sede" columns={6}>
        <FormFieldItem
          colSpan={4}
          component={TextInput}
          label="Indirizzo"
          placeholder="Indirizzo"
          name="hqAddress"
        />
        <FormFieldItem
          inline
          label="Numero"
          placeholder="Numero"
          component={TextInput}
          name="hqCivic"
        />
        <FormFieldItem
          inline
          component={TextInput}
          label="CAP"
          placeholder="CAP"
          name="hqZip"
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={3}>
        <FormFieldItem
          label="Comune"
          placeholder="Comune"
          component={TextInput}
          name="hqCity"
        />
        <FormFieldItem
          inline
          label="Provincia"
          placeholder="Provincia"
          component={TextInput}
          name="hqProvince"
        />
        <FormFieldItem
          inline
          label="Nazione"
          placeholder="Nazione"
          component={TextInput}
          name="hqCountry"
        />
      </FormFieldsContainer>
    </>
  );
}
