import * as React from 'react';
import { Button, message, Space } from 'antd';
import { RelationEditor } from 'client/components/form/relations/useRelationEditor';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceLineDto } from 'common/dto/generated/InvoiceLineDto';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import { EconomicEntrySelectInput } from '../../economy/economic-entry/EconomicEntrySelectInput';
import {
  InvoiceLineMassiveEditDto,
  InvoiceLineMassiveEditSchema
} from 'common/dto/query/InvoiceLineMassiveEditDto';
import { EconomicEntryApi } from '../../economy/economic-entry/EconomicEntryApi';
import { AxiosResponse } from 'axios';
import { EconomicEntryDto } from 'common/dto/generated/EconomicEntryDto';
import { useField, useFormikContext } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { cloneDeep } from 'lodash';
import { InvoiceLineMassiveEditForm } from './InvoiceLineMassiveEditForm';

export interface IInvoiceLineMassiveEditModalProps {
  invoice: InvoiceDto;
  editor: RelationEditor<InvoiceLineDto>;
  editable?: boolean;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

export function InvoiceLineMassiveEditModal(
  props: IInvoiceLineMassiveEditModalProps
) {
  const { editor, invoice, visible, setVisible } = props;

  return (
    <>
      <FormikAugmented<InvoiceLineMassiveEditDto>
        enableReinitialize
        initialValues={new InvoiceLineMassiveEditDto()}
        validationSchema={InvoiceLineMassiveEditSchema}
        validationContext={{
          machineActions: InvoiceMachine.actionsFor(invoice)
        }}
        onSubmit={async (rawValues, actions) => {
          if (!props.editable) return;
          try {
            const input = await rawValues.validate();
            let economicEntryResponse:
              | AxiosResponse<EconomicEntryDto>
              | undefined;

            if (input.economicEntryId) {
              economicEntryResponse = await EconomicEntryApi.find({
                id: input.economicEntryId
              });
            }

            const updatedLines = editor.items.map(line => {
              return Object.assign(cloneDeep(line), {
                ...input,
                periodStartDate: input.periodStartDate ?? line.periodStartDate,
                periodEndDate: input.periodEndDate ?? line.periodEndDate,
                economicEntryId: input.economicEntryId ?? line.economicEntryId,
                economicEntry: input.economicEntryId
                  ? economicEntryResponse?.data
                  : line.economicEntry
              });
            });

            editor.replaceAll(updatedLines);
            message.success('Linee modificate');
            setVisible(false);
            actions.resetForm();
          } catch (e) {
            message.error('Si è verificato un errore nella gestione delle Linee'); // prettier-ignore
            console.error(e);
          }
        }}
      >
        <FormikForm nested editable={props.editable} helpInTooltips>
          <InvoiceLineMassiveEditForm
            visible={visible}
            setVisible={setVisible}
            editable={props.editable}
          />
        </FormikForm>
      </FormikAugmented>
    </>
  );
}
