import * as React from 'react';
import { useState } from 'react';
import { NotificationOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { InvoiceBatchApi } from 'client/components/schema/invoice-batch/InvoiceBatchApi';
import { NotificationColumns } from 'client/components/schema/notification-sdi/table/NotificationColumns';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { Section } from 'client/ui/section/Section';
import { NotificationQueryDto } from 'common/dto/query/NotificationQueryDto';

interface BatchNotificationsSectionProps {
  batchId: number | null;
}

export function BatchNotificationsSection(
  props: BatchNotificationsSectionProps
) {
  const { batchId } = props;

  const [query, setQuery] = useState<NotificationQueryDto>(
    new NotificationQueryDto()
  );

  const { response, loading } = useApiQuery(
    InvoiceBatchApi.notifications.list,
    {
      skip: batchId == null,
      data: {
        id: batchId!,
        query: query
      }
    }
  );

  const notifications = response?.data.items;

  return (
    <Section icon={<NotificationOutlined />} title="Notifiche SDI">
      <Table
        rowKey="id"
        loading={loading}
        columns={NotificationColumns}
        dataSource={notifications}
        size="middle"
        onChange={(pagination, filters, sorter) => {
          setQuery(
            new NotificationQueryDto({
              ...query,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize
            })
          );
        }}
        pagination={{
          size: 'small',
          position: ['bottomRight'],
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.pageSize,
          current: query.pageNumber,
          pageSizeOptions: ['2', '10', '20', '30', '40']
        }}
      />
    </Section>
  );
}
