import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';

@GenerateDto()
class EconomicStatementSimulationItemsQuery {
  @SchemaLabel('N. Fattura')
  invoiceNumber?: string | null;
  @SchemaLabel('N. Protocollo')
  protocolNumber?: string | null;
  @SchemaLabel('Anno Protocollo')
  protocolYear?: string | null;
  @SchemaLabel('Da Data')
  fromDate?: Date | null;
  @SchemaLabel('A Data')
  toDate?: Date| null;
  @SchemaLabel('Fornitore')
  supplierName?: string| null;  
  @SchemaLabel('Referente amministrativo')
  adminReferentName?: string| null;
  @SchemaLabel('CIG')
  cigCode?: string| null;

  @SchemaPageNumber()
  pageNumber!: number;
  @SchemaPageSize()
  pageSize!: number;

  @SchemaLabel("Ordinamento")
  orderBy?: string = 'id';
  @SchemaLabel("Direzione di Ordinamento")
  orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;
}

/**
 * Rappresentazione DTO della classe EconomicStatementSimulationItemsQuery 
 * Hash: 48699e09304e3dfa1d4635c26bf36ee9
 */
@ValidationSchema(() => EconomicStatementSimulationItemsQuerySchema)
export class EconomicStatementSimulationItemsQueryDto {
  @ApiProperty({ required: false, type: String, description: 'N. Fattura' })
  invoiceNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'N. Protocollo' })
  protocolNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Anno Protocollo' })
  protocolYear?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Da Data' })
  fromDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'A Data' })
  toDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Fornitore' })
  supplierName?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Referente amministrativo' })
  adminReferentName?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'CIG' })
  cigCode?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, type: String, description: 'Ordinamento' })
  orderBy?: string | undefined = 'id';
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<EconomicStatementSimulationItemsQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof EconomicStatementSimulationItemsQueryDto ? values : plainToClass(EconomicStatementSimulationItemsQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await EconomicStatementSimulationItemsQuerySchema.validate(classToPlain(this), options);
    return new EconomicStatementSimulationItemsQueryDto(validated);
  }
}

export const EconomicStatementSimulationItemsQuerySchema = yup
  .object({
    invoiceNumber: yup.string().nullable().label('N. Fattura'),
    protocolNumber: yup.string().nullable().label('N. Protocollo'),
    protocolYear: yup.string().nullable().label('Anno Protocollo'),
    fromDate: yup.date().nullable().label('Da Data'),
    toDate: yup.date().nullable().label('A Data'),
    supplierName: yup.string().nullable().label('Fornitore'),
    adminReferentName: yup.string().nullable().label('Referente amministrativo'),
    cigCode: yup.string().trim().nullable().label('CIG'),
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    orderBy: yup.string().default('id').label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento')
  })
  .noUnknown()
  .meta({ schemaName: "EconomicStatementSimulationItemsQuerySchema" })
  .required();
