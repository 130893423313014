import { TagsOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import * as React from 'react';

/**
 * Evidenziazione delle spedizioni con ID duplicato durante la migrazione
 */
export function ShipmentDuplicateTag() {
  return (
    <Tooltip title="Migrazione: Spedizione Duplicata su Siged">
      <TagsOutlined style={{ color: '#faad14' }} />
    </Tooltip>
  );
}
