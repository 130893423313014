import { createTypedColumns } from 'client/ui/table/createTypedColumns';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { UserDto } from 'common/dto/generated/UserDto';
import React from 'react';
import { UserRoleTag } from '../view/UserRoleTag';

export const UserColumns = createTypedColumns(UserDto, {
  id: {
    title: 'Id',
    dataIndex: 'id'
  },
  login: {
    title: 'Login',
    dataIndex: 'login'
  },
  documentalUserCode: {
    title: 'Utente Documentale',
    dataIndex: 'documentalUserCode',
    sorter: true,
    ...createColumnFilter(FilterInputType.Text)
  },
  name: {
    title: 'Nome',
    dataIndex: 'name',
    sorter: true,
    ...createColumnFilter(FilterInputType.Text)
  },
  role: {
    title: 'Ruolo',
    dataIndex: 'role',
    render: (_: any, record: UserDto) => {
      return <UserRoleTag role={record.role} />;
    }
  }
});
