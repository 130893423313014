import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { ContractType, ContractLevel, ClassificationKind, ContractState, TermsKind } from 'common/schema/contract/ContractTypes';
import { EconomicEntryDto } from './EconomicEntryDto';
import { SupplierDto } from './SupplierDto';
import { UserDto } from './UserDto';
import { string } from 'yup';

/**
 * Rappresentazione DTO della classe Contract definita in: src/server/schema/contract/Contract.entity.ts
 * Hash: 70ec6f6e836692a61fe33b0c0b1bc895
 */
@ValidationSchema(() => ContractSchema)
export class ContractDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: String })
  migrationId?: string | null | undefined;
  @ApiProperty({ enum: ['Contract', 'OrderLetter', 'Agreement', 'PurchaseOrder'], description: 'Tipo di Contratto' })
  contractType!: ContractType;
  @ApiProperty({ required: false, enum: ['Simple', 'Framework', 'Connected'], description: 'Livello' })
  contractLevel?: ContractLevel | null | undefined;
  @ApiProperty({ required: false, enum: ['CertifiedPrivateWriting', 'AdministrativePublicAct', 'AdministrativeAct', 'LegallyVerifiedPrivateWriting', 'HistoricPublicAct'], description: 'Tipo Atto' })
  contractClassification?: ClassificationKind | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Numero' })
  number?: number | null | undefined;
  @ApiProperty({ required: false, enum: ['InProgress', 'Signing', 'Stipulated', 'Registered', 'Canceled'], description: 'Stato' })
  state?: ContractState | null | undefined;
  @ApiProperty({ type: String, description: 'Oggetto' })
  subject!: string;
  @ApiProperty({ required: false, type: String, description: 'Dettaglio Oggetto' })
  notes?: string | null | undefined;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Importo Totale' })
  @TypeTransformDecimal()
  totalAmount?: Decimal | null | undefined;
  @ApiProperty({ required: false, type: String, pattern: '^-?\\d+(.\\d{1,8})?$', description: 'Importo Netto' })
  @TypeTransformDecimal()
  netAmount?: Decimal | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'CIG' })
  cigCode?: string | null | undefined;
  @ApiProperty({ type: Boolean, description: 'Esclusione CIG' })
  excludeCig!: boolean;
  @ApiProperty({ required: false, type: String, description: 'Motivo esclusione CIG' })
  cigExclusionReason?: string | null | undefined;
  @ApiProperty({ type: String, description: 'CUP' })
  cupCode!: string;
  @ApiProperty({ required: false, type: String, description: 'CIP' })
  cipCode?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'D.lgs. 231/2002' })
  isDlgs2312002?: boolean | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date', description: 'Data Scadenza' })
  expireDate?: Date | null | undefined;
  @ApiProperty({ type: String, format: 'date', description: 'Data Registrazione' })
  registrationDate!: Date;
  @ApiProperty({ required: false, type: String, description: 'Tipo procedurea ANAC' })
  anacProcedureKind?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Numero di Procedura ANAC' })
  anacNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date', description: 'Data Atto ANAC' })
  anacDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Completamento' })
  isCompleted!: boolean;
  @ApiProperty({ type: Number, description: 'Termini di pagamento (n° di giorni)' })
  paymentTermsDays!: number;
  @ApiProperty({ enum: ['WorkingDaysFriday', 'WorkingDaysSaturday', 'SolarDays'], description: 'Tipo termini di pagamento' })
  paymentTermsKind!: TermsKind;
  @ApiProperty({ type: Number, description: 'Termini di verifica delle prestazioni (n° di giorni)' })
  testingTermsDays!: number;
  @ApiProperty({ enum: ['WorkingDaysFriday', 'WorkingDaysSaturday', 'SolarDays'] })
  testingTermsKind!: TermsKind;
  @ApiProperty({ required: false, type: String, description: 'Id Documento' })
  documentUid?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Registro Documento' })
  documentRegister?: string | null | undefined;
  @ApiProperty({ required: false, type: Number })
  documentNumber?: number | null | undefined;
  @ApiProperty({ required: false, type: Number })
  documentYear?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Ufficio' })
  requirementOfficeCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'System ID Ufficio' })
  requirementOfficeId?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  requirementOfficeMigrated?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean })
  hasStampDuty?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean })
  hasRegistration?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean })
  hasPaperCopyReturn?: boolean | null | undefined;
  @ApiProperty({ required: false, type: String })
  paymentType?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  negotiationId?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  negotiationValue?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  negotiationCode?: string | null | undefined;
  @ApiProperty({ type: Boolean, description: 'connesso a fatture' })
  hasInvoices!: boolean;
  @ApiProperty({ required: false, type: Number, description: 'Referente Amministrativo' })
  adminReferentId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  adminReferent?: UserDto | null;
  @ApiProperty({ required: false, type: String })
  adminReferentDocumentalCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Ruolo Referente Amministrativo' })
  adminReferentRoleCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Ruolo Referente Amministrativo' })
  adminReferentRole?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Referente DEC' })
  decReferentId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  decReferent?: UserDto | null;
  @ApiProperty({ required: false, type: String })
  decReferentDocumentalCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Ruolo Referente DEC' })
  decReferentRoleCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Ruolo Referente DEC' })
  decReferentRole?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Referente RUP' })
  rupReferentId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  rupReferent?: UserDto | null;
  @ApiProperty({ required: false, type: String })
  rupReferentDocumentalCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Ruolo Referente RUP' })
  rupReferentRoleCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Ruolo Referente RUP' })
  rupReferentRole?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  deleteAt?: Date | null;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: () => [EconomicEntryDto], description: 'Codici di conto economico' })
  @Type(() => EconomicEntryDto)
  economicEntries!: EconomicEntryDto[];
  @ApiProperty({ required: false, type: () => [SupplierDto] })
  @Type(() => SupplierDto)
  suppliers!: SupplierDto[];
  @ApiProperty({ required: false, type: String })
  migrationOriginRepertoryId?: string | null | undefined;
  @ApiProperty({ required: false, type: Number })
  migrationYearKey?: number | null | undefined;
  @ApiProperty({ required: false, type: String })
  migrationActOther?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  migrationFrameworkContractCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  migrationTributeCode?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ContractDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ContractDto ? values : plainToClass(ContractDto, values));
    }
  }

  isReady() {
    return this.state != null;
  }

  isDeleted() {
    return !!this.deleteAt;
  }

  async validate(options?: any) {
    const validated = await ContractSchema.validate(classToPlain(this), options);
    return new ContractDto(validated);
  }
}

/** Interfaccia simmetrica al DTO ContractDto */
export type IContractType = IDto<ContractDto>;

/**
 * DTO Paginato della classe Contract
 */
export class PaginatedContractDto {
  @ApiProperty({ type: [ContractDto] })
  @Type(() => ContractDto)
  items!: ContractDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const ContractSchema = yup
  .object({
    id: yup.number(),
    contractType: yup.string().oneOfEnum(ContractType).required().label('Tipo di Contratto'),
    contractLevel: yup.string().oneOfEnum(ContractLevel).nullable().label('Livello'),
    contractClassification: yup.string().oneOfEnum(ClassificationKind).nullable().label('Tipo Atto'),
    number: yup.number().nullable().label('Numero'),
    state: yup.string().oneOfEnum(ContractState).nullable().label('Stato'),
    subject: yup.string().required().max(100).label('Oggetto'),
    notes: yup.string().nullable().label('Dettaglio Oggetto'),
    totalAmount: yup.mixed().nullable().needsAction('edit.important').label('Importo Totale'),
    netAmount: yup.mixed().nullable().needsAction('edit.important').label('Importo Netto'),
    cigCode: yup.string().trim().nullable().when('excludeCig', {
          is: false,
          then: s => s.required().length(10),
          otherwise: s =>
            s
              .notRequired()
              .nullable()
              .transform(() => null)
        }).needsAction('edit.important').label('CIG'),
    excludeCig: yup.boolean().required().default(false).needsAction('edit.important').label('Esclusione CIG'),
    cigExclusionReason: yup.string().nullable().when('excludeCig', {
          is: true,
          then: s =>
            s.required(
              "Se si esclude il CIG, è obbligatorio indicare il motivo dell'esclusione"
            ),
          otherwise: s =>
            s
              .notRequired()
              .nullable()
              .transform(() => null)
        }).needsAction('edit.important').label('Motivo esclusione CIG'),
    cupCode: yup.string().required().default('NA').needsAction('edit.important').label('CUP'),
    cipCode: yup.string().nullable().label('CIP'),
    isDlgs2312002: yup.boolean().nullable().label('D.lgs. 231/2002'),
    expireDate: yup.date().dateOnlyFormat().nullable().label('Data Scadenza'),
    registrationDate: yup.date().dateOnlyFormat().required().label('Data Registrazione'),
    anacProcedureKind: yup.string().nullable().label('Tipo procedurea ANAC'),
    anacNumber: yup.string().nullable().label('Numero di Procedura ANAC'),
    anacDate: yup.date().dateOnlyFormat().nullable().label('Data Atto ANAC'),
    isCompleted: yup.boolean().default(false).label('Completamento'),
    paymentTermsDays: yup.number().required()
          .integer()
          .min(0)
          .oneOf(
            [30, 60],
            'I giorni per i termini di pagamento devono essere 30 o 60'
          ).needsAction('edit.important').label('Termini di pagamento (n° di giorni)'),
    paymentTermsKind: yup.string().oneOfEnum(TermsKind).required().needsAction('edit.important').label('Tipo termini di pagamento'),
    testingTermsDays: yup.number().required()
          .integer()
          .min(0)
          .oneOf(
            [30, 60],
            'I giorni per i termini di verifica delle prestazioni devono essere 30 o 60'
          ).needsAction('edit.important').label('Termini di verifica delle prestazioni (n° di giorni)'),
    testingTermsKind: yup.string().oneOfEnum(TermsKind).required().needsAction('edit.important'),
    documentUid: yup.string().nullable().label('Id Documento'),
    documentRegister: yup.string().nullable().label('Registro Documento'),
    documentNumber: yup.number().nullable(),
    documentYear: yup.number().nullable(),
    requirementOfficeCode: yup.string().nullable().label('Codice Ufficio'),
    requirementOfficeId: yup.string().nullable().label('System ID Ufficio'),
    requirementOfficeMigrated: yup.string().nullable(),
    hasStampDuty: yup.boolean().nullable(),
    hasRegistration: yup.boolean().nullable(),
    hasPaperCopyReturn: yup.boolean().nullable(),
    paymentType: yup.string().nullable(),
    negotiationId: yup.string().nullable(),
    negotiationValue: yup.string().nullable(),
    negotiationCode: yup.string().nullable(),
    adminReferentId: yup.number().nullable().label('Referente Amministrativo'),
    adminReferentDocumentalCode: yup.string().nullable(),
    adminReferentRoleCode: yup.string().nullable().label('Codice Ruolo Referente Amministrativo'),
    adminReferentRole: yup.string().nullable().label('Ruolo Referente Amministrativo'),
    decReferentId: yup.number().nullable().label('Referente DEC'),
    decReferentDocumentalCode: yup.string().nullable(),
    decReferentRoleCode: yup.string().nullable().label('Codice Ruolo Referente DEC'),
    decReferentRole: yup.string().nullable().label('Ruolo Referente DEC'),
    rupReferentId: yup.number().nullable().label('Referente RUP'),
    rupReferentDocumentalCode: yup.string().nullable(),
    rupReferentRoleCode: yup.string().nullable().label('Codice Ruolo Referente RUP'),
    rupReferentRole: yup.string().nullable().label('Ruolo Referente RUP'),
    economicEntries: yup.array(yup.object({ id: yup.number().required() }))
          .length(1, 'È obbligatorio selezionare un codice di conto economico')
          .required().needsAction('edit.important').label('Codici di conto economico'),
    suppliers: yup.array(yup.object({ id: yup.number().required() }))
  })
  .noUnknown()
  .meta({ schemaName: "ContractSchema" })
  .required();
