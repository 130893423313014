import { Alert, Button, Space } from 'antd';
import { InfoBoxActions } from 'client/ui/info-box/InfoBoxActions';
import { MandateDto } from 'common/dto/generated/MandateDto';
import React from 'react';

export interface IMandateAlertInfoBoxProps {
  mandate: MandateDto;
}

export function MandateAlertInfoBox(props: IMandateAlertInfoBoxProps) {
  const { mandate } = props;

  if (mandate.accountingLines && mandate.accountingLines.length > 0)
    return null;

  // Nascondi l'alert per i mandati non importati da SIAB (e.g. quelli creati a mano)
  if (!mandate.isSiabImported) return null;

  return (
    <Alert
      message="Attenzione"
      description={
        <>
          Nessuna linea contabile risulta associata al mandato. Per associare le
          linee, modificare il mandato e aggiungerle dalla tabella sottostante
          col pulsante Gestisci Linee, poi salvare le modifiche.
        </>
      }
      type="warning"
      action={
        <Space>
          <a href="#mandateAccountingLineTable">
            <Button size="middle" type="default">
              Vedi qui
            </Button>
          </a>
        </Space>
      }
      showIcon
    />
  );
}
