import { CheckboxOptionType } from 'antd';
import { SelectOption } from 'client/ui/form/input/SelectInput';
import {
  CigExclusionReason,
  ClassificationKind,
  ContractState,
  ContractType,
  TermsKind
} from './ContractTypes';

export const contractTypeOptions: SelectOption[] = [
  { label: 'Convenzione', value: ContractType.Agreement },
  { label: 'Contratto', value: ContractType.Contract },
  { label: 'Lettera di Commessa', value: ContractType.OrderLetter },
  { label: 'Ordine di Acquisto', value: ContractType.PurchaseOrder }
];

export const ContractStateOptions: SelectOption[] = [
  { value: ContractState.InProgress, label: 'In Lavorazione' },
  { value: ContractState.Registered, label: 'Registrato' },
  { value: ContractState.Signing, label: 'Alla Firma' },
  { value: ContractState.Stipulated, label: 'Stipulato' },
  { value: ContractState.Canceled, label: 'Annullato' }
];

export const PaymentTermsKindOptions: CheckboxOptionType[] = [
  {
    label: 'Solari',
    value: TermsKind.SolarDays
  },
  {
    label: 'Lavorativi Lun-Ven',
    value: TermsKind.WorkingDaysFriday
  },
  {
    label: 'Lavorativi Lun-Sab',
    value: TermsKind.WorkingDaysSaturday
  }
];

export const ClassificationOptions: SelectOption[] = [
  {
    value: ClassificationKind.CertifiedPrivateWriting,
    label: 'Scrittura Privata con Sottoscrizione Autenticata'
  },
  {
    value: ClassificationKind.AdministrativePublicAct,
    label: 'Atto Pubblico Amministrativo'
  },
  { value: ClassificationKind.AdministrativeAct, label: 'Atto Amministrativo' },
  {
    value: ClassificationKind.LegallyVerifiedPrivateWriting,
    label: 'Scrittura Privata con Sottoscrizione Accertata Giudiziale'
  },
  {
    value: ClassificationKind.HistoricPublicAct,
    label: 'Storico Atto Pubblico Amministrativo'
  }
];

export const CigExclusionReasonOptions: SelectOption[] = [
  {
    label:
      'Contratti di lavoro conclusi dalle stazioni appaltanti con i propri dipendenti (articolo 17, comma 1, lett. g) del Codice dei contratti pubblici',
    value: CigExclusionReason.ConcludedContract
  },
  {
    label: 'Contratti di lavoro temporaneo (legge 24 giugno 1997 n. 196)',
    value: CigExclusionReason.TemporaryContract
  },
  {
    label:
      'Appalti di cui all’articolo 9, comma 1, del Codice dei contratti pubblici',
    value: CigExclusionReason.Art9Comma1
  },
  {
    label:
      "Appalti aggiudicati per l'acquisto di acqua e per la fornitura di energia o di combustibili destinati alla produzione di energia, di cui agli articoli 11 e 12 del Codice dei contratti pubblici",
    value: CigExclusionReason.EnergyProduction
  },
  {
    label:
      'Trasferimento di fondi da parte delle amministrazioni dello Stato in favore di soggetti pubblici, se relativi alla copertura di costi per le attività istituzionali espletate dall’ente (vedi faq C 2)',
    value: CigExclusionReason.FundsTrasfer
  },
  {
    label:
      'Amministrazione diretta ai sensi dell’articolo 3, comma 1 lettera gggg) del Codice dei contratti pubblici (vedi faq C 3)',
    value: CigExclusionReason.Art3Comma1GGGG
  },
  {
    label: 'Affidamenti diretti a società in house (vedi faq C 4)',
    value: CigExclusionReason.DirectAssignments
  },
  {
    label:
      'Risarcimenti corrisposti dalle imprese assicuratrici appaltatrici ai soggetti terzi, estranei al rapporto contrattuale, danneggiati dalle stazioni appaltanti assicurate (vedi faq C 5)',
    value: CigExclusionReason.Compensation
  },
  {
    label:
      'Indennizzi e risarcimenti corrisposti a seguito di procedure espropriative, poste in essere da stazioni appaltanti o da enti aggiudicatori (vedi faq C 6)',
    value: CigExclusionReason.Indemnity
  },
  {
    label:
      'Incarichi di collaborazione ex articolo 7, comma 6, del decreto legislativo n. 165/2001 (testo unico sul pubblico impiego), (vedi faq C 7)',
    value: CigExclusionReason.Collaboration
  },
  {
    label:
      'Spese effettuate dai cassieri, che utilizzano il fondo economale (solo se tali spese non originano da contratti d’appalto e se sono state tipizzate dall’amministrazione mediante l’introduzione di un elenco dettagliato all’interno di un proprio regolamento di contabilità ed amministrazione. Vedi anche faq C8)',
    value: CigExclusionReason.Economale
  },
  {
    label:
      'Erogazione diretta, a titolo individuale, di contributi da parte della pubblica amministrazione a soggetti indigenti o comunque a persone in condizioni di bisogno economico e fragilità personale e sociale, ovvero finalizzati alla realizzazione di progetti educativi (vedi par. 3.5 della Determinazione n. 4/2011 aggiornata con delibera n. 556/2017)',
    value: CigExclusionReason.DirectSupply
  },
  {
    label:
      'Prestazioni socio-sanitarie in regime di accreditamento (par. 3.5 della Determinazione n. 4/2011 aggiornata con delibera n. 556/2017) (vedi faq D 4)',
    value: CigExclusionReason.SocialHealthService
  },
  {
    label:
      'Contratti di associazione che prevedono il pagamento di quote associative ( par. 3.10 della Determinazione n. 4/2011 aggiornata con delibera n. 556/2017)',
    value: CigExclusionReason.MembershipFees
  },
  {
    label:
      'Contratti dell’Autorità giudiziaria non qualificabili come contratti di appalto (par. 3.11 della Determinazione n. 4/2011 aggiornata con delibera n. 556/2017) (vedi faq D 7)',
    value: CigExclusionReason.JudicialAuthority
  },
  {
    label:
      'Convenzione in materia di difesa, protezione civile e prevenzione contro i pericoli sottoscritte da organizzazioni e associazioni senza scopo di lucro, di cui all’art. 17, comma 1, lett. h) del Codice dei contratti pubblici, nel caso in cui questi rivestano carattere non oneroso per l’amministrazione procedente (par. 2.8 della Determinazione n. 4/2011 aggiornata con delibera n. 556/2017)',
    value: CigExclusionReason.DefenseCivilProtection
  },
  {
    label:
      'Sponsorizzazione pura di cui all’art. 19, comma 1, del Codice dei contratti pubblici (vedi par. 2.10 della Determinazione n. 4/2011 aggiornata con delibera n. 556/2017)',
    value: CigExclusionReason.Sponsor
  },
  {
    label:
      'Contratti aventi ad oggetto i servizi forniti da banche centrali di cui all’art. 17, comma 1, lett. e) del Codice dei contratti pubblici',
    value: CigExclusionReason.Art17Comma1E
  }
];
