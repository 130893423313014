import * as React from 'react';
import { DataItem } from 'client/ui/form/field/DataItem';
import { fromPairs } from 'lodash';

import { useModuleConfigOptions } from 'client/components/schema/config/ClientConfigModule';
import { Alert } from 'antd';

export interface ClientInvoiceViewProps {}

export function ClientInvoiceView(props: ClientInvoiceViewProps) {
  const configData = useModuleConfigOptions('fiscal.client');
  if (!configData) {
    return (
      <Alert message="Errore nella lettura dei dati dal file di configurazione" />
    );
  }
  const data = fromPairs(configData.map(d => [d.name, d.value]));

  return (
    <>
      <DataItem label="Denominazione" value={data.name} strong />
      <DataItem label="Codice Fiscale" value={data.fiscalNumber} />
      <DataItem
        label="Indirizzo"
        value={
          data.address +
          ', ' +
          data.city +
          ' (' +
          data.province +
          '), ' +
          data.zip +
          ' ' +
          data.country
        }
      />
    </>
  );
}
