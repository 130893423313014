/** Stato del Contratto */
export enum ContractState {
  /** In lavorazione */
  InProgress = 'InProgress',
  /** Alla Firma */
  Signing = 'Signing',
  /** Stipulato */
  Stipulated = 'Stipulated',
  /** Registrato */
  Registered = 'Registered',
  /** Annullato */
  Canceled = 'Canceled'
}

/** Tipo di Contratto */
export enum ContractType {
  /** Contratto */
  Contract = 'Contract',
  /** Lettera di Commessa */
  OrderLetter = 'OrderLetter',
  /** Convenzione */
  Agreement = 'Agreement',
  /** Ordine di Acquisto */
  PurchaseOrder = 'PurchaseOrder'
}

export enum ContractLevel {
  /** Semplice */
  Simple = 'Simple',
  /** Quadro */
  Framework = 'Framework',
  /** Collegato */
  Connected = 'Connected'
}

/** Referente contratto */
export enum ReferentType {
  DEC = 'DEC',
  RUP = 'RUP',
  Admin = 'Amministrativo'
}

export enum TermsKind {
  /** Lavorativi (lunedì - venerdì) */
  WorkingDaysFriday = 'WorkingDaysFriday',
  /** Lavorativi (lunedì - sabato) */
  WorkingDaysSaturday = 'WorkingDaysSaturday',
  /** Solari */
  SolarDays = 'SolarDays'
}

export enum ContractDocumentType {
  /** Documento di Repertorio */
  Repertory = 'Repertory',
  /** Documento di Protocollo */
  Protocol = 'Protocol'
}

export enum ClassificationKind {
  /** Scrittura Privata con Sottoscrizione Autenticata */
  CertifiedPrivateWriting = 'CertifiedPrivateWriting',
  /** Atto Pubblico Amministrativo */
  AdministrativePublicAct = 'AdministrativePublicAct',
  /** Atto Amministrativo */
  AdministrativeAct = 'AdministrativeAct',
  /** Scrittura Privata con Sottoscrizione Accertata Giudiziale */
  LegallyVerifiedPrivateWriting = 'LegallyVerifiedPrivateWriting',
  /** Storico Atto Pubblico Amministrativo */
  HistoricPublicAct = 'HistoricPublicAct'
}

export enum CigExclusionReason {
  // Contratti di lavoro conclusi dalle stazioni appaltanti con i propri dipendenti (articolo 17, comma 1, lett. g) del Codice dei contratti pubblici
  ConcludedContract = 'ConcludedContract',
  // Contratti di lavoro temporaneo (legge 24 giugno 1997 n. 196)
  TemporaryContract = 'TemporaryContract',
  // Appalti di cui all’articolo 9, comma 1, del Codice dei contratti pubblici
  Art9Comma1 = 'Art9Comma1',
  // Appalti aggiudicati per l'acquisto di acqua e per la fornitura di energia o di combustibili destinati alla produzione di energia, di cui agli articoli 11 e 12 del Codice dei contratti pubblici
  EnergyProduction = 'EnergyProduction',
  // Trasferimento di fondi da parte delle amministrazioni dello Stato in favore di soggetti pubblici, se relativi alla copertura di costi per le attività istituzionali espletate dall’ente (vedi faq C 2)
  FundsTrasfer = 'FundTransfer',
  // Amministrazione diretta ai sensi dell’articolo 3, comma 1 lettera gggg) del Codice dei contratti pubblici (vedi faq C 3)
  Art3Comma1GGGG = 'Art3Comma1GGGG',
  // Affidamenti diretti a società in house (vedi faq C 4)
  DirectAssignments = 'DirectAssignments',
  // Risarcimenti corrisposti dalle imprese assicuratrici appaltatrici ai soggetti terzi, estranei al rapporto contrattuale, danneggiati dalle stazioni appaltanti assicurate (vedi faq C 5)
  Compensation = 'Compensation',
  // Indennizzi e risarcimenti corrisposti a seguito di procedure espropriative, poste in essere da stazioni appaltanti o da enti aggiudicatori (vedi faq C 6)
  Indemnity = 'Indemnity',
  // Incarichi di collaborazione ex articolo 7, comma 6, del decreto legislativo n. 165/2001 (testo unico sul pubblico impiego), (vedi faq C 7)
  Collaboration = 'Collaboration',
  // Spese effettuate dai cassieri, che utilizzano il fondo economale (solo se tali spese non originano da contratti d’appalto e se sono state tipizzate dall’amministrazione mediante l’introduzione di un elenco dettagliato all’interno di un proprio regolamento di contabilità ed amministrazione. Vedi anche faq C8)
  Economale = 'Economale',
  // Erogazione diretta, a titolo individuale, di contributi da parte della pubblica amministrazione a soggetti indigenti o comunque a persone in condizioni di bisogno economico e fragilità personale e sociale, ovvero finalizzati alla realizzazione di progetti educativi (vedi par. 3.5 della Determinazione n. 4/2011 aggiornata con delibera n. 556/2017)
  DirectSupply = 'DirectSupply',
  // Prestazioni socio-sanitarie in regime di accreditamento (par. 3.5 della Determinazione n. 4/2011 aggiornata con delibera n. 556/2017) (vedi faq D 4)
  SocialHealthService = 'SocialHealthService',
  // Contratti di associazione che prevedono il pagamento di quote associative ( par. 3.10 della Determinazione n. 4/2011 aggiornata con delibera n. 556/2017)
  MembershipFees = 'MembershipFees',
  // Contratti dell’Autorità giudiziaria non qualificabili come contratti di appalto (par. 3.11 della Determinazione n. 4/2011 aggiornata con delibera n. 556/2017) (vedi faq D 7)
  JudicialAuthority = 'JudicialAuthority',
  // Convenzione in materia di difesa, protezione civile e prevenzione contro i pericoli sottoscritte da organizzazioni e associazioni senza scopo di lucro, di cui all’art. 17, comma 1, lett. h) del Codice dei contratti pubblici, nel caso in cui questi rivestano carattere non oneroso per l’amministrazione procedente (par. 2.8 della Determinazione n. 4/2011 aggiornata con delibera n. 556/2017)
  DefenseCivilProtection = 'DefenseCivilProtection',
  // Sponsorizzazione pura di cui all’art. 19, comma 1, del Codice dei contratti pubblici (vedi par. 2.10 della Determinazione n. 4/2011 aggiornata con delibera n. 556/2017)
  Sponsor = 'Sponsor',
  // Contratti aventi ad oggetto i servizi forniti da banche centrali di cui all’art. 17, comma 1, lett. e) del Codice dei contratti pubblici
  Art17Comma1E = 'Art17Comma1E'
}
