/**
 * Congruenza delle linee di contabilizzazione del mandato
 */
export enum AmountsCongruenceType {
  /** Linee di contabilizzazione non ancora collegate */
  ToAssign = 'ToAssign',
  /** Importi congruenti */
  CongruentAmounts = 'CongruentAmounts',
  /** Importi incongruenti */
  IncongruentAmounts = 'IncongruentAmounts'
}
