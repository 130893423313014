import { SelectOption } from 'client/ui/form/input/SelectInput';
import {
  AcceptanceState,
  InvoiceReferenceType,
  InvoiceState
} from './InvoiceTypes';

export const InvoiceReferenceTypeLabels = {
  [InvoiceReferenceType.Contract]: 'Contratto',
  [InvoiceReferenceType.Agreement]: 'Convenzione',
  [InvoiceReferenceType.OrderLetter]: 'Lettera di Commessa',
  [InvoiceReferenceType.PurchaseOrder]: 'Ordine di Acquisto',
  [InvoiceReferenceType.Reception]: 'Ricezione'
};

export const InvoiceStateOptions: SelectOption[] = [
  { value: InvoiceState.ToValidate, label: 'Da associare' },
  { value: InvoiceState.Validated, label: 'Da Esitare|Protoc.' },
  { value: InvoiceState.ToAccount, label: 'Da contabilizzare' },
  { value: InvoiceState.Rejected, label: 'Rifiutata' },
  { value: InvoiceState.Accounted, label: 'Contabilizzata' }
];

export const InvoiceAcceptanceLabel = {
  [AcceptanceState.Accepted]: 'Accettata',
  [AcceptanceState.Rejected]: 'Rifiutata',
  [AcceptanceState.Indeterminate]: 'Indeterminata'
};
