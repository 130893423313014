import * as React from 'react';
import './ModalFooter.scss';

export interface IModalFooterProps {
  children: React.ReactNode;
}

/**
 * Footer delle modal di ant utilizzabile direttamente **all'interno del body**
 * quando è necessario inserire pulsanti come il Submit di un form, che non può
 * essere esterno.
 */
export function ModalFooter(props: IModalFooterProps) {
  return (
    <div className="ant-modal-footer ant-modal-footer--inside-body">
      {props.children}
    </div>
  );
}
