import { TagsOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { ISODate } from 'client/ui/display/date/ISODate';
import { StateTag } from 'client/ui/state/StateTag';
import { createTypedColumns } from 'client/ui/table/createTypedColumns';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import React from 'react';
import { MandateReference } from '../view/MandateReference';
import { ShipmentDossierName } from '../view/ShipmentDossierName';
import { ShipmentDuplicateTag } from '../view/ShipmentDuplicateTag';
import { ShipmentOriginalDossier } from '../view/ShipmentOriginalDossier';
import { ShipmentStateTag } from '../view/ShipmentStateTag';
import { ShipmentDescription } from '../view/ShipmentDescription';
import { Space } from 'antd';

export const ShipmentColumns = createTypedColumns(ShipmentDto, {
  number: {
    title: 'Numero',
    dataIndex: 'number',
    ...createColumnFilter(FilterInputType.Text),
    sorter: true,
    width: 100,
    fixed: 'left'
  },
  state: {
    title: 'Stato',
    dataIndex: 'state',
    width: 120,
    render: state => <ShipmentStateTag state={state} />
  },
  mandateNumber: {
    title: 'Mandato',
    dataIndex: 'mandateNumber',
    width: 110,
    render: (_, record) => <MandateReference shipment={record} />
  },
  isIntegration: {
    title: 'Tipo',
    dataIndex: 'isIntegration',
    width: 110,
    render: (_, record) => (
      <Space direction="horizontal" size={0} align="center">
        {record.isIntegration ? (
          <StateTag color="purple" children="Integrazione" />
        ) : (
          <StateTag children="Originale" />
        )}
        {record.isDuplicated && <ShipmentDuplicateTag />}
      </Space>
    )
  },
  mandateBeneficiary: {
    title: 'Beneficiario',
    dataIndex: 'mandate.beneficiary',
    ...createColumnFilter(FilterInputType.Text),
    sorter: true,
    render: (_, record) => record.mandate?.beneficiary ?? '–'
  },
  recipient: {
    title: 'Destinatario',
    dataIndex: 'recipient',
    width: 220,
    ...createColumnFilter(FilterInputType.Text),
    sorter: true,
    render: (_, record) => record.recipient ?? '–'
  },
  shipDate: {
    title: 'Data Invio',
    dataIndex: 'shipDate',
    width: 100,
    render: shipDate => <ISODate date={shipDate} />
  },
  createdAt: {
    title: 'Data',
    dataIndex: 'createdAt',
    width: 100,
    ...createColumnFilter(FilterInputType.DateRange),
    sorter: true,
    render: createdAt => <ISODate date={createdAt} />
  },
  updatedAt: {
    title: 'Data Modifica',
    dataIndex: 'updatedAt',
    width: 100,
    render: updatedAt => <ISODate date={updatedAt} />
  },
  dossier: {
    title: 'Fascicolo',
    dataIndex: 'dossierNumber',
    width: 170,
    render: (_, record) => <ShipmentDossierName shipment={record} />
  },
  originalDossier: {
    title: 'Fascicolo Originale',
    dataIndex: 'originalDossierNumber',
    width: 170,
    render: (_, record) => <ShipmentOriginalDossier shipment={record} />
  },
  shipmentDescription: {
    title: 'Descrizione',
    dataIndex: 'description',
    width: 400,
    ...createColumnFilter(FilterInputType.Text),
    render: (_, record) => <ShipmentDescription shipment={record} />
  }
});
