import { apiClient } from 'client/core/network/apiClient';
import {
  ContractDto,
  PaginatedContractDto
} from 'common/dto/generated/ContractDto';
import { PaginatedInvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceQueryDto } from 'common/dto/query/InvoiceQueryDto';
import { ContractQueryDto } from 'common/dto/query/ContractQueryDto';
import { ContractStateChangeDto } from 'common/dto/query/ContractStateChangeDto';
import { adminReferentFilterHeader } from 'common/schema/contract/adminReferentFilterHeader';
import { ConfigStorage } from 'client/components/config/ConfigStorage';
import { PaginatedContractAttachmentDto } from 'common/dto/generated/ContractAttachmentDto';
import { ContractAttachmentQueryDto } from 'common/dto/query/ContractAttachmentQueryDto';

export const ContractApi = {
  create: (opts: { input: ContractDto }) =>
    apiClient
      .requestWithResponseType(ContractDto)
      .post(`/contracts`, opts.input),

  softDelete: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(ContractDto)
      .delete(`/contracts/${opts.id}`),

  restore: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(ContractDto)
      .post(`/contracts/${opts.id}/actions/restore`),

  update: (opts: { input: ContractDto }) =>
    apiClient
      .requestWithResponseType(ContractDto)
      .put(`/contracts/${opts.input.id}`, opts.input),

  find: (opts: { id: number }) =>
    apiClient.requestWithResponseType(ContractDto).get(`/contracts/${opts.id}`),

  invoices: {
    list: (opts: { id: number; query: InvoiceQueryDto }) =>
      apiClient
        .requestWithResponseType(PaginatedInvoiceDto)
        .get(`/contracts/${opts.id}/invoices`, { params: opts.query })
  },

  list: (opts: { query: ContractQueryDto }) =>
    apiClient.requestWithResponseType(PaginatedContractDto).get(`/contracts`, {
      params: opts.query,
      headers: {
        [adminReferentFilterHeader]: ConfigStorage.getValue(
          'adminReferentFilter'
        )
      }
    }),

  changeState: (opts: { id: number; input: ContractStateChangeDto }) =>
    apiClient
      .requestWithResponseType(ContractDto)
      .put(`/contracts/${opts.id}/state`, opts.input),

  attachments: {
    list: (opts: { contractId: number; query: ContractAttachmentQueryDto }) =>
      apiClient
        .requestWithResponseType(PaginatedContractAttachmentDto)
        .get(`contracts/${opts.contractId}/attachments`, { params: opts.query })
  }
};
