import { IInvoiceExportFieldsSelection } from 'common/dto/query/types/InvoiceExportQueryTypes';

export function getFieldsInitialValue(): IInvoiceExportFieldsSelection {
  return {
    invoice: {
      id: false,
      currency: false,
      totalAmount: false,
      totalVatAmount: false,
      totalVatTaxableAmount: false,
      invoiceType: false,
      supplier: false,
      vatNumber: false,
      fiscalNumber: false,
      issuedNumber: false,
      issuedDate: false,
      acceptance: false,
      expiresAt: false,
      protocol: false,
      protocolDate: false,
      receivedAt: false,
      testingDate: false,
      executionDate: false,
      suspendedReason: false,
      references: false
    },
    contract: {
      subject: false,
      suppliers: false,
      totalAmount: false,
      netAmount: false,
      cigCode: false,
      cupCode: false,
      documentYear: false,
      documentNumber: false,
      expireDate: false,
      paymentTermsDays: false,
      testingTermsDays: false,
      anacProcedureKind: false,
      requirementOfficeCode: false,
      adminReferent: false,
      rupReferent: false,
      decReferent: false,
      contractType: false,
      state: false,
      economicEntries: false
    },
    accounting: {
      amount: false,
      state: false,
      debtState: false,
      debtReason: false,
      paymentDate: false,
      expenseNature: false,
      pccExportedAccounting: false,
      pccExportedPayment: false
    },
    lines: {
      economicEntryCode: false,
      periodStartDate: false,
      periodEndDate: false,
      priceTotal: false,
      vatRate: false
    },
    suspending: {
      suspendedFromDate: false,
      suspendedToDate: false,
      suspendedReason: false
    },
    mandate: {
      mandateNumber: false,
      camicia: false,
      expenseChapter: false,
      beneficiary: false,
      amount: false,
      mandateYear: false,
      originYear: false,
      sicogeState: false,
      gebilaState: false,
      signatureDate: false,
      validateDate: false,
      paymentDate: false,
      managementPlan: false,
      commitmentType: false,
      commitmentNumber: false,
      shipDate: false
    }
  };
}
