import * as React from 'react';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { RouteComponentProps } from 'react-router-dom';
import { AccountingLineQueryDto } from 'common/dto/query/AccountingLineQueryDto';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { useCallback, useEffect, useState } from 'react';
import { AccountingLineApi } from 'client/components/schema/accounting-line/AccountingLineApi';
import { AccountingLineDto } from 'common/dto/generated/AccountingLineDto';
import { AccountingLineStateTag } from 'client/components/schema/accounting-line/view/AccountingLineStateTag';
import { SorterResult } from 'antd/lib/table/interface';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { mapAccountingLineFilterToOptions } from './mapAccountingLineFilterToOptions';
import {
  getDateRange,
  getInvoiceFilter
} from 'client/ui/table/TableColumnFilter';
import { TableSticky } from 'client/ui/table/TableSticky';
import {
  getPaginationUrlQuery,
  setPaginationUrlQuery
} from 'client/components/tables/pagination/paginationUrlQuery';
import { getPaginationUrlColumns } from 'client/components/tables/pagination/paginationUrlColumns';
import { AccountingLineColumnSort } from 'common/dto/query/types/AccountingLineQueryTypes';
import { AdminReferentFilter } from 'client/components/config/AdminReferentFilter';

interface Params {
  filter?: string;
}
export interface AccountingLineListPageProps
  extends RouteComponentProps<Params> {}

/**
 * Pagina di Elenco delle Linee di Contabilizzazione
 */
export function AccountingLineListPage(props: AccountingLineListPageProps) {
  const { match } = props;
  const [options, setOptions] = useState(
    mapAccountingLineFilterToOptions(match.params.filter)
  );
  const defaultQuery = {
    pageNumber: 1,
    pageSize: 20
  };

  const [query, setQuery] = useState<AccountingLineQueryDto>(
    new AccountingLineQueryDto(
      getPaginationUrlQuery(props.location.search, {
        ...defaultQuery,
        ...options.query
      })
    )
  );

  const { response, loading, error } = useApiQuery(AccountingLineApi.list, {
    data: { query }
  });
  const lines = response?.data?.items;

  const columns = getPaginationUrlColumns<
    AccountingLineDto,
    AccountingLineQueryDto
  >(options.columns, query, {
    'invoice.expiresAt': ['expireDateFrom', 'expireDateTo'],
    invoiceId: ['invoiceNumber', 'invoiceYear']
  });

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/accounting/lines/all',
    title: `Contabilizzazione`
  });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: options.title,
    sideMenuKey: options.sideMenuPrefix + '-' + options.filter,
    menuKey: 'accounting'
  });

  // Aggiorno le opzioni
  useEffect(() => {
    const updatedOptions = mapAccountingLineFilterToOptions(
      match.params.filter
    );
    setOptions(updatedOptions);
    setQuery(
      new AccountingLineQueryDto(
        getPaginationUrlQuery(props.location.search, {
          ...defaultQuery,
          ...updatedOptions.query
        })
      )
    );
  }, [match, options.filter]);

  /**
   * Gestiamo la selezione delle righe, disattivandola in caso di checkbox
   * per la selezione di una lista di linee, per evitare il cambio di pagina
   * in caso di missclick della box.
   */
  const handleRowClick = useCallback(
    (record: AccountingLineDto) => {
      return {
        style: {
          cursor: 'pointer'
        },
        onClick: () =>
          props.history.push(`/accounting/lines/${options.filter}/${record.id}`)
      };
    },
    [options.filter]
  );

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco delle linee contabili"
        error={error}
      />
    );
  }

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle children={options.title} />}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        tags={options.tag && <AccountingLineStateTag state={options.tag} />}
        extra={<AdminReferentFilter />}
      />
      <PageContent noPadding noSpace>
        <TableSticky
          loading={loading}
          rowKey="id"
          columns={columns}
          onRow={handleRowClick}
          dataSource={lines}
          size="middle"
          onChange={(pagination, filters, sorter) => {
            const { field, order } = sorter as SorterResult<AccountingLineDto>;
            const expirationDateRange = getDateRange(
              filters['invoice.expiresAt']
            );

            // descrizione
            const description = filters.description
              ? filters.description[0].toString()
              : null;

            const invoiceFilter = getInvoiceFilter(filters['invoiceId']);

            const lineQuery = new AccountingLineQueryDto({
              ...query,
              description,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize,
              orderBy: order
                ? (field?.toString() as any)
                : AccountingLineColumnSort.id,
              orderByDirection: order
                ? ColumnSortDirections[order]
                : ColumnSortDirections.descend,
              expireDateFrom: expirationDateRange.from,
              expireDateTo: expirationDateRange.to,
              invoiceNumber: invoiceFilter.invoiceNumber,
              invoiceYear: invoiceFilter.invoiceYear
            });
            setPaginationUrlQuery(props, lineQuery);
          }}
          pagination={{
            size: 'small',
            position: ['bottomRight'],
            showSizeChanger: true,
            total: response?.data.meta.total,
            pageSize: query.pageSize,
            current: query.pageNumber,
            pageSizeOptions: ['5', '10', '20', '30', '40']
          }}
        />
      </PageContent>
    </CorePageLayout>
  );
}
