import { ColumnsType } from 'antd/lib/table';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { SupplierDto } from 'common/dto/generated/SupplierDto';

export const SupplierListColumns: ColumnsType<SupplierDto> = [
  {
    title: 'Denominazione',
    dataIndex: 'fullname',
    sorter: true,
    ...createColumnFilter(FilterInputType.Text),

    render: (_, record) => record.getName()
  },
  {
    title: 'P. IVA - CF',
    dataIndex: 'fiscalIdCode',
    ...createColumnFilter(FilterInputType.Text),
    width: 250,
    render: (_, record) => record.getFiscalNumber()
  },
  {
    title: 'Indirizzo',
    dataIndex: 'hqAddress',
    render: (_, record) => record.getFullAddress()
  }
];
