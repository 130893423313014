import React from 'react';
import clx from 'classnames';
import { Menu } from 'antd';
const { SubMenu } = Menu;
import { FileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useCurrentSideMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';

export interface UserSideMenuProps {
  classNames?: string;
}

/**
 * Menu laterale della sezione fatture.
 */
export function UserSideMenu(props: UserSideMenuProps) {
  const currentPathKey = useCurrentSideMenuKey();

  return (
    <Menu
      className={props.classNames}
      mode="inline"
      defaultSelectedKeys={['all']}
      selectedKeys={currentPathKey}
      defaultOpenKeys={['users']}
    >
      <SubMenu key="users" title="Utenti" icon={<FileOutlined />}>
        <Menu.Item key="all">
          <Link to="/users">Tutti</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
}
