import { ApiOutlined, UnlockOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { UserApi } from 'client/components/schema/user/UserApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { DataItem } from 'client/ui/form/field/DataItem';
import { Section } from 'client/ui/section/Section';
import { UserDto } from 'common/dto/generated/UserDto';
import React, { useCallback, useState } from 'react';
import { Typography } from 'antd';
import { SectionButton } from 'client/ui/section/SectionButton';
import { Roles } from 'common/permissions/Roles';
import { useFormikContext } from 'formik';

const { Text, Paragraph } = Typography;

interface IUserTokenSectionProps {
  user: UserDto;
}

export function UserTokenSection(props: IUserTokenSectionProps) {
  const { user } = props;

  const formik = useFormikContext<UserDto>();

  const [createToken, { response }] = useApiMutation(
    UserApi.createServiceToken,
    {}
  );
  const serviceToken = response?.data.serviceTokenJwt;

  const generateToken = useCallback(async () => {
    try {
      await createToken({ data: { id: user.id } });
      message.success(`Token generato con successo`);
    } catch (e) {
      message.error(`Errore durante la generazione del token. Riprovare.`);
    }
  }, [createToken, user]);

  if (formik.values.role != Roles.System.name) {
    return null;
  }

  // È possibile generare il token solo se l'utente è salvato
  const isEnabled = user.id != null;

  return (
    <Section
      icon={<UnlockOutlined />}
      title="Token"
      hasPadding
      extra={
        <SectionButton
          icon={<ApiOutlined />}
          disabled={!isEnabled}
          onClick={e => {
            generateToken();
            e.stopPropagation();
          }}
        >
          Genera token
        </SectionButton>
      }
    >
      {serviceToken && (
        <Text type="warning">
          Il token è stato creato. Copiarlo e salvarlo in un luogo sicuro. Al
          ricaricamento della pagina non sarà possibile ottenerlo nuovamente (a
          meno di rigenerarne uno nuovo, invalidando il precedente)
        </Text>
      )}
      {!isEnabled && (
        <Text type="warning">
          Effettua il salvataggio dell'utenza per generare il token di accesso
          alle API.
        </Text>
      )}
      <DataItem
        label="Token d'Accesso"
        value={
          serviceToken ? (
            <Paragraph
              copyable={{
                text: serviceToken,
                tooltips: ['Copia token', 'Token copiato!']
              }}
            >
              <Text code>{serviceToken}</Text>
            </Paragraph>
          ) : (
            ''
          )
        }
      />
    </Section>
  );
}
