import { apiClient } from 'client/core/network/apiClient';
import {
  PaginatedShipmentDto,
  ShipmentDto
} from 'common/dto/generated/ShipmentDto';
import { ShipmentQueryDto } from 'common/dto/query/ShipmentQueryDto';
import { ShipmentStateChangeDto } from 'common/dto/query/ShipmentStateChangeDto';

export const ShipmentApi = {
  create: (opts: { input: ShipmentDto }) =>
    apiClient
      .requestWithResponseType(ShipmentDto)
      .post(`/shipments`, opts.input),

  update: (opts: { input: ShipmentDto }) =>
    apiClient
      .requestWithResponseType(ShipmentDto)
      .put(`/shipments/${opts.input.id}`, opts.input),

  find: (opts: { id: number }) =>
    apiClient.requestWithResponseType(ShipmentDto).get(`/shipments/${opts.id}`),

  list: (opts: { query: ShipmentQueryDto }) =>
    apiClient.requestWithResponseType(PaginatedShipmentDto).get(`/shipments`, {
      params: opts.query
    }),

  changeState: (opts: { id: number; input: ShipmentStateChangeDto }) =>
    apiClient
      .requestWithResponseType(ShipmentDto)
      .put(`/shipments/${opts.id}/state`, opts.input),

  integrations: {
    list: (opts: { id: number }) =>
      apiClient
        .requestWithResponseType(PaginatedShipmentDto)
        .get(`/shipments/${opts.id}/integrations`),

    create: (opts: { id: number }) =>
      apiClient
        .requestWithResponseType(ShipmentDto)
        .post(`/shipments/${opts.id}/integrations`)
  },

  send: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(ShipmentDto)
      .post(`/shipments/${opts.id}/dossier`),

  softDelete: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(ShipmentDto)
      .delete(`/shipments/${opts.id}`)
};
