import React from 'react';
import { PageLayout } from './PageLayout';

export interface CorePageLayoutProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * Layout principale dell'applicativo con main menu e side menu.
 */
export function CorePageLayout(props: CorePageLayoutProps) {
  return (
    <PageLayout hasMainMenu hasSideMenu className={props.className}>
      {props.children}
    </PageLayout>
  );
}
