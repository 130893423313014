import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaPageNumber, SchemaPageSize, SchemaLabel } from "cli/generate/decorators/SchemaDecorators";
import { DocumentalUserTypeFilter } from 'common/schema/documental/DocumentalTypes';




@GenerateDto()
class DocumentalUsersQuery {
    @SchemaPageNumber()
    pageNumber!: number;
    @SchemaPageSize()
    pageSize!: number;

    @SchemaLabel('Codice Utente')
    code?: string | null;

    @SchemaLabel('Codice Utente (esatto)')
    exactCode?: string | null;

    @SchemaLabel('Tipo Persona')
    isUser?: boolean | null;

    @SchemaLabel('Tipo Ufficio')
    isOffice?: boolean | null

    @SchemaLabel('Tipo corrispondente')
    correspondentType?: DocumentalUserTypeFilter | null;

    @SchemaLabel('Descrizione')
    description?: string | null;
}

/**
 * Rappresentazione DTO della classe DocumentalUsersQuery 
 * Hash: f2df35620c44960cd2152c02178bf646
 */
@ValidationSchema(() => DocumentalUsersQuerySchema)
export class DocumentalUsersQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, type: String, description: 'Codice Utente' })
  code?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Utente (esatto)' })
  exactCode?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Tipo Persona' })
  isUser?: boolean | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Tipo Ufficio' })
  isOffice?: boolean | null | undefined;
  @ApiProperty({ required: false, enum: ['INTERNAL', 'EXTERNAL', 'GLOBAL'], description: 'Tipo corrispondente' })
  correspondentType?: DocumentalUserTypeFilter | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Descrizione' })
  description?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<DocumentalUsersQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof DocumentalUsersQueryDto ? values : plainToClass(DocumentalUsersQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await DocumentalUsersQuerySchema.validate(classToPlain(this), options);
    return new DocumentalUsersQueryDto(validated);
  }
}

export const DocumentalUsersQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    code: yup.string().nullable().label('Codice Utente'),
    exactCode: yup.string().nullable().label('Codice Utente (esatto)'),
    isUser: yup.boolean().nullable().label('Tipo Persona'),
    isOffice: yup.boolean().nullable().label('Tipo Ufficio'),
    correspondentType: yup.string().oneOfEnum(DocumentalUserTypeFilter).nullable().label('Tipo corrispondente'),
    description: yup.string().nullable().label('Descrizione')
  })
  .noUnknown()
  .meta({ schemaName: "DocumentalUsersQuerySchema" })
  .required();
