import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';

/**
 * Rappresentazione DTO della classe InvoiceSupplierData definita in: src/server/schema/invoice/invoice/invoice-data/InvoiceSupplierData.embedded.ts
 * Hash: c03ce684842d43ead9076a3ac4703f4e
 */
@ValidationSchema(() => InvoiceSupplierDataSchema)
export class InvoiceSupplierDataDto {
  @ApiProperty({ required: false, type: String, description: 'Denominazione' })
  name?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome' })
  contactName?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Cognome' })
  contactSurname?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Paese' })
  fiscalIdCountry?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Partita IVA' })
  fiscalIdCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Regime Fiscale' })
  fiscalScheme?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Codice Fiscale' })
  fiscalNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Indirizzo' })
  hqAddress?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Numero Civico' })
  hqCivic?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'CAP' })
  hqZip?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Comune' })
  hqCity?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Provincia' })
  hqProvince?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nazione' })
  hqCountry?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceSupplierDataDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceSupplierDataDto ? values : plainToClass(InvoiceSupplierDataDto, values));
    }
  }

  getFiscalNumber(): string {
    if (this.fiscalIdCode != null && this.fiscalIdCode !== '') {
      return this.fiscalIdCode;
    }

    return this.fiscalNumber!;
  }

  getName(): string {
    if (!this.name || this.name === '') {
      return `${this.contactName} ${this.contactSurname}`;
    }

    return this.name;
  }

  getFiscalId(): string | null {
    if (!this.fiscalIdCountry && !this.fiscalIdCode) {
      return null;
    }

    return `${this.fiscalIdCountry}${this.fiscalIdCode}`;
  }

  async validate(options?: any) {
    const validated = await InvoiceSupplierDataSchema.validate(classToPlain(this), options);
    return new InvoiceSupplierDataDto(validated);
  }
}

/** Interfaccia simmetrica al DTO InvoiceSupplierDataDto */
export type IInvoiceSupplierDataType = IDto<InvoiceSupplierDataDto>;

/**
 * DTO Paginato della classe InvoiceSupplierData
 */
export class PaginatedInvoiceSupplierDataDto {
  @ApiProperty({ type: [InvoiceSupplierDataDto] })
  @Type(() => InvoiceSupplierDataDto)
  items!: InvoiceSupplierDataDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const InvoiceSupplierDataSchema = yup
  .object({
    name: yup.string().nullable().when(['contactName', 'contactSurname'], {
          is: (
            contactName: string | null | undefined,
            contactSurname: string | null | undefined
          ) => !contactName || !contactSurname,
          then: schema =>
            schema.required(
              'Inserire la Denominazione oppure Nome e Cognome (entrambi i campi)'
            )
        }).label('Denominazione'),
    contactName: yup.string().nullable().label('Nome'),
    contactSurname: yup.string().nullable().label('Cognome'),
    fiscalIdCountry: yup.string().nullable().label('Codice Paese'),
    fiscalIdCode: yup.string().nullable().label('Partita IVA'),
    fiscalScheme: yup.string().nullable().label('Regime Fiscale'),
    fiscalNumber: yup.string().nullable().when(['fiscalIdCountry', 'fiscalIdCode'], {
          is: (
            fiscalIdCountry: string | null | undefined,
            fiscalIdCode: string | null | undefined
          ) => !fiscalIdCountry || !fiscalIdCode,
          then: schema =>
            schema.required(
              'Inserire il Codice Fiscale, oppure entrambi i campi Sigla Nazione IVA e Partita IVA'
            )
        }).label('Codice Fiscale'),
    hqAddress: yup.string().nullable().label('Indirizzo'),
    hqCivic: yup.string().nullable().label('Numero Civico'),
    hqZip: yup.string().nullable().label('CAP'),
    hqCity: yup.string().nullable().label('Comune'),
    hqProvince: yup.string().nullable().label('Provincia'),
    hqCountry: yup.string().nullable().label('Nazione')
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceSupplierDataSchema" })
  .required();
