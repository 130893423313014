import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';

/**
 * Rappresentazione DTO della classe InvoiceAttachment definita in: src/server/schema/invoice/invoice-attachment/InvoiceAttachment.entity.ts
 * Hash: 76175ce730b2e1b4231e5854a81198d6
 */
@ValidationSchema(() => InvoiceAttachmentSchema)
export class InvoiceAttachmentDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: Number })
  invoiceId!: number;
  @ApiProperty({ required: false, type: String })
  filePath?: string | null | undefined;
  @ApiProperty({ type: String })
  name!: string;
  @ApiProperty({ required: false, type: String })
  compression?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  format?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  description?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  signedFilePath?: string | null | undefined;
  @ApiProperty({ type: Boolean, description: 'Allegato caricato' })
  isUploaded!: boolean;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  deletedAt?: Date | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceAttachmentDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceAttachmentDto ? values : plainToClass(InvoiceAttachmentDto, values));
    }
  }

  getFileNameWithFormat() {
    let nameWithFormat;
    if (
      this.format &&
      !this.name.toLowerCase().endsWith('.' + this.format.toLowerCase())
    ) {
      nameWithFormat = `${this.name}.${this.format}`;
    } else {
      nameWithFormat = this.name;
    }

    return nameWithFormat;
  }

  async validate(options?: any) {
    const validated = await InvoiceAttachmentSchema.validate(classToPlain(this), options);
    return new InvoiceAttachmentDto(validated);
  }
}

/** Interfaccia simmetrica al DTO InvoiceAttachmentDto */
export type IInvoiceAttachmentType = IDto<InvoiceAttachmentDto>;

/**
 * DTO Paginato della classe InvoiceAttachment
 */
export class PaginatedInvoiceAttachmentDto {
  @ApiProperty({ type: [InvoiceAttachmentDto] })
  @Type(() => InvoiceAttachmentDto)
  items!: InvoiceAttachmentDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const InvoiceAttachmentSchema = yup
  .object({
    id: yup.number(),
    invoiceId: yup.number(),
    filePath: yup.string().nullable(),
    name: yup.string().required(),
    compression: yup.string().nullable(),
    format: yup.string().nullable(),
    description: yup.string().nullable(),
    signedFilePath: yup.string().nullable()
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceAttachmentSchema" })
  .required();
