import { StateTag } from 'client/ui/state/StateTag';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import * as React from 'react';
import { DocumentalDossierLink } from '../../documental/DocumentalDossierLink';

export interface IShipmentDossierNameProps {
  shipment: ShipmentDto;
}

/**
 * Visualizzazione del Fascicolo di una Spedizione UBRRAC
 */
export function ShipmentDossierName(props: IShipmentDossierNameProps) {
  const { shipment } = props;

  if (!shipment.dossierNumber || !shipment.dossierYear) {
    return <StateTag color="orange">Da Creare</StateTag>;
  }

  return <DocumentalDossierLink dossierData={shipment} />;
}
