import { UnorderedListOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { PccExportApi } from 'client/components/schema/pcc/PccExportApi';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { Section } from 'client/ui/section/Section';
import { PccRowQueryDto } from 'common/dto/query/PccRowQueryDto';
import React, { useState } from 'react';
import { PccModelType } from 'server/schema/pcc/pcc-model-base/PccTypes';
import { PccRowColumns } from './PccRowColumns';

interface IPccRowSectionProps {
  pccExportId: number;
  model: PccModelType;
}

export function PccRowSection(props: IPccRowSectionProps) {
  const { pccExportId, model } = props;

  const [query, setQuery] = useState<PccRowQueryDto>(
    new PccRowQueryDto({ pageNumber: 1, pageSize: 10 })
  );

  const { response, loading } = useApiQuery(PccExportApi.lines.list, {
    skip: pccExportId == null,
    data: {
      id: pccExportId!,
      query: query
    }
  });

  const pccRows = response?.data.items ?? [];
  const total = response?.data.meta.total ?? 0;

  return (
    <Section
      icon={<UnorderedListOutlined />}
      title={
        model == PccModelType.Reception
          ? 'Fatture della Comunicazione'
          : 'Linee di Contabilizzazione della Comunicazione'
      }
    >
      <Table
        rowKey="id"
        loading={loading}
        columns={PccRowColumns}
        dataSource={pccRows}
        size="middle"
        onChange={(pagination, filters, sorter) => {
          setQuery(
            new PccRowQueryDto({
              ...query,
              pageNumber: pagination.current ?? query.pageNumber,
              pageSize: pagination.pageSize ?? query.pageSize
            })
          );
        }}
        pagination={
          total <= query.pageSize
            ? false
            : {
                size: 'small',
                position: ['bottomRight'],
                showSizeChanger: true,
                total: total,
                pageSize: query.pageSize,
                current: query.pageNumber,
                pageSizeOptions: ['5', '10', '20', '30', '40']
              }
        }
      />
    </Section>
  );
}
