import React from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import {
  FormFieldsContainer,
  FormFieldsContainerProps
} from 'client/ui/form/field/row/FormFieldsContainer';
import { IExportField } from '../../../../../common/schema/invoice/exports/ExportFields';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import './FormFieldsContainerCheckBox.scss';
import { Divider, Space } from 'antd';
import { ExportGroupCheckbox } from './ExportGroupCheckbox';

export interface FormFieldsContainerCheckBoxProps
  extends Omit<FormFieldsContainerProps, 'children'> {
  fields: IExportField[];
}

export function FormFieldsContainerCheckBox(
  props: FormFieldsContainerCheckBoxProps
) {
  const { fields, title, ...otherProps } = props;

  return (
    <>
      <Divider plain orientation="left">
        <Space split={<Divider type="vertical" />}>
          <div className="form-fields-container-title">{title}</div>
          <ExportGroupCheckbox group={fields[0].prefix} />
        </Space>
      </Divider>
      <FormFieldsContainer
        {...otherProps}
        className="form-fields-container-check-box"
      >
        {fields.map((field, index) => (
          <FormFieldItem
            key={field.prefix + index}
            label={null}
            component={CheckboxInput}
            children={field.label}
            name={`fields.${field.prefix}.${field.name}`}
          />
        ))}
      </FormFieldsContainer>
    </>
  );
}
