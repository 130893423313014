import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import React, { useCallback, useState } from 'react';
import { RadioInput } from 'client/ui/form/input/RadioInput';
import { ConfigEnumInput } from 'client/ui/form/input/configurable/ConfigEnumInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import {
  InvoiceExportResultTypes,
  InvoiceStateOptions,
  SdiStateOptions
} from 'common/dto/query/types/InvoiceExportQueryTypes';
import { DocumentalUserSelectInput } from 'client/components/schema/documental/DocumentalUserSelectInput';
import { Divider } from 'antd';
import { QueryWhereCondition } from 'common/schema/orm/OrmTypes';

export interface InvoiceExportSearchFormProps {}

export function InvoiceExportSearchForm(props: InvoiceExportSearchFormProps) {
  const labelWidth: ILabelWidth = 'width-120';

  return (
    <div style={{ maxWidth: '900px', margin: 'auto' }}>
      <FormFieldsContainer
      // title="Modalità di Ricerca e Numero Risultati"
      // removeTopTitleSpacing
      >
        <FormFieldItem
          labelWidth={labelWidth}
          component={RadioInput}
          name="selectionMode"
          label="Modalità Ricerca"
          options={[
            {
              label: 'Tutte le condizioni devono essere soddisfatte',
              value: QueryWhereCondition.And
            },
            {
              label: 'Almeno una condizione deve essere soddisfatta',
              value: QueryWhereCondition.Or
            }
          ]}
        />
        <FormFieldItem
          labelWidth={labelWidth}
          component={RadioInput}
          name="resultNumber"
          label="Numero Fatture"
          options={[
            {
              label: 'Tutti i risultati',
              value: InvoiceExportResultTypes.all
            },
            {
              label: 'Solo le prime 250',
              value: InvoiceExportResultTypes.partial
            }
          ]}
        />
      </FormFieldsContainer>

      <Divider style={{ margin: '16px 0' }} />

      {/* title="Criteri di Ricerca" */}
      <FormFieldsContainer columns={4}>
        <FormFieldItem
          colSpan={2}
          labelWidth={labelWidth}
          component={ConfigEnumInput}
          feature="sdi.values"
          enumKey="invoiceType"
          label="Tipo Documento"
          name="invoiceTypeCode"
          placeholder="Tipo Documento"
          dropdownMatchSelectWidth={false}
          allowClear={true}
        />
        <FormFieldItem
          inline
          component={TextInput}
          label="Numero"
          name="invoiceNumber"
          placeholder="Numero"
          allowClear={true}
        />
        <FormFieldItem
          inline
          component={TextInput}
          label="Divisa"
          name="currency"
          placeholder="Divisa"
          allowClear={true}
        />
      </FormFieldsContainer>

      <FormFieldsContainer>
        <FormFieldItem
          labelWidth={labelWidth}
          component={TextInput}
          label="Denominazione"
          name="supplierName"
          placeholder="Denominazione Contraente"
          allowClear={true}
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={2}>
        <FormFieldItem
          labelWidth={labelWidth}
          component={NumberInput}
          label="Numero Protocollo"
          name="protocolNumber"
          placeholder="Numero Protocollo"
        />
        <FormFieldItem
          inline
          component={NumberInput}
          label="Anno"
          name="protocolYear"
          placeholder="Anno Protocollo"
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={2}>
        <FormFieldItem
          labelWidth={labelWidth}
          component={DatePickerInput}
          name="fromDate"
          label="Data Fattura: Da"
          type="from"
          allowClear={true}
        />
        <FormFieldItem
          inline
          component={DatePickerInput}
          name="toDate"
          label="A"
          type="to"
          allowClear={true}
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={2}>
        <FormFieldItem
          labelWidth={labelWidth}
          component={DatePickerInput}
          name="fromRegistrationDate"
          label="Registrazione: Da"
          type="from"
          allowClear={true}
        />
        <FormFieldItem
          inline
          component={DatePickerInput}
          name="toRegistrationDate"
          label="A"
          type="to"
          allowClear={true}
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={2}>
        <FormFieldItem
          labelWidth={labelWidth}
          label="Stato Fattura"
          placeholder="Stato Fattura"
          component={SelectInput}
          options={InvoiceStateOptions}
          name="invoiceState"
          allowClear={true}
        />
        <FormFieldItem
          inline
          label="Stato SDI"
          placeholder="Stato SDI"
          component={SelectInput}
          options={SdiStateOptions}
          name="sdiState"
          allowClear={true}
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={2}>
        <FormFieldItem
          labelWidth={labelWidth}
          component={DatePickerInput}
          name="fromPaymentDate"
          label="Pagamento: Da"
          type="from"
          allowClear={true}
        />
        <FormFieldItem
          inline
          component={DatePickerInput}
          name="toPaymentDate"
          label="A"
          type="to"
          allowClear={true}
        />
      </FormFieldsContainer>

      <FormFieldsContainer columns={3}>
        <FormFieldItem
          labelWidth={labelWidth}
          name="adminReferent"
          label="Referenti"
          component={DocumentalUserSelectInput}
          placeholder="Amministrativo"
          allowClear={true}
        />
        <FormFieldItem
          inline
          name="rupReferent"
          label="RUP"
          component={DocumentalUserSelectInput}
          placeholder="RUP"
          allowClear={true}
        />
        <FormFieldItem
          inline
          name="decReferent"
          label="DEC"
          component={DocumentalUserSelectInput}
          placeholder="DEC"
          allowClear={true}
        />
      </FormFieldsContainer>
    </div>
  );
}
