import { StateTag } from 'client/ui/state/StateTag';
import { ActionCanResult } from 'common/fsm/EntityMachine';
import * as React from 'react';

interface Props {
  result: ActionCanResult;
}

/**
 * Visualizzazione come Tag del messaggio di non possibilità di contabilizzare la fattura
 */
export function AccountingToProtocolAlertTag(props: Props) {
  if (props.result.allowed) return null;
  return <StateTag color="orange" children={props.result.message} />;
}
