import React from 'react';
import { UserApi } from './UserApi';
import { UserDto } from 'common/dto/generated/UserDto';
import { UserListQueryDto } from 'common/dto/query/UserListQueryDto';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import Typography from 'antd/lib/typography';
import { AsyncText } from 'client/ui/form/text/AsyncText';
import { UserQueryDto } from 'common/dto/query/UserQueryDto';
import { SelectProps } from 'antd';

const { Text } = Typography;

interface Props extends SelectProps<any> {
  name: string;
  label?: React.ReactNode;
  query?: UserQueryDto;
  placeholder?: string;
}

// TODO Gestire errori
export function UserSelectInput(props: Props) {
  const {
    name,
    label,
    query: initialQuery,
    placeholder,
    ...otherProps
  } = props;

  const query = initialQuery ?? new UserQueryDto();

  return (
    <AsyncSelectInput
      {...otherProps}
      name={name}
      placeholder={placeholder}
      query={{
        apiFn: UserApi.list,
        options: (_, search) => ({
          data: {
            query: new UserQueryDto({
              ...query,
              documentalUserCode: search
            })
          }
        })
      }}
      optionTransform={(user: UserDto) => {
        return {
          label: `${user.documentalUserCode ?? ''} | ${user.name ?? ''}`,
          value: user.id
        } as any;
      }}
      responseTransform={result => result.items}
      responseMeta={data => ({
        shown: data.items.length,
        total: data.meta.total
      })}
      dropdownMatchSelectWidth={false}
      showSearch
      refreshOnSearch
      allowClear
      {...otherProps}
    />
  );
}

UserSelectInput.renderFieldValue = (value?: number | null) => {
  if (!value) return '-';

  return (
    <AsyncText
      query={{ apiFn: UserApi.find, options: { data: { id: value } } }}
      responseTransform={(user: UserDto) => {
        return `${user.name}`;
      }}
    />
  );
};
