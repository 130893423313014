import { ArrowRightOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { ISODate } from 'client/ui/display/date/ISODate';
import { Link } from 'react-router-dom';
import React from 'react';
import {
  notificationCodeLabels,
  notificationTypeLabels
} from 'common/schema/notification/NotificationLabels';
import { NotificationDirectionSymbol } from '../NotificationDirectionSymbol';
import { NotificationDto } from 'common/dto/generated/NotificationDto';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';

export const NotificationColumns: ColumnsType<NotificationDto> = [
  {
    title: '',
    dataIndex: 'direction',
    width: 35,
    align: 'right',
    render: (_, record) => (
      <NotificationDirectionSymbol direction={record.direction} />
    )
  },
  {
    title: 'Data',
    dataIndex: 'receivedAt',
    sorter: true,
    width: 120,
    ...createColumnFilter(FilterInputType.DateRange),
    render: date => <ISODate date={date} />
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    width: 210,
    render: (_, record) => notificationTypeLabels[record.type]
  },
  {
    title: 'Codice',
    dataIndex: 'code',
    width: 190,
    render: (_, record) =>
      record.code ? notificationCodeLabels[record.code] : ''
  },
  {
    title: 'Descrizione',
    dataIndex: 'description'
  },
  {
    title: '',
    dataIndex: 'azioni',
    align: 'right',
    width: 110,
    render: (_, record) => (
      <Link to={`/invoicing/notifications/${record.id}`}>
        Dettaglio <ArrowRightOutlined />
      </Link>
    )
  }
];
