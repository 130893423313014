import { Tag, TagProps } from 'antd';
import * as React from 'react';

export interface StateTagProps extends TagProps {}

/**
 * Tag generico per centralizzare i Tag di Stato degli elementi
 */
export function StateTag(props: StateTagProps) {
  return <Tag {...props} />;
}
