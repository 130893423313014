import * as React from 'react';
import { Divider } from 'antd';
import { AsyncSelectMeta } from './AsyncSelectInput';
import './AsyncSelectInputMeta.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

export interface IAsyncSelectInputMetaProps {
  meta?: AsyncSelectMeta;
}

/**
 * Dati aggiuntivi per la select asincrona
 */
export function AsyncSelectInputMeta(props: IAsyncSelectInputMetaProps) {
  const { meta } = props;

  if (!meta) return null;

  return (
    <>
      <Divider style={{ margin: '4px 0' }} />
      <div className="async-select-input-meta">
        <div className="async-select-input-meta--icon">
          <InfoCircleOutlined />
        </div>
        <div className="async-select-input-meta--text">
          Mostrati {meta.shown} di {meta.total}.
          {meta.shown < meta.total && (
            <>
              <br />
              Filtrare la ricerca digitando per migliorare i risultati.
            </>
          )}
        </div>
      </div>
    </>
  );
}
