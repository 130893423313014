import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel } from "cli/generate/decorators/SchemaDecorators";
import { InvoiceState, AcceptanceState } from "common/schema/invoice/InvoiceTypes";
import { QueryWhereCondition } from "common/schema/orm/OrmTypes";
import { InvoiceExportResultTypes, IInvoiceExportFieldsSelection } from "./types/InvoiceExportQueryTypes";

@GenerateDto()
class InvoiceExportCreateQuery {
    // TODO Rendere Opzionali
    @SchemaLabel('Data Fattura inizio')
    fromDate?: Date | null;
    @SchemaLabel('Data Fattura fine')
    toDate?: Date | null;

    @SchemaLabel('Modalità Ricerca')
    selectionMode?: QueryWhereCondition | undefined = QueryWhereCondition.And
    @SchemaLabel('Numero Fatture da Esportare')
    resultNumber?: InvoiceExportResultTypes | undefined = InvoiceExportResultTypes.all
    
    @SchemaLabel('Tipo Documento')
    invoiceTypeCode?: string | null;
    @SchemaLabel('Numero Fattura')
    invoiceNumber?: string | null;
    @SchemaLabel('Divisa')
    currency?: string | null;
    @SchemaLabel('Denominazione Contraente')
    supplierName?: string | null;

    @SchemaLabel('Numero Protocollo')
    protocolNumber?: number | null;
    @SchemaLabel('Anno Protocollo')
    protocolYear?: number | null;

    @SchemaLabel('Data Registrazione Fattura inizio')
    fromRegistrationDate?: Date | null;
    @SchemaLabel('Data Registrazione Fattura fine')
    toRegistrationDate?: Date | null;

    @SchemaLabel('Stato Fattura')
    invoiceState?: InvoiceState | null;
    @SchemaLabel('Stato SDI ')
    sdiState?: AcceptanceState | null;

    @SchemaLabel('Data Pagamento inizio')
    fromPaymentDate?: Date | null;
    @SchemaLabel('Data Pagamento fine')
    toPaymentDate?: Date | null;

    @SchemaLabel('Referente Amministrativo')
    adminReferent?: string | null;
    @SchemaLabel('Referente RUP')
    rupReferent?: string | null;
    @SchemaLabel('Referente DEC')
    decReferent?: string | null;

    @SchemaLabel('Campi da esportare')
    fields?: IInvoiceExportFieldsSelection | undefined = {invoice:{}, contract:{}, accounting:{}, lines:{}, mandate:{}, suspending:{}};
}

/**
 * Rappresentazione DTO della classe InvoiceExportCreateQuery 
 * Hash: e6834c590d120095de31460442201a1c
 */
@ValidationSchema(() => InvoiceExportCreateQuerySchema)
export class InvoiceExportCreateQueryDto {
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Fattura inizio' })
  fromDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Fattura fine' })
  toDate?: Date | null | undefined;
  @ApiProperty({ required: false, enum: ['AND', 'OR'], description: 'Modalità Ricerca' })
  selectionMode?: QueryWhereCondition | undefined = QueryWhereCondition.And;
  @ApiProperty({ required: false, enum: ['all', 'partial'], description: 'Numero Fatture da Esportare' })
  resultNumber?: InvoiceExportResultTypes | undefined = InvoiceExportResultTypes.all;
  @ApiProperty({ required: false, type: String, description: 'Tipo Documento' })
  invoiceTypeCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Numero Fattura' })
  invoiceNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Divisa' })
  currency?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Denominazione Contraente' })
  supplierName?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Numero Protocollo' })
  protocolNumber?: number | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Anno Protocollo' })
  protocolYear?: number | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Registrazione Fattura inizio' })
  fromRegistrationDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Registrazione Fattura fine' })
  toRegistrationDate?: Date | null | undefined;
  @ApiProperty({ required: false, enum: ['ToValidate', 'Validated', 'ToAccount', 'Rejected', 'Accounted', 'PaidPartial', 'Paid'], description: 'Stato Fattura' })
  invoiceState?: InvoiceState | null | undefined;
  @ApiProperty({ required: false, enum: ['Accepted', 'Rejected', 'Indeterminate'], description: 'Stato SDI ' })
  sdiState?: AcceptanceState | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Pagamento inizio' })
  fromPaymentDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Pagamento fine' })
  toPaymentDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Referente Amministrativo' })
  adminReferent?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Referente RUP' })
  rupReferent?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Referente DEC' })
  decReferent?: string | null | undefined;
  @ApiProperty({ required: false, type: Object, description: 'Campi da esportare' })
  fields?: IInvoiceExportFieldsSelection | undefined = {invoice:{}, contract:{}, accounting:{}, lines:{}, mandate:{}, suspending:{}};

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceExportCreateQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceExportCreateQueryDto ? values : plainToClass(InvoiceExportCreateQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await InvoiceExportCreateQuerySchema.validate(classToPlain(this), options);
    return new InvoiceExportCreateQueryDto(validated);
  }
}

export const InvoiceExportCreateQuerySchema = yup
  .object({
    fromDate: yup.date().nullable().label('Data Fattura inizio'),
    toDate: yup.date().nullable().label('Data Fattura fine'),
    selectionMode: yup.string().oneOfEnum(QueryWhereCondition).default(QueryWhereCondition.And).label('Modalità Ricerca'),
    resultNumber: yup.string().oneOfEnum(InvoiceExportResultTypes).default(InvoiceExportResultTypes.all).label('Numero Fatture da Esportare'),
    invoiceTypeCode: yup.string().nullable().label('Tipo Documento'),
    invoiceNumber: yup.string().nullable().label('Numero Fattura'),
    currency: yup.string().nullable().label('Divisa'),
    supplierName: yup.string().nullable().label('Denominazione Contraente'),
    protocolNumber: yup.number().nullable().label('Numero Protocollo'),
    protocolYear: yup.number().nullable().label('Anno Protocollo'),
    fromRegistrationDate: yup.date().nullable().label('Data Registrazione Fattura inizio'),
    toRegistrationDate: yup.date().nullable().label('Data Registrazione Fattura fine'),
    invoiceState: yup.string().oneOfEnum(InvoiceState).nullable().label('Stato Fattura'),
    sdiState: yup.string().oneOfEnum(AcceptanceState).nullable().label('Stato SDI '),
    fromPaymentDate: yup.date().nullable().label('Data Pagamento inizio'),
    toPaymentDate: yup.date().nullable().label('Data Pagamento fine'),
    adminReferent: yup.string().nullable().label('Referente Amministrativo'),
    rupReferent: yup.string().nullable().label('Referente RUP'),
    decReferent: yup.string().nullable().label('Referente DEC'),
    fields: yup.mixed().default({invoice:{}, contract:{}, accounting:{}, lines:{}, mandate:{}, suspending:{}}).label('Campi da esportare')
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceExportCreateQuerySchema" })
  .required();
