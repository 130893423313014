import React, { useCallback, useEffect, useState } from 'react';
import { Button, Table, Space, Layout, Typography } from 'antd';
const { Text } = Typography;

import { LinkOutlined, SearchOutlined } from '@ant-design/icons';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { Formik } from 'formik';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TextInput } from 'client/ui/form/input/TextInput';
import { MandateAccountingLineColumns } from '../../accounting-line/table/MandateAccountingLineColumns';
import { BottomDrawer } from 'client/ui/drawer/BottomDrawer';
import { RelationEditor } from 'client/components/form/relations/useRelationEditor';
import { AccountingLineDto } from 'common/dto/generated/AccountingLineDto';
import { AccountingLineQueryDto } from 'common/dto/query/AccountingLineQueryDto';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { AccountingLineApi } from '../../accounting-line/AccountingLineApi';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { MandateDto } from 'common/dto/generated/MandateDto';
import { DocumentalUserSelectInput } from '../../documental/DocumentalUserSelectInput';
import { UserSelectInput } from '../../user/UserSelectInput';
import { UserQueryDto } from 'common/dto/query/UserQueryDto';
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { AccountingLineColumnSort } from 'common/dto/query/types/AccountingLineQueryTypes';

export interface AccountingLinesDrawerProps {
  mandate: MandateDto;
  editor: RelationEditor<AccountingLineDto>;
  isOpen: boolean;
  onClose: () => void;
}

/**
 * Drawer per l'associazione e modifica delle linee di contabilizzazione
 * del mandato
 */
export function AccountingLinesDrawer(props: AccountingLinesDrawerProps) {
  const { editor, isOpen, onClose } = props;

  // Ricerca linee
  const [query, setQuery] = useState(
    new AccountingLineQueryDto({
      pageNumber: 1,
      pageSize: 20,
      canBeLinkedToMandate: true,
      currentMandateId: props.mandate.id
    })
  );
  const { response, loading, error } = useApiQuery(AccountingLineApi.list, {
    data: { query }
  });
  const lines = response?.data.items;

  // Stato della selezione
  const [selected, setSelected] = useState(() => [...editor.items]);
  const rowSelection: TableRowSelection<AccountingLineDto> = {
    selectedRowKeys: selected.map(d => d.id),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows);
    }
  };

  useEffect(() => {
    // Aggiorno la selezione quando cambiano dall'editor
    setSelected([...editor.items]);
  }, [editor.items]);

  // Aggiornamento linee nel mandato
  const handleConfirm = useCallback(() => {
    editor.replaceAll(selected);
    onClose();
  }, [editor, selected, onClose]);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const formContext = useFormikFormContext();
  if (!formContext.editable) return null;

  return (
    <>
      <Formik
        initialValues={query}
        onSubmit={async rawValues => {
          const values = await rawValues.validate();
          setQuery(
            new AccountingLineQueryDto({
              ...query,
              ...values
            })
          );
        }}
        onReset={() => {
          setQuery(
            new AccountingLineQueryDto({
              pageNumber: query.pageNumber,
              pageSize: query.pageSize,
              canBeLinkedToMandate: true,
              currentMandateId: props.mandate.id
            })
          );
        }}
      >
        <BottomDrawer
          title="Associazione delle linee di contabilizzazione al mandato"
          onClose={handleCancel}
          visible={isOpen}
          key="bottom"
          footer={
            <Space style={{ float: 'right' }}>
              <Button key="back" onClick={handleCancel}>
                Annulla
              </Button>
              <Button
                icon={<LinkOutlined />}
                type="primary"
                onClick={handleConfirm}
              >
                Collega Linee Selezionate
              </Button>
            </Space>
          }
          sider={
            <FormikForm editable={true} helpInTooltips showVisualFeedback>
              <Space direction="vertical" style={{ maxWidth: '100%' }}>
                <Text strong>Cerca Linee Contabili</Text>
                <FormFieldsContainer>
                  <FormFieldItem
                    labelWidth="width-70"
                    component={TextInput}
                    label="Fornitore"
                    name="supplierName"
                    placeholder="Fornitore"
                  />
                  <FormFieldItem
                    labelWidth="width-70"
                    component={TextInput}
                    label="CIG"
                    name="cigCode"
                    placeholder="CIG"
                  />
                  <FormFieldItem
                    labelWidth="width-70"
                    component={TextInput}
                    label="CUP"
                    name="cupCode"
                    placeholder="CUP"
                  />
                  <FormFieldItem
                    component={UserSelectInput}
                    label="Referente"
                    labelWidth="width-70"
                    name="adminReferentId"
                    placeholder="Codice utente"
                    query={
                      new UserQueryDto({
                        excludeAdminRoles: true,
                        orderBy: 'name'
                      })
                    }
                  />
                </FormFieldsContainer>
                <Space style={{ float: 'right' }}>
                  <Button htmlType="reset">Reset</Button>
                  <FormikSendButton icon={<SearchOutlined />} type="primary">
                    Cerca
                  </FormikSendButton>
                </Space>
              </Space>
            </FormikForm>
          }
        >
          <Table
            rowSelection={rowSelection}
            rowKey="id"
            columns={MandateAccountingLineColumns}
            dataSource={lines}
            size="middle"
            onChange={(pagination, filters, sorter) => {
              const {
                field,
                order
              } = sorter as SorterResult<AccountingLineDto>;

              setQuery(
                new AccountingLineQueryDto({
                  ...query,
                  orderBy: order
                    ? (field?.toString() as AccountingLineColumnSort)
                    : AccountingLineColumnSort.id,
                  orderByDirection: order
                    ? ColumnSortDirections[order]
                    : ColumnSortDirections.descend,
                  pageNumber: pagination.current ?? query.pageNumber,
                  pageSize: pagination.pageSize ?? query.pageSize
                })
              );
            }}
            pagination={{
              size: 'small',
              position: ['bottomRight'],
              showSizeChanger: true,
              total: response?.data.meta.total,
              pageSize: query.pageSize,
              current: query.pageNumber,
              pageSizeOptions: ['5', '10', '20', '30', '40']
            }}
          />
        </BottomDrawer>
      </Formik>
    </>
  );
}
