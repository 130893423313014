import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from "client/ui/table/TableColumnSort";
import { PccRowState } from "common/schema/pcc/pcc-row/PccRowTypes";


@GenerateDto()
class PccRowQuery {
  @SchemaPageNumber()
  pageNumber!: number;

  @SchemaPageSize()
  pageSize!: number;

  @SchemaLabel("Stato della linea di esportazione")
  state?: PccRowState | null;

  @SchemaLabel("Data fine")
  toDate?: Date | null;

  @SchemaLabel("Ordinamento")
  orderBy?: string = 'id';

  @SchemaLabel("Direzione di Ordinamento")
  orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;
  
  @SchemaLabel('Ricerca Identificativo')
  identifier?: string | null;
}

/**
 * Rappresentazione DTO della classe PccRowQuery 
 * Hash: a06b73a83910ac7d9edc0606445956ee
 */
@ValidationSchema(() => PccRowQuerySchema)
export class PccRowQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, enum: ['toImport', 'error', 'notFound', 'imported'], description: 'Stato della linea di esportazione' })
  state?: PccRowState | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data fine' })
  toDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Ordinamento' })
  orderBy?: string | undefined = 'id';
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;
  @ApiProperty({ required: false, type: String, description: 'Ricerca Identificativo' })
  identifier?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<PccRowQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof PccRowQueryDto ? values : plainToClass(PccRowQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await PccRowQuerySchema.validate(classToPlain(this), options);
    return new PccRowQueryDto(validated);
  }
}

export const PccRowQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    state: yup.string().oneOfEnum(PccRowState).nullable().label('Stato della linea di esportazione'),
    toDate: yup.date().nullable().label('Data fine'),
    orderBy: yup.string().default('id').label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento'),
    identifier: yup.string().nullable().label('Ricerca Identificativo')
  })
  .noUnknown()
  .meta({ schemaName: "PccRowQuerySchema" })
  .required();
