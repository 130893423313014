import { WarningOutlined } from '@ant-design/icons';
import { TagProps } from 'antd';
import { DoubleTag, TooltipTagProps } from 'client/ui/tag/DoubleTag';
import { AmountsCongruenceType } from 'common/schema/mandate/AmountsCongruenceType';
import * as React from 'react';

export interface IAmountsCongruenceTagProps {
  congruence: AmountsCongruenceType;
}

/**
 * Visualizzazione come Tag dell'incongruenza con il totale del mandato
 * degli importi delle linee di contabilizzazione collegate
 */
export function AmountsCongruenceTag(props: IAmountsCongruenceTagProps) {
  if (props.congruence !== AmountsCongruenceType.IncongruentAmounts) {
    return null;
  }

  return (
    <DoubleTag
      left={{ color: 'red', children: <WarningOutlined />, visible: true }}
      right={{ color: 'red', children: 'Importi Incongruenti', visible: true }}
    />
  );
}
