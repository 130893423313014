import * as React from 'react';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { DownloadOutlined } from '@ant-design/icons';
import { PageHeading } from 'client/ui/layout/page/heading/PageHeading';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { RouteComponentProps } from 'react-router';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { BreadcrumbsContainer } from 'client/ui/breadcrumb/BreadcrumbsContainer';
import { PccExportSection } from 'client/components/schema/accounting-line/report/edit/PccExportSection';
import { PccExportApi } from 'client/components/schema/pcc/PccExportApi';
import { getPccExportDownloadLink } from 'client/components/schema/pcc/getPccExportDownloadLink';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { AnchorAPILink } from 'client/ui/link/AnchorAPILink';
import { PccResponseModalButton } from './PccResponseModalButton';
import { PccRowSection } from '../section/PccRowSection';
import { PccExportStateTag } from 'client/components/schema/pcc/PccExportStateTag';
import { PccResponseSection } from '../section/PccResponseSection';

interface Params {
  id: string;
  filter?: string;
}

export interface PCCExportPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina di dettaglio della report PCC (file csv)
 */
export function PccExportPage(props: PCCExportPageProps) {
  const id = parseInt(props.match.params.id, 10);

  const { response, error, loading } = useApiQuery(PccExportApi.find, {
    data: { id }
  });
  const pccExport = response?.data;

  useBreadcrumbItem({ path: '/', title: `Home` });
  useBreadcrumbItem({
    path: '/accounting/lines/all',
    title: `Contabilizzazione`
  });
  useBreadcrumbItem({
    priority: 20,
    path: props.location.pathname,
    title: `Report #${id}`,
    sideMenuKey: props.match.params.filter ?? 'shipped-accounting',
    menuKey: 'accounting'
  });

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere al Report"
        error={error}
      />
    );
  }

  if (!pccExport || loading) return <LoadingPage />;

  return (
    <CorePageLayout>
      <PageHeading
        title={<PageHeadingTitle>Dettaglio Comunicazione PCC</PageHeadingTitle>}
        tags={<PccExportStateTag pccExport={pccExport} />}
        breadcrumbRender={() => <BreadcrumbsContainer />}
        extra={
          <>
            <AnchorAPILink
              showAsButton
              href={getPccExportDownloadLink(pccExport.id)}
              icon={<DownloadOutlined />}
            >
              Download Report PCC
            </AnchorAPILink>
            {!pccExport.responseId && <PccResponseModalButton id={id} />}
          </>
        }
      />
      <PageContent fixedWidht>
        {/*  Dati Generali  */}
        <PccExportSection pccExport={pccExport} />

        {/* SEZIONE Dati Importazione */}
        <PccResponseSection response={pccExport.response} />

        {/* SEZIONE Linee esportazione PCC */}
        <PccRowSection pccExportId={id} model={pccExport.model} />
      </PageContent>
    </CorePageLayout>
  );
}
