import { ColumnsType } from 'antd/lib/table';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import { ShipmentColumns } from './ShipmentColumns';

export const ShipmentDetailColumns: ColumnsType<ShipmentDto> = [
  ShipmentColumns.number,
  ShipmentColumns.state,
  ShipmentColumns.mandateBeneficiary,
  ShipmentColumns.dossier,
  ShipmentColumns.shipmentDescription
];
