import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownloadOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { Button, Col, message, Result, Row, Space, Steps } from 'antd';
const { Step } = Steps;
import { InvoiceApi } from 'client/components/schema/invoice/InvoiceApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { ModalFooter } from 'client/ui/modal/ModalFooter';
import {
  InvoiceExportCreateQueryDto,
  InvoiceExportCreateQuerySchema
} from 'common/dto/query/InvoiceExportCreateQueryDto';
import React, { useCallback, useState } from 'react';
import { History } from 'history';
import { InvoiceExportSearchForm } from './InvoiceExportSearchForm';
import { StepContent } from 'client/ui/steps/StepContent';
import { InvoiceExportFieldsSelection } from './InvoiceExportFieldsSelection';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { Link, useHistory } from 'react-router-dom';
import { getInvoiceExportDownloadLink } from './getInvoiceExportDownloadLink';
import { AnchorAPILink } from 'client/ui/link/AnchorAPILink';
import { getFieldsInitialValue } from './getFieldsInitialValue';
import moment from 'moment';

export enum ExportResultFlag {
  Warning = 'Warning'
}

export interface InvoiceExportPageProps {
  history?: History;
  isMenu?: boolean;
}

export function InvoiceExportModal(props: InvoiceExportPageProps) {
  const [isVisible, setIsVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [fileId, setFileId] = useState<number | ExportResultFlag | undefined>();

  const [createExport, createExportState] = useApiMutation(
    InvoiceApi.export.create,
    { invalidates: [InvoiceApi.export.list] }
  );
  const history = useHistory();

  const handleCancel = useCallback(() => {
    if (createExportState.loading) return;
    setIsVisible(false);
    setCurrentStep(0);
  }, [createExportState, setIsVisible, setCurrentStep, setFileId]);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  const goToFilePage = () => {
    if (!fileId) return;
    history.push(`/invoicing/exports/${fileId}`);
    setIsVisible(false);
    setCurrentStep(0);
  };

  const initialValues = new InvoiceExportCreateQueryDto({
    fields: getFieldsInitialValue(),
    protocolYear: moment().year()
  });

  return (
    <>
      {props.isMenu ? (
        <span onClick={() => setIsVisible(true)}>
          <DownloadOutlined /> Esporta Fatture
        </span>
      ) : (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => setIsVisible(true)}
        >
          Esporta Fatture
        </Button>
      )}

      <ModalDialog
        width={1200}
        style={{ top: 20, minWidth: '1200px' }}
        // title="Esportazione Fatture"
        title={
          <Row align="middle" gutter={24}>
            <Col flex="0 0 auto">Esportazione Fatture</Col>
            <Col flex="1 1 0">
              <Steps size="small" current={currentStep}>
                <Step title="Criteri di Ricerca" />
                <Step title="Selezione Campi da Esportare" />
                <Step title="Download File" />
              </Steps>
            </Col>
            <Col flex="0 0 60px" style={{ height: '10px', width: '60px' }} />
          </Row>
        }
        visible={isVisible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={!createExportState.loading}
      >
        <FormikAugmented<InvoiceExportCreateQueryDto>
          initialValues={initialValues}
          onSubmit={async rawValues => {
            const input = await rawValues.validate();
            setCurrentStep(currentStep + 1);
            try {
              const response = await createExport({ data: { input } });
              setFileId(response.data.id);
              if (props.history) {
                message.success('File generato correttamente.');
                props.history.push(`/invoicing/exports/${response.data.id}`);
              }
            } catch (error: any) {
              // Gateway Timeout Exception
              if (
                error.code === 'ECONNABORTED' ||
                error.message === 'Network Error' ||
                error.response.status === 504
              ) {
                setFileId(ExportResultFlag.Warning);
                return;
              }

              setFileId(undefined);
              message.error('Si è verificato un errore.');
            }
          }}
          validationContext={{}}
          validationSchema={InvoiceExportCreateQuerySchema}
          validateOnChange={true}
          validateOnBlur={true}
          validateOnMount={false}
        >
          <FormikForm
            editable={true}
            disabled={createExportState.loading}
            helpInTooltips
          >
            <StepContent step={0} current={currentStep}>
              <InvoiceExportSearchForm />

              <ModalFooter>
                <Button
                  key="back-0"
                  onClick={handleCancel}
                  disabled={createExportState.loading}
                >
                  Annulla
                </Button>

                <Button
                  key="next-0"
                  type="primary"
                  ghost
                  onClick={nextStep}
                  disabled={createExportState.loading}
                >
                  Successivo <ArrowRightOutlined />
                </Button>
              </ModalFooter>
            </StepContent>

            <StepContent
              step={1}
              current={currentStep}
              style={{ marginTop: '-16px' }}
            >
              <InvoiceExportFieldsSelection />

              <ModalFooter>
                <Button
                  key="back-1"
                  onClick={handleCancel}
                  disabled={createExportState.loading}
                >
                  Annulla
                </Button>
                <Button
                  key="prev-1"
                  type="primary"
                  ghost
                  onClick={prevStep}
                  disabled={createExportState.loading}
                >
                  <ArrowLeftOutlined /> Precedente
                </Button>

                <FormikSendButton
                  icon={<FileTextOutlined />}
                  type="primary"
                  key="submit"
                  loading={createExportState.loading}
                >
                  Genera Esportazione
                </FormikSendButton>
              </ModalFooter>
            </StepContent>
            <StepContent step={2} current={currentStep}>
              {createExportState.loading ? (
                <div style={{ minHeight: '300px', position: 'relative' }}>
                  <LoaderSpin full />
                </div>
              ) : (
                <div style={{ minHeight: '300px' }}>
                  <Result
                    status={
                      fileId === ExportResultFlag.Warning
                        ? 'info'
                        : fileId != null
                        ? 'success'
                        : 'error'
                    }
                    title={
                      fileId === ExportResultFlag.Warning ? (
                        <>
                          La generazione impiegherà più tempo del previsto.
                          <br />
                          Appena l'operazione sarà conclusa, il file sarà
                          disponibile nell'
                          <Link to="/invoicing/exports">
                            Elenco delle esportazioni fatture
                          </Link>
                          .
                        </>
                      ) : fileId != null ? (
                        'File generato correttamente!'
                      ) : (
                        'Errore nella generazione del File.'
                      )
                    }
                    subTitle={null}
                    extra={
                      fileId === ExportResultFlag.Warning ? (
                        <></>
                      ) : fileId != null ? (
                        <Space>
                          <Button key="back-2" onClick={handleCancel}>
                            Chiudi
                          </Button>
                          <AnchorAPILink
                            showAsButton
                            href={getInvoiceExportDownloadLink(fileId)}
                            icon={<DownloadOutlined />}
                            type="primary"
                          >
                            Download Esportazione Fatture
                          </AnchorAPILink>
                        </Space>
                      ) : (
                        <Space>
                          <Button key="close-2" onClick={handleCancel}>
                            Chiudi
                          </Button>
                          <Button
                            key="prev-2"
                            type="primary"
                            ghost
                            onClick={() => setCurrentStep(0)}
                            disabled={createExportState.loading}
                          >
                            <ArrowLeftOutlined /> Riprova
                          </Button>
                        </Space>
                      )
                    }
                  />
                </div>
              )}
            </StepContent>
          </FormikForm>
        </FormikAugmented>
      </ModalDialog>
    </>
  );
}
