import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { ContractDto } from './ContractDto';

/**
 * Rappresentazione DTO della classe ContractAttachment definita in: src/server/schema/contract/attachment/ContractAttachment.entity.ts
 * Hash: 7ed0075e033f5fe8cd0b225bef500c2b
 */
@ValidationSchema(() => ContractAttachmentSchema)
export class ContractAttachmentDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ type: String, description: 'Descrizione' })
  description!: string;
  @ApiProperty({ required: false, type: String, description: 'Nome File' })
  filename?: string | null | undefined;
  @ApiProperty({ type: Boolean, description: 'Necessita firma' })
  signedRequired!: boolean;
  @ApiProperty({ required: false, type: String, description: 'Path' })
  path?: string | null;
  @ApiProperty({ required: false, type: Number, description: 'Fornitore' })
  contractId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => ContractDto })
  @Type(() => ContractDto)
  contract?: ContractDto | null;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data Creazione' })
  createdAt?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String })
  migrationId?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ContractAttachmentDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ContractAttachmentDto ? values : plainToClass(ContractAttachmentDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ContractAttachmentSchema.validate(classToPlain(this), options);
    return new ContractAttachmentDto(validated);
  }
}

/** Interfaccia simmetrica al DTO ContractAttachmentDto */
export type IContractAttachmentType = IDto<ContractAttachmentDto>;

/**
 * DTO Paginato della classe ContractAttachment
 */
export class PaginatedContractAttachmentDto {
  @ApiProperty({ type: [ContractAttachmentDto] })
  @Type(() => ContractAttachmentDto)
  items!: ContractAttachmentDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const ContractAttachmentSchema = yup
  .object({
    id: yup.number(),
    description: yup.string().required().max(100).label('Descrizione'),
    filename: yup.string().nullable().label('Nome File'),
    signedRequired: yup.boolean().required().label('Necessita firma'),
    path: yup.string().nullable().label('Path'),
    contractId: yup.number().nullable().required().label('Fornitore')
  })
  .noUnknown()
  .meta({ schemaName: "ContractAttachmentSchema" })
  .required();
