import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthActions, useAuth, useCurrentUser } from '../AuthModule';
import { Spin, Alert } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { UserApi } from 'client/components/schema/user/UserApi';
import { useLocation } from 'react-router';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';

export interface IAuthCurrentLoaderProps {
  children: React.ReactNode;
}

const SkipHttpStatuses = [400, 401];

/**
 * Caricamento dell'utente corrente. Serve anche per controllare come side
 * effect se l'utente è loggato, in quanto i token sono salvati su
 * cookie HTTP-Only e non sono quindi accessibili da JS.
 */
export function AuthCurrentLoader(props: IAuthCurrentLoaderProps) {
  const dispatch = useDispatch();
  const { logged } = useAuth();
  const current = useCurrentUser();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null as any);
  const [errorType, setErrorType] = useState('error' as 'error' | 'info');

  useEffect(() => {
    let isMounted = true;

    const fetchCurrent = async () => {
      try {
        setLoading(true);
        const result = await UserApi.findCurrentUser({});
        dispatch(AuthActions.currentLoaded(result.data));
      } catch (e) {
        // Gestiamo il caso in cui il componente è stato ricaricato
        if (!isMounted) return;

        if (e && e.response && SkipHttpStatuses.includes(e.response.status)) {
          // Semplicemente non autorizzato, lasciamo la gestione al
          // `refreshTokenInterceptor`.
        } else if (e.response.status === 302) {
          setErrorType('info');
          setError(getNetworkErrorMessage(e, 'Reindirizzamento in corso...'));
        } else {
          setErrorType('error');
          setError(
            getNetworkErrorMessage(
              e,
              "Si è verificato un errore durante il caricamento dell'utente."
            )
          );
        }
      }
      setLoading(false);
    };

    // Se l'utente non è loggato, inutile provare il caricamento.
    // TODO/LOW Gestire meglio, perché altrimenti con la strategia OpenAM non
    // funziona proprio. Probabilmente anche con Windows?
    // if (!logged) {
    //   setLoading(false);
    //   return;
    // }

    fetchCurrent();

    return () => {
      isMounted = false;
    };
  }, [logged]);

  if (loading) return <LoadingPage />;

  if (error) {
    return (
      <NetworkAlertPage message="Impossibile caricare l'utente" error={error} />
    );
  }
  return <>{props.children}</>;
}
