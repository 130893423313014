import { ColumnsType } from 'antd/lib/table';
import { ShipmentDto } from 'common/dto/generated/ShipmentDto';
import { ShipmentColumns } from './ShipmentColumns';

export const ShipmentOriginalDetailColumns: ColumnsType<ShipmentDto> = [
  ShipmentColumns.number,
  ShipmentColumns.state,
  ShipmentColumns.shipDate,
  ShipmentColumns.mandateBeneficiary,
  ShipmentColumns.dossier
];
