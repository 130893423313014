import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';

/**
 * Rappresentazione DTO della classe InvoiceClientData definita in: src/server/schema/invoice/invoice/invoice-data/InvoiceClientData.embedded.ts
 * Hash: 374c412bf84556719d16f91c1dab9aaa
 */
@ValidationSchema(() => InvoiceClientDataSchema)
export class InvoiceClientDataDto {
  @ApiProperty({ required: false, type: String })
  name?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  contactName?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  contactSurname?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  fiscalIdCountry?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  fiscalIdCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  fiscalNumber?: string | null | undefined;
  @ApiProperty({ type: String })
  hqAddress!: string;
  @ApiProperty({ required: false, type: String })
  hqCivic?: string | null | undefined;
  @ApiProperty({ type: String })
  hqZip!: string;
  @ApiProperty({ type: String })
  hqCity!: string;
  @ApiProperty({ required: false, type: String })
  hqProvince?: string | null | undefined;
  @ApiProperty({ type: String })
  hqCountry!: string;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceClientDataDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceClientDataDto ? values : plainToClass(InvoiceClientDataDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await InvoiceClientDataSchema.validate(classToPlain(this), options);
    return new InvoiceClientDataDto(validated);
  }
}

/** Interfaccia simmetrica al DTO InvoiceClientDataDto */
export type IInvoiceClientDataType = IDto<InvoiceClientDataDto>;

/**
 * DTO Paginato della classe InvoiceClientData
 */
export class PaginatedInvoiceClientDataDto {
  @ApiProperty({ type: [InvoiceClientDataDto] })
  @Type(() => InvoiceClientDataDto)
  items!: InvoiceClientDataDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const InvoiceClientDataSchema = yup
  .object({
    name: yup.string().nullable(),
    contactName: yup.string().nullable(),
    contactSurname: yup.string().nullable(),
    fiscalIdCountry: yup.string().nullable(),
    fiscalIdCode: yup.string().nullable(),
    fiscalNumber: yup.string().nullable(),
    hqAddress: yup.string().required(),
    hqCivic: yup.string().nullable(),
    hqZip: yup.string().required(),
    hqCity: yup.string().required(),
    hqProvince: yup.string().nullable(),
    hqCountry: yup.string().required()
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceClientDataSchema" })
  .required();
