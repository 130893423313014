import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { ShipmentPriority, ShipmentState } from 'common/schema/shipment/ShipmentTypes';
import { MandateDto, MandateSchema } from './MandateDto';
import { ValidationAction } from 'common/validation/ValidationAction';
import schema from 'yup/lib/schema';

/**
 * Rappresentazione DTO della classe Shipment definita in: src/server/schema/accounting/shipment/Shipment.entity.ts
 * Hash: 52e504b1ea131144caca7bf395bab503
 */
@ValidationSchema(() => ShipmentSchema)
export class ShipmentDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: Number })
  number?: number | null | undefined;
  @ApiProperty({ required: false, type: String })
  migrationId?: string | null | undefined;
  @ApiProperty({ type: String, description: 'Oggetto' })
  subject!: string;
  @ApiProperty({ required: false, type: String })
  description?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Id Autore' })
  authorId!: number;
  @ApiProperty({ required: false, type: String, description: 'Destinatario' })
  recipient?: string | null | undefined;
  @ApiProperty({ required: false, enum: ['Low', 'Medium', 'High', 'Highest'], description: 'Priorità' })
  priority?: ShipmentPriority | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Tipo di Provvedimento' })
  checkKind?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Oggetto Provvedimento' })
  checkSubject?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Descrizione Provvedimento' })
  checkDescription?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Descrizione Ulteriore Provvedimento' })
  checkDescriptionDetail?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Mandato' })
  mandateId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => MandateDto })
  @Type(() => MandateDto)
  mandate?: MandateDto | null;
  @ApiProperty({ type: Boolean })
  isIntegration!: boolean;
  @ApiProperty({ required: false, type: Number })
  originalShipmentId?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Classificazione' })
  classification?: string | null | undefined;
  @ApiProperty({ required: false, type: () => ShipmentDto })
  @Type(() => ShipmentDto)
  originalShipment?: ShipmentDto | null;
  @ApiProperty({ type: Boolean })
  isDuplicated!: boolean;
  @ApiProperty({ required: false, type: String })
  protocolMigrationId?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  protocolUid?: string | null | undefined;
  @ApiProperty({ required: false, type: Number })
  protocolYear?: number | null | undefined;
  @ApiProperty({ required: false, type: String })
  protocolNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  dossierUid?: string | null | undefined;
  @ApiProperty({ required: false, type: Number })
  dossierYear?: number | null | undefined;
  @ApiProperty({ required: false, type: String })
  dossierNumber?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  dossierCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date' })
  shipDate?: Date | null | undefined;
  @ApiProperty({ required: false, enum: ['InProgress', 'Shipped'] })
  state!: ShipmentState;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  deletedAt?: Date | null;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ShipmentDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ShipmentDto ? values : plainToClass(ShipmentDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ShipmentSchema.validate(classToPlain(this), options);
    return new ShipmentDto(validated);
  }
}

/** Interfaccia simmetrica al DTO ShipmentDto */
export type IShipmentType = IDto<ShipmentDto>;

/**
 * DTO Paginato della classe Shipment
 */
export class PaginatedShipmentDto {
  @ApiProperty({ type: [ShipmentDto] })
  @Type(() => ShipmentDto)
  items!: ShipmentDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const ShipmentSchema = yup
  .object({
    id: yup.number(),
    number: yup.number().nullable(),
    subject: yup.string().required().max(2000).needsAction('edit').label('Oggetto'),
    description: yup.string().nullable().needsAction('edit'),
    authorId: yup.number().label('Id Autore'),
    recipient: yup.string().nullable().needsAction('edit').label('Destinatario'),
    priority: yup.string().oneOfEnum(ShipmentPriority).nullable().needsAction('edit').label('Priorità'),
    checkKind: yup.string().nullable().required().needsAction('edit').label('Tipo di Provvedimento'),
    checkSubject: yup.string().nullable().needsAction('edit').label('Oggetto Provvedimento'),
    checkDescription: yup.string().nullable().needsAction('edit').label('Descrizione Provvedimento'),
    checkDescriptionDetail: yup.string().nullable().needsAction('edit').label('Descrizione Ulteriore Provvedimento'),
    mandateId: yup.number().nullable()
          .when(['checkKind', '$kindWithMandate'], {
            is: (checkKind: string, kindWithMandate?: string[]) =>
              kindWithMandate?.includes(checkKind),
            then: schema =>
              schema.required('È necessario collegare un mandato alla spedizione.'),
            otherwise: schema => schema.notRequired()
          })
          // Elimina il campo in caso di update di spedizioni integrative
          .when(['isIntegration', '$action'], {
            is: (isIntegration: boolean, action: string) =>
              isIntegration && action === ValidationAction.edit,
            then: (schema: any) => schema.notRequired().strip()
          }).needsAction('edit').label('Mandato'),
    isIntegration: yup.boolean().required(),
    originalShipmentId: yup.number().nullable(),
    classification: yup.string().nullable().required().needsAction('edit').label('Classificazione'),
    isDuplicated: yup.boolean().required().default(false),
    protocolMigrationId: yup.string().nullable(),
    state: yup.string().oneOfEnum(ShipmentState).default(ShipmentState.InProgress)
  })
  .noUnknown()
  .meta({ schemaName: "ShipmentSchema" })
  .required();
