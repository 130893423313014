import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';

/**
 * Rappresentazione DTO della classe InvoiceIssuerData definita in: src/server/schema/invoice/invoice/invoice-data/InvoiceIssuerData.embedded.ts
 * Hash: 5d70f59e5e6efec7ea7f935563fe40d8
 */
@ValidationSchema(() => InvoiceIssuerDataSchema)
export class InvoiceIssuerDataDto {
  @ApiProperty({ required: false, type: String })
  name?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  contactName?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  contactSurname?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  fiscalIdCountry?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  fiscalIdCode?: string | null | undefined;
  @ApiProperty({ required: false, type: String })
  fiscalNumber?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<InvoiceIssuerDataDto>) {
    if (values != null) {
      Object.assign(this, values instanceof InvoiceIssuerDataDto ? values : plainToClass(InvoiceIssuerDataDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await InvoiceIssuerDataSchema.validate(classToPlain(this), options);
    return new InvoiceIssuerDataDto(validated);
  }
}

/** Interfaccia simmetrica al DTO InvoiceIssuerDataDto */
export type IInvoiceIssuerDataType = IDto<InvoiceIssuerDataDto>;

/**
 * DTO Paginato della classe InvoiceIssuerData
 */
export class PaginatedInvoiceIssuerDataDto {
  @ApiProperty({ type: [InvoiceIssuerDataDto] })
  @Type(() => InvoiceIssuerDataDto)
  items!: InvoiceIssuerDataDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const InvoiceIssuerDataSchema = yup
  .object({
    name: yup.string().nullable(),
    contactName: yup.string().nullable(),
    contactSurname: yup.string().nullable(),
    fiscalIdCountry: yup.string().nullable(),
    fiscalIdCode: yup.string().nullable(),
    fiscalNumber: yup.string().nullable()
  })
  .noUnknown()
  .meta({ schemaName: "InvoiceIssuerDataSchema" })
  .required();
