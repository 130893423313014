import { CheckOutlined } from '@ant-design/icons';
import { Button, message, Typography } from 'antd';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { InfoBox } from 'client/ui/info-box/InfoBox';
import { InfoBoxActions } from 'client/ui/info-box/InfoBoxActions';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import * as React from 'react';
import { useCallback } from 'react';
import { InvoiceApi } from '../InvoiceApi';

const { Title, Text, Paragraph } = Typography;

export interface IInvoiceValidateInfoBoxProps {
  invoice: InvoiceDto;
}

export function InvoiceValidateInfoBox(props: IInvoiceValidateInfoBoxProps) {
  const actionable = InvoiceMachine.can(props.invoice, 'state.validate', true);

  if (!actionable.allowed && actionable.skipped) return null;

  return (
    <InfoBoxActions
      title="Associazione Fattura"
      text={
        actionable.allowed ? (
          <>
            Tutti i campi richiesti sono presenti. È possibile segnare la
            fattura come "Associata" per escluderla dall'elenco "Da Associare" e
            procedere all'Accettazione/Rifiuto e alla Protocollazione.
          </>
        ) : (
          <ul>
            {actionable.errors?.map(error => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        )
      }
    />
  );
}
