import { MailOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ShipmentApi } from 'client/components/schema/shipment/ShipmentApi';
import { ShipmentOriginalDetailColumns } from 'client/components/schema/shipment/table/ShipmentOriginalDetailColumns';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { Section } from 'client/ui/section/Section';
import { MandateDto } from 'common/dto/generated/MandateDto';
import { ShipmentQueryDto } from 'common/dto/query/ShipmentQueryDto';
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface IShipmentSectionProps {
  mandate: MandateDto;
}

export function ShipmentSection(props: IShipmentSectionProps) {
  const { mandate } = props;

  const [query, setQuery] = useState(
    new ShipmentQueryDto({
      pageNumber: 1,
      pageSize: 10,
      mandateId: mandate.id
    })
  );

  const { response, loading, error } = useApiQuery(ShipmentApi.list, {
    data: { query }
  });
  const shipments = response?.data?.items ?? [];

  return (
    <Section icon={<MailOutlined />} title="Spedizioni UBRRAC">
      <Table
        columns={[
          ...ShipmentOriginalDetailColumns,
          {
            title: '',
            dataIndex: 'azioni',
            align: 'right',
            render: (_, record) => (
              <Link to={`/shipping/all/${record.id}`}>
                Vai <ArrowRightOutlined />
              </Link>
            )
          }
        ]}
        rowKey="id"
        dataSource={shipments}
        size="middle"
        onChange={pagination => {
          setQuery(
            new ShipmentQueryDto({
              ...query,
              pageNumber: pagination.current || query.pageNumber,
              pageSize: pagination.pageSize || query.pageSize
            })
          );
        }}
        pagination={{
          size: 'small',
          position: ['bottomRight'],
          showSizeChanger: true,
          total: response?.data.meta.total,
          pageSize: query.pageSize,
          current: query.pageNumber,
          pageSizeOptions: ['5', '10', '20', '30', '40']
        }}
      />
    </Section>
  );
}
