import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaPageNumber, SchemaPageSize, SchemaLabel } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from "client/ui/table/TableColumnSort";
import { ShipmentState } from "common/schema/shipment/ShipmentTypes";


@GenerateDto()
class ShipmentQuery {
    @SchemaPageNumber()
    pageNumber!: number;
    
    @SchemaPageSize()
    pageSize!: number;

    @SchemaLabel('Stato')
    state?: ShipmentState; 

    @SchemaLabel("Data inizio")
    fromDate?: Date | null;
    @SchemaLabel("Data fine")
    toDate?: Date | null;

    @SchemaLabel("Da data creazione")
    creationFromDate?: Date | null;
    @SchemaLabel("A data creazione")
    creationToDate?: Date | null;

    @SchemaLabel("Beneficiario mandato")
    beneficiary?: string | null;

    @SchemaLabel("Descrizione")
    description?: string | null;

    @SchemaLabel("Destinatario")
    recipient?: string | null;

    @SchemaLabel("Numero")
    number?: number | null;

    @SchemaLabel("Ordinamento")
    orderBy?: string = 'number';

    @SchemaLabel("Direzione di Ordinamento")
    orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;

    @SchemaLabel('Id Mandato')
    mandateId?: number | null;
}

/**
 * Rappresentazione DTO della classe ShipmentQuery 
 * Hash: b516c991e858a5f7f87a7269605caae0
 */
@ValidationSchema(() => ShipmentQuerySchema)
export class ShipmentQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, enum: ['InProgress', 'Shipped'], description: 'Stato' })
  state?: ShipmentState | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data inizio' })
  fromDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Data fine' })
  toDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Da data creazione' })
  creationFromDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'A data creazione' })
  creationToDate?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Beneficiario mandato' })
  beneficiary?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Descrizione' })
  description?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Destinatario' })
  recipient?: string | null | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Numero' })
  number?: number | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Ordinamento' })
  orderBy?: string | undefined = 'number';
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;
  @ApiProperty({ required: false, type: Number, description: 'Id Mandato' })
  mandateId?: number | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ShipmentQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ShipmentQueryDto ? values : plainToClass(ShipmentQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ShipmentQuerySchema.validate(classToPlain(this), options);
    return new ShipmentQueryDto(validated);
  }
}

export const ShipmentQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    state: yup.string().oneOfEnum(ShipmentState).label('Stato'),
    fromDate: yup.date().nullable().label('Data inizio'),
    toDate: yup.date().nullable().label('Data fine'),
    creationFromDate: yup.date().nullable().label('Da data creazione'),
    creationToDate: yup.date().nullable().label('A data creazione'),
    beneficiary: yup.string().nullable().label('Beneficiario mandato'),
    description: yup.string().nullable().label('Descrizione'),
    recipient: yup.string().nullable().label('Destinatario'),
    number: yup.number().nullable().label('Numero'),
    orderBy: yup.string().default('number').label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento'),
    mandateId: yup.number().nullable().label('Id Mandato')
  })
  .noUnknown()
  .meta({ schemaName: "ShipmentQuerySchema" })
  .required();
