import { apiClient } from 'client/core/network/apiClient';
import {
  EconomicEntryDto,
  PaginatedEconomicEntryDto
} from 'common/dto/generated/EconomicEntryDto';

export const EconomicEntryApi = {
  find: (opts: { id: number }) =>
    apiClient
      .requestWithResponseType(EconomicEntryDto)
      .get(`/economic-entries/${opts.id}`),

  create: (opts: { input: EconomicEntryDto }) =>
    apiClient
      .requestWithResponseType(EconomicEntryDto)
      .post(`/economic-entries/`, opts.input),

  update: (opts: { id: number; input: EconomicEntryDto }) =>
    apiClient
      .requestWithResponseType(EconomicEntryDto)
      .put(`/economic-entries/${opts.id}`, opts.input),

  list: () =>
    apiClient
      .requestWithResponseType(PaginatedEconomicEntryDto)
      .get('/economic-entries'),

  delete: (opts: { id: number }) =>
    apiClient.request().delete(`/economic-entries/${opts.id}`)
};
