import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaPageNumber, SchemaPageSize } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { Model003Action } from 'server/schema/pcc/model003/Model003Types';
import { PccModelType } from 'server/schema/pcc/pcc-model-base/PccTypes';

@GenerateDto()
class PccExportQuery {
    @SchemaPageNumber()
    pageNumber!: number;
    @SchemaPageSize()
    pageSize!: number;

    @SchemaLabel('Azione')
    action!: Model003Action | null | undefined;

    @SchemaLabel('Modello')
    model!: PccModelType | null | undefined;

    @SchemaLabel("Ordinamento")
    orderBy?: string = 'createdAt';
    @SchemaLabel("Direzione di Ordinamento")
    orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;
}

/**
 * Rappresentazione DTO della classe PccExportQuery 
 * Hash: aa93ea67ee2e29841e1184f38a7a7436
 */
@ValidationSchema(() => PccExportQuerySchema)
export class PccExportQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Numero di pagina' })
  pageNumber!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione pagina' })
  pageSize!: number;
  @ApiProperty({ required: false, enum: ['CO', 'CP', 'CS'], description: 'Azione' })
  action?: Model003Action | null | undefined;
  @ApiProperty({ required: false, enum: ['002', '003'], description: 'Modello' })
  model?: PccModelType | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Ordinamento' })
  orderBy?: string | undefined = 'createdAt';
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<PccExportQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof PccExportQueryDto ? values : plainToClass(PccExportQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await PccExportQuerySchema.validate(classToPlain(this), options);
    return new PccExportQueryDto(validated);
  }
}

export const PccExportQuerySchema = yup
  .object({
    pageNumber: yup.number().default(1).min(1).integer().positive().label('Numero di pagina'),
    pageSize: yup.number().default(20).min(10).max(50).integer().positive().label('Dimensione pagina'),
    action: yup.string().oneOfEnum(Model003Action).nullable().label('Azione'),
    model: yup.string().oneOfEnum(PccModelType).nullable().label('Modello'),
    orderBy: yup.string().default('createdAt').label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento')
  })
  .noUnknown()
  .meta({ schemaName: "PccExportQuerySchema" })
  .required();
