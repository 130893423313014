import * as React from 'react';
import './StepContent.scss';

export interface StepContentProps {
  current: number;
  step: number;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

/**
 * Contenitore per un singolo step
 * viene renderizzato solo se lo step è quello corrente
 *
 */
export function StepContent(props: StepContentProps) {
  const { children, current, step, ...othersProps } = props;
  if (step != current) return null;
  return (
    <div className="step-content" {...othersProps}>
      {children}
    </div>
  );
}
