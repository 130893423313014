import { AxiosPromise, AxiosResponse } from 'axios';
import { useState, useEffect, useCallback } from 'react';
import { objToKey } from 'client/core/hooks/objToKey';
import { useNetworkContext } from '../context/NetworkContext';
import { filter } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

export type ApiGet<T extends {}, R> = (data: T) => AxiosPromise<R>;

export interface ApiQueryOptions<T extends {}, R> {
  skip?: boolean;
  dependsOn?: object;
  onLoad?: (response: AxiosResponse<R>) => void;
  onInvalidate?: () => void;
  data: T;
  cast?: new () => R;
}

export function useApiQuery<T extends {}, R>(
  apiFn: ApiGet<T, R>,
  options: ApiQueryOptions<T, R>
) {
  const [response, setResponse] = useState(
    undefined as AxiosResponse<R> | undefined
  );
  const [error, setError] = useState(false as boolean | any);
  const [loading, setLoading] = useState(false);
  const [refetchId, setRefetchId] = useState(0);
  const network = useNetworkContext();

  // Gestione del fetch in base ai parametri.
  useEffect(() => {
    const fetch = async () => {
      setError(false);
      setLoading(true);

      try {
        const result = await apiFn(options.data);
        // if (options.cast) {
        //   result.data = plainToClass(options.cast as any, result.data);
        // }
        setResponse(result);
        setLoading(false);
        if (options.onLoad) options.onLoad(result);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    if (!options.skip) fetch();
  }, [objToKey(options), refetchId]);

  const refetch = () => setRefetchId(refetchId + 1);

  // Gestione del ricaricamento in base all'invalidamento di una query
  useEffect(() => {
    const invalidatorSub = network.invalidator
      .pipe(filter(invalidate => invalidate.queries.includes(apiFn)))
      .subscribe(invalidate => {
        refetch();
        options.onInvalidate?.();
      });

    // Unsubscribe
    return () => {
      invalidatorSub.unsubscribe();
    };
  }, [refetchId]);

  // Reset
  const setPristine = useCallback(() => {
    setResponse(undefined);
    setLoading(false);
  }, [setResponse, setLoading]);

  return { response, error, loading, refetch, setPristine };
}
