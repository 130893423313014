import React, { useCallback, useState } from 'react';
import { Modal, Button, message, Tooltip } from 'antd';

import { BookOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { Formik } from 'formik';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ModalFooter } from 'client/ui/modal/ModalFooter';
import { TextInput } from 'client/ui/form/input/TextInput';
import { InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import {
  ProtocolInvoiceOptionsDto,
  ProtocolInvoiceOptionsSchema
} from 'common/dto/query/ProtocolInvoiceOptionsDto';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { InvoiceApi } from '../InvoiceApi';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { InvoiceMachine } from 'common/schema/invoice/InvoiceMachine';
import { DataItem } from 'client/ui/form/field/DataItem';
import { ISODate } from 'client/ui/display/date/ISODate';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { ContractApi } from '../../contract/ContractApi';
import moment from 'moment';
import { ProtocolResponseBag } from 'common/dto/response-bag/ProtocolResponseBag';

const invoiceType = (invoice: InvoiceDto) => {
  return invoice.invoiceTypeCode === 'TD04' ? 'Nota di credito' : 'Fattura';
};

export interface InvoiceProtocolModalProps {
  invoice: InvoiceDto;
  setErrorBag: (bag: ProtocolResponseBag) => void;
  partial?: boolean;
}

/**
 * Modal per Protocollazione e invio al RUF della Fattura
 */
export function InvoiceProtocolModal(props: InvoiceProtocolModalProps) {
  const [isVisible, setIsVisible] = useState(false);

  const { invoice, partial } = props;

  const { response: contractResponse } = useApiQuery(ContractApi.find, {
    skip: invoice.contractId == null,
    data: {
      id: invoice.contractId!
    }
  });

  const [protocol, protocolState] = useApiMutation(
    InvoiceApi.actions.protocol,
    { invalidates: [InvoiceApi.find] }
  );

  const actionable = InvoiceMachine.can(
    invoice,
    partial ? 'edit.protocol:partial' : 'edit.protocol',
    true
  );

  const handleConfirm = useCallback(
    () =>
      new Promise((resolve, reject) => {
        Modal.confirm({
          style: { top: '150px' },
          title: 'Sei sicuro di voler protocollare la fattura?',
          icon: <ExclamationCircleOutlined />,
          okText: 'Protocolla',
          cancelText: 'Annulla',
          content:
            'A seguito della trasmissione non sono più consentite modifiche ai dati della fattura. Questa operazione non può essere annullata. Confermi l’invio della fattura al Protocollo e al Registro Unico delle Fatture?',
          onOk() {
            resolve(true);
          },
          onCancel() {
            resolve(false);
          }
        });
      }),
    [invoice]
  );

  const handleCancel = useCallback(() => {
    if (protocolState.loading) return;
    setIsVisible(false);
  }, [protocolState, setIsVisible]);

  // DTO di base per protocollare
  const initialValues = new ProtocolInvoiceOptionsDto({
    protocolSubject:
      invoice.protocolSubject ??
      invoiceType(invoice) +
        ' n.' +
        `${invoice.issuedNumber}` +
        ' del ' +
        `${moment(invoice.issuedDate).format('DD/MM/YYYY')}` +
        ' di ' +
        `${invoice.supplier?.getName()}`,
    classification: invoice.protocolClassification ?? '4.5.2', // TODO da configurazione
    vatRelevant:
      invoice.protocolVatRelevant == null ? true : invoice.protocolVatRelevant
  });

  if (!actionable.allowed && actionable.skipped) return null;

  const contract = contractResponse?.data;

  return (
    <>
      <Tooltip title={!actionable.allowed && actionable.message}>
        <Button
          type="primary"
          icon={<BookOutlined />}
          ghost
          size="large"
          disabled={!actionable.allowed}
          onClick={() => setIsVisible(true)}
        >
          Protocolla
        </Button>
      </Tooltip>
      <ModalDialog
        size="small"
        title="Protocollazione Fattura ed invio al RUF"
        visible={isVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Formik<ProtocolInvoiceOptionsDto>
          initialValues={initialValues}
          onSubmit={async rawValues => {
            const isConfirmed = await handleConfirm();
            if (!isConfirmed) return;
            let input: ProtocolInvoiceOptionsDto;
            try {
              input = await rawValues.validate();
            } catch (e: any) {
              message.error(e.message);
              return;
            }

            try {
              const result = await protocol({
                data: { id: invoice.id, input }
              });
              message.success(`La fattura è stata protocollata correttamente.`);
              props.setErrorBag(result.data);
              setIsVisible(false);
            } catch (e) {
              message.error(
                'Si è verificato un errore durante la protocollazione.'
              );
            }
          }}
          validate={schemaToValidator(ProtocolInvoiceOptionsSchema)}
          validateOnChange={false}
        >
          <FormikForm
            editable={!partial}
            disabled={protocolState.loading}
            helpInTooltips
          >
            <FormFieldsContainer
              title="Dati Protocollazione"
              removeTopTitleSpacing
            >
              <FormFieldItem
                component={TextAreaInput}
                // TODO generare oggetto fattura in base al invoiceTypeCode
                name="protocolSubject"
                label="Oggetto"
                placeholder="Oggetto"
                rows={2}
              />
              <DataItem
                ghost
                label="Mittente"
                value={invoice.supplier?.getName()}
              />
              <FormFieldItem
                component={TextInput}
                name="classification"
                label="Classificazione"
                placeholder="Classificazione"
              />
              <FormFieldItem
                component={CheckboxInput}
                name="vatRelevant"
                label="IVA"
                children="Spesa rilevante ai fini IVA"
              />
              <DataItem
                label="Referente"
                value={contract?.adminReferent?.name ?? '-'}
              />
              {contract?.adminReferentRole && (
                <DataItem
                  label="Ruolo Referente"
                  value={contract?.adminReferentRole ?? '-'}
                />
              )}
              <DataItem
                label="RUP"
                value={contract?.rupReferent?.name ?? '-'}
              />
              {contract?.rupReferentRole && (
                <DataItem
                  label="Ruolo RUP"
                  value={contract?.rupReferentRole ?? '-'}
                />
              )}
              <DataItem
                label="DEC"
                value={contract?.decReferent?.name ?? '-'}
              />
              {contract?.decReferentRole && (
                <DataItem
                  label="Ruolo DEC"
                  value={contract?.decReferentRole ?? '-'}
                />
              )}
            </FormFieldsContainer>
            <FormFieldsContainer title="Dati Registro Unico Fatture">
              <DataItem label="Numero" value={invoice.issuedNumber ?? '-'} />
              <DataItem
                label="Data"
                value={<ISODate date={invoice.issuedDate} />}
              />
              <DataItem label="Codice CIG" value={contract?.cigCode ?? '-'} />
              <DataItem label="Codice CUP" value={contract?.cupCode ?? '-'} />
            </FormFieldsContainer>

            <ModalFooter>
              <Button
                key="back"
                onClick={handleCancel}
                disabled={protocolState.loading}
              >
                Annulla
              </Button>
              <FormikSendButton
                icon={<BookOutlined />}
                type="primary"
                key="submit"
                loading={protocolState.loading}
              >
                Invia a Protocollo e RUF
              </FormikSendButton>
            </ModalFooter>
          </FormikForm>
        </Formik>
      </ModalDialog>
    </>
  );
}
