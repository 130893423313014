import { IInvoiceType, InvoiceDto } from 'common/dto/generated/InvoiceDto';
import { IDto } from 'common/dto/shared/DtoInterfaces';
import moment from 'moment';
import { DebtState } from '../accounting-line/AccountingLineTypes';
import { AcceptanceState, InvoiceState } from './InvoiceTypes';

export class InvoiceLogic {
  /**
   * Ritorna `true` se la fattura passata richiede revisione (fattura "scaduta non pagata").
   *
   * DATA una fattura scaduta
   * 1. NON HA linee di contabilizzazione
   * 2. OPPURE SE invece ha linee: ALMENO UNA linea deve essere *non pagata* E deve essere *liquidabile* E non è stata *comunicata*
   */
  static needRevision(invoice: IInvoiceType): boolean {
    const lastDayPreviousMonth = moment()
      .clone()
      .subtract(1, 'months')
      .endOf('month');

    // La fattura deve essere scaduta
    const expiredConstraint =
      invoice.expiresAt &&
      moment(invoice.expiresAt).isBefore(lastDayPreviousMonth);

    // La fattura non deve essere revisionata
    const revisionConstraint = !invoice.revisionDate;

    // La fattura deve essere non contabilizzata, o contabilizzata ma non ancora pagata
    const toAccountConstraint = invoice.state === InvoiceState.ToAccount;

    // La fattura deve essere contabilizzata ma con linee non pagate e liquidabili
    const linesConstraint = invoice.accountingLines?.some(
      line =>
        !line.pccExportedAccounting &&
        [DebtState.LIQ, DebtState.LIQdaNL, DebtState.LIQdaSOSP].includes(line.debtState) && 
        (!line.paymentDate || moment(line.paymentDate).isAfter(lastDayPreviousMonth))
    ); // prettier-ignore

    //
    return !!(
      expiredConstraint &&
      revisionConstraint &&
      (toAccountConstraint || linesConstraint)
    );
  }

  /**
   * Genera il testo dinamico di alert per la sezione Accounting della fattura.
   * NB È necessario che la fattura necessiti dell'alert, altrimenti ritorna una stringa "sporca"
   */
  static getInvoiceAccountingAlert(invoice: IDto<InvoiceDto>) {
    var alertText = `Per contabilizzare la fattura è necessario che sia`;
    var needAndText = false;

    // Se è da accettare
    if (invoice.acceptance !== AcceptanceState.Accepted) {
      alertText += ` accettata`;
      needAndText = true;
    }

    // Se è da protocollare
    if (invoice.protocolNumber == null) {
      alertText += needAndText ? ` e protocollata` : ` protocollata`;
      needAndText = true;
    }

    // Se mancano le date di Esecuzione e Collaudo
    if (invoice.executionDate == null || invoice.testingDate == null) {
      alertText += needAndText
        ? ` e siano presenti le date di Esecuzione e Collaudo`
        : /*sia*/ `no presenti le date di Esecuzione e Collaudo`;
    }

    return alertText;
  }

  /**
   * Controlla se è possibile modificare i riepiloghi IVA o le linee di una fattura.
   * Sono editabili solo le fatture _cartacee_ in uno stato non successivo
   * alla contabilizzazione.
   */
  static canEditVatSummariesAndLines(invoice: IDto<InvoiceDto>) {
    const vatEditStates = [
      InvoiceState.ToValidate,
      InvoiceState.Validated,
      InvoiceState.ToAccount,
      InvoiceState.Accounted
    ];

    return (
      !invoice.isSDI() && vatEditStates.some(state => state === invoice.state)
    );
  }
}
