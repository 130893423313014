import * as React from 'react';
import { AuditOutlined } from '@ant-design/icons';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { RadioInput } from 'client/ui/form/input/RadioInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Section } from 'client/ui/section/Section';
import {
  ContractLevel,
  ContractType,
  TermsKind
} from 'common/schema/contract/ContractTypes';
import { DocumentInput } from 'client/ui/form/input/dedicated/DocumentInput';
import { CurrencyInput } from 'client/ui/form/input/CurrencyInput';
import { DataItem } from 'client/ui/form/field/DataItem';
import { ConfigEnumInput } from 'client/ui/form/input/configurable/ConfigEnumInput';
import {
  ClassificationOptions,
  contractTypeOptions,
  PaymentTermsKindOptions
} from 'common/schema/contract/ContractLabels';
import { ContractRegisterItem } from 'client/components/schema/contract/view/ContractRegisterItem';
import { ContractDto } from 'common/dto/generated/ContractDto';
import { ExcludeCigForm } from './components/ExcludeCigForm';
import { useFormikContext } from 'formik';

export interface ContractGeneralSectionProps {
  contract: ContractDto;
}

export function ContractGeneralSection(props: ContractGeneralSectionProps) {
  const formik = useFormikContext<ContractDto>();
  return (
    <Section icon={<AuditOutlined />} title="Dati Generali" hasPadding>
      {/** DATI GENERALI CONTRATTO */}
      <FormFieldsContainer columns={4}>
        <FormFieldItem
          component={TextInput}
          colSpan={3}
          label="Oggetto"
          name="subject"
          placeholder="Oggetto"
        />
        <FormFieldItem
          inline
          component={NumberInput}
          colSpan={1}
          label="Numero"
          name="number"
          placeholder="Numero"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          component={TextAreaInput}
          label="Dettaglio"
          name="notes"
          placeholder="Dettaglio Oggetto"
          rows={2}
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={2}>
        <FormFieldItem
          component={CurrencyInput}
          label="Importo Totale"
          name="totalAmount"
          placeholder="Importo complessivo"
        />
        <FormFieldItem
          inline
          component={CurrencyInput}
          label="Importo Netto"
          name="netAmount"
          placeholder="Importo complessivo al netto IVA"
        />
      </FormFieldsContainer>

      {/** DATI DEL DOCUMENTO e PROTOCOLLO */}
      <FormFieldsContainer title="Tipologia Documento" columns={3}>
        <FormFieldItem
          label="Tipo"
          component={SelectInput}
          name="contractType"
          placeholder="Tipo Contratto"
          options={contractTypeOptions}
        />
        <FormFieldItem
          inline
          label="Tipo Atto"
          component={SelectInput}
          name="contractClassification"
          placeholder="Seleziona Tipologia Atto..."
          options={ClassificationOptions}
        />

        <FormFieldItem
          inline
          label="Livello"
          component={RadioInput}
          name="contractLevel"
          options={[
            {
              label: 'Collegato',
              value: ContractLevel.Connected
            },
            {
              label: 'Quadro',
              value: ContractLevel.Framework
            },
            {
              label: 'Semplice',
              value: ContractLevel.Simple
            }
          ]}
        />
      </FormFieldsContainer>

      {/* CODICI IDENTIFICATVI */}
      <FormFieldsContainer columns={3}>
        <FormFieldItem
          component={TextInput}
          label="CIG"
          name="cigCode"
          placeholder={
            !formik.values.excludeCig ? 'Codice Identificativo Gara' : ''
          }
          disabled={formik.values.excludeCig}
        />
        <FormFieldItem
          inline
          component={TextInput}
          label="CUP"
          name="cupCode"
          placeholder="Codice Unico Progetto"
          allowClear
        />
        <FormFieldItem
          inline
          component={TextInput}
          label="CIP"
          name="cipCode"
          placeholder="Codice Identificativo Pratica"
          allowClear
        />
      </FormFieldsContainer>
      {/** Form esclusione CIG */}
      <ExcludeCigForm />

      {/** Collegamento a Documentale (PROTOCOLLO) con input dedicato MODAL */}
      <FormFieldsContainer title="Collegamento a Documentale" columns={6}>
        <FormFieldItem
          colSpan={2}
          component={DocumentInput}
          label="Registrazione N."
          name="documentNumber"
          placeholder="Numero"
          protocolData={props.contract}
          // TODO Nella query di collegamento al documentale valorizzare il campo documentalUID per il link a PiTre
        />
        <ContractRegisterItem label="Registro" colSpan={2} />
        <FormFieldItem
          inline
          component={NumberInput}
          label="Numero"
          name="documentNumber"
          placeholder="Numero"
          readOnly
        />
        <FormFieldItem
          inline
          component={NumberInput}
          label="Anno"
          name="documentYear"
          placeholder="Anno"
          readOnly
        />
      </FormFieldsContainer>

      {/* DATE DI RIFERIMENTO */}
      <FormFieldsContainer title="Date Riferimento" columns={2}>
        <FormFieldItem
          labelWidth="width-180"
          component={DatePickerInput}
          type="from"
          label="Data Contratto"
          name="registrationDate"
          placeholder="Data Contratto"
        />
        <FormFieldItem
          inline
          component={DatePickerInput}
          type="from"
          label="Data Scadenza"
          name="expireDate"
          placeholder="Data Scadenza"
        />
        <FormFieldItem
          labelWidth="width-180"
          component={CheckboxInput}
          label="D.lgs. 231/2002"
          children="Soggetto al D.lgs. 231/2002"
          name="isDlgs2312002"
          defaultChecked={false}
        />
        <FormFieldItem
          inline
          component={CheckboxInput}
          label="Completamento"
          children="Completata"
          name="isCompleted"
          defaultChecked={false}
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={2}>
        <FormFieldItem
          labelWidth="width-180"
          component={NumberInput}
          label="Termini di pagamento (gg)"
          name="paymentTermsDays"
          placeholder="n. di giorni"
        />
        <FormFieldItem
          inline
          label="Tipo giorni"
          component={RadioInput}
          name="paymentTermsKind"
          options={PaymentTermsKindOptions}
        />
        <FormFieldItem
          labelWidth="width-180"
          component={NumberInput}
          label="Termini di Collaudo (gg)"
          name="testingTermsDays"
          placeholder="n. di giorni"
        />
        <FormFieldItem
          inline
          label="Tipo giorni"
          component={RadioInput}
          name="testingTermsKind"
          options={[
            {
              label: 'Solari',
              value: TermsKind.SolarDays
            },
            {
              label: 'Lavorativi Lun-Ven',
              value: TermsKind.WorkingDaysFriday
            },
            {
              label: 'Lavorativi Lun-Sab',
              value: TermsKind.WorkingDaysSaturday
            }
          ]}
        />
      </FormFieldsContainer>

      {/** ANAC */}
      <FormFieldsContainer title="ANAC" columns={2}>
        <FormFieldItem
          colSpan={2}
          component={ConfigEnumInput}
          feature="contract.values"
          enumKey="anacProcedureKind"
          dropdownMatchSelectWidth={600}
          label="Tipo Procedura"
          name="anacProcedureKind"
          placeholder="Tipo Procedura ANAC"
        />
        <FormFieldItem
          inline
          component={TextInput}
          label="Numero di Procedura"
          name="anacNumber"
          placeholder="Numero di Procedura ANAC"
        />

        <FormFieldItem
          inline
          component={DatePickerInput}
          type="from"
          label="Data Atto"
          name="anacDate"
          placeholder="Data Registrazione"
        />
      </FormFieldsContainer>
    </Section>
  );
}
