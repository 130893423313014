import React from 'react';
import { DocumentalProtocolLink } from './DocumentalProtocolLink';

export interface ProtocolSwitchField {
  documentUid?: string | null | undefined;
  documentNumber?: number | null | undefined;
  documentYear?: number | null | undefined;
  protocolUid?: number | null;
  protocolNumber?: number | null;
  protocolYear?: number | null;
}

interface DocumentalSwitchLinkProps {
  protocolData: ProtocolSwitchField;
}

/**
 * Switch per adattare i dati registrati e creare il link al protocollo del Documentale
 */
export function DocumentalSwitchLink(props: DocumentalSwitchLinkProps) {
  const {
    documentNumber,
    documentYear,
    documentUid,
    protocolUid,
    protocolNumber
  } = props.protocolData;

  // in caso sia presente il protocollUid (InvoiceDto) passiamo i dati direttamente al componente
  if (protocolUid || protocolNumber) {
    return <DocumentalProtocolLink protocolData={props.protocolData} />;
  }
  // Altrimenti per i contratti (ContractDto) adattiamo i dati
  return (
    <DocumentalProtocolLink
      protocolData={{
        protocolNumber: documentNumber,
        protocolYear: documentYear,
        protocolUid: Number(documentUid)
      }}
    />
  );
}
