import { ColumnsType } from 'antd/lib/table';
import { ISODate } from 'client/ui/display/date/ISODate';
import { createTypedColumns } from 'client/ui/table/createTypedColumns';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { ActivityLogDto } from 'common/dto/generated/ActivityLogDto';
import React from 'react';

export const ActivityLogColumns = createTypedColumns(ActivityLogDto, {
  createdAt: {
    title: 'Data',
    dataIndex: 'createdAt',
    width: 160,
    ...createColumnFilter(FilterInputType.DateRange),
    sorter: true,
    render: date => <ISODate date={date} format={'DD/MM/YYYY HH:mm'} />
  },
  author: {
    title: 'Autore',
    dataIndex: 'author',
    width: 220,
    render: (_, record) => `${record.user.name}`
  },
  description: {
    title: 'Descrizione',
    dataIndex: 'description'
  }
});

export const ActivityLogTableColumn = [
  ActivityLogColumns.createdAt,
  ActivityLogColumns.author,
  ActivityLogColumns.description
];
