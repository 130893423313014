import { TagProps } from 'antd';
import { StateTag } from 'client/ui/state/StateTag';
import { AccountingCongruenceType } from 'common/schema/invoice/AccountingCongruenceType';
import * as React from 'react';

export interface IAccountingCongruenceTagProps {
  congruence: AccountingCongruenceType;
}

function congruenceToProps(congruence: AccountingCongruenceType): TagProps {
  switch (congruence) {
    case AccountingCongruenceType.ToAccount:
      return { visible: false };
    case AccountingCongruenceType.CongruentAmounts:
      return { visible: false };
    case AccountingCongruenceType.IncongruentAmounts:
      return { color: 'red', children: 'Importi Incongruenti', visible: true };
  }
}

/**
 * Visualizzazione come Tag dell'incongruenza con il totale della fattura
 * degli importi delle linee di contabilizzazione
 */
export function AccountingCongruenceTag(props: IAccountingCongruenceTagProps) {
  return <StateTag {...congruenceToProps(props.congruence)} />;
}
