import React from 'react';
import clx from 'classnames';
import { Menu } from 'antd';
const { SubMenu } = Menu;
import {
  CalculatorOutlined,
  SendOutlined,
  TableOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useCurrentSideMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { Roles } from 'common/permissions/Roles';

export interface InvoicingMenuProps {
  classNames?: string;
}

/**
 * Menu laterale della sezione Contabilizzazione.
 *
 */
export function AccountingSideMenu(props: InvoicingMenuProps) {
  const currentPathKey = useCurrentSideMenuKey();
  const user = useCurrentUser();

  return (
    <Menu
      className={props.classNames}
      mode="inline"
      defaultSelectedKeys={['all']}
      selectedKeys={currentPathKey}
      defaultOpenKeys={['accounting', 'to-ship', 'report']}
    >
      <SubMenu
        key="accounting"
        title="Linee Contabili"
        icon={<CalculatorOutlined />}
      >
        {/* Linee Contabili per Stato della linea */}
        <Menu.Item key="lines-all">
          <Link to="/accounting/lines/all">Tutte</Link>
        </Menu.Item>
        <Menu.Item key="lines-not-payable">
          <Link to="/accounting/lines/not-payable">Non Pagabili</Link>
        </Menu.Item>
        <Menu.Item key="lines-to-ship">
          <Link to="/accounting/lines/to-ship">Da Spedire</Link>
        </Menu.Item>
        <Menu.Item key="lines-to-pay">
          <Link to="/accounting/lines/to-pay">Spedite</Link>
        </Menu.Item>
        <Menu.Item key="lines-paid">
          <Link to="/accounting/lines/paid">Pagate</Link>
        </Menu.Item>
      </SubMenu>

      {/* Report per esportazioni PCC */}
      {user && Roles[user.role].hasPermission('pcc.exports.read') && (
        <>
          <SubMenu
            key="to-ship"
            title="Da comunicare PCC"
            icon={<SendOutlined />}
          >
            <Menu.Item key="to-ship-reception">
              <Link to="/accounting/to-ship/reception">Ricezione Cartacee</Link>
            </Menu.Item>
            <Menu.Item key="to-ship-accounting">
              <Link to="/accounting/to-ship/accounting">Contabilizzazione</Link>
            </Menu.Item>
            <Menu.Item key="to-ship-expiration">
              <Link to="/accounting/to-ship/expiration">Scadenze</Link>
            </Menu.Item>
            <Menu.Item key="to-ship-payment">
              <Link to="/accounting/to-ship/payment">Pagamenti</Link>
            </Menu.Item>
          </SubMenu>
          {/* Archivio Report PCC */}
          <SubMenu key="report" title="Report" icon={<TableOutlined />}>
            <Menu.Item key="shipped-reception">
              <Link to="/accounting/pcc-exports/shipped-reception">
                Ricezione Cartacee
              </Link>
            </Menu.Item>
            <Menu.Item key="shipped-accounting">
              <Link to="/accounting/pcc-exports/shipped-accounting">
                Contabilizzazione
              </Link>
            </Menu.Item>
            <Menu.Item key="shipped-payment">
              <Link to="/accounting/pcc-exports/shipped-payment">
                Pagamento
              </Link>
            </Menu.Item>
            <Menu.Item key="shipped-expiration">
              <Link to="/accounting/pcc-exports/shipped-expiration">
                Scadenza
              </Link>
            </Menu.Item>
            <Menu.Item key="shipping-response">
              <Link to="/accounting/pcc-exports/shipping-response">
                Risposte PCC
              </Link>
            </Menu.Item>
          </SubMenu>
        </>
      )}
    </Menu>
  );
}
