import {
  DebtState,
  ExpenseNature
} from 'common/schema/accounting-line/AccountingLineTypes';

export enum PccRowState {
  /** Da importare */
  ToImport = 'toImport',
  /** Con errore */
  Error = 'error',
  /** Importazione linea non presente nell'export generato */
  NotFound = 'notFound',
  /** Correttamente Importata  */
  Imported = 'imported'
}

export const PccRowStateMap = [
  { value: PccRowState.ToImport, label: 'Da Importare', color: 'orange' },
  { value: PccRowState.Error, label: 'Errore', color: 'red' },
  { value: PccRowState.NotFound, label: 'Non riconosciuta', color: 'red' },
  {
    value: PccRowState.Imported,
    label: 'Importata Correttamente',
    color: 'green'
  }
];

export interface PccRowIdentifier {
  invoiceNumber: string;
  invoiceDate: string;
  totalAmount?: string | null;
  supplierFiscalId?: string | null;
  lineAmount?: string | null;
  lineExpenseNature?: ExpenseNature | null;
  debtState?: DebtState | null;
}

export enum PccRowReferenceType {
  Invoice = 'invoice',
  AccountingLine = 'accountingLine'
}
