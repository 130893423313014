import * as React from 'react';
import { InvoiceSupplierDataDto } from 'common/dto/generated/InvoiceSupplierDataDto';

export interface ISupplierAddressProps {
  supplier: InvoiceSupplierDataDto;
}

/**
 * Anteprima indirizzo del fornitore
 */
export function SupplierAddress(props: ISupplierAddressProps) {
  const supplier = props.supplier;
  return (
    <span>
      {supplier.hqAddress}
      {supplier.hqCivic ? `, ${supplier.hqCivic}` : ''} - {supplier.hqZip}{' '}
      {supplier.hqCity}
      {supplier.hqProvince ? ` (${supplier.hqProvince})` : ''}
    </span>
  );
}
