import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { Tag, Typography } from 'antd';
import { IEconomicEntryNode } from 'common/schema/economic-entry/createTreeFromEconomicEntries';
const { Text } = Typography;

export const EconomicEntryColumns: ColumnsType<IEconomicEntryNode> = [
  {
    title: 'Codice di Conto Economico',
    dataIndex: 'title',
    render: (_, record) =>
      record.type === 'Leaf' ? (
        <div>
          <Text code style={{ marginRight: '15px', marginLeft: '5px' }}>
            {record.entry.code}
          </Text>
          <Text strong>{record.entry.title}</Text>
        </div>
      ) : (
        <span>{record.label}</span>
      )
  },
  {
    title: 'Codice PCM',
    dataIndex: 'chapterCode',
    width: 120,
    render: (_, record) =>
      record.type == 'Leaf' ? (
        <div style={{ width: '50px', textAlign: 'right' }}>
          {record.entry.pcmCode ?? ''}
        </div>
      ) : (
        <span></span>
      )
  }
];
